@mixin cardsTitle {
	font-size: 16px;
	font-family: "basiercircle";
	font-weight: 600;
	line-height: 20px;
}

@mixin tabsTitle {
	font-size: 16px;
	font-family: "basiercircle";
	font-weight: 400;
	line-height: 20px;
}

@mixin profileContent {
	font-size: 14px;
	font-family: "basiercircle";
	font-weight: 400;
	line-height: 18px;
}

@mixin profileName {
	font-size: 20px;
	font-family: "basiercircle";
	font-weight: 600;
	line-height: 28px;
}

@mixin status {
	font-size: 13px;
	font-family: "basiercircle";
	font-weight: 400;
	line-height: 16px;
}

@mixin layoutItems {
	font-size: 16px;
	font-family: "basiercircle";
	font-weight: 600;
	line-height: 20px;
}

@mixin userName {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
}

@mixin paginationText {
	font-family: "basiercircle";
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

@mixin quickText {
	font-size: 16px;
	font-family: "basiercircle";
	font-weight: 400;
	line-height: 20px;
}

@mixin alertText {
	font-size: 14px;
	font-family: "basiercircle";
	font-weight: 400;
	line-height: 20px;
}
