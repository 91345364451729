@import "modules/candidate/Profile/Variables/styles.scss";

.btnAdd {
	display: flex;
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	span {
		@include profileContent;
		color: $swamp;
		font-weight: 500;
		margin-left: 6px;
	}
}

.botIcon {
	margin: 0 0 0 7px;
}
