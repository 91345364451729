.scheduleHeader {
	display: flex;
	gap: 6px;
	color: #334155;
	margin-bottom: 20px;
}
.scheduleTitle {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-top: -2px;
}
.scheduleList {
	display: flex;
	flex-direction: column;
	gap: 8px;

	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.skeleton {
	margin-bottom: 8px;
}
.scheduleItem {
	font-family: "Work Sans";
	font-style: normal;
	line-height: 150%;
	background: white;
	padding: 6px 8px 6px 8px;
	border-radius: 6px;

	&.red {
		color: #f04438;
	}
	&.yellow {
		color: #f59e0b;
	}
	&.green {
		color: #00857a;
	}

	svg {
		color: currentColor;
	}
	.badge {
		background: color-mix(in srgb, currentColor, transparent 90%);
	}

	.headline {
		color: inherit;
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 6px;
		.name {
			color: #334155;
			font-weight: 500;
			font-size: 12px;
		}
		.badge {
			color: currentColor;
			font-size: 12px;
			font-weight: 500;
			height: 18px;
			line-height: 100%;
			display: flex;
			align-items: center;
			padding: 0 6px;
			border-radius: 99px;
		}
	}
	.datetime {
		display: flex;
		align-items: center;
		gap: 10px;
		.date,
		.time {
			display: flex;
			align-items: center;
			gap: 6px;
			span {
				color: #94a3b8;
				font-size: 14px;
				font-weight: 400;
				line-height: 100%;
			}
		}
	}

	.status {
		color: #64748b;
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 10px;
		font-style: italic;
		font-weight: 400;
		line-height: 150%;
		&.confirmed {
			color: #00857a;
			font-weight: 500;
		}
	}
}
