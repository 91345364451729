@import "../../Variables/styles.scss";

@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: $blackPearl;
}
@mixin alignCenter {
	display: flex;
	align-items: center;
}

.root {
	position: absolute;
	background: $primayBackground;
	border: 1px solid $snow;
	box-sizing: border-box;
	box-shadow: 0px 0px 2px rgba(24, 24, 25, 0.04),
		0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198),
		0px 2.01027px 6.0308px rgba(0, 0, 0, 0.0417275),
		0px 1.12694px 3.38082px rgba(0, 0, 0, 0.035),
		0px 0.598509px 1.79553px rgba(0, 0, 0, 0.0282725),
		0px 0.249053px 0.747159px rgba(0, 0, 0, 0.0196802);
	border-radius: $raduisButton;
	top: 45px;
	right: 0;
	z-index: 200;
	width: max-content;
	.header {
		@include alignCenter;
		padding: $spacing;
		justify-content: space-between;
		h6 {
			@include font;
			font-size: 16px;
			font-weight: 600;
		}
		button {
			background-color: transparent;
		}
	}
	.toggleAll {
		@include alignCenter;
		@include font;
		padding: $layoutMenuPadding;
		gap: 10px;
		padding-left: 14px;
		font-size: 16px;
		line-height: 20px;
		cursor: pointer;
		&:hover {
			border-radius: 5px;
			background: #fafbfb;
		}
	}
	.istoggleAllDisabled {
		color: gray;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-top: 10px;
		padding-left: 14px;
		li {
			@include alignCenter;
			padding: $layoutMenuPadding;
			// margin-bottom: 16px;
			margin-right: 20px;
			gap: 10px;
			cursor: pointer;
			&:hover {
				border-radius: 5px;
				background: #fafbfb;
			}
			span {
				@include font;
				font-size: 16px;
				line-height: 20px;
				text-align: right;
			}
		}
	}
	.bodyModal {
		padding: $spacing;
		h3 {
			@include font;
			line-height: 20px;
			color: $raven;
			margin-bottom: 12px;
		}
		h4 {
			@include font;
			color: $primayColor;
			margin-bottom: 26px;
		}
		h5 {
			display: flex;
			align-items: center;
			@include font;
			line-height: 17px;
			color: $raven;
			cursor: pointer;
			&.opened {
				svg {
					transform: rotate(90deg);
				}
			}
			span {
				color: $raven;
				margin-left: 10px;
			}
		}
	}
}
