@mixin scrollStyle {
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.container {
	display: flex;
	flex-direction: column;
	height: 500px;
	&.small {
		height: 222px;
	}
	&.isExpanded {
		height: calc(100% - 48px);
	}
}
.field {
	padding: 9px 0 9px 5px;
	border-bottom: 1px solid #e2e8f0;
	display: flex;
	align-items: center;
	gap: 10px;
	&.autoCompleteField {
		min-height: 41px;
	}
}

.fieldLabel {
	flex-grow: 1;
	display: flex;
	align-items: center;
	gap: 5px;
	height: 100%;
	margin: 0;
	color: #475569;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	white-space: nowrap;
	position: relative;
}

.searchDropdown {
	width: 100% !important;
	z-index: 1;
	padding: 10px;
	gap: 5px;
	top: 27px;
}

.inputClassName {
	padding: 0;
	margin-left: 8px;
	border-bottom: none !important;
	color: #475569;
}

.templates {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 7px;

	border-radius: 8px;
	border: 1px solid #f3f4f6;
	background: #fff;

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&.active {
		border-color: #00857a;
	}
	svg {
		&.active {
			transform: rotate(180deg);
		}
	}
}

.placeholders {
	display: flex;
	gap: 10px;
	padding: 9px 0;
	.label {
		&::before {
			content: "[";
			color: #00857a;
		}
		&::after {
			content: "]";
			color: #00857a;
		}
	}
}
.placeholdersContainer {
	height: calc(100% - 36px) !important;
}
.editor {
	border-top: 1px solid #e5e5e5;
	overflow: auto;
	overflow-x: hidden;
	display: flex;
	overflow-x: hidden;
	flex-direction: column;
	justify-content: space-between;
	height: -webkit-fill-available;
	padding-bottom: 8px;
	padding-right: 8px;
	@include scrollStyle;
}
.editorContainerClassName {
	flex-grow: 1;
}
.editorInput {
	height: 100%;
	overflow: unset;
	border-radius: 8px;
	background: white;
	border: unset;
	border-radius: 0;
	&:focus {
		border: unset;
	}
	@include scrollStyle;
}

div.editorInput > div > div {
	height: 100%;
}

.editorContent {
	padding: 12px;
	min-height: auto;
	padding-bottom: 20px;

	border: 0;
}

.placeholders {
	display: flex;
	justify-content: flex-end;
}

.attachments {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-top: 5px;
}
.iconWrapper {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	border: 3px solid #f8fafc;
	background: #f1f5f9;
	color: #64748b;
}

.previewContainer {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.5px;
	display: flex;
	padding: 5px;
	border: 1px solid;
	border-color: #cbd5e1;
	gap: 16px;
	border-radius: 8px;
	width: 100%;
	.fileInfos {
		display: flex;
		gap: 16px;
		align-items: center;
		white-space: nowrap;
		flex-grow: 1;
		min-width: 0;
		.name {
			color: #374151;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.fileStatus {
		display: flex;
		gap: 16px;
		align-items: center;
		flex-shrink: 0;
		.completed {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 8px;
			.deleteIcon {
				display: flex;
				align-items: center;
				margin-left: 8px;
			}
		}
		.progress {
			display: flex;
			align-items: center;
			gap: 16px;
			.percentage {
				color: #374151;
				font-weight: 500;
				width: 30px;
			}
			.bar {
				width: 100px;
				height: 8px;
				border-radius: 99px;
				background-color: #f6f6f6;
				overflow: hidden;
				div {
					height: 100%;
					background: #26ad74;
					transition: width 0.2s;
				}
			}
		}
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #334155;
	padding: 10px;
}

.title {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;
	background-color: transparent;
	padding: 0;
	color: #fff;
	text-transform: capitalize;
}

.headerButton {
	background: transparent;
	padding: 0;
	width: 1.125rem;
	height: 1.125rem;
}
.icon {
	width: 1.125rem;
	height: 1.125rem;
	path {
		stroke: #fff;
		width: 1.125rem;
		height: 1.125rem;
	}
}

.placeholdersSuggestions {
	margin-top: 5px;
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
	padding-bottom: 6px;
	.list {
		display: flex;
		gap: 5px;
		overflow: auto;
		padding-bottom: 6px;
		&::-webkit-scrollbar {
			height: 6px;
		}

		&::-webkit-scrollbar-track {
			margin-block: 3px;
			border-radius: 99px;
			background: #f4f4f433;
		}

		&::-webkit-scrollbar-thumb {
			background: #cacaca;
			border-radius: 99px;
		}
	}
	.label {
		color: #475569;
		font-family: "Work Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		padding-bottom: 7px;
	}
}
.placeholderSuggestion {
	all: unset;
	cursor: pointer;
	padding: 4px 8px;
	border-radius: 7px;
	border: 1px solid #e2e8f0;

	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-transform: capitalize;
	&:hover {
		background-color: #f3f4f6;
	}
}
.ccButtons {
	display: flex;
	align-items: center;
	.cta {
		box-shadow: none;
		padding: 0;
		padding-left: 8px;
		background: none;

		color: #475569;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		&:hover {
			text-decoration-line: underline;
		}
	}
}

.saveTemplate {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 7px;

	border-radius: 8px;
	border: 1px solid #e6f3f2;
	background: #e6f3f2;

	color: #00857a;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	.icon {
		path {
			stroke: #00857a;
		}
	}
}

.subjectInput {
	border: none !important;
	padding: 0;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	flex-grow: 1;
	&:focus {
		border: none !important;
	}
}
