.deleteButton {
	display: flex;
	align-items: center;
	background: transparent;
	padding: 0px;
}

.categorySelectWrapper {
	overflow: hidden;
}
