.icon {
	align-items: center;
	display: flex;

	path {
		stroke: #334155;
	}
}

.button {
	display: flex;
	padding: 8px;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	&:hover {
		background: #f1f5f9;
	}
}
