.root {
	background: #ffffff;
	box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	gap: 10px;
	.addContractButton {
		background: #ffffff;
		box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
		border-radius: 4px;
		border: 1px solid #babfc3;
		color: #202223;
		margin-bottom: 20px;

		&:focus {
			outline: auto;
		}
	}
}

.channelInfo {
	display: flex;
	flex-direction: column;
	padding: 0 15px;
	align-items: center;
	img,
	.hiddenBox {
		height: 50px;
		width: 90%;
		margin-top: 10px;
		margin-bottom: 20px;
	}

	p {
		font-family: "basiercircle";
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #202223;
		justify-self: center;
	}
}
