.datePicker {
	text-align: left;
	flex-direction: column;
	display: inline-flex;
	position: relative;
	width: 100%;
}

.label {
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin-bottom: 0.25rem;
}

.label {
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	font-size: 1.2rem;
	line-height: 1.25rem;
	display: flex;
	align-items: center;
	gap: 1.1rem;
}

.calendarContainer,
.stickyCalendarContainer {
	display: flex;
	flex-direction: column;
}

.stickyCalendarContainer td div {
	margin: inherit;
}

.stickyCalendarContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	min-width: 410px;
	min-height: fit-content;
	padding-bottom: 10px;
}

.container {
	display: flex;
	align-items: center;
	padding: 1.5rem;
	border-top: 1px solid #e5e5e5;
	flex-basis: auto;
}

.cancelButton {
	color: #334155;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
}

.cancelButton,
.saveButton {
	height: 40px;
	padding: 10px 20px;

	div {
		font-size: 16px;
	}
}

.footer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	padding-right: 14px;
}

.greyCard {
	align-self: stretch;
	border-radius: 8px;
	background: #f8fafc;
	margin-top: 12px;
}
