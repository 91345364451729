.newSelect {
	> input {
		padding: 0 !important;
	}
	> div {
		> span:first-child {
			align-items: center;
			flex-wrap: wrap;
			gap: 1rem;
			height: 100%;
		}
	}
}
.newSelectValue {
	display: inline-flex;
	align-items: center;
	color: #202223;
	font-family: BasierCircle, sans-serif;
	border-radius: 2px;
	padding: 2px 5px;
	text-transform: capitalize;
	button {
		border: none;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.multiValue {
	@extend .newSelectValue;
	background-color: #e4e5e7;
}

.newSelectOption {
	padding: 10px;
	font-family: BasierCircle, sans-serif;
	cursor: pointer;
	text-transform: capitalize;
	&:hover,
	&:focus {
		background-color: #eff0f3;
	}
}
