$primary-color: #00857a;

.container {
	display: flex;
	gap: 1rem;

	.itemWrapper {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		label {
			display: flex;
			cursor: pointer;
			font-weight: 400;
			position: relative;
			overflow: hidden;
			font-size: 14px;
			font-family: "Work Sans";
			margin-bottom: 0px;

			input {
				position: absolute;
				left: -9999px;
				line-height: 1.125rem;
				height: 1.125rem;
				width: 1.125rem;
				&:checked + span {
					&:before {
						box-shadow: inset 0 0 0 0.4375em $primary-color;
					}
				}
			}
			span {
				display: flex;
				align-items: center;
				border-radius: 99em;
				transition: 0.25s ease;
				color: #262626;
				font-family: "Work Sans";
				line-height: 1.125rem;

				&:before {
					display: flex;
					flex-shrink: 0;
					content: "";
					height: 1.125rem;
					width: 1.125rem;
					border-radius: 50%;
					margin-right: 0.375em;
					transition: 0.25s ease;
					box-shadow: inset 0 0 0 0.125em #d1d5db;
				}
			}
		}
	}
}

.containerRow {
	flex-direction: row;
}

.containerColumn {
	flex-direction: column;
}
