.infoIcon {
	outline: none;
}

.notificationContainer {
	padding: 7px;
	white-space: pre-wrap;
	word-break: break-word;
	width: 279px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
}
