.content {
	color: #334155;
}

.contentContainer {
	border-top: 1px solid var(--border-gray, #e2e8f0);
	display: flex;
	padding-top: 20px;
	align-items: flex-start;
	gap: 20px;
}

.form {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 5px;
}
.avatar {
	width: 80px;
	height: 80px;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
	}
}

.fullName {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 5px;
}

.info {
	display: flex;
	align-items: center;
	gap: 10px;
	.icon {
		display: flex;
		align-items: center;
	}
}

.position {
	margin-bottom: 5px;
}

.socialIcons {
	margin-top: 10px;
	display: flex;
	gap: 10px;
}

.input {
	border: none !important;
	padding: 0 !important;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-radius: 0 !important;
	&.fullName {
		font-size: 16px;
		font-weight: 600 !important;
	}
}
