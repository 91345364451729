.templateContainer {
	gap: 24px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.templateHeader {
	gap: 16px;
	display: flex;
	flex-direction: column;

	.templateTitle {
		margin: 0;
		color: #334155;
		font-size: 20px;
		font-weight: 500;
		line-height: 150%;
		font-style: normal;
		padding-bottom: 12px;
		font-family: Work Sans;
		border-bottom: 1px solid #e2e8f0;
	}

	.templateDescriptionContainer {
		gap: 10px;
		display: flex;
		flex-direction: column;
		.templateDescriptionLabel {
			font-size: 18px;
			font-weight: 400;
			line-height: 150%;
			color: #64748b;
			font-style: normal;
			font-family: Work Sans;
			letter-spacing: -0.5px;
		}
		.templateDescriptionCard {
			gap: 10px;
			display: flex;
			padding: 12px;
			border-radius: 12px;
			background: #fafbfd;

			font-size: 16px;
			font-weight: 400;
			color: #374151;
			line-height: 150%;
			font-style: normal;
			letter-spacing: -0.5px;
			font-family: Work Sans;
		}
	}
}

.formBodyContainer {
	gap: 24px;
	display: flex;
	position: relative;
	flex-direction: column;
}

.sectionContainer {
	gap: 24px;
	display: flex;
	padding-bottom: 24px;
	flex-direction: column;
	border-bottom: 1px solid #e2e8f0;

	.sectionTitle {
		margin: 0;
		font-size: 18px;
		font-weight: 500;
		line-height: 150%;
		color: #374151;
		font-style: normal;
		font-family: Work Sans;
		letter-spacing: -0.5px;
	}
}

.fieldContainer {
	gap: 12px;
	display: flex;
	flex-direction: column;
	.fieldLabel {
		font-size: 16px;
		font-weight: 400;
		color: #374151;
		line-height: 21px;
		font-style: normal;
		font-family: Work Sans;
		letter-spacing: -0.5px;
	}
}

.scoreCardFieldContainer {
	gap: 12px;
	display: flex;
	flex-direction: column;
	.scoreCardLabel {
		font-size: 16px;
		font-weight: 400;
		color: #374151;
		line-height: 21px;
		font-style: normal;
		font-family: Work Sans;
		letter-spacing: -0.5px;
	}
	.scoreCardContainer {
		background: #fff;
		border-radius: 12px;
		border: 1px solid #e2e8f0;

		.scoreCardItem {
			gap: 8px;
			display: flex;
			padding: 10px 14px;
			align-items: center;
			justify-content: space-between;
			&:not(:last-child) {
				border-bottom: 1px solid #e2e8f0;
			}
			.scoreCardItemTitle {
				font-size: 16px;
				font-weight: 400;
				color: #334155;
				line-height: 150%;
				font-style: normal;
				font-family: Work Sans;
			}
			.scoreRatingContainer {
				gap: 8px;
				display: flex;
				align-items: center;
				.scoreRatingBtn {
					padding: 8px;
				}
			}
		}
	}
}

.scoreRatingContainer {
	gap: 8px;
	display: flex;
	align-items: center;
}
.evaluationScoreBtn {
	&__red {
		border: 1px solid #d92d20 !important;
		svg path {
			stroke: #d92d20 !important;
		}
		span {
			color: #d92d20;
		}
	}
	&__yellow {
		border: 1px solid #f59e0b !important;
		svg path {
			stroke: #f59e0b !important;
		}
		span {
			color: #d92d20;
		}
	}
	&__green {
		border: 1px solid #039855 !important;
		svg path {
			stroke: #039855 !important;
		}
		span {
			color: #039855;
		}
	}
}

.editorInput {
	height: auto;
	min-height: 130px;
	max-height: 300px;
	border-radius: 8px;
	background: white;
	border: 1px solid #e5e5e5;
}
.editorContent {
	padding: 12px;
	min-height: 130px;
	padding-bottom: 20px;
}
.editorInputFocused {
	border: 1px solid #00857a;
}
.evaluationNoteLength {
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	font-style: normal;
	font-family: Work Sans;
	color: var(--gray-700, #334155);
}

.formFooterContainer {
	gap: 16px;
	bottom: 0;
	z-index: 999;
	display: flex;
	position: sticky;
	padding-top: 24px;
	background-color: white;
	border-top: 1px solid #e2e8f0;
}

.required {
	padding-left: 4px;
	color: #d92d20;
	font-weight: 400;
}

div.editorTextError {
	color: #d92d20;
	font-weight: 500;
}
