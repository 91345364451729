$primary-color: #00857a;

div.card {
	width: 50vw;
}

.cardsList {
	grid-row: 2/3;
	grid-column: 2/3;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.nextButtonContainer {
	display: flex;
	justify-content: flex-end;
}

.stepper {
	height: 32px;
	grid-column: 2/3;
	grid-row: 1/2;
	margin: 50px auto 60px;
}

.skillsSkeletonContainer,
.languagesSkeletonContainer {
	display: flex;
	gap: 7px;
}

.breadcrumb {
	display: flex;
	gap: 10px;
	align-items: center;
}

.breadcrumbItem {
	display: flex;
	flex-direction: column;
	width: fit-content;
}

.breadcrumbItem span:last-child {
	margin-top: -6px;
}

.thirdBreadcrumbItem .top,
.fourthBreadcrumbItem .top {
	transform: skew(40deg);
}

.thirdBreadcrumbItem .bottom,
.fourthBreadcrumbItem .bottom {
	transform: skew(-40deg);
}

.breadcrumbItem:first-child {
	margin-right: -10px;
}

.firstBreadcrumbItem .top {
	clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%);
	width: 19%;
}

.firstBreadcrumbItem .bottom {
	clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.lastBreadcrumbItem .top {
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 12% 100%);
}

.lastBreadcrumbItem .bottom {
	clip-path: polygon(12% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.lastBreadcrumbItem {
	margin-left: -10px;
}

.containedIcon {
	fill: $primary-color;
	path {
		stroke: $primary-color;
	}
}

.breadcrumbs {
	display: flex;
	flex-direction: column;
	gap: 14px;
}
