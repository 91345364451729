.dateWrraper {
	display: flex;
	align-items: center;
	border-left: 3px solid #00ba88;
	&.supplierDate {
		flex-direction: column;
		align-items: flex-start;
		> div {
			margin-bottom: 6px;
		}
		.calendarHour {
			margin-left: 6px;
		}
	}
}

.calendrierIcon {
	fill: #00ba88;
	margin-left: 7px;
}

.calendarDate {
	display: flex;
	align-items: center;

	> span {
		font-family: "basiercircle";
		margin-left: 8px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		color: #202223;
	}
}

.calendarHour {
	display: flex;
	align-items: center;
	margin-left: 12px;
	> span {
		font-family: "basiercircle";
		margin-left: 8px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		color: #202223;
	}
}
