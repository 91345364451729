.root {
	display: grid;
	grid-template-columns: 186px 168px 1fr;
	column-gap: 0.75rem;
	width: 100%;
	height: 100%;

	input {
		width: 80px;
	}
}
