.fileUploaderContainer {
	width: 380px;
	padding: 8px 16px;
	border-radius: 8px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;

	justify-content: space-between;
	background-color: #f4f6f9;
}

.container {
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
	justify-content: center;
}
.fileNameContentContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	overflow: hidden;
}
.fileName {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "basiercircle";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 258px;
}
.size {
	color: #aaa6b9;
	font-family: "DM Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.downloadIcon {
	cursor: pointer;
}
.rootContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.gridContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
}

.downloadIcon {
	background-color: transparent;
}
.fileIcon {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
}
