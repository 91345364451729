.paper {
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	outline: 0ch;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.padding {
	padding: 12px;
}
