$primary-color: #00857a;

.modal {
	padding: 62px 4px 4px 4px;
	width: 715px;
	height: 500px;
}

.playIconOrange g path:first-child {
	fill: rgba(255, 255, 255, 0.35);
}
