.container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	padding-top: 215px;
	word-break: initial;
}

.emptyBlock {
	text-align: center;
	font-family: "Work Sans";
	font-style: normal;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 468px;
}

.title {
	all: unset;
	margin-top: 24px;
	color: var(--text-dark, #334155);
	font-size: 20px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: -0.5px;
}

.description {
	margin-top: 8px;
	color: #64748b;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}
