.form {
	padding: 20px;
	width: 65%;
	.formTitle {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-bottom: 20px;
	}
	.candidateInfos {
		display: flex;
		padding: 10px;
		gap: 10px;
		align-items: center;
		border-radius: 8px;
		background: #f8fafc;
		width: 100%;
		.candidateName {
			color: #334155;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
		.candidatePosition {
			color: #64748b;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			display: block;
			font-weight: 400;
			font-size: 12px;
			max-width: 300px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.fields {
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin-top: 32px;
	}

	.label {
		color: #6b7280;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 12px;
	}
}

.locationPicker {
	padding: 16px;
	border-radius: 6px;
	background: #f8fafc;
}

.sidebar {
	padding: 16px;
	width: 35%;
}

.timesList {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.noteContainer {
	margin: 5px 0px 20px 0px;
}

.textarea {
	resize: none;
	width: 100%;
	display: flex;
	height: 100px;
	padding: 10.5px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	background: #fff;
	&::placeholder {
		color: #a3a3a3;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}

.textarea:focus {
	border: 1px solid #66b6af;
}

.error {
	padding-top: 10px;
}

.row {
	width: 100%;
	display: flex;
	gap: 32px;
	margin-bottom: 32px;
	.field {
		width: 50%;
	}
}

.viewApplication {
	display: flex;
	align-items: center;
	gap: 8px;
	text-decoration: none !important;
	margin-left: auto;
	background: transparent;
	padding: 6px 12px;
	border: 1px solid #00857a;
	color: #00857a !important;
	border-radius: 8px;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	cursor: pointer;
	background: #fff;

	&:focus {
		outline: none;
	}
}

.disabledIcon {
	path {
		stroke: #cbd5e1;
	}
}

.banner {
	display: flex;
	padding: 12px;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	margin-bottom: 39px;

	border-radius: 8px;
	border: 1px solid #dbeef7;
	background: #eff8ff;

	color: #64748b;

	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
