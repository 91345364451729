.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 14px;
	padding: 16px;
}
.cancel > button {
	height: 100%;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
}

.cancel > button > div {
	line-height: 17px;
	color: #525252;
}
.deleteButton {
	background-color: #dc2626 !important;
	box-shadow: 0px 0px 1px #dc2626 inset;
	color: #fff;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.trashIcon path {
	stroke: white;
}
