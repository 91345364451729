.confirm_modal {
	display: flex;
	flex-direction: column;
	gap: 30px;
	h3 {
		font-family: "Roboto";
		font-style: normal;
		font-size: 24px;
		font-weight: 600;
		color: #1e293b;
		line-height: 28px;
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__message {
		gap: 16px;
		display: flex;
		align-items: center;
		p {
			font-weight: 500;
			font-size: 15px;
			color: #111827;
			line-height: 24px;
			font-family: "Work Sans";
		}
		svg {
			min-width: 45px;
		}
	}

	&__checkbox {
		display: flex;
		input[type="checkbox"]:checked + label::before {
			background-color: #00857a !important;
			border: 1px solid #00857a !important;
		}
	}
	&__containerCheckbox {
		label {
			font-family: "Work Sans";
			color: #111827 !important;
			font-weight: 400 !important;
			font-size: 14px !important;
			padding-left: 19px !important;
			line-height: 20px !important;
			margin-top: -2px !important;
		}
		input {
			margin-top: -25px;
			margin-left: 3px;
			z-index: 999;
			scale: 1.3;
		}
	}

	&__footer {
		gap: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__close {
		top: 28px;
		right: 24px;
		z-index: 100;
		cursor: pointer;
		position: absolute;
		&:hover svg path {
			stroke: #4b5563;
		}
	}
}
