.table {
	table {
		width: 100%;
	}
	thead {
		position: sticky;
		top: 0;
		background: white;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			height: 1px;
			width: 100%;
			background: #f6f6f7;
		}
	}
	th {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #303131;
		padding: 22px;
	}
	td {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #48494a;
		padding: 22px;
		width: 371px;
	}
	tbody {
		tr {
			cursor: pointer;
		}
	}
}

.textAlignRight {
	text-align: right;
}
.viewAll {
	display: flex;
	height: 60px;
	background: #fafbfb;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #fd5749;
	cursor: pointer;
}

@media only screen and (max-width: 1280px) {
	.table {
		table {
			th {
				padding: 4px;
			}
			td {
				padding: 22px 4px;
				width: inherit;
			}
		}
	}
}
