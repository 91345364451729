.ag-theme-alpine {
	--ag-range-selection-border-color: rgba(4, 181, 166, 0.1);
	--ag-checkbox-checked-color: #00857a;
	--ag-row-border-color: #e5e5e5;
	--ag-header-background-color: #fff;
	--ag-header-foreground-color: #262626;
	--ag-row-hover-color: #e6f3f2;
	--ag-selected-row-background-color: rgba(4, 181, 166, 0.1);
	--ag-font-size: 1rem;
	--ag-font-family: "Work Sans";
}
.ag-row-even.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-selected {
	--ag-font-size: 1rem;
	--ag-font-family: "Work Sans";
}

.ag-theme-alpine .ag-icon-arrows {
	display: none;
}

.ag-theme-alpine .ag-ltr .ag-cell {
	display: flex;
	align-items: center;
	border-right: 1px solid #e5e5e5 !important;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
	border-right: 1px solid #e5e5e5;
	border-top: none;
}
.ag-cell,
.ag-cell-not-inline-editing,
.ag-cell-normal-height,
.ag-cell-value,
.ag-cell-focus {
	border-left: 1px solid #e5e5e5;
}

.ag-cell-value {
	border: none;
}
.span#cell-_test-0 {
	background-color: none;
}

.ag-header-cell-comp-wrapper,
.ag-cell-label-container {
	height: 120% !important;
	padding: 0;
}
.ag-header-cell-text {
	font-weight: 700 !important;
	line-height: 1.125rem !important;
	font-family: "Work Sans", sans-serif !important;
}
.ag-header-cell-text {
	color: #737373;
}
span.ag-header-icon.ag-header-cell-menu-button {
	display: flex;
	align-items: center;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
	padding: 1rem 0.5rem 1rem 0 !important;
}

.ag-row-even,
.ag-row .ag-row-level-0,
.ag-row-position-absolute,
.ag-row-first,
.ag-row-focus {
	padding: 0 !important;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
	padding: 1rem !important;
}
.ag-row-even.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-first.ag-row-last.ag-row-focus,
.ag-theme-alpine .ag-row {
	border-top: none;
	border-bottom: none;
	cursor: pointer;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
	border-top: none !important;
	border-bottom: none !important;
}

.ag-root-wrapper.ag-ltr.ag-layout-normal {
	border: none;
	border-radius: 8px;
}

.ag-root.ag-unselectable.ag-layout-normal {
	border: 1px solid #d4d4d4;
	border-radius: 8px;
}

.ag-theme-alpine .ag-header-cell-resize::after {
	left: calc(50% - 0px) !important;
	width: 1px !important;
	height: 100% !important;
	opacity: 0 !important;
}

.ag-ltr .ag-header-cell-resize {
	right: 0;
	width: 15px;
}
.ag-header-cell-resize {
	top: -17px !important;
}
.ag-header-cell.ag-focus-managed {
	&:last-child {
		border-right: -5px solid #e5e5e5;
	}
}

.ag-header-cell.ag-focus-managed:hover {
	cursor: pointer;
}

.ag-theme-alpine .ag-pinned-left-header,
.ag-theme-alpine .ag-pinned-right-header {
	border: none !important;
	border-right: 1px solid #e5e5e5 !important;
}

.ag-pinned-right-header {
	border: none !important;
	border-left: 1px solid #e5e5e5 !important;
}
.ag-theme-alpine .ag-checkbox-input-wrapper {
	font-size: 18px !important;
	line-height: 18px !important;
	font-style: normal !important;
}

.ag-root-wrapper.ag-ltr.ag-layout-normal {
	border: none;
	box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 6%);
	border-radius: 8px;
}

.ag-dnd-ghost {
	cursor: grabbing;
}

.ag-root.ag-unselectable.ag-layout-normal {
	border-radius: 8px;
}
.ag-theme-alpine {
	border: none !important;
}
.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
	visibility: hidden;
}

.ag-horizontal-right-spacer.ag-scroller-corner {
	min-width: 1px !important;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
	border-right: 1px solid #e5e5e5;
}

.ag-theme-alpine,
.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
	border-left-color: #e5e5e5 !important;
}
.ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row::after {
	display: none;
}
.ag-theme-alpine .ag-ltr .ag-header-select-all {
	margin-left: 15px !important;
	margin-right: 15px !important;
}

.ag-menu.ag-ltr.ag-popup-child {
	border: 1px solid #e5e5e5 !important;
	border-radius: 5px;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.ag-theme-alpine .ag-header-row .ag-header-row-column {
	max-width: 100% !important;
}

.ag-body-horizontal-scroll,
.ag-body-horizontal-scroll-viewport,
.ag-body-horizontal-scroll-container {
	height: 8px !important;
	max-height: 8px !important;
	min-height: 8px !important;
	background: #f7f8fa;
}
//.ag-body-viewport::-webkit-scrollbar {
//	width: 0.5rem;
//}
.ag-body-horizontal-scroll-viewport {
	background: #f5f5f5;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
}
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
	height: 0.5rem;
}
.ag-body-viewport::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
	background: transparent;
}
.ag-body-viewport::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
	background: #d4d4d4;
	border-radius: 50px;
}

.ag-body-viewport::-webkit-scrollbar-thumb:hover,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover {
	background: #c4c4c4;
}

.ag-filter {
	overflow: hidden;
}
.ag-menu.ag-ltr.ag-popup-child {
	z-index: 999;
}
.ag-pinned-left-cols-container,
.ag-pinned-left-header {
	box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	-moz-box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	-webkit-box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	z-index: 1;
}
.ag-pinned-left-header {
	box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	-moz-box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	-webkit-box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	z-index: 1;
}

.ag-pinned-right-cols-container,
.ag-pinned-right-header {
	box-shadow: rgb(0 0 0 / 10%) -2px 0px 10px -2px;
	-moz-box-shadow: rgb(0 0 0 / 10%) -2px 0px 10px -2px;
	-webkit-box-shadow: rgb(0 0 0 / 10%) -2px 0px 10px -2px;
	z-index: 1;
}
.ag-pinned-right-header {
	box-shadow: rgb(0 0 0 / 10%) -2px 0px 10px 2px;
	-moz-box-shadow: rgb(0 0 0 / 10%) -2px 0px 10px 2px;
	-webkit-box-shadow: rgb(0 0 0 / 10%) -2px 0px 10px 2px;
	z-index: 1;
}

.tippy-box[data-theme~="translucent"] {
	background-color: #262626;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container {
	min-height: 0;
}

.ag-center-cols-viewport::-webkit-scrollbar {
	opacity: 0;
}

.ag-theme-alpine .ag-cell {
	span {
		width: 100%;
	}
}

.ag-body-viewport.ag-row-animation.ag-layout-normal {
	background: #f7f8fa;
}

.ag-overlay {
	pointer-events: all;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
	border: none !important;
}
.ag-header-cell-comp-wrapper {
	display: flex;
	align-items: center;
}
.ag-body-horizontal-scroll-viewport {
	margin-left: -3px;
}

.centered {
	justify-content: center;
}
