.header {
	display: flex;
	height: 70px;
	padding: 10px 20px;
	align-items: center;
	width: 100%;

	border-top: 1px solid #f1f1f1;
	background: #f8fafc;
}

.leftIcon {
	width: 24px;
	height: 24px;
	path {
		fill: #94a3b8;
	}
}

.starIcon {
	path {
		stroke: #94a3b8;
	}
}
.title {
	margin: 0;
	margin-left: 15px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.star {
	margin-left: 8px;
}

.cta {
	background: transparent;
	padding: 0;
	width: 24px;
	height: 24px;
}
