.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 21px;
	border-bottom: 1px solid #e2e8f0;
	.title {
		margin: 0;
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
	.closeBtn {
		display: flex;
		width: 36px;
		height: 36px;
		padding: 8px;
		justify-content: center;
		align-items: center;
		gap: 8px;

		border-radius: 8px;
		border: 1px solid #fff;
		background: #f8fafc;
	}
}

.body {
	flex-grow: 1;
	overflow-y: auto;
	.container {
		display: flex;
		padding: 20px 20px 0px 20px;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	.row {
		width: 100%;
	}

	.label {
		color: #6b7280;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 12px;
		.required {
			color: #d92d20 !important;
		}
	}

	.paperClassName {
		max-width: 300px;
	}

	.entityContainer {
		display: flex;
		padding: 7px 10.5px;
		align-items: center;
		gap: 7px;
		align-self: stretch;

		color: #262626;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;

		&:hover {
			cursor: pointer;
		}
		.checkIcon {
			width: 21px;
			min-width: 21px;
			.icon {
				path {
					stroke: #334155;
				}
			}
		}
	}

	.rowIcon {
		path {
			stroke: #6b7280;
		}
	}

	.banner {
		color: #64748b;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;

		display: flex;
		padding: 12px;
		gap: 10px;

		border-radius: 8px;
		border: 1px solid #dbeef7;
		background: #eff8ff;
	}

	.newMailContainer {
		display: flex;
		overflow: hidden;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
	.body {
		flex-grow: 1;
		overflow-x: auto;
		padding: 10px;
		background-color: #fff;
	}

	.placeholdersAndTemplates {
		display: flex;
		flex-direction: column;
		min-width: 258px;
		max-width: 258px;
		border-left: 1px solid #e2e8f0;
		border-radius: 0;
		&.hide {
			display: none;
		}
		.header {
			background: #334155;
		}
	}

	.confirmButtonClassName {
		background: #d92d20;
		box-shadow: none;
		&:hover {
			background: #d92d20;
		}
		svg {
			path {
				stroke: #fff;
			}
		}
	}
	.headerButton {
		background: transparent;
		padding: 0;
		width: 1.125rem;
		height: 1.125rem;
	}
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #262626;
		padding: 10px;
	}

	.title {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.125rem;
		overflow: hidden;
		white-space: pre;
		text-overflow: ellipsis;
		background-color: transparent;
		padding: 0;
		color: #fff;
		text-transform: capitalize;
	}

	.icon {
		width: 1.125rem;
		height: 1.125rem;
		path {
			stroke: #fff;
			width: 1.125rem;
			height: 1.125rem;
		}
	}

	.comboBoxContainer {
		margin-bottom: 12px;
	}
}

.footer {
	position: unset;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding: 16px 13px;
	background: #fff;
	border-top: 1px solid #e2e8f0;
	.btn {
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		padding: 8px 16px;
		border-radius: 8px;
		height: 40px;
		&.outlined {
			color: #334155;
			border: 1px solid #e2e8f0;
			box-shadow: none;
		}
	}
}

.errorMessage {
	margin-top: 0.5rem;
}
