.tag {
	height: 24px;
	display: flex;
	padding: 4px 8px;
	align-items: center;
	gap: 10px;

	border-radius: 4px;
	border: 1px solid #e2e8f0;
	background: #fff;

	white-space: nowrap;
}

.tagLabel {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}
.tagIcon {
	background: transparent;
	padding: 0;
	height: 16px;
}

.closeIcon {
	width: 16px;
	height: 16px;
}
