$primary-color: #00857a;

.container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-rows: repeat(2, auto);
	justify-content: center;
}

.line {
	background-color: $primary-color;
	width: 200px;
	height: 3px;
}

.link {
	position: relative;
	display: flex;
}

.profileLabel,
.vacancyLabel {
	position: absolute;
	top: 45px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: $primary-color;
}

.profileLabel {
	left: -7px;
}

.vacancyLabel {
	left: -13px;
}
