.picture {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	box-shadow: 0px 0px 0px 2px #ffffff;
	border-radius: 50% !important;
	background: white;
	cursor: pointer;
}

.avatar {
	display: flex;
	align-items: center;
	border-radius: 50% !important;
	cursor: pointer;

	> div {
		position: relative;
		font-size: 13px;
	}
}

.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
}

div.firstCollaboratorPicture {
	margin-left: -3px;
}

.collaboratorPicture {
	border-radius: 50%;
	border: 3px solid white;
	margin-left: -8px;
}

.images {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
}
