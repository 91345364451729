.filterRecapItem {
	display: flex;
	align-items: center;
	height: 39px;
	padding: 4px 0px;

	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;

	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	.label,
	.operator,
	.value {
		text-transform: capitalize;

		color: #334155;
		padding: 0 8px;

		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		white-space: nowrap;
	}
	.value {
		text-transform: none;
	}
	.operator,
	.value {
		border-left: 1px solid #e2e8f0;
	}
}
