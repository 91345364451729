.container {
	margin-bottom: -70px;
}

.contentLayout {
	display: flex;
	flex: 1 0 0;
	background: #fff;
	min-height: calc(100vh - 70px);
	max-height: calc(100vh - 70px);
}

.content {
	flex-grow: 1;
}
