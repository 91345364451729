.underlay {
	position: fixed;
	inset: 0;
}

.popover {
	border: 1px solid var(--spectrum-global-color-gray-400);
	box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
	border-radius: 6px;
}
