.container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	width: 616px;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
		0px 2px 4px -2px rgba(16, 24, 40, 0.06);
	background-color: white;

	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	font-size: 16px;
}

.boldText {
	font-weight: 600;
}

.header {
	background-color: #334155;
	color: white;
	padding: 10px 18px;
}

.content {
	padding: 25px 30px;
	color: #334155;
}

.contentContainer {
	border-top: 1px solid var(--border-gray, #e2e8f0);
	display: flex;
	padding: 20px 0px;
	align-items: flex-start;
	gap: 50px;
}

.avatar {
	flex-shrink: 0;
	width: 110px;
	height: 110px;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
	}
}

.fullName {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 5px;
}

.info {
	display: flex;
	gap: 10px;
	.icon {
		display: flex;
		margin-top: 2px;
	}
	margin-bottom: 5px;
}

.position {
	margin-bottom: 5px;
}

.socialIcons {
	margin-top: 10px;
	display: flex;
	gap: 10px;
}

.pre {
	white-space: pre-wrap;
}
