.container {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr max-content;
	padding: 10.5px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
}

input.input[type="text"][readonly] {
	border: 0px solid transparent;
	background-color: transparent;
	padding: 0px;
	min-height: fit-content;
	cursor: grab;
	font-family: "Work Sans";
	&:focus {
		border: 0px solid transparent !important;
	}
}

.button {
	display: flex;
	background-color: transparent;
	padding: 0px;
}
