.notifications_container {
	border: 1px solid #f3f4f6;
	background-color: white;
	border-radius: 12px;

	.badge {
		height: 20px;
		min-width: 20px;
		padding: 0 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 400;
		background: #f3f4f6;
		border-radius: 99px;
		color: #334155;
	}

	.header {
		padding: 28px 20px 0 20px;
		border-bottom: 1px solid #f3f4f6;

		.title {
			margin-bottom: 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.text {
				font-family: "BasierCircle";
				font-size: 23px;
				font-weight: 500;
				letter-spacing: -0.5px;
				color: #334155;
			}
		}

		.mark_as_read {
			all: initial;
			font-family: "Work Sans";
			font-size: 15px;
			line-height: 21px;
			letter-spacing: -0.5px;
			color: #475569;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.nav {
			display: flex;
			justify-content: space-between;

			.filters {
				display: flex;
				gap: 16px;

				.filter_btn {
					all: initial;
					cursor: pointer;
					padding: 12px 4px 12px 4px;
					display: flex;
					gap: 8px;
					align-items: center;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: -0.5px;
					text-align: left;
					color: #334155;
					border-bottom: 3px solid transparent;

					&.active {
						border-bottom: 3px solid #1a9187;
						color: #00857a;
					}
				}
			}

			.settings_btn {
				all: initial;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 500;
				height: 36px;
				padding: 0 16px;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 7px;
				border: 1px solid #f3f4f6;
				color: #334155;
				cursor: pointer;

				&:hover {
					background: #f5f5f5;
				}
			}
		}
	}

	.content {
		padding: 20px;

		.collapsible {
			border: 1px solid #e5e7eb;
			border-radius: 8px;
			overflow: hidden;

			&_header {
				min-height: 62px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 12px 12px 12px 20px;

				.title {
					font-family: "Work Sans";
					font-size: 18px;
					font-weight: 500;
					letter-spacing: -0.5px;
					color: #334155;
					display: flex;
					align-items: center;
					gap: 10px;
					line-height: 1;
				}

				.trigger {
					all: initial;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					height: 36px;
					padding: 0 12px;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 7px;
					border: 1px solid #f3f4f6;
					color: #334155;
					cursor: pointer;

					&:hover {
						background: #f5f5f5;
					}

					&[disabled] {
						opacity: 0.5;
						pointer-events: none;
					}
				}
			}

			&_divider {
				border-top: 1px solid #e5e7eb;
			}

			&_content {
				overflow: hidden;
				transition: height 0.2s;

				.notif_item {
					display: flex;
					padding: 16px 14px;
					gap: 12px;
					cursor: pointer;

					&:hover {
						background-color: #f9fafb;
					}

					.notif_avatar,
					.notif_icon {
						width: 35px;
						height: 35px;
						flex-shrink: 0;
					}

					.notif_icon {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #f3f4f6;
						border-radius: 8px;
						color: #9ca3af;
					}

					.unread_badge {
						width: 15px;
						height: 15px;
						background-color: #f59e0b;
						border: 1.5px solid white;
						border-radius: 99px;
					}

					.notif_content {
						flex-grow: 1;

						.notif_content_header {
							display: flex;
							justify-content: space-between;
							margin-bottom: 12px;

							.name {
								font-family: "Work Sans";
								font-size: 16px;
								font-weight: 500;
								letter-spacing: -0.5px;
								color: #334155;
								margin-top: -2px;
							}

							.time {
								font-family: "Work Sans";
								font-size: 14px;
								color: #4b5563;
							}
						}

						.text {
							font-family: "Work Sans";
							font-size: 15px;
							letter-spacing: -0.5px;
							color: #334155;
						}
					}
				}

				.notif_item_divider {
					border-top: 1px solid #e5e7eb;
					margin: 0 16px;
				}

				.mission_title {
					display: flex;
					align-items: center;
					margin-top: 12px;
					margin-bottom: 4px;

					&_text {
						display: flex;
						align-items: center;
						height: 32px;
						border: 1px solid #e5e7eb;
						padding: 0 12px;
						border-radius: 99px;
						font-family: "Work Sans";
						font-size: 16px;
						font-weight: 500;
						letter-spacing: -0.5px;
						color: #334155;
					}

					&_divider {
						border-top: 1px solid #e5e7eb;
						flex-grow: 1;
						margin: 10px;
					}
				}
			}
		}
	}
}

.infiniteLoadingIndicator {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #334155;
}

.viewDetails {
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 13.628px;
}

.buttonWrapper {
	color: #00857a;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 0;
	margin-top: 12px;
	background: #ffffff00;
}
