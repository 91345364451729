@import "modules/candidate/Profile/Variables/styles.scss";

.btnContainer {
	padding: 8px 0;
}

.btnAdd {
	display: flex;
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	& span {
		@include profileContent;
		color: $swamp;
		margin-left: 6px;
		font-weight: 500;
	}
}

.profileTagsItems {
	display: flex;
	align-items: center;
	gap: 6px;
	flex-wrap: wrap;
	position: relative;

	.containerSelectTags {
		width: 554px;
		background: #ffffff;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 14px 16px;
		.listItems {
			list-style: none;
			padding: 0;
			li {
				padding: 8px;
				cursor: pointer;
				&:last-child {
					margin-bottom: 10px;
				}
				&:hover {
					background-color: #f6f6f7;
				}
				h3 {
					@include profileContent;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					margin-bottom: 0;
					font-weight: 500;
					span {
						text-transform: capitalize;
					}
				}
				> span {
					display: inline-block;
					color: #6d7175;
					padding: 2px 8px;
					border: 1px solid #8c9196;
					border-radius: 66px;
					font-size: 12px;
				}
			}
		}
		.searchBox {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 14px;
			input {
				border: 1px solid $borderInput;
				width: 382px;
				height: 36px;
				line-height: 36px;
				padding: 0 12px;
				min-height: inherit;
			}
			button {
				background: $white;
				@include layoutItems;
				font-size: 14px;
				border: 1px solid $borderInput;
				padding: 7px 12px;
			}
		}
	}
	.item {
		font-size: 14px;
		font-family: "basiercircle";
		font-weight: 400;
		line-height: 18px;
		color: #202223;
		border: 1px solid #babfc3;
		padding: 3px 6px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		background-color: #fafafb;
		button {
			background: transparent;
			padding: 0;
			display: flex;
			align-items: center;
			margin-left: 12px;
		}
	}
}
