.cancel {
	display: flex;
	height: 40px;
	padding: 8px 16px;
	justify-content: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:focus-visible {
		outline: none !important;
	}
	&:hover {
		background: #f1f5f9;
	}
	&:disabled {
		border: none;
		border: 1px solid #cbd5e1;
		color: #cbd5e1;
		background: #f1f5f9;
	}
}
.btn {
	gap: 8px;
	height: 40px;
	display: flex;
	padding: 12px 20px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;

	align-items: center;
	justify-content: center;

	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	font-style: normal;
	font-family: "Work Sans";
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	background-color: #fff;

	&:focus-visible {
		outline: none !important;
	}

	&Success {
		color: #fff;
		border: 1px solid #00857a;
		background-color: #00857a;

		&:hover {
			background-color: #006a62;
		}
	}

	&SuccessOutline {
		color: #00857a;
		background-color: white;
		border: 1px solid #00857a;

		&:hover {
			background: #f1f5f9;
		}
	}

	&Link {
		color: #334155;
		background-color: white;
		border: 1px solid #e2e8f0;

		&:hover {
			background-color: #f9fafb;
		}
	}
}
.btnDiscard {
	display: flex;
	height: 40px;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #d92d20;
	background: #fff;
	color: #d92d20;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.description {
	display: flex;
	gap: 21px;
	align-items: center;
	padding: 20px;
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
