.container {
	display: flex;
	padding: 15px;
}

.subContainer {
	display: flex;
	flex-direction: column;
	gap: 80px;
}

.main {
	width: 70%;
}

.sidebar {
	width: 30%;
}
