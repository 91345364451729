@import "../../Variables/styles.scss";

.container {
	// cursor: pointer;
	.title {
		display: flex;
		align-items: flex-start;
		gap: 0.5rem;
		@include profileContent;
		p {
			margin: 0;
			color: $raven;
		}
		svg {
			circle {
				fill: $raven;
			}
		}
	}
	.toggle {
		background: transparent;
		display: flex;
		align-items: center;
		border: none;
		outline: none;
		width: 1rem;
		height: 1rem;
		padding: 0;
		margin: 4px;
		svg {
			transition: transform 0.4s ease;
			transform: rotate(-90deg);
		}
		&.down svg {
			transform: rotate(0);
		}
	}

	.linkedTo {
		@include profileContent;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 2px 8px;
		width: fit-content;
		color: $brown;
		background: #ffebd3;
		border-radius: 66px;
		overflow: hidden;
		max-height: 0;
		padding: 0;
		&.show {
			transition: max-height 0.3s ease;
			height: auto;
			max-height: 2rem;
			padding: 2px 8px;
			width: max-content;
		}
	}
	.date {
		align-items: baseline !important;
	}
	.dot {
		color: #a3a3a3;
		font-size: 1rem;
		line-height: 1.125rem;
		margin: 0 0.375rem 0 0.5rem;
	}
	.tooltip {
		padding: 7px 10px;
		color: #fff;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
	}
	.noteVisibility {
		svg {
			width: 16px;
			height: 16px;
		}
	}
	.header__card {
		padding: 1rem;
		&.noteDrawer {
			padding: 0;
		}
		.avatar__condidate {
			width: 2.5rem;
			height: 2.5rem;
			margin-right: 0.5rem;
			border-radius: 3px;
		}
		.section {
			display: flex;
			flex-direction: column;
			margin-left: 0.5rem;
			.info__condidate {
				display: flex;
				align-items: center;
				margin-bottom: 0.375rem;
				width: 100%;
				.name__condidate {
					font-size: 1rem;
					font-weight: 600;
					line-height: 1.125rem;
					color: #262626;
					display: block;
					font-family: "Work Sans";
					&.noteDrawer {
						font-size: 18px;
						font-style: normal;
						font-weight: 500;
						line-height: 150%; /* 27px */
					}
				}

				.job__condidate {
					display: flex;
					align-items: center;
					color: #108c82;
					font-weight: 500;
					font-size: 14px;
					gap: 0.5rem;
					&.noteDrawer {
						font-size: 16px;
						font-style: normal;
						line-height: 150%; /* 24px */
					}

					.title {
						color: #108c82;
						font-size: 1rem;
						line-height: 1.125rem;
						font-weight: 500;
						font-family: "Work Sans";
						&.noteDrawer {
							font-size: 16px;
							font-style: normal;
							line-height: 150%; /* 24px */
						}
					}
					&:hover {
						.title {
							text-decoration: underline;
							color: #006a62;
							cursor: pointer;
						}
						.briefcase {
							fill: #006a62 !important;
						}
					}
				}
			}
			.phone_condidate {
				display: flex;
				align-items: center;
				color: #737373;
				font-size: 14px;
				font-weight: 500px;
				letter-spacing: 0.5px;
				svg {
					cursor: pointer;
				}
				.phone,
				.date {
					font-family: "Work Sans";
					font-size: 0.875rem;
					line-height: 1rem;
					font-weight: 400;
					color: #737373;
					&.noteDrawer {
						font-size: 16px;
						font-style: normal;
						line-height: 150%; /* 24px */
					}
				}

				.dot {
					line-height: 1rem;
				}
				.tooltip {
					padding: 7px 10px;
					color: #fff;
					font-family: "Work Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 21px;
				}
				.noteVisibility {
					display: flex;
					align-items: center;

					.usersRest {
						background: #f1f5f9;
						border-radius: 39px;
						padding: 2px 4px;
						color: #334155;
						font-family: "Work Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
						width: 26px;
						height: 26px;
					}
				}
			}
		}
		.note__section {
			position: relative;
			padding: 0.75rem 1rem;
			background: #f8fafc;
			border-radius: 0px 16px 16px 16px;

			span[data-lexical-mention="true"] {
				font-weight: bold;
			}

			.truncat__text {
				display: block;
				display: -webkit-box;
				max-width: 100%;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			p,
			span {
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 24px */
				color: #171717;
				z-index: 1;
				cursor: default;
			}

			.dotsVertical {
				position: absolute;
				right: 0.25rem;
				top: 0.75rem;
				cursor: pointer;
				display: none;
				z-index: 2;
			}
			.actions {
				display: flex;
				flex-direction: column;
				background-color: #fff;
				position: absolute;
				right: 0.25rem;
				top: 2rem;
				width: 5rem;
				padding: 0.25rem 0;
				border: 1px solid #e5e5e5;
				border-radius: 5px;
				z-index: 9999;
				box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
					0px 2px 4px -1px rgba(0, 0, 0, 0.06);
				div {
					width: 100%;
					font-size: 1rem;
					font-weight: 400;
					font-family: "Work Sans";
					line-height: 1.125rem;
					padding: 0.5rem 0.75rem;
					&:hover {
						background-color: #f5f5f5;
					}
				}
				:last-child {
					color: #dc2626;
				}
			}
			&:hover .dotsVertical {
				display: block;
			}
		}
	}
}
.noteActions {
	display: flex;
	gap: 8px;
	margin-right: 5px;
	&:hover {
		cursor: pointer;
	}
	&.noteDrawer {
		svg {
			width: 20px;
			height: 20px;
		}
	}
}
.flex {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.noteBody {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.view__note__btn {
	margin-top: 5px;
	background: none;
	padding: 0;
	color: #108c82;
	text-decoration: none;
	font-weight: 500;
	z-index: 10;
	margin-right: 0px;
	font-family: "Work Sans";
	&:hover {
		text-decoration: underline;
		color: #006a62;
	}
}
.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
}
div.firstCollaboratorPicture {
	margin-left: -3px;
}

.collaboratorPicture {
	border-radius: 50%;
	border: 3px solid white;
	margin-left: -8px;
}

.picture {
	max-width: 100%;
	max-height: 100%;
	border-radius: 50%;
}

.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
}
.avatar {
	display: flex;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;

	> div {
		position: relative;
		font-size: 13px;
	}
}
.visibiliytyRest {
	width: 30px;
	height: 30px;
	background: #f1f5f9;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #334155;
	font-size: 14px;
	font-family: "Work Sans";
	margin-left: 4px;
	cursor: pointer;
}

.tag {
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	padding: 4px 8px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
	max-height: 29px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	z-index: unset;

	.tagName {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 200px;
	}
	.departementIcon {
		display: flex;
		width: 20px;
		height: 20px;
		margin-right: 4px;
	}
}
.collaboratorsContainer {
	display: flex;
	gap: 1px;
	align-items: center;
	.images {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.avatarWrapper {
		width: fit-content;
		display: flex;
		align-items: center;
	}
	div.firstCollaboratorPicture {
		margin-left: -3px;
	}

	.collaboratorPicture {
		border-radius: 50%;
		border: 3px solid white;
		margin-left: -8px;
	}
	.picture {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0px;

		/* /ring-2/ring-white */

		box-shadow: 0px 0px 0px 2px #ffffff;
		border-radius: 50%;
		background: white;

		cursor: pointer;
	}
	.avatarWithBorder {
		margin-left: -8px;
		border: 3px solid white;
	}
	.avatar {
		display: flex;
		align-items: center;
		border-radius: 50%;
		cursor: pointer;

		> div {
			position: relative;
			font-size: 13px;
		}
	}
}

.trashIcon {
	path {
		stroke: #334155;
	}
}
