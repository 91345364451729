@import "modules/SearchCandidate/TableVariables/fonts.scss";

.section {
	text-align: left;
	&:not(:last-child) {
		margin-bottom: 14px;
	}

	&__content {
		width: 100%;
	}

	&__btn {
		background: transparent;
		padding: 0 4px;
		color: black;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 14px;
		gap: 0.5rem;

		p {
			@include MediumParagraph;
			font-weight: 500;
			color: #262626;
			text-transform: capitalize;
			letter-spacing: -0.5px;
		}

		.green_span {
			color: #1a9187;
			font-weight: 500;
		}
	}
}

.arrow {
	transition: all 200ms ease-in-out;
}

.right {
	transform: rotate(90deg);
}
