.container {
	position: relative;
	> button {
		background: transparent;
		.icon {
			fill: #aeb4b9;
		}
		&:disabled {
			cursor: not-allowed;
		}
	}
}
.isEmpty {
	.icon {
		fill: #edeeef !important;
	}
}

.menuItems {
	position: absolute;
	background: #ffffff;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	padding: 8px;
	z-index: 9999;
	width: 100%;
	top: 5px;
	right: 0px;
	width: 176px;
}

.menuItem {
	display: flex;
	align-items: center;
	padding: 10px 12px 10px 0px;
	width: 100%;
	height: 40px;
	background: white;
	position: relative;
	> span,
	a {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		margin-left: 20px;
		text-align: left;
		flex: 1;
		text-decoration-line: none;
	}
}
.menuItem:hover {
	background: #f6f6f7;
	border-radius: 2px;
}

.loader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(246, 246, 247, 1);
	position: absolute;
	height: 100%;
	left: 0;

	> div {
		margin-bottom: 0;
		padding: 5px 0;
		> span {
			width: 20px;
			height: 20px;
			border: 2px solid rgba(0, 0, 0, 0);
			border-top-color: #ff6849;
		}
	}
}

.backdrop {
	background-color: rgba(23, 24, 24, 0.05);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9998;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	cursor: auto;
}
