.container {
	display: grid;
	column-gap: 10px;
	row-gap: 13px;
	grid-template-columns: 35px 1fr;
	grid-template-rows: 35px 42px;
	padding: 1rem;

	.picture {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	.infoWrapper {
		grid-column: 2 / -1;
		grid-row: 1 / 2;
		gap: 5px;
		display: flex;
		flex-direction: column;
	}

	.author {
		width: 120px;
		height: 16px;
	}

	.date {
		width: 60px;
		height: 16px;
	}

	.body {
		grid-column: 1 / -1;
		grid-row: 2 / 3;
	}

	.bodySkeleton {
		border-radius: 20px;
		border-top-left-radius: 2px;
	}
}
