@import "modules/SearchCandidate/TableVariables/fonts.scss";

.container {
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	svg {
		margin: 1rem auto 0.5rem auto;
	}
}

p {
	padding: 0;
	margin: 0;
}

.title {
	color: #262626;
	@include MediumParagraph;
	margin-bottom: 0.25rem;
	font-weight: 500;
}

.suggestion {
	color: #a3a3a3;
	font-family: "Work Sans";
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: -0.5px;
	line-height: 21px;
}

.loading {
	color: #a3a3a3;
	font-family: "Work Sans";
	font-size: 1rem;
	text-align: center;
	margin: 10px;
}
