.taskItem {
	padding: 0;
	display: block;

	.title {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: hsl(200, 5%, 11%);
		margin: 0 0 5px 0;
		width: calc(100% - 143px);
		text-align: left;
		word-break: break-all;
	}
	.detail {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		margin: 0 0 3px;
		color: #48494a;
		text-align: justify;
	}
}
.taskItemTitle {
	display: flex;
	align-items: flex-start;
}
.less {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
	&.more {
		max-height: 220px;
	}
	.title {
		margin-top: 20px;
	}
}
.taskButton {
	background-color: transparent;
	width: 100%;
}
