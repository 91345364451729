.tabs {
	margin-bottom: 0;
}

.stickyHeader {
	padding: 16px 0;
	background-color: white;
	position: sticky;
	top: 76px;
	z-index: 10;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.alertContainer {
	background-color: white;
	margin-bottom: 20px !important;
	padding: 16px 0 !important;
	:global {
		.description {
			margin: 0 !important;
		}
	}
}
