.container {
	margin-bottom: -70px;
}
.layout {
	min-height: calc(100vh - 70px);
	max-height: calc(100vh - 70px);
	background: #fff;
	display: flex;
	flex-direction: column;
}
.contentLayout {
	display: flex;
	padding: 0;
	flex-grow: 1;
	overflow-y: auto;
}

.sidebar {
	width: 311px;
	padding: 8px 20px 0px 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
}

.content {
	flex-grow: 1;
	margin-bottom: 16px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
}
