.calendar {
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px 13px;
}

.fullWidth {
	width: 100%;

	table {
		margin-top: 10px;
		width: 100%;
	}
}

.toggleButton {
	margin-left: auto;
}

.selectAll {
	display: flex;
	gap: 16px;
	align-items: center;
	border-radius: 10px;
	border: 0.5px solid #e2e8f0;
	background: #fff;
	padding: 13px var(--6, 16px);
	box-shadow: 0px 1px 1px 0px rgba(0, 14, 51, 0.05);
	width: 100%;

	div {
		color: #334155;
		text-align: center;
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: -0.5px;
	}
}

.greyCard {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	border-radius: 8px;
	background: #f8fafc;
	margin-top: 12px;
}

.separator {
	height: 1px;
	width: 100%;
	margin: 8px 0px;
	background-color: #e2e8f0;
}
