.applicantName {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}
.companyType {
	border-radius: 100px;
	padding: 4px 8px;
}
.freelancer {
	background: #e5effd;
	color: #814aff;
}
.consultancy {
	background: #ffebd3;
	color: #916a00;
}
.status {
	border-radius: 100px;
	padding: 4px 17px;
	color: #48494a;
}
.signed {
	background: #e2f1ea;
}
.pending {
	background: #f1f2f3;
}
.progressContainer {
	width: 250px;
}
.progress {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 14px;
	color: #48494a;
	margin: 0;
}
