.container {
	height: 100%;
}

.root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.field {
	padding: 24px 0;
	border-bottom: 1px solid #f5f5f5;
	display: flex;
	align-items: center;
	&.fieldOffices {
		padding: 8px 0;
	}

	&:last-child {
		border-bottom: 0;
	}
	.label {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #737373;
		width: 30%;
	}
	.infoLabel {
		width: 70%;
		font-family: "Work Sans";
		&.fullWidth {
			width: 100%;
		}
		.phoneNumber {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #262626;
		}
		&.editMode {
			display: flex;
			gap: 6px;
			.editInput {
				position: relative;
				border: 1px solid #e5e5e5;
				border-radius: 3px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				width: 100%;
				.deletBtn {
					position: absolute;
					top: 10px;
					right: 10px;
					background: transparent;
					border: none;
					padding: 0;
				}
			}
		}

		.textLabel {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #262626;
			height: 36px;
			min-height: 36px;
			padding: 10px;
		}
		.inputStyle {
			background: #ffffff;
			font-family: "Work Sans";
			border: 1px solid #e5e5e5;
			border-radius: 3px;
			padding: 10px;
			width: 100%;
			height: 36px;
			min-height: 36px;
			color: #262626;
		}
	}
}
