@import "modules/SearchCandidate/TableVariables/fonts.scss";

.panel {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	gap: 20.5px;
	position: relative;
}

.tabsNav {
	flex: 2;
	overflow: hidden;
}
.tabs {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0 3px;
	list-style: none;
	white-space: nowrap;
}

.default {
	color: #737373;
	padding: 2.5px 7px;
	border-radius: 20px;
	border: 1px solid #a3a3a3;
	margin-left: 0.5rem;
}

.tab,
.tabDefault {
	all: unset;
	position: relative;
	text-align: center;
	padding: 10px 0;
	text-decoration: none;
	@include SmallParagraph;
	color: #262626;
	font-weight: 500;
	gap: 5px;
	&.active {
		&::before {
			content: "";
			position: absolute;
			height: 2px;
			background-color: #00857a;
			bottom: -2px;
			left: 0;
			right: 0;
		}
	}
	&:hover {
		color: #222;
		cursor: pointer;
	}
}

.tab {
	&:not(:last-child) {
		display: flex;
		align-items: center;
		padding-right: 25px;

		&::before {
			right: 25px;
		}
	}
}

.tabDefault {
	display: flex;
	align-items: center;
	padding: 7px 5px;
	&.active {
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #00857a;
			bottom: -2px;
			left: 0;
		}
	}
	&:hover {
		color: #222;
		cursor: pointer;
	}
}
.presentationSlider {
	width: 100%;
	height: 2px;
	margin-left: 3px;
	background-color: #e5e5e5;
}
.btnContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.dropDownUl {
	width: 10rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	color: #262626;
	list-style: none !important ;
}
.dropDownLi {
	width: 100%;
	padding: 0.5rem 0.75rem;
	text-align: left;
	@include SmallParagraph;
	color: #262626;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}
.dropDownLi:hover {
	background-color: #f5f5f5;
}

.flexContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.seeMore {
	@include MediumParagraph;
	color: #262626;
	position: relative;
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.25rem;
}
.arrow {
	display: block;
}

.containerTabs {
	display: flex;
	white-space: nowrap;
	width: calc(100% - 120px);
	overflow: hidden;
	visibility: hidden;
	height: 0;
}
