.root {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;

	.text {
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.125rem;
		font-family: "Work Sans";
		/* identical to box height, or 120% */
		width: 6rem;

		display: flex;
		align-items: center;

		/* neutral/500 */

		color: #737373;
	}
}
