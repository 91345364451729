.card {
	display: flex;
	gap: 8px;
}

.inputContainer {
	margin-bottom: 10px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.input {
	all: unset;
	box-sizing: border-box;
	height: 40px;
	padding: 0 12px;
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);

	&:focus {
		border: 1px solid #00857a;
	}
}

.actionButton {
	all: unset;
	color: var(--text-dark, #334155);
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	cursor: pointer;

	&:hover {
		background: var(--bg-hover, #f8fafc);
	}

	&.hidden {
		opacity: 0;
	}
}

.titleError {
	margin-top: 8px;
}
