.container {
	position: relative;
	display: flex;
	padding: 12px 8px 8px 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-radius: 5px;
	border: 1px solid #e2e8f0;
	background: #f8fafc;
}

.head {
	position: absolute;
	top: -9px;
	left: 10px;
	display: flex;
	gap: 5px;
	align-items: center;
}
