$primaryColor: #ff6849;
$successColor: #36ba90;
$borderColor: #b9bdc0;
$inputBorderColor: #8d8d8d;
$gray: #f6f6f6;
$darkGray: #394047;
$lightGray: #7f8992;
$notifUnredBackgroundColor: #f3f5fa;
$white: #ffffff;
$black: #000000;
$sidebarBtnActive: #fa6669;
$greenModalBgColor: #5bc4a3;
$anchorsColor: #58626b;
$notification: #fafbfd;
$borderColor: #e2e2e2;
$lineHover: #f7f8f9;
$paused: #f75356;
$inputColor: #3d4b60;
$senior: #4eb2d7;
$avatar: #e9f0f4;
$bullColor: #909aa3;
$classLabel: #9ba2a7;
$sectionBorderColor: #e4e7ee;
$labelBorderColor: #d0d5d9;
$stoppedColor: #ffbf64;
$arrow: #9ea0a3;
$btnDisabled: #d2d7db;
$divider: #f0f1f4;
$pacItem: #5c636a;
$jobData: #969a9e;
$bodyBg: #f1f2f5;
$contactColor: #505c68;
$btnTrColor: #8a95a1;
$btnFaded: #bbc2c9;
$accepted: #53c79f;
$live: #ffc168;
$dropdownPauseColor: #ed5151;
$stepperDividerColor: #bdc7d1;
$stepperTitleColor: #8b99a6;
$btnInterv: #d1d8dc;
$btnBlueColor: #5dacd0;
$editProfileBorder: #dcdfe6;
$cvSidebarColor: #3d4d65;
$darkOrange: #f1684d;
$checkboxBorderColor: #bcc7cd;
$skillsIntroBg: #daedf7;
$skillsIntroBorder: #b3cfdd;
$skillsIntroColor: #31708f;
$disabledSelect: #f7f8fa;
$alertColor: #ab686c;
$alertBg: #ffedee;
$alertBorder: #ffacb1;
$dangerBg: #f34b57;
$btnDangerBg: #fee6e8;
$btnDangerBorder: #f6d8db;
$backdropBg: #394148;
$cvBorderColor: #e0e1e4;
$inviteTextColor: #353c46;
$dropdownBorderColor: #cccccc;
$dropdownItemColor: #666666;
$datePickerItemsBg: #eeeeee;
$datePickerItemsColor: #999999;
$datePickerTfoot: #f9f9f9;
$notifColor: #515d6f;
$notifBorderColor: #f3f4f5;
$notifTimeColor: #7b8691;
$signupIcons: #ebedee;
$staticPageContainer: #eaecee;
$notifOff: #e5e5e5;
$missionNotif: #a5b1bb;
$dot: #ebecf0;
$disabled: #b8bcc0;
$panelColor: #7496aa;
$panelBg: #fbfbfb;
$panelHeadeColor: #505f75;
$panelBorderColor: #e9eef1;
$feedbackErrorBgcolor: #f54953;
$loaderBasecolor: #eef0f1;
$color404: #879098;
$labelItem: #f6f8fa;
$trColor: #626b74;
$dashboardBg: #fafafb;
$pColor: #707a82;
$bidList: #51585e;
$tableBg: #fefefe;
$linkedInColor: #f3f8fb;
$linkedInColorHover: #52a2c6;
$greenColor: #3ebb90;
$dashboardBorder: #dcdddd;
$btnColor: #c0c4c7;
$refuse: #e8634a;
$draftColor: #888e95;
$sentColor: #6dd4dc;
$extendedColor: #68bde1;
$publishedColor: #60cfac;
$bids: #90989e;
$dashboardSide: #2d3540;
$pending: #fcd16a;
$sliderColor: #e9e9e9;
$selectColor: #626b74;
$buttonHeader: #b1b9c3;
$selectControl: #b3b3b3;
$selectOuterColor: #e6e6e6;
$selectControlBottom: #d9d9d9;
$footerBg: #1c2029;
$footerSectionBorder: #292f3c;
$maxYellow: #f9a937;
$dropdownHover: #ea5f43;
$fileGroupBorder: #e5e6e6;
$dragActive: #f6f7f8;
$dragCancelBtn: #b6bec6;
$fileUploaderColor: #bbc6d3;
$labelRemoveColor: #7c8894;
$labelItemBorder: #e7e7e7;
$labelRemoveBg: #e4eaf0;
$labelClose: #777777;
$BullIcon: #f54b55;
$itemEditIcon: #e2e3e7;
$companyRole: #9ea0a7;
$companyBtn: #eeeff1;
$tooltipInner: #e5e5ec;
$tooltipBorder: #ccd3d8;
$darkTooltip: #394047;
$leaveContent: #193651;
$getHelpHolder: #dadbe0;
$getHelpHover: #f5f5f7;
$getHelpInput: #d0d0d0;
$cookiesBorder: #fd6950;
$cookiesLink: #2d557d;
$headerDivider: #475261;
$offlineLi: #240d3c;
$featureBtn: #464e57;
$pika: #ef684c;
$pikaButton: #59636c;
$sliderActive: #96dbfa;
$richEditorBlockquote: #eee;
$richEditorControl: #dedfe2;
$selectablePlaceholder: #bdbdbd;
$selectableDropdown: #eaebeb;
$selectValue: #007eff;
$selectHover: #d0021b;
$selectOption: #7d8690;
$selectMulti: #c2e0ff;
$selectMultiHover: #d8eafd;
$selectPlaceholder: #9d9d9d;
$selectMultiValueHover: #0071e6;
$selectMultiDisabled: #fcfcfc;
$selectMultiDisabledBorder: #e3e3e3;
$invitationName: #e6e6eb;
$invitationDescription: #f8f8f9;
$invitationDescriptionBorder: #22a6e5;
$invitationSuccess: #58636c;
$bidBoxBorder: #e1e4e7;
$requestIcon: #bc5359;
$requestIconSpinner: #ae9047;
$sortBox: #c2ccd3;
$sortAction: #96999a;
$sortMenu: #e5e7eb;
$matchingDetails: #e9ebed;
$svgStroke: #dfe6ec;
$interviewPopOver: #d3dadf;
$interviewTitle: #b8c0c8;
$bidAction: #fd846b;
$warningBg: #fff2b5;
$warning: #8d7d32;
$companyLeftBorder: #edebeb;
$cvIntro: #f1f3f6;
$stepsP: #c8cbd4;
$switcherBg: #f7f8fc;
$greyBg: #f7f9fa;
$greyBorder: #eaf0f8;
$featureBody: #f8f9fb;
$webcomBg: #040606;
$webcomGreenBg: #37ba90;
$webcomRoundBtn: #4b4b4b;
$webcomError: #f67274;
$webcomLiBg: #f9755a;
$webcomLiHoverBg: #cc533a;
$legend: #eff0f3;
$spends: #54657e;
$slideDon: #eceff4;
$invoiceColor: #282b2d;
$searchInput: #636e7e;
$searchP: #ef4d56;
$resetFilter: #c0c3cc;
$searchInputBg: #a4a7b0;
$searchInputBorder: #e5656d;
$veryLightYellow: #fffdf3;
$lightYellow: #ffe89b;
$trBorder: #f1f2f6;
$tableLinkColor: #9fa7ae;
$depSelect: #d7d9e0;
$onboarded: #1a91cf;
$notifWatchColor: #7b8691;
$bidsTitle: #3d4d60;
$notifIcon: #dedede;
$titleColor: #cbd2d8;
$interviewersColor: #f34235;
$oldBoxColor: #939ba3;
$borderBoxColor: #e2e6eb;
$interviewRedColor: #ec666e;
$filled: #1a91ce;
$focusColor: #73b7f8;
$clearFilterColor: #727e96;
$highlight: #f7dad4;

//
$blueDarker: #091b34;
$gray900: #111827;
$gray700: #374151;
$gray500: #6b7280;
$gray300: #d1d5db;
$gray200: #e5e7eb;
$gray100: #f3f4f6;
$primary500: #00857a;
$primary400: #339d95;
$primary300: #66b6af;
$primary200: #99ceca;
$primary100: #cce7e4;
$red500: #ef4444;
$red400: #f87171;
$red200: #fecaca;
$red100: #fee2e2;
