.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;
	margin-block: 6px;
}
