.supplierTable {
	td,
	th {
		padding: 1.5rem 1rem;
		white-space: nowrap;
	}

	.acceptCta {
		color: #00a47c;
		padding: 0;
	}
	.refuseCta {
		color: #58626b;
		padding: 0 10px 0 0;
	}
	.fileLink {
		display: block;
		font-size: 14px;
		color: #ff6849;
		text-decoration: none;
		text-transform: none;
		width: 240px !important;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			text-decoration: underline;
		}
	}
}
.date {
	white-space: nowrap;
}
