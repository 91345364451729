.container {
	margin-bottom: -60px;
	height: calc(100vh - 70px);
	display: flex;
	flex-direction: column;
}

.contentLayout {
	display: flex;
	background: #fff;
	flex-grow: 1;
	overflow-y: auto;
}
