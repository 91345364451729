.label {
	display: flex;
	align-items: center;
	gap: 12px;

	div {
		color: #334155;
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}

.accordion {
	background-color: #f9fbfd !important;
	border-radius: 8px !important;
	overflow: hidden;
	width: 100%;
	padding: 0 !important;
	border: none !important;
}

.accordionContent {
	&[data-state="open"] {
		margin-top: 0 !important;
		border-top: none !important;
	}
}

.triggerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px !important;

	padding: 16px !important;
	cursor: pointer;
	background-color: #ffffff !important;
	.headerText {
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		color: #334155;
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.toggle {
		font-size: 16px;
		color: #6b7280;
		display: flex;
		align-items: center;
	}
}

.fileInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem !important;
	border-top: 1px solid #e0e6ed;
	.name {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		font-family: "Work Sans";
		color: #334155;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		flex-grow: 1;
		max-width: 200px;
	}
	.size {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 14px;
		font-weight: 400;
		color: #64748b;
		font-family: "Work Sans";
	}
	.deleteButton {
		background-color: transparent;
		display: flex;
		justify-content: flex-end;
		padding: 0;
	}
}
