.popover {
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
		0px 1px 3px 0px rgba(16, 24, 40, 0.1);
	padding: 8px 12px;
	color: #334155;
	font-family: "Work Sans";
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.controls {
	display: flex;
	align-items: center;
	gap: 38px;
	button {
		all: unset;
		border-radius: 8px;
		background: #f8fafc;
		height: 36px;
		width: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			background: #eff3f5;
		}
	}
}

.preview {
	display: flex;
	align-items: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	div {
		width: 33.33%;
		text-align: center;
	}
}
