@import "modules/SearchCandidate/TableVariables/fonts.scss";

.modal {
	&__title {
		font-weight: 500;
		@include H3text;
		color: #262626;
		margin: 0;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 1.5rem 0;

		svg {
			display: block;
			cursor: pointer;
		}
	}

	&__form_group {
		margin-bottom: 1.5rem;
	}

	&__text {
		@include SmallParagraph;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 21px;
		color: #404040;
		margin: 20px 0;
	}

	&__label {
		@include SmallParagraph;
		font-weight: 500;
		line-height: 1.125rem;
		color: #737373;
		display: block;
		margin: 0 0 0.25rem 0;
	}

	&__input {
		@include SmallParagraph;
		font-weight: 400;
		line-height: 1.125rem;
		color: #262626;
		display: block;
		border: 1px solid #e5e5e5;
		border-radius: 3px;
		padding: 0.5rem;
		width: 100%;

		&::placeholder {
			color: #a3a3a3;
		}

		&:hover {
			background-color: #fafafa;
		}

		&:focus {
			border: 1px solid #66b6af;
			background-color: white;
		}

		&.error {
			border: 1px solid #f87171;
		}
	}

	&__btn_container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 0.5rem;
	}

	&__btn_cancel {
		@include SmallParagraph;
		font-weight: 500;
		background: transparent;
		color: #525252;
		font-family: "Work Sans";
		padding: 0.75rem 1rem;

		&:hover {
			background: #f5f5f5;
		}
	}

	&__error {
		display: flex;
		align-items: center;
		@include XsmallParagraph;
		font-weight: 500;
		color: #dc2626;
		margin-top: 0.25rem;
	}
}
