$font-family: "Work Sans";

.clampedContent {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.clampedContent *,
.unclampedContent * {
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	word-break: break-word;
}

.seeMoreButton,
.seeLessButton {
	margin-top: 5px;
	background: none;
	padding: 0;
	color: #108c82;
	text-decoration: none;
	font-weight: 500;
	margin-right: 0px;
	&:hover {
		text-decoration: underline;
		color: #006a62;
	}
}
