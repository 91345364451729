@import "../../Variables/styles.scss";

@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}
.rootQuick {
	background: $primayBackground;
	box-shadow: 0px 0px 2px 2px rgba(24, 24, 25, 0.04),
		0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198);
	border-radius: $raduisButton;
	position: absolute;
	z-index: 10;
	padding: $spacing;
	padding-right: 50px;
	top: 30px;
	right: 0;
	min-width: max-content;
	h3 {
		@include font;
		line-height: 20px;
		color: $aluminium;
		margin-bottom: 10px;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			@include font;
			color: $primayColor;
			margin-bottom: 6px;
			span {
				margin: 0;
			}
		}
	}
}
