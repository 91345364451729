.weatherEmptyState {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
	margin-top: 83px;
	img {
		max-width: 138px;
	}
	p {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		color: #fff;
	}
}
.date {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #fff;
	text-transform: uppercase;
}
.username {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	color: #fff;
}
