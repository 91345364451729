.container {
	display: flex;
	font-style: normal;
	font-family: "Roboto";
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-top: 12vh;
	margin: auto;
	width: 720px;
	gap: 20px;

	.heading {
		display: flex;
		flex-direction: column;
		gap: 16px;
		h1 {
			font-size: 20px;
			font-weight: 600;
			line-height: 23px;
		}
		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 19px;
		}
	}
}
