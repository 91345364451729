.td {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 12.25px;
	line-height: 14px;
	color: #202223;
	margin: auto;
}

td.disabledCell {
	cursor: not-allowed;
}

.cellContent {
	color: #56576a;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	display: flex;
	font-weight: 500;
	font-size: 15px;
}

.weekendCellContent {
	color: #92939d;
}

div div.cellContentSelected {
	color: white;
}

td div.disabledCellContent {
	color: #dee2e8;
}

td .middleRange {
	background-color: #e6f3f2;
	color: white;
	border-radius: 0%;

	div {
		color: #1a9187;
	}
}

td.disabledCell {
	cursor: not-allowed;
}

.currentDay {
	color: #1a9187;
	border-radius: 5px;
}

.cell {
	outline: 2px solid transparent;
	outline-offset: 2px;
	border-radius: 11px;
	width: 35px;
	height: 35px;
}

td .selected {
	--tw-text-opacity: 1;
	background-color: #1a9187;
	color: white;
	border-radius: 5px;
}

.disabled {
	color: gray;
}

.button {
	outline: 2px solid transparent;
	outline-offset: 2px;
	font-family: inherit;
	font-size: 100%;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}

.bigCell {
	margin-bottom: 10px !important;
	width: 50px;
	height: 44px;
}

.whiteCell {
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 1px 1px 0px rgba(0, 14, 51, 0.05);
}
