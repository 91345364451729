.description {
	display: flex;
	gap: 21px;
	align-items: center;
	padding: 20px;
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.btn {
	height: 40px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
}

.confirm {
	color: #fff;
	background-color: #d92d20;
	border-color: #d92d20;
}
