// @import "../../../../Variables//styles.scss";
@import "modules/candidate/Profile/Variables/styles.scss";
.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	p {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 1.125rem;
		line-height: 1.5rem;
		color: #262626;
		margin-bottom: 0.25rem;
	}
	span {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.125rem;
		color: #a3a3a3;
	}
}
