.dialogContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	h2 {
		color: #334155;
		font-family: "Work Sans";
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 20px;
	}
	h3 {
		color: #334155;
		font-family: "Work Sans";
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		margin-bottom: 8px;
	}
	p {
		color: #64748b;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 30px;
		text-align: center;
		width: 622px;
	}
}
.imageCover {
	margin-bottom: 46px;
	img {
		display: block;
		width: 100%;
	}
}
.ctaBlock {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	.getStartedBtn {
		border-radius: 8px;
		border: 1px solid #00857a;
		background: #00857a;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #fff;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 12px;
		padding: 8px 54px;
		min-width: 284px;
		outline: none;
	}
	.skipBtn {
		color: #475569;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.5px;
		background: none;
		padding: 8px 54px;
		min-width: 284px;
		outline: none;
	}
}
