.container {
	display: flex;
	padding: 14px;
	align-items: center;
	gap: 29px;
	align-self: stretch;
	border-radius: 6px;
	border: 1px solid #e5e5e5;
	background: #fff;
	height: 44px;
	&.multiLines {
		align-items: flex-start;
		height: 215px;
	}
}

@keyframes rotateHorizontal {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(360deg);
	}
}

.icon {
	animation: rotateHorizontal 2s infinite linear;
}

.iconWrapper {
	display: flex;
	padding: 6px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
}

.textLineSkeletonContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	&.multiLines {
		align-self: stretch;
		overflow-y: hidden;
	}
}

.textLineSkeleton {
	border-radius: 7px;
	background: #e2e8f0;
	width: 100%;
	height: 12px;
}
