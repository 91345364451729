@import "../../Variables/styles.scss";

.container {
	width: 100%;
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	p {
		@include profileContent;
		line-height: 1.43;
		margin: 0;
		color: $blackPearl;
	}
}
