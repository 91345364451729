.mainTitle {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 27px;
	letter-spacing: -0.5px;
}

.secondaryTitle {
	color: #64748b;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.head {
	display: flex;
	align-items: center;
	gap: 7px;
}

.separator {
	width: 100%;
	height: 1px;
	background-color: #e2e8f0;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
}
