$font-family: "Work Sans";
.collaboratorsContainer {
	display: flex;
	gap: 10px;
	align-items: center;
}

.collaboratorsWrapper {
	display: flex;
	&.disabled {
		opacity: 0.5;
	}
}
div.firstCollaboratorPicture {
	margin-left: -3px;
}

.collaboratorPicture {
	border-radius: 100%;
	border: 3px solid white;
	margin-left: -8px;
}

.images {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
}
.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
	width: 34px;
	height: 34px;
}

.picture {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 34px;
	height: 34px;
	box-shadow: 0px 0px 0px 2px #ffffff;
	border-radius: 50%;
	background: white;
	cursor: pointer;
}

.avatar {
	display: flex;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;

	> div {
		position: relative;
		font-size: 13px;
	}
}

.addIcon {
	cursor: pointer;
	width: 28px;
	height: 28px;
}

.disabledButton {
	display: flex;
	align-items: center;
	background: #f1f5f9;
	border-radius: 9999px;
	padding: 0;
	cursor: default;
}

.error {
	margin-top: 0.5rem;
}

.remainingUsers {
	display: flex;
	width: 33px;
	height: 33px;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 38px;
	border: 1px solid #fff;
	background: #f1f5f9;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

	color: #334155;
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	cursor: pointer;
}

.remainingAttendeesList {
	display: flex;
	flex-direction: column;
	gap: 7px;
	padding: 7px 10px;
}
.attendee {
	display: flex;
	align-items: center;
	gap: 7px;
	color: #334155;
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	.avatar {
		display: flex;
		align-items: center;
		border-radius: 50%;
		cursor: pointer;

		> div {
			position: relative;
			font-size: 13px;
		}
	}
}

.badgeOwner {
	padding: 0px 4px;
	border-radius: 8px;
	background: #e6f3f2;

	color: #00857a;
	font-family: $font-family;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.avatarImg {
	border-radius: 100%;
}

.skeleton {
	display: flex;
	align-items: center;
	.skeletonItem {
		border: 1px solid #fff;
		margin-left: -5px;
	}
}
