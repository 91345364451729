.container {
	max-width: 100%;
	overflow: hidden;
}

div.container ul.list {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}

.container span.text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	color: #111827;
	font-size: 14px;
	line-height: 16px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.container li {
	padding-top: 14px !important;
	padding-bottom: 14px !important;
}

.container li:not(:last-child):before {
	margin-left: 7px !important;
}

.container li {
	background-color: #f9fafb !important;
}

.container li:not(:last-child):after {
	border-left: 50px solid #f9fafb !important;
}
