.box_container {
	width: 100%;
	background: #ffff;
	padding: 15px;
	border: 1px solid #f1f2f3;
	box-sizing: border-box;
	border-radius: 10px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	.box_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}
}
.headerStart {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	color: #181819;
}
.dateTimeDisplayer {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	.time {
		font-family: "basiercircle";
		font-size: 14px;
		line-height: 16px;
		color: #8c9196;
	}
}
.divider {
	display: inline-flex;
	width: 2px;
	height: 18px;
	background: #00ba88;
	border-radius: 5px;
}

.emptyStateContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	.flexColumn {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}

.gray_text {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #babec3;
}
.dark_gray_text {
	color: #8c9196;
}

.emptyStateButton {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	background: #ffffff;
	box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
	border-radius: 4px;
	border: 1px solid #fd5749;
	font-family: "basiercircle";
	font-size: 14px;
	line-height: 20px;
	color: #fd5749;
}
.flexCenter {
	display: flex;
	align-items: center;
	gap: 5px;
}
.count {
	background: #fd5749;
	border-radius: 50%;
	color: white;
	width: 20px;
	height: 20px;
	margin-left: 5px;
	font-size: 11px;
	line-height: 20px;
}
.attendees {
	display: flex;
	align-items: center;
	gap: 2px;
}
