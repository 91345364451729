.cardContent {
	gap: 60px;
}

.tabsTitleContainer {
	display: flex;
	gap: 4px;
	align-items: center;
}

.tabPanels {
	border: none;
}

div.tabsHeader {
	margin-bottom: 0px;
}

.tabsHeader > div {
	height: 0px;
}

div.tabsRoot {
	gap: 0px;
}

.descriptionForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 28px;
	background-color: #f9fafb;
	padding: 14px;
	border-radius: 0 0 12px 12px;
}

.inputContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr min-content;
	column-gap: 13px;
}
