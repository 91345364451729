.link-editor {
	display: flex;
	align-items: center;
	gap: 8px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	max-width: 400px;
	width: 100%;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 0 0 8px 8px;
	transition: opacity 0.5s;
	will-change: transform;
}

.padding6 {
	padding: 6px;
}

.link-editor a {
	color: #00857a;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	text-decoration-line: underline;
	flex-grow: 1;
	padding: 8px;
}

.link-editor .button {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 6px;
	border-radius: 8px;
	cursor: pointer;
	margin: 0 2px;
}

.link-editor .button.hovered {
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: #eee;
}

.link-editor .button i,
.actions i {
	background-size: contain;
	display: inline-block;
	height: 20px;
	width: 20px;
	vertical-align: -0.25em;
}

.link-editor .link-cancel,
.link-editor .link-confirm,
.link-editor .link-edit,
.link-editor .link-trash {
	height: 36px;
	width: 36px;
	padding: 4px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	display: flex;
	justify-content: center;
}

.link-editor .link-input {
	color: #64748b;
	margin: 0px;
	height: 36px;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 400;
	border-radius: 8px;
	background-color: #f8fafc;
	padding: 8px;
	border: 1px solid #e2e8f0;
}

.link-confirm svg path {
	stroke: #475569;
}
