.container {
	display: flex;
	align-items: center;
	padding: 1.5rem;
	border-top: 1px solid #e5e5e5;
	flex-basis: auto;
}

.cancelButton {
	color: #334155;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
}

.cancelButton,
.saveButton {
	height: 40px;
	padding: 8px 32px;

	div {
		font-size: 16px;
	}
}
