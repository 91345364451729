.scoreContainer {
	display: flex;
	padding: var(--1, 4px) var(--2, 8px);
	align-items: center;
	gap: var(--1, 4px);
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);

	span {
		color: var(--text-dark, #334155);
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		line-height: 150%;
	}
}
