.root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	h4 {
		margin-bottom: 2px;
	}
	select {
		padding: 0 10px;
		height: 30px !important;
		border: 1px solid #e2e2e2;
	}
	.fieldContainer {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;
		p {
			margin-bottom: 0;
		}
		> div {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 1rem;
			flex-wrap: wrap;
			position: relative;
			button {
				align-self: unset;
			}
			select ~ svg {
				position: absolute;
				right: 5px;
			}
		}
		.fieldError {
			color: red;
			font-size: 13px;
		}
	}

	input {
		border: none !important;
		height: 30px;
		min-width: 5px !important;
		&:focus {
			border: none !important;
			box-shadow: none !important;
		}
	}
}
