.drawerContent {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--6, 24px);

	.header {
		background-color: #fff;
		position: sticky;
		top: 0;
		width: inherit;
		z-index: 2;

		.headerInline {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: var(--6, 24px);

			.closeBtn {
				gap: 12px;
				display: flex;
				border-radius: 8px;
				background: #fff;
				align-items: center;

				color: #1e293b;
				font-weight: 500;
				line-height: 21px;
				font-size: 15.75px;
				font-style: normal;
				letter-spacing: -0.5px;
				font-family: "Work Sans";

				&:hover {
					background: #f8fafc;
				}
			}
		}
	}

	.divider {
		height: 1px;
		align-self: stretch;
		background: var(--border-gray, #e2e8f0);
	}

	.headerContainer {
		width: inherit;
		padding: 0 var(--4, 16px);
		position: relative;

		.inline {
			border-radius: var(--lg, 12px);
			padding: var(--4, 16px);
			border: 1px solid #f1f5f9;
			background: var(--gray-50, #f8fafc);
			display: inline-flex;
			width: inherit;

			.avatar {
				margin-right: 18px;
				width: 55px;
				height: 55px;
			}

			.blockName {
				display: flex;
				flex-direction: column;
				width: inherit;

				.nameContainer {
					display: flex;
					align-items: flex-start;
					align-self: baseline;
					gap: var(--gap-size, 8px);

					.name {
						color: var(--text-dark, #1e293b);
						font-family: "Work Sans";
						font-size: 18px;
						font-style: normal;
						font-weight: 600;
						line-height: 150%;
					}
				}

				.inlineSpace {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.phoneContainer,
					.mailContainer,
					.scoreContainer {
						display: flex;
						align-items: center;
						gap: 4px;
						align-self: end;

						a {
							text-decoration: none !important;
						}
					}

					.profileType {
						display: flex;
						padding: 0 var (--2, 8px);
						align-items: flex-start;
						gap: var(--2, 8px);
						font-family: "Work Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
						width: fit-content;

						.profileTypeFreelancer {
							color: #0253c5;
							padding: 2px 4px;
							border-radius: var(--md, 8px);
							background: #d7efff;
						}

						.profileTypeConsultancy {
							color: #764ada;
							padding: 2px 4px;
							border-radius: var(--md, 8px);
							background: #f1eeff;
						}
					}
				}
			}
		}

		.ConsultantContactTitle {
			color: var(--gray-400, #9ca3af);
			font-family: "Work Sans";
			font-size: 12.25px;
			font-style: normal;
			font-weight: 600;
			line-height: 14px;
			text-transform: uppercase;
		}
	}

	.tabPanel {
		width: inherit;
		padding: 0 var(--6, 24px);

		.tabs {
			display: flex;
			align-items: flex-start;
			gap: var(--2, 8px);
			margin-bottom: 10px;

			.tab {
				all: unset;
				position: relative;
				display: flex;
				margin: 0;
				height: 32px;
				padding: 0px var(--2, 8px);
				align-items: center;
				gap: var(--1, 4px);
				flex-shrink: 0;
				list-style: none;
				color: var(--text-light, #64748b);
				text-align: center;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%;
				letter-spacing: -0.5px;
				border-radius: var(--md, 8px);

				&.active {
					color: #262626;
					background: var(--bg-brand-light, #e6f3f2);
					color: var(--text-brand-dark, #006a62);
				}

				&:hover {
					color: #222;
					cursor: pointer;
				}
			}
		}
	}

	.panel {
		overflow-y: auto;
		width: inherit;
		padding: 0 var(--6, 24px);
		margin-bottom: var(--6, 24px);
		height: 100%;
	}

	.presentationSlider {
		height: 2px;
		align-self: stretch;
		background: var(--gray-100, #f1f5f9);
	}
}

.flexStart {
	align-self: flex-start !important;
}

.green {
	color: #039855;
}
.yellow {
	color: #f59e0b;
}
.red {
	color: #dc2626;
}
.headerTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}
