.datePickerContainer {
	position: relative;
	.datePickerOpener {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #f1f2f3;
		.date {
			font-family: "basiercircle";
			font-size: 12px;
			line-height: 20px;
			color: #8c9196;
		}
	}
}
.button {
	font-family: "basiercircle";
	line-height: 20px;
	color: #fd5749;
	background: #fff;
	border: none;
	outline: none;
}
.disabled {
	cursor: not-allowed;
	color: darkgray;
}
.boxDatePicker {
	visibility: hidden;
	&.showDatePicker {
		visibility: visible;
	}
}
.resetBtn {
	width: 45px;
	background: #ffffff;
	border: 1px solid #fd5749;
	box-sizing: border-box;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 6.5px 12px;
	display: flex;
	align-items: center;
	svg {
		height: 16px;
		path {
			fill: #fd5749;
		}
	}
}
