.countainer {
	display: flex;
	padding: 8px 10px;
	align-items: center;
	gap: 12px;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;

	span {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	svg {
		width: 24px;
		height: 24px;
	}
}
