.form {
	display: flex;
	margin: 0 10px;
	padding: 10px;
	border-radius: 12px;
	border: 1px solid #f1f1f1;
	flex-grow: 1;
}
.newMailContainer {
	flex-grow: 1;
}
.body {
	background-color: #fff;
}
.footer {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	margin-top: 10px;
}

.placeholdersAndTemplates {
	display: flex;
	flex-direction: column;
	width: 280px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
	&.hide {
		display: none;
	}
	.header {
		background: #334155;
	}
}

.confirmButtonClassName {
	background: #d92d20;
	box-shadow: none;
	&:hover {
		background: #d92d20;
	}
	svg {
		path {
			stroke: #fff;
		}
	}
}
.headerButton {
	background: transparent;
	padding: 0;
	width: 1.125rem;
	height: 1.125rem;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #262626;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 10px;
}

.title {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;
	background-color: transparent;
	padding: 0;
	color: #fff;
	text-transform: capitalize;
}

.icon {
	width: 1.125rem;
	height: 1.125rem;
	path {
		stroke: #fff;
		width: 1.125rem;
		height: 1.125rem;
	}
}
