.label {
	display: flex;
	align-items: flex-start;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	p {
		display: inline;
	}
}
.required {
	color: red !important;
	font-family: "BasierCircle";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin-top: 8px;
}
