.drawerContainer {
	height: 100%;
	padding: 20px;
	display: flex;
	position: relative;
	flex-direction: column;
}

.drawerHeaderContainer {
	top: 0;
	gap: 16px;
	z-index: 999;
	display: flex;
	position: sticky;
	flex-direction: column;
	background-color: white;
}
.titleContainer {
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	justify-content: space-between;
	border-bottom: 1px solid #e2e8f0;
	.flexContainer {
		gap: 12px;
		display: flex;
		align-items: center;
	}
	.title {
		font-size: 24px;
		font-weight: 500;
		color: #334155;
		line-height: 24px;
		font-style: normal;
		font-family: Work Sans;
		letter-spacing: -0.5px;
	}
	.closeBtn {
		padding: 8px;
		display: flex;
		outline: none;
		border-radius: 8px;
		align-items: center;
		background: white;
		border: 1px solid #e2e8f0;
		&:hover {
			background: #f8fafc;
		}
	}
}
.candidateCardContainer {
	gap: 16px;
	display: flex;
	padding: 12px;
	align-items: center;
	border-radius: 16px;
	background: #f8fafc;
	border: 1px solid #e2e8f0;
	.avatar {
		width: 55px;
		height: 55px;
		cursor: pointer;
		border-radius: 12px;
		border: 1.5px solid var(--White, #fff);
	}
	.candidateDescription {
		gap: 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.candidateName {
			font-size: 18px;
			font-weight: 500;
			line-height: 100%;
			font-style: normal;
			font-family: Work Sans;
			text-transform: capitalize;
			color: var(--gray-700, #334155);
			margin-bottom: 0;
		}
		.vacancyTitle {
			font-size: 16px;
			font-weight: 400;
			line-height: 150%;
			font-style: normal;
			font-family: Work Sans;
			color: var(--text-light, #64748b);
			font-variant-numeric: lining-nums tabular-nums;
		}
	}
}
.divider {
	width: 100%;
	border-bottom: 1px solid #e2e8f0;
}

.drawerBodyContainer {
	gap: 24px;
	flex-grow: 1;
	display: flex;
	overflow-y: auto;
	padding-top: 24px;
	flex-direction: column;
	form {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.tabsContainer {
	gap: 4px;
	display: flex;
	align-items: center;

	.tabItem {
		margin: 0;
		display: flex;
		padding: 10px 16px;
		border-radius: 8px;
		align-items: center;
		background-color: white;

		font-size: 16px;
		font-weight: 500;
		color: #334155;
		line-height: 100%;
		font-style: normal;
		letter-spacing: -0.5px;
		font-family: "Work Sans";

		&.active {
			color: #006a62;
			background: #e6f3f2;
			&:hover {
				color: #006a62;
				cursor: pointer;
			}
		}

		&:hover {
			color: #222;
			cursor: pointer;
		}
	}
}

.priorityBtn {
	&__red {
		border: 1px solid #d92d20 !important;
		span {
			color: #d92d20;
		}
	}
	&__yellow {
		border: 1px solid #f59e0b !important;
		span {
			color: #d92d20;
		}
	}
	&__green {
		border: 1px solid #039855 !important;
		span {
			color: #039855;
		}
	}
}

.fieldContainer {
	gap: 12px;
	display: flex;
	flex-direction: column;
}
.fieldContainerInline {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.fieldLabel {
	display: flex;
	font-size: 16px;
	min-width: 100px;
	font-weight: 500;
	color: #334155;
	margin-bottom: 0;
	line-height: 21px;
	font-style: normal;
	align-items: center;
	font-family: Work Sans;
	letter-spacing: -0.5px;
	svg {
		margin-right: 12px;
	}
	span {
		color: #64748b;
		font-weight: 400;
		padding-left: 4px;
	}
	.required {
		color: #d92d20;
		font-weight: 400;
	}
}

.dueDateContainer {
	gap: 8px;
	display: flex;
}
.datePickerInput {
	width: 300px;
	height: 44px;
	border-radius: 8px;
}

div.timePickerField {
	height: 44px;
	width: 142px;
}

.checkboxContainer {
	gap: 16px;
	display: flex;
	align-items: center;

	label {
		margin: 0;
		margin-top: 1px;
		font-size: 16px;
		font-weight: 400;
		line-height: 100%;
		font-style: normal;
		font-family: Work Sans;
		letter-spacing: -0.5px;
		color: var(--text-dark, #334155);
	}
	.checkboxField {
		scale: 1.5;
		margin-top: 0px;
		margin-left: 5px;
		&:checked {
			background-color: #00857a;
			border: 1px solid #00857a;
		}
	}
}

.comboBoxContainer {
	width: 450px;
	&[disabled] {
		background-color: #f1f5f9;
		input {
			background-color: #f1f5f9 !important;
		}
	}
}

.priorityContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.priorityBtnContainer {
		gap: 8px;
		width: 450px;
		display: flex;
		align-items: center;
	}
}

.footerContainer {
	gap: 16px;
	bottom: 0;
	z-index: 999;
	display: flex;
	position: sticky;
	margin-top: 24px;
	padding-top: 24px;
	background-color: white;
	border-top: 1px solid #e2e8f0;
}
