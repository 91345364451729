.banner {
	display: flex;
	align-items: center;
	gap: 24px;
	height: 72px;
	background-color: white;
	padding: 16px;
}

.icon {
	// wid
}

.message {
	flex-grow: 1;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.5px;
	color: #4b5563;
}

.link {
	height: 36px;
	padding: 0 16px;
	color: white;
	text-decoration: none;
	border-radius: 8px;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	align-items: center;
	background-color: #f59e0b;
	&:hover {
		color: white;
		background-color: #f59e0baa;
	}
}

.close_btn {
	all: unset;
	cursor: pointer;
	height: 24px;
}
