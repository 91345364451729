.field {
	display: flex;
}

.placeholder {
	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
	background-color: transparent;
	padding-left: 0px;
}
