.globalStyle {
	color: #fff;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: 600;
}
.weatherContent {
	flex-grow: 1;
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 20px;
	background: url(../../images/office-set.png) 40% 95% no-repeat
		rgba(123, 97, 255, 1);
}
.date {
	@extend .globalStyle;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 5px;
	margin-bottom: 5px;
	text-transform: uppercase;
}
.titleWeather {
	@extend .globalStyle;
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 10px;
}
.currentDay {
	display: flex;
	gap: 5px;
	align-items: flex-end;
	.icon {
		width: 62px;
		height: 54px;
		margin-left: -8px;
		img {
			width: 62px;
			height: 54px;
			object-fit: cover;
		}
	}
	.label {
		font-size: 12px;
		line-height: 1;
		text-transform: uppercase;
		display: block;
		@extend .globalStyle;
		margin-bottom: 5px;
	}
	.temp {
		font-size: 28px;
		line-height: 1;
		display: block;
		@extend .globalStyle;
	}
}
.forecast {
	padding: 0;
	margin: 32px 0 0;
}
.forecastItem {
	display: flex;
	gap: 15px;
	margin-top: 12px;
	align-items: center;
	.icon {
		width: 40px;
		height: 40px;
		margin-left: -8px;
		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
		}
	}
	.date {
		font-size: 12px;
		line-height: 1;
		display: block;
		@extend .globalStyle;
		margin-bottom: 0;
	}
	.label {
		font-size: 16px;
		line-height: 1;
		display: block;
		@extend .globalStyle;
	}
}
.loaderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	background-image: none;
	span {
		border-top-color: rgba(123, 97, 255, 1);
	}
}
.emptyState {
	display: flex;
	padding: 20px;
	background-image: none;
}
