.tasksContainer {
	height: 429px;
	[data-reach-tab-panels] {
		margin-top: 24px;
	}
}
.loader_container {
	width: 100%;
	height: 330px;
	display: flex;
	align-items: center;
	justify-content: center;
}
