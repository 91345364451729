@mixin card() {
	background-color: #fff;
	border: 1px solid #e2e8f0;
	border-radius: 12px;
	padding: 12px;
	height: auto;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 14px;
	flex-shrink: 0;
	align-self: stretch;
	padding: 2rem 1rem;

	.separator {
		height: 1px;
		flex-shrink: 0;
		align-self: stretch;
		stroke-width: 1px;
		stroke: #e2e8f0;
		background-color: #e2e8f0;
	}

	.flex {
		display: flex;
		align-items: flex-start;
		gap: 14px;
		align-self: stretch;
		width: 100%;

		.flexColumn {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 14px;
			flex: 1;
			min-width: 60%;
		}

		@media (max-width: 800px) {
			flex-wrap: wrap;
		}
	}
}
