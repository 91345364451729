.root {
	gap: 12px;
	display: flex;
	padding: 12px 14px;
	// margin-right: 14px;
}

.container {
	gap: 12px;
	display: flex;

	flex-direction: column;

	.kanbanContainer {
		gap: 12px;
		display: flex;
	}
}

.addNewStep {
	min-width: 302px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--lg, 12px);
	position: relative;
	background-color: #fff;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		border: 9px dashed #cbd5e1;
		top: -8px;
		bottom: -8px;
		left: -8px;
		right: -8px;
		border-radius: 20px;
	}
	.addNewStepCta {
		padding: 8px 12px;
		display: flex;
		align-items: center;
		gap: 8px;
		border-radius: var(--md, 8px);
		border: 1px solid var(--border-gray, #e2e8f0);
		background: var(--white, #fff);
		color: var(--text-dark, #334155);
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
		letter-spacing: -1px;
		z-index: 9;
		&:hover {
			cursor: pointer;
		}
	}

	.container [data-reach-tab-panels] {
		background-color: #fff;
		width: fit-content;
	}
}
