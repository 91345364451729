div.readonly {
	border: 1px solid #e2e8f0;
	background: red;
	border-width: 0px;

	div > div > div,
	div.avatar img {
		border-radius: 23% !important;
	}
}

div.readOnlyCustomStyle {
	border: 1px solid #e2e8f0;
	background: transparent !important;
	border-width: 0px;

	div > div > div,
	div.avatar img {
		border-radius: 23% !important;
	}
}

.customSelect {
	position: relative;
	min-width: 250px;
	height: 44px;
	max-height: unset;
	z-index: 99;
	align-items: center;
	gap: 25px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	&.editMissionAvatars {
		width: 100%;
		.selectHeader {
			height: 50px;
			max-height: unset;
		}
	}
	&_disable {
		background: #f8fafc;
		cursor: auto;
	}

	.selectHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px 11px 6px 12px;
		gap: 8px;
		border-radius: 8px 8px 0 0;
		cursor: pointer;
		max-height: 40px;
		&.readonly {
			cursor: default;
		}
		.selectedOption {
			font-weight: 400;
			font-size: 15.75px;
			line-height: 21px;
			display: flex;
			align-items: center;
			letter-spacing: -0.5px;
			color: #374151;
		}

		.arrow {
			transition: transform 0.3s;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}

		&.open .arrow {
			transform: rotate(180deg);
		}
	}

	.SelectOnClose {
		border-radius: 8px;
	}

	.SelectOnOpen {
		border-radius: 8px;
	}

	.visible {
		max-height: 500px;
		height: auto;
		opacity: 1;
		transition: max-height 0.8s ease-in-out, opacity 0.4s ease-in-out;
		margin-top: 3px;
		position: absolute;
		width: 100%;
		min-width: 252px;

		.searchArea {
			display: flex;
			gap: 10px;
			padding: 14px;
			align-items: center;
			background: #ffffff;
			border: 1px solid #e2e8f0;
			border-radius: 8px 8px 0 0;
			border-bottom: none;

			.search {
				font-family: "Work Sans";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #94a3b8;
				border: none;
			}
		}

		.optionsList {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			background: #ffffff;
			border: 1px solid #e5e7eb;
			border-radius: 0 0 8px 8px;
			width: 100%;
			max-height: 18rem;
			overflow-y: auto;
			margin: 0;

			&::-webkit-scrollbar-track {
				border: 1px solid #f9fafb;
				padding: 2px 0;
				background-color: #f9fafb;
				border-radius: 0 0 8px 0;
			}

			&::-webkit-scrollbar {
				width: 4.5px;
				border-radius: 8px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #d4d4d4;
				border: 1px solid #d4d4d4;
			}

			.option {
				width: 100%;
				padding: 10px;
				cursor: pointer;

				&:hover {
					background-color: #f5f5f5;
				}

				&.selected {
					background-color: #f5f5f5;
					font-weight: bold;
				}
			}
		}
	}
}

.customStyle {
	position: relative;
	min-width: 250px;
	z-index: 99;
	align-items: center;
	gap: 25px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	// background: #fff;
	&.editMissionAvatars {
		width: 100%;
		.selectHeader {
			height: 50px;
			max-height: unset;
		}
	}
	&_disable {
		// background: #f8fafc;
		cursor: auto;
	}

	.selectHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px 11px 6px 12px;
		gap: 8px;
		border-radius: 8px 8px 0 0;
		cursor: pointer;
		max-height: 40px;
		&.readonly {
			cursor: default;
		}
		.selectedOption {
			font-weight: 400;
			font-size: 15.75px;
			line-height: 21px;
			display: flex;
			align-items: center;
			letter-spacing: -0.5px;
			color: #374151;
		}

		.arrow {
			transition: transform 0.3s;
		}

		&.open .arrow {
			transform: rotate(180deg);
		}
	}

	.visible {
		max-height: 500px;
		height: auto;
		opacity: 1;
		transition: max-height 0.8s ease-in-out, opacity 0.4s ease-in-out;
		margin-top: 3px;
		position: absolute;
		width: 100%;
		min-width: 252px;
	}
}

.customOption {
	display: flex;
	align-items: center;
	gap: 5px;

	.avatar {
		border-radius: 50% !important;
	}

	img {
		width: 28px;
		height: 28px;
		border-radius: 50% !important;
		margin-right: 10px;
	}

	span {
		font-family: "Work Sans";
		font-weight: 400;
		font-size: 15.75px;
		line-height: 21px;
		display: flex;
		align-items: center;
		letter-spacing: -0.5px;
		color: #374151;
	}
}
.customStyleOption {
	display: flex;
	align-items: center;
	gap: 5px;

	.avatar {
		border-radius: 50% !important;
	}

	img {
		width: 28px;
		height: 28px;
		border-radius: 50% !important;
		margin-right: 10px;
	}

	span {
		font-family: "Work Sans";
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		// letter-spacing: -0.5px;
		color: #374151;
	}
}

.hidden {
	max-height: 0;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
