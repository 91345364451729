.title {
	color: var(--text-dark, #334155);
	font-family: BasierCircle;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 18.88px;
	margin-bottom: 12px;
}

.list {
	display: flex;
	flex-wrap: wrap;
	row-gap: 20px;
	margin: 0 -12px;
}

.itemWrapper {
	padding: 0 12px;
	width: 20%;
}

.paginationContainer {
	display: flex;
	justify-content: center;
	:global {
		.pagination {
			all: revert;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 32px;
			margin-bottom: 32px;
			li {
				list-style: none;
				[role="button"] {
					all: revert;
					font-family: "Work Sans";
					font-size: 14px;
					font-weight: 600;
					text-align: center;
					color: #525252;
					cursor: pointer;
					&:hover {
						background: transparent !important;
						color: #262626 !important;
					}
				}
				&.active > [role="button"] {
					background-color: white !important;
					color: #262626 !important;
					position: relative;
					&::after {
						content: "";
						position: absolute;
						inset: auto 0 0 0;
						background-color: #00857a;
						height: 2px;
						border-radius: 99px 99px 0 0;
					}
				}
			}
			li:not(:is(.previous, .next)) {
				[role="button"] {
					height: 32px;
					padding: 0 12px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
			li:is(.previous, .next) {
				.disabled {
					color: #d4d4d4;
				}
				[role="button"] {
					height: 32px;
					width: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				&::after,
				&::before {
					content: "";
				}
			}
		}
	}
}
