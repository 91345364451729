@import "modules/SearchCandidate/TableVariables/fonts.scss";

.results_count {
	@include SmallParagraph;
	color: #737373;
	font-weight: 400;
	line-height: 1.125rem;
	padding: 0;
	.greenText {
		color: #00857a;
		font-weight: 500;
	}
	margin: 0.75rem 0 0.75rem 0.25rem;
}

.menuContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	h5 {
		font-size: 16px;
		color: #000000;
	}
	.menuDropdownContainer {
		height: auto;
		display: flex;
		align-items: center;
		.listItem {
			display: flex;
			align-items: center;
		}
	}
}

.selectIconsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.cellCard {
	border: 1px solid #d4d4d4;
	border-radius: 3px;
	background: #ffffff;
	padding: 5px;
	margin-right: 5px;
	font-family: "Work Sans", sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125rem;
	text-align: left;
	color: #262626;
}

.genericCellLeft,
.genericCellRight {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	text-align: left;
	font-family: "Work Sans", sans-serif;
	line-height: 1.125rem;
}

.badgesContainer {
	z-index: 100;
}

.genericCellRight {
	text-align: right;
}

.selectIconsContainer {
	display: flex;
	align-items: center;
}

.emptyStateContainer {
	height: 2px;
	border-radius: 5px;
	background: #d4d4d4;
	width: 1rem;
	text-align: center;
	margin: 0 auto;
}
.headerItem {
	margin-left: 0.5rem;
	font-weight: 400;
	line-height: 1.125rem;
	font-family: "Work Sans", sans-serif;
	color: #262626;
}
.dots {
	background: transparent;
	&:hover {
		background: transparent;
		cursor: pointer;
		svg path {
			stroke: #737373;
		}
	}
	&.dots[aria-expanded="true"] {
		background-color: transparent;
		svg path {
			stroke: #262626;
		}
	}
}

.quickView {
	background: transparent;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
		svg path {
			stroke: #737373;
		}
	}
}

.LastNoteCell {
	display: flex;
	position: relative;
	width: 100%;
}

.lastNoteMore {
	right: 0;
	color: #00857a;
	font-family: "Work Sans", sans-serif;
	font-size: 14px;
	font-weight: 400;
}
.lastNoteMore:hover {
	font-weight: 500;
	text-decoration: underline;
}
.lastNoteValue {
	max-width: 85%;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: "Work Sans", sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #262626;
}

.link {
	font-size: 14px;
	color: #00857a;
}

.link:hover,
.link:active {
	color: #00857a;
	text-decoration: underline;
}
