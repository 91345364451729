.container {
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 28px;
	width: 50vw;
	background: #ffffff;
	border: 1px solid #d1d5db;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
}
