.dayButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 22px;
	background: #f6f6f6;
	border-radius: 8px;
	box-shadow: none;
	color: #1e293b;
}

.marginLeft10 {
	margin-left: 7px;
}

.dayButton:hover {
	background-color: #f6f6f6;
}

.dayButtonSelected,
.dayButtonSelected:hover {
	background-color: #ff6849;
	color: white;
}

.daysInOfficeCard {
	min-height: auto;
	padding: 13px 7px 19px 15px;
}
