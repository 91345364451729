$red-color: #dc2626;
$font-family: "Work Sans";

.wrapper {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.container {
	padding: 21px;
	width: 500px;
	border-radius: 12px;
	background-color: white;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.descriptionContainer {
	display: flex;
	gap: 21px;
	align-items: flex-start;
	margin: 32px 0px;
}

.title {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 23.04px;
	line-height: 24px;
	color: #171717;
	margin-bottom: 0px;
}

.description {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 24px;
	color: #262626;
}

.deleteButton {
	background-color: $red-color !important;
	box-shadow: 0px 0px 1px $red-color inset;
}

.trashIcon path {
	stroke: white;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 14px;
}

.notificationIcon {
	margin-top: 3px;
	height: 47px;
	width: 109px;
}

.cancel > button {
	height: 100%;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
}

.cancel > button > div {
	line-height: 17px;
	color: #525252;
}

.closeButton {
	background-color: transparent;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px;
}
