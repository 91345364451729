.root {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1rem;
}

.filters {
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	margin: 0;
	padding-right: 1rem;
	padding-top: 0.25rem;
	margin-bottom: 0.75rem;
}

.filterWrapper {
	display: grid;
	grid-template-columns: max-content auto max-content;
	column-gap: 0.75rem;
}

.where {
	display: flex;
	align-items: center;
	color: #737373;
	line-height: 1.125rem;
	font-weight: 400;
	font-size: 1rem;
	font-family: "Work Sans", sans-serif;
}

div.logicalOperator,
.where {
	width: 5.5rem;
}

.deleteIconWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover path {
		stroke: #525252;
	}
}

.highlighted {
	position: relative;
	&:before {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		content: "";
		height: 100%;
		padding: 0.5rem;
		animation: highlight 3s ease-in-out;
		border-radius: 3px;
	}
}
@keyframes highlight {
	0% {
		box-shadow: none;
	}
	16% {
		box-shadow: 0 0 0 3px #00857a;
	}
	33% {
		box-shadow: 0 0 0 1px #00857a;
	}
	48% {
		box-shadow: 0 0 0 3px #00857a;
	}
	64% {
		box-shadow: 0 0 0 1px #00857a;
	}
	85% {
		box-shadow: 0 0 0 3px #00857a;
	}
	100% {
		box-shadow: none;
	}
}
