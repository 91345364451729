@import "modules/SearchCandidate/TableVariables/fonts.scss";
@import "modules/candidate/Profile/Variables/margin.scss";

.root {
	padding: 16px 15px 0 15px;
}

.headerContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: relative;
	.first {
		display: flex;
		gap: 10px;
		align-items: center;
		button {
			background-color: #f8fafc;
			cursor: pointer;
			padding: 0.75rem;
			margin: 0;

			&:hover {
				background-color: #f5f5f5;
			}

			&svg {
				display: block;
			}
		}
	}
	.subContainer {
		display: flex;
		gap: 1rem;
		align-items: flex-start;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		padding: 1rem;
		box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
			0px 4px 6px -2px rgba(0, 0, 0, 0.05);
		border-radius: $buttonsRadius;
		position: fixed;
		z-index: 999;
		right: 0;
		top: 67;
		.closeButton {
			all: unset;
			cursor: pointer;
			margin-right: 0.357rem;
		}
	}
	.headerAlert {
		display: flex;
		gap: 1.429rem;
		align-items: flex-start;
		.contentText {
			display: flex;
			flex-direction: column;
			width: 23rem;
			.textGras {
				@include SmallParagraph;
				font-weight: 600;
				color: #262626;
				line-height: 1.5rem;
				margin: 0;
				margin-bottom: 0.5rem;
				letter-spacing: -0.5px;
			}
			.textNormal {
				@include SmallParagraph;
				font-weight: 400;
				color: #737373;
				line-height: 1.5rem;
				margin: 0;
				margin-bottom: 0.5rem;
				letter-spacing: -0.5px;
			}
			.okButton {
				all: unset;
				cursor: pointer;
				@include SmallParagraph;
				font-weight: 600;
				color: #00857a;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.headerCandidate {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btnContainer {
			display: flex;
			gap: 0.75rem;
		}
		.countContainer {
			border-radius: 18px;
			background-color: white;
			color: #00857a;
			padding: 1px 4px 1px 4px;
		}
	}
}

.separator {
	background-color: #e5e5e5;
	height: 1px;
}
.filtersContainer {
	display: flex;
	margin-top: 1rem;
	padding: 0;
	justify-content: space-between;
	.firstContainer {
		display: flex;
		gap: 10px;
		align-items: center;
		align-items: center;
		.search {
			input {
				width: 100%;
				padding: 0.75rem 2rem 0.75rem 1rem;
				flex-grow: 1;
				border-radius: $buttonsRadius;
			}

			input:focus-within {
				box-shadow: 0px 0px 0px 1px #66b6af inset;
			}
		}
		.error {
			input:focus-within,
			input {
				box-shadow: 0px 0px 0px 1px red inset;
			}
		}
	}
	.secondContainer {
		display: flex;
		gap: 10px;
		align-items: center;
		.switchBtns {
			display: flex;
			gap: 0;
			align-items: center;
			[data-reach-tab] {
				padding: 0;
				border-bottom: none;
			}
		}
	}
}
.menuContainer {
	display: flex;
	flex-direction: column;
	outline: 1px solid #e5e5e500;
	outline-offset: -1px;
	background: #ffffff;
	border-radius: 5px;
	width: 200px;
	input {
		@include SmallParagraph;
		width: 100%;
		flex-grow: 1;
	}
	ul {
		display: flex;
		flex-direction: column;
		list-style-type: none;
		margin: 0;
		padding: 0;
		@include SmallParagraph;
		color: #525252;
		font-weight: 500;
		margin: 0.25rem 0;
		white-space: nowrap;
		li {
			display: flex;
			gap: 0.5rem;
			align-items: center;
			padding: 0.5rem 1rem 0.5rem 0.75rem;
			&:hover {
				cursor: pointer;
				background-color: #f5f5f5;
			}
			.check {
				width: 1.125rem;
				height: 1.125rem;
			}
		}
	}
	.btnShow,
	.btnNewSite {
		all: unset;
		color: #00857a;
		padding: 0.75rem 1rem;
		@include SmallParagraph;
		font-weight: 500;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.btnNewSite {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;
	}
}

.processContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	padding: 0.25rem 0;
	width: 100%;
	button {
		display: flex;
		align-items: center;
		white-space: nowrap;
		gap: 0.5rem;
		background-color: #ffffff;
		padding: 0.5rem 1rem;
		border-radius: 0;
		color: #171717;
		@include SmallParagraph;
		font-weight: 400;
		&:hover {
			cursor: pointer;
			background-color: #f5f5f5;
		}
	}
}

.dotsContainer {
	display: flex;
	flex-direction: column;
	padding: 0.25rem 0;

	button {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		background-color: #ffffff;
		padding: 0.5rem 1rem;
		@include SmallParagraph;
		color: #171717;
		font-weight: 400;
		&:hover {
			cursor: pointer;
			background-color: #f5f5f5;
		}
	}
}

.recap_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	gap: 0.25rem;
	@include SmallParagraph;
	color: #fff;
	font-weight: 500;
	padding: 0.5rem 0.75rem;
	background-color: #00857a;
}

.arrow {
	transition: all 200ms ease-in-out;

	&.up {
		transform: rotate(180deg);
	}
}

.filter_btns_container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}
.button_text {
	font-weight: 400;
}

.btnPlay {
	all: unset;
	cursor: pointer;
	text-decoration: underline;
	@include SmallParagraph;
	color: #00786e;
	&:hover {
		text-decoration: underline;
		color: #00786e;
	}
}
.selectItems {
	display: block !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
