.combobox {
	height: 36px;
	width: 180px;
	&:hover {
		background: #fff !important;
	}
	input {
		cursor: pointer;
		background: #fff !important;
		&:hover {
			background: #fff;
		}
	}
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.error {
	margin-top: 0.5rem;
}

.optionsWrapper {
	max-height: 300px !important;
}
