button.saveButton {
	padding: 7px 10.5px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	gap: 6px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	max-width: 78px;
	background: #00857a;
	color: #ffffff;
	border: 1px solid #00857a;

	&:disabled {
		background: #f1f1f1;
		color: #8c9196;
		box-shadow: none;
		border: none;
		svg {
			path {
				stroke: #a3a3a3;
			}
		}
		&:hover {
			background: #f1f1f1;
		}
	}

	&:hover {
		background: #006a62;
	}

	&.isLoading {
		opacity: 1;
		background: #00857a;
	}
}

.iconSave path {
	stroke: #00857a;
}
