@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

.container {
	position: relative;
}

.tabsContainer [data-reach-tab-list] {
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 6px;
}

.tabsContainer [data-reach-tab] {
	@include font;
	font-weight: 500;
	line-height: 17px;
	color: #303131;
	border: none;
	margin-right: 10px;
	padding: 5px 12px;
}

.tabsContainer [data-reach-tab][data-selected] {
	color: #ff6849;
	background: #ffe1db;
	border-radius: 4px;
	border: none;
}

.addGroupButton {
	position: absolute;
	top: 8px;
	right: 23px;
}
