@import "src/modules/consultancy/shared/shared-components";

.showMoreButton {
	@extend .linkButton;
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	display: inline-block !important;
}
.errorField {
	font-size: 13px;
	color: red;
}
