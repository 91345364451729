$font-family: "Work Sans";

.card {
	display: flex;
	padding: 16px 16px 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;

	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;
}
.owner,
.collaborators {
	display: flex;
	flex-direction: column;
	gap: 10px;
	main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 34px;
	}
}

.collaborativeGroup {
	display: flex;
	padding: 6px 12px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	width: 100%;
	border-radius: 12px;
	background: #f8fafc;

	.upperSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.title {
			display: flex;
			align-items: center;
			gap: 4px;
			color: #334155;
			font-family: $font-family;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			letter-spacing: normal;
		}
		.description {
			color: #64748b;
			font-family: $font-family;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
	}
}
.title {
	margin: 0;
	color: #334155;
	font-family: $font-family;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: normal;
}

.description {
	color: #64748b;
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 2px;
}

.divider {
	margin: 0;
	border-top: 1px solid #e2e8f0;
	width: 100%;
}

.toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.mutedText {
	font-weight: 400;
	font-size: 14px;
	color: #94a3b8;
}
