.notifications {
	&__settings {
		display: grid;
		grid-gap: 25px;
		grid-template-columns: 1fr;
		@media (min-width: 1000px) {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 10px;
		}
	}
	&__leftColumn {
		display: flex;
		flex-direction: column;
		gap: 25px;
	}
	&__rightColumn {
		display: flex;
		align-items: flex-start;
		gap: 25px;
	}
	&__loader {
		span {
			width: 30px !important;
			height: 30px !important;
			border-top-color: #00857a !important;
		}
	}
}
