.sidebar {
	width: 218.335px;
	flex-shrink: 0;
	border-right: 1px solid #f1f1f1;

	padding: 16px 11px;
}

.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;

	padding: 0 12px 12px 12px;
	margin: 0;
}

.nav {
	display: flex;
	flex-direction: column;
}

.navItem {
	list-style: none;

	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background: #f9fafb;
	}
	&.active {
		border-radius: 4px;
		background: #f8fafc;
	}
}

.label {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.link {
	display: flex;
	gap: 12px;
	text-decoration: none;
	text-transform: capitalize;
	padding: 8px 12px;

	&:focus {
		text-decoration: none;
	}
	&.active {
		color: #00857a;
		.label {
			color: #00857a;
		}
	}
}

.icon {
	width: 21px;
	height: 21px;
	color: #94a3b8;
	&.active {
		color: #00857a;
	}
}

.counter {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1px 5px;
	width: 25px;
	height: 20px;
	padding: 1px 5px;
	border-radius: 3px;
	background: #f1f5f9;

	color: #64748b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&.draft {
		color: #d92d20;
	}
}
