@keyframes highlight-border {
	0% {
		border: 1px solid #00857a;
	}
	100% {
		border: 1px solid #e2e8f0;
	}
}

@keyframes highlight-icon {
	0% {
		stroke: #00857a;
	}
	100% {
		stroke: #94a3b8;
	}
}

.headerContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.section {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26.8px;
	margin: 0;
}
.left {
	display: flex;
	align-items: center;
	gap: 20px;
	width: 290px;
}
.right {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
}
.calendarSubHeader {
	display: flex;
	gap: 10px;
}
.scheduleButton {
	padding: 6px 16px;
	gap: 8px;

	border-radius: 10px;
	border: 1px solid #00857a;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.calendarTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.scheduleMenuItem {
	width: 100%;
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	align-self: stretch;
	background: none;
	border: none;
	color: #334155;
}

.scheduleMenuIcon {
	path {
		stroke: #334155;
	}
}

.scheduleMenuItemText {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.views {
	display: flex;
	padding: 4px;
	align-items: center;
	border-radius: 3px;
	background: #f1f5f9;
}

.viewButton {
	padding: 3px 6px;
	background: none;
	border: none;

	color: #334155;

	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	transition: all 0.3s ease-out;
	&.active {
		border-radius: 3px;
		background: #fff;

		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
}

.navigation {
	display: flex;
}
.navigationButton {
	display: flex;
	padding: 0px;
	width: 36px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;

	border-radius: 8px 0px 0px 8px;
	border: 1px solid #e2e8f0;
	background-color: #fff;
	color: #334155;
	&.next {
		border-radius: 0px 8px 8px 0px;
	}
}

.iconNext {
	transform: rotate(180deg);
}
.schedule {
	position: relative;
}
.scheduleMenu {
	position: absolute;
	top: 33px;
	z-index: 9;
	display: flex;
	width: 169px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
}

.scheduleMenuItem {
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	align-self: stretch;
	background: none;
	border: none;
	color: #334155;
}

.scheduleMenuItemText {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.banner {
	display: flex;
	width: 100%;
	padding: 8px;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;

	max-height: 35px;
	border-radius: 5px;
	background: #ebf7f6;

	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	&.info {
		border-radius: 8px;
		border: 1px solid #dbeef7;
		background: #eff8ff;
	}
	&.inProgress {
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #f8fafc;
		.bannerCloseIcon {
			svg {
				path {
					stroke: #64748b;
				}
			}
		}
	}
	&.finished {
		color: #00857a;
		font-family: "Work Sans";
		font-size: 14px;
		letter-spacing: -0.3px;

		border-radius: 8px;
		border: 1px solid #dbeef7;
		background: #e6f3f2;
	}
	&.failed {
		color: #955a00;
		font-family: "Work Sans";
		font-size: 14px;
		letter-spacing: -0.3px;

		border-radius: 8px;
		border: 1px solid rgba(245, 158, 11, 0.16);
		background: #fffbeb;
		.bannerCloseIcon {
			svg {
				path {
					stroke: #f59e0b;
				}
			}
		}
	}
}

.bannerCloseIcon {
	width: 21px;
	height: 20px;
	margin-left: auto;
	background: none;
	padding: 0;
	svg {
		path {
			stroke: #339d95;
		}
	}
}

.syncButton {
	text-decoration: none;
	padding: 2px 6px;

	border-radius: 4px;
	background: #fff;

	color: #000;
	font-family: "Work Sans";
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.filter {
	padding: 5px 8px;
	position: relative;
	border-radius: 6px;
	border: 1px solid #e2e8f0;
	background: #fff;
	display: flex;
	height: 32px;
	width: 40px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	height: 100%;
	&.isHighlighted {
		border: 1px solid #00857a;
	}
}

.filterIcon {
	&.isHighlighted {
		path {
			stroke: #00857a;
		}
	}
}

.toggleWeekends {
	display: flex;
	align-items: center;
	gap: 7px;
	gap: 8px;
	padding: 14px 16px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	white-space: nowrap;
}
.toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.highlightButton {
	&:active {
		animation: highlight-border 0.3s forwards;
	}
}

.highlightIcon {
	&:active {
		path {
			animation: highlight-icon 0.3s forwards;
		}
	}
}

.today {
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	border-radius: 6px;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
}

.infoIcon {
	path {
		fill: #6787b5;
	}
}

.blue {
	path {
		stroke: #6787b5 !important;
	}
}

.settingsLink {
	display: flex;
	padding: 10px 16px;
	align-items: center;
	gap: 7px;

	border-top: 1px solid #e2e8f0;

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-decoration: none;
}
