.list {
	list-style: none;
	padding: 0;
	display: flex;
	margin-bottom: 0;
	margin-right: 5px;
	li {
		list-style: none;
		.itemData {
			width: 30px;
			height: 30px;
			img {
				display: block;
				width: 30px;
				height: 30px;
				border-radius: 40px;
			}
		}
	}
}
.assignedMembers {
	display: flex;
	// margin-top: 10px;
	align-items: center;
}
.restOfUsers {
	width: 30px !important;
	height: 30px;
	border-radius: 40px;
	background: #f0f3f5;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	color: #303131;
	margin-right: 5px;
}
.opener {
	display: flex;
	align-items: center;
	justify-content: center;
}

.container {
	display: flex;
	gap: 6px;
	align-items: center;
	width: 100%;
	line-height: 0;
}
