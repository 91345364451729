@import "modules/SearchCandidate/TableVariables/fonts.scss";

.chip {
	padding: 0.25rem 0.75rem;
	position: relative;
	box-shadow: 0px 0px 0px 1px #d4d4d4 inset;
	border-radius: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.25rem;
	max-width: 100%;
	white-space: nowrap;
	&:global(.modeInline) {
		box-shadow: none;
		border: 1px solid #e5e5e5;
		border-radius: 84px;
		padding: 7px;
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #262626;
		&:hover {
			box-shadow: none;
			border: 1px solid #e5e5e5;
			path {
				stroke: #525252;
			}
		}
	}
	span {
		@include SmallParagraph;
		font-weight: 500;
	}

	.text {
		color: #525252;
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.125rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		flex-grow: 1;
		font-family: "Work Sans";
		text-transform: capitalize;
	}

	&:hover {
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
		box-shadow: 0px 0px 0px 1px #00857a inset;
		border-color: #66b6af;

		path {
			stroke: #525252;
		}
	}
}

.chipDelete {
	box-shadow: 0px 0px 0px 1px #00857a inset;
	&:hover path {
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
	}
}

.iconWrapper {
	display: flex;
	align-items: center;
}
