.datePickerRoot {
	width: 100%;
	min-height: 44px;
	z-index: 1;
}

.datePickerInput {
	padding: 14px;
	border-radius: 8px;
	background-color: white;
	min-width: 410px;
	height: 44px;
}

.greyCard {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	border-radius: 8px;
	background: #f8fafc;
	margin-top: 12px;
}

.selectAll {
	display: flex;
	gap: 16px;
	align-items: center;
	border-radius: 10px;
	padding: 13px var(--6, 16px);
	width: 100%;

	div {
		color: #334155;
		text-align: center;
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: -0.5px;
	}
}

.toggleButton {
	margin-left: auto;
}

.separator {
	height: 1px;
	width: 100%;
	padding: 0px 2px;
	background-color: #e2e8f0;
}
