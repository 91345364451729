.status {
	&__settings {
		display: grid;
		grid-gap: 25px;
		grid-template-columns: 1fr;
	}
	&__leftColumn {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	&__loader {
		span {
			width: 30px !important;
			height: 30px !important;
			border-top-color: #00857a !important;
		}
	}
}

.title {
	margin-bottom: 15px;
}
.description {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 12px;
}

.combobox {
	height: 44px;
	max-width: 400px;
	&:hover {
		background: #fff !important;
	}
	input {
		cursor: pointer;
		background: #fff !important;
		&:hover {
			background: #fff;
		}
	}
}
