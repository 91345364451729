@import "modules/SearchCandidate/TableVariables/fonts.scss";

.results_count {
	@include SmallParagraph;
	color: #737373;
	font-weight: 400;
	line-height: 1.125rem;
	padding: 0;
	.greenText {
		color: #00857a;
		font-weight: 500;
	}
	.grayText {
		color: #737373;
		font-weight: 500;
	}
	margin: 0.75rem 0 0.75rem 0.25rem;
}

.menuContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	h5 {
		font-size: 16px;
		color: #000000;
	}
	.menuDropdownContainer {
		height: auto;
		display: flex;
		align-items: center;
		.listItem {
			display: flex;
			align-items: center;
		}
	}
}

.selectIconsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.cellCard {
	border: 1px solid #d4d4d4;
	border-radius: 3px;
	background: #ffffff;
	padding: 5px;
	margin-right: 5px;
	font-family: "Work Sans", sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125rem;
	text-align: left;
	color: #262626;
}

.genericCellLeft,
.genericCellRight {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	text-align: left;
	font-family: "Work Sans", sans-serif;
	line-height: 1.125rem;
}

.badgesContainer {
	z-index: 100;
}

.genericCellRight {
	text-align: right;
}

.selectIconsContainer {
	display: flex;
	align-items: center;
}

.emptyStateContainer {
	height: 2px;
	border-radius: 5px;
	background: #d4d4d4;
	width: 1rem;
	text-align: center;
	margin: 0 auto;
}
.headerItem {
	margin-left: 0.5rem;
	font-weight: 400;
	line-height: 1.125rem;
	font-family: "Work Sans", sans-serif;
	color: #262626;
	text-decoration-line: none;
}
.dots {
	background: transparent;
	&:hover {
		background: transparent;
		cursor: pointer;
		svg path {
			stroke: #737373;
		}
	}
	&.dots[aria-expanded="true"] {
		background-color: transparent;
		svg path {
			stroke: #262626;
		}
	}
}
.dotsHorizontal {
	all: unset;
	display: flex;
	align-items: center;
	padding: 0.5rem;
}

.popperContainer {
	background: #ffffff;
	border: 1px solid #e5e5e5;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	min-width: 204px;
}
.quickView {
	background: transparent;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
		svg path {
			stroke: #737373;
		}
	}
}

.LastNoteCell {
	display: flex;
	position: relative;
	width: 100%;
}

.lastNoteMore {
	right: 0;
	color: #00857a;
	font-family: "Work Sans", sans-serif;
	font-size: 14px;
	font-weight: 400;
}
.lastNoteMore:hover {
	font-weight: 500;
	text-decoration: underline;
}
.lastNoteValue {
	max-width: 85%;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: "Work Sans", sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #262626;
}

.actionsContainer {
	display: flex;
	gap: 8px;
	.optimize {
		display: flex;
		padding: 7px 12px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		background: #00857a;

		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		color: #fff;

		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 13.628px; /* 97.346% */
		max-height: 36px;

		.iconMagic {
			color: #fff;
		}
	}
	.edit {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px 12px;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #fff;

		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 13.628px; /* 97.346% */
		max-height: 36px;
		.iconEdit {
			color: #334155;
		}
	}
}

.potentialMatchesTotal {
	border-radius: 8px;
	display: inline-block;
	background: #00857a;
	height: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 13.628px;
	color: #fff;
	padding: 4px 11px;
}
