.root svg path {
	stroke: #8a96a8;
}

.options {
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	outline: 0ch;
	box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 10%),
		0px 2px 4px -1px rgb(0 0 0 / 6%);
	padding: 0.25rem 0px;
}

.item {
	padding: 0.5rem 0.75rem;
	width: max-content;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125rem;
	color: #262626;
	font-family: "Work Sans";
	cursor: pointer;
}

.item:hover {
	background-color: #f5f5f5;
}
