.container {
	position: absolute;
	background: #ffffff;
	box-shadow: 0 0 2px rgb(0 0 0 / 20%), 0 2px 10px rgb(0 0 0 / 10%);
	border-radius: 4px;
	padding: 8px;
	z-index: 9999;
	width: 100%;
	top: 0px;
	right: 195px;
	width: 200px;
}
.positionToTop {
	top: unset;
	bottom: 40px;
}
.menuItem {
	display: flex;
	align-items: center;
	padding: 10px 12px;
	width: 100%;
	background: white;
	> span {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		margin-left: 20px;
		text-align: left;
		flex: 1;
	}
	.loadingElement {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		.loader {
			margin-bottom: 0px;
			> span {
				width: 20px;
				height: 20px;
			}
		}
	}
}
.menuItem:hover {
	background: #f6f6f7;
	border-radius: 2px;
}
