.underlay {
	position: fixed;
	inset: 0;
}

.popover {
	background: #fff;
	border-radius: 6px;

	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
}

.arrow {
	position: absolute;
	fill: #e2e8f0;
	stroke: #e2e8f0;
	stroke-width: 1px;
	width: 12px;
	height: 12px;

	&[data-placement="top"] {
		top: 100%;
		transform: translateX(-50%);
	}

	&[data-placement="bottom"] {
		bottom: 100%;
		transform: translateX(-50%) rotate(180deg);
	}

	&[data-placement="left"] {
		left: 100%;
		transform: translateY(-50%) rotate(-90deg);
	}

	&[data-placement="right"] {
		right: 100%;
		transform: translateY(-50%) rotate(90deg);
	}
}
