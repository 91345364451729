.slotItem {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	.timeSlotSeparator {
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
	& > div {
		display: flex;
		gap: 12px;
		align-items: center;
	}
	.addTimeSlotBtn {
		all: unset;
		cursor: pointer;
		margin-left: auto;
		display: flex;
		padding: 4px;
		align-items: center;
		gap: 10px;
		border-radius: 3px;
		background: #f1f5f9;
		&:hover {
			background: #dce0e4;
		}
		&.borderless {
			background: none;
		}
	}
}

.row {
	display: flex;
	align-items: center;
	gap: 12px;
}

.closeIcon {
	width: 21px;
	height: 21px;
}

.error {
	margin-bottom: 12px;
}

.timeInput {
	min-width: 84px;
}
