@mixin inputStyle {
	width: 100%;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&::placeholder {
		color: #94a3b8;
	}

	&:focus {
		border-color: #00857a;
		box-shadow: 0px 0px 0px 5px rgba(105, 194, 188, 0.12);
	}

	&.hasError {
		border-color: #dc2626;

		&:focus {
			box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12);
		}
	}
}

.fieldError {
	color: #dc2626;
	font-family: BasierCircle;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin-top: 8px;
}

.drawerContent {
	display: flex;
	flex-direction: column;
}

.header {
	position: sticky;
	top: 0;
	z-index: 10;
	display: flex;
	padding: 21px;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid #f5f5f5;
	background-color: white;
}

.header .title {
	flex-grow: 1;
	margin: 0;
	color: #334155;
	font-family: "BasierCircle";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.header .button {
	display: flex;
	width: 36px;
	height: 36px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;

	&:hover {
		background: #f8fafc;
	}
}

.section {
	padding: 20px;
	flex-grow: 1;
}

.section .note {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 10px 12px;
	border-radius: 8px;
	background: #f8fafc;
}

.descriptionContainer {
	display: flex;
	align-items: flex-start;
	gap: 9px;
	margin-bottom: 20px;
}

.description {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-radius: 8px;
}

.footer {
	position: sticky;
	bottom: 0;
	z-index: 10;
	display: flex;
	padding: 16px 21px;
	align-items: center;
	gap: 8px;
	border-top: 1px solid #f5f5f5;
	background-color: white;
}

.footer .submitBtn {
	padding: 0px 16px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	color: #fff;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background-color: #006a62;
	}
}

.footer .cancelBtn {
	padding: 0px 16px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background: #f8fafc;
	}
}

.field {
	display: block;
	margin-top: 20px;
}

.field .label {
	margin-bottom: 12px;
	color: #344054;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.field .label .asterisk {
	color: #dc2626;
}

.field .input {
	min-height: 40px;
	padding: 0 12px;
}

.field .textarea {
	padding: 12px;
	resize: vertical;
	min-height: 180px;
}

.field .input,
.field .textarea {
	@include inputStyle;
}

.field {
	:global {
		.form-group {
			margin-bottom: 0;
		}

		.intl-tel-input {
			input {
				@include inputStyle;
				overflow: hidden;
				min-height: 40px;
				padding: 0;
			}

			.selected-flag {
				background-color: transparent !important;
				font-family: "Work Sans";
				font-size: 16px;

				&:hover {
					background-color: transparent !important;
				}
			}
		}

		.phone-has-error {
			.intl-tel-input {
				input {
					border-color: #dc2626 !important;

					&:focus {
						box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12) !important;
					}
				}
			}
		}

		.help-block.inline-error {
			min-height: 0;
			padding: 0;
		}
	}

	.maxLength {
		position: absolute;
		right: 12px;
		bottom: 12px;
		color: #8c9196;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}

.checkboxLabel {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.divider {
	border-top: 1px solid #e2e8f0;
	margin: 20px 0;
}

.resumeHelper {
	color: #667085;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	display: flex;
	align-items: center;
	margin-top: 12px;
	gap: 4px;
}

.tooltip {
	:global {
		.rc-tooltip-inner {
			color: #fff;
			font-family: BasierCircle;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18.488px;
			background-color: #334155 !important;
			border-color: #334155 !important;
			border-radius: 4px;
			padding: 5px 12px !important;
		}

		.rc-tooltip-arrow {
			background-color: #334155 !important;
			border-color: #334155 !important;
		}
	}
}

.questionnaireForm {
	margin-top: 20px;
}

.questionnaireForm span,
.questionnaireForm label {
	color: #64748b;
}

.questionnaireLabel {
	position: relative;
}

.questionnaireLabel p {
	color: #344054;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.questionnaireLabel p {
	display: inline;
}

.questionnaireError {
	margin-top: -8px;
}

.checkboxConfirmation {
	margin-top: 5px;
}
