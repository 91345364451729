$primary-color: #00857a;

.rateButton {
	height: 44px;
	padding: 8px 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.fieldsContainer {
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 100%;
}
.descriptionContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.selectedRate {
	background-color: $primary-color;
	color: white;
}

.comment[type="text"] {
	padding: 7px 12px;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	min-height: 44px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;

	&::placeholder {
		color: #94a3b8;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: -0.5px;
	}
	&:focus {
		border-color: $primary-color;
		box-shadow: none;
	}
}

.label {
	color: #64748b;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 0px;
}

.asterisk {
	color: red;
}

.field {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	width: 100%;
}

.calendarField {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
}

.clockIcon path {
	fill: #64748b;
}

.rateIcon path {
	stroke: #64748b;
}

.dateIcon path {
	stroke-width: 1.2px;
}

.rates {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 8px;
	min-width: 410px;
}

.footer {
	display: flex;
	gap: 16px;
}

.separator {
	width: 100%;
	height: 1px;
	background-color: #e2e8f0;
}

.labelContainer {
	display: flex;
	align-items: center;
	gap: 8px;
	color: #64748b;
}

.calendarLabelContainer {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 10px;
}

.comboxBoxInput {
	width: 100%;
	min-width: 410px;
	background-color: white;
	&:hover {
		input {
			background-color: #fafafa !important;
		}
	}
	input {
		background-color: white !important;
		&:hover {
			background-color: #fafafa !important;
		}
	}
}

.container input.rateInput {
	padding: 13px !important;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	min-height: 44px;
	min-width: 410px;

	&:focus {
		border-color: $primary-color;
		box-shadow: none;
	}
}

div.amount {
	margin-bottom: 0px;
}

.cancelButton,
.addButton {
	padding: 8px 42px;
	height: 44px;
	border-radius: 10px;
	div {
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
}

.cancelButton {
	background: #fff;
	color: #334155;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
	div {
		color: #334155;
	}
}

.inputError {
	display: flex;
	flex-direction: column;
	gap: 5px;
	min-width: 410px;
}

.uploaderContainer {
	min-width: 410px;
	max-width: 410px;
}
