$primary-color: #00857a;

.container {
	flex-grow: 1;
	padding: 24px;
}

.grid {
	display: grid;
	grid-template-columns: min-content 1fr max-content 1fr;
	row-gap: 8px;
	margin-top: 40px;
}

.timeLabel {
	grid-column: 2 / 6;
	color: #4b5563;
	font-family: "Work Sans";
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 15px */
	letter-spacing: -0.5px;
}

.datePicker {
	margin-right: 24px;
	width: 300px;
}

.attendeesContainer {
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.locationContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 24px;
}

.radioInput {
	display: flex;
	gap: 5px;
}

.description,
.name {
	display: inline;
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.name {
	color: $primary-color;
}

.label {
	color: #4b5563;
	font-family: "Work Sans";
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 15px */
	letter-spacing: -0.5px;
}

.textarea {
	resize: none;
	margin-top: 5px;
	width: 100%;
	display: flex;
	height: 100px;
	padding: 10.5px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	background: #fff;
	&::placeholder {
		color: #a3a3a3;
		/* text-sm/Regular */
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 21px */
	}
}

.toLabel {
	margin: 0px 7px;
	color: #9ca3af;
	font-family: "Work Sans";
	font-size: 12.25px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px;
	display: flex;
	padding-top: 13px;
}

.address {
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	white-space: nowrap;
	line-height: 150%;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-shrink: 1;
}

.editText {
	color: $primary-color;
}

.editButton:hover {
	background-color: transparent;
}

.editButton {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.penIcon g path {
	stroke: $primary-color;
}

.textarea:focus {
	border: 1px solid #66b6af;
}

div.radioGroup {
	gap: 0.5rem;
	width: 100%;
}

div.radioGroup label span {
	line-height: 21px !important;
}

.customAddressLabel {
	width: 134px !important;
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

.companyAddressLabel {
	width: 100%;
	flex-grow: 0;
	width: 166px;
	flex-shrink: 0;
}

.onlineLabel {
	width: 100%;
	flex-grow: 0;
	width: 166px;
	flex-shrink: 0;
}

div.timePicker {
	border-radius: 5px;
	height: 37px;
}
