@import "modules/SearchCandidate/TableVariables/fonts.scss";
@import "modules/candidate/Profile/Variables/margin.scss";

.buttonAdd,
.buttonAddNotes,
.addSiteButton {
	display: flex;
	gap: 0.5rem;
	padding: 0.75rem 1rem;
	align-items: center;
	background-color: #f9fafb;
	border-radius: $buttonsRadius;
	box-shadow: 0px 0px 0px 1px #00857a inset;
	@include SmallParagraph;
	font-weight: 500;
	margin: 0;
	color: #00857a;
	&:hover {
		background-color: #e6f3f2;
	}
}

.buttonAddNotes {
	background-color: #00857a;
	color: #fff;
	&:hover {
		background-color: #006a62;
	}
}

.viewMoreButton {
	background-color: #ffffff;
	color: #525252;
	border: 1px solid #e5e5e5;
	&:hover {
		background-color: rgba(229, 229, 229, 0.82);
	}
}
.addSiteButton {
	padding: 11px 14px 11px 11px;
	background-color: #00857a;
	color: #fff;
	&:hover {
		background-color: #006a62;
	}
}
.btn__go__back {
	background-color: #fff;
	color: #525252;
	border: 1px solid #e5e5e5;
	@include SmallParagraph;
	border-radius: 3px;
	margin: 0.5rem 0rem 0 1rem;
}

.buttonProcess {
	display: flex;
	gap: 11px;
	padding: 0.75rem 1rem;
	align-items: center;
	background-color: #00857a;
	border-radius: $buttonsRadius;
	box-shadow: 0px 0px 0px 1px #00857a inset;
	@include SmallParagraph;
	font-weight: 500;
	margin: 0;
	color: white;

	&:hover {
		background-color: #006a62;
	}

	&.disabled {
		background-color: #edeff2;
		box-shadow: none;
		color: #a3a3a3;
		cursor: not-allowed;
		pointer-events: none;
		svg path {
			stroke: #a3a3a3;
		}
	}
}

.buttonSave {
	background-color: #00857a;
	border-radius: 3px;
	gap: 0.25rem;
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	@include SmallParagraph;
	font-size: 1rem;
	font-weight: 500;
	margin: 0;
	color: white;

	&:hover {
		background-color: #006a62;
	}

	&.disabled {
		background-color: #edeff2;
		box-shadow: none;
		color: #a3a3a3;
		cursor: not-allowed;
		pointer-events: none;
		svg path {
			stroke: #a3a3a3;
		}
	}
}
.buttonSaveNote {
	background-color: #00857a;
	color: #fff;
	border-radius: 5px;
	gap: 0.25rem;
	padding: 0.75rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	@include SmallParagraph;
	font-size: 1rem;
	font-weight: 500;
	margin: 0;
	&:hover {
		background-color: #006a62;
	}
	&.disabled {
		background: #f5f5f5;
		color: #d4d4d4;
		cursor: not-allowed;
		pointer-events: none;
		opacity: 1;
	}
}

.buttonClear {
	background-color: #f5f5f5;
	border-radius: 3px;
	gap: 0.25rem;
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	@include SmallParagraph;
	font-size: 1rem;
	font-weight: 500;
	margin: 0;
	color: #525252;

	&:hover {
		background-color: #e5e5e5;
	}

	&.disabled {
		background: #f5f5f5;
		color: #d4d4d4;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.btn__save {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	background-color: #f5f5f5;
	padding: 0.75rem 1rem;
	border: none;
	border-radius: 3px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;
	color: #d4d4d4;
	cursor: default;
}

.btnAddFilter {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.75rem 1rem;
	border-radius: $buttonsRadius;
	border: none;
	background-color: white;
	gap: 0.5rem;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	@include SmallParagraph;
	color: #525252;
	margin: 0;
	font-weight: 500;
	&:hover {
		background-color: #f5f5f5;
	}
}
.btnAddNote,
.btnCriteria {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.75rem 1rem;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	border-radius: $buttonsRadius;
	background-color: white;
	gap: 0.5rem;
	line-height: 1.125rem;
	@include SmallParagraph;
	font-weight: 500;
	color: #525252;
	margin: 0;

	.buttonTextContainer {
		flex: 1;
		text-align: left;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.buttonText {
		@include SmallParagraph;
		font-weight: 500;
		line-height: 1.125rem;
		color: #525252;
		margin: 0;
	}

	&:hover {
		background-color: #f5f5f5;
	}
	&:active {
		background-color: #fff;
		box-shadow: 0px 0px 0px 1px #66b6af inset;
	}
	&.expanded {
		background-color: #fff;
		box-shadow: 0px 0px 0px 1px #66b6af inset;
	}
	&.fixed {
		width: 250px;
		max-width: 250px;
		min-width: 180px;
	}
}
.btnAddNote {
	flex-grow: 1;
	justify-content: space-between;
	box-shadow: 0px 0px 0px 1px #d4d4d4 inset;

	&.disabled {
		background-color: #f5f5f5;
		cursor: default;
		opacity: 1;
	}

	&.noSelection {
		.buttonTextContainer {
			line-height: 1.125rem;
			font-weight: 400;
			color: #a3a3a3;
		}
	}

	&:hover {
		background-color: #fafafa;
	}

	&.isBlock {
		width: 100% !important;
	}
}

.btnLeft {
	display: flex;
	align-items: center;
	background-color: white;
	box-sizing: border-box;
	color: #525252;
	border-radius: $buttonsRadius 0 0 $buttonsRadius;
	border: 1px solid #e5e5e5;
	height: 36.75px;
	border-right: none;
	padding: 0.75rem;

	&.active {
		border: 1px solid #00857a;
		color: #00857a;
	}

	&:hover {
		background-color: #f5f5f5;
	}
}
.btnRight {
	display: flex;
	align-items: center;
	background-color: white;
	box-sizing: border-box;
	color: #525252;
	border-radius: 0 $buttonsRadius $buttonsRadius 0;
	border: 1px solid #e5e5e5;
	height: 36.75px;

	border-left: none;
	padding: 0.75rem;

	&.active {
		border: 1px solid #00857a;
		color: #00857a;
	}

	&:hover {
		background-color: #f5f5f5;
	}
}

.btnDots {
	all: unset;
	text-align: center;
	border-radius: $buttonsRadius;
	display: flex;
	align-items: center;
	padding: 0.75rem;
	background-color: white;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	cursor: pointer;
	&:hover {
		background-color: #f5f5f5;
		box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	}

	&.active {
		background-color: #fff;
		box-shadow: 0px 0px 0px 1px #66b6af inset;
	}

	&.expanded {
		background-color: #fff;
		box-shadow: 0px 0px 0px 1px #66b6af inset;
	}
}

.btn_content {
	position: absolute;
	bottom: 0;
	transform: translateY(calc(100% + 0.5rem));
	z-index: 1000;
	background-color: #ffffff;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	border: 1px solid #e5e5e5;
	overflow: hidden;
	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}

	&.wide {
		right: 0;
		left: 0;
	}
	&.width {
		width: 100%;
	}
	&.visibility {
		bottom: 37px;
	}
}

.btnDelete {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	gap: 11px;
	background-color: #dc2626;
	color: white;
	font-family: "Work Sans";
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;

	&:hover {
		background-color: #b91c1c;
	}
}
.btnRoot {
	position: relative;
	&.visibility {
		z-index: unset;
	}
	&.visibilitySearch {
		flex: 1 1 0;
	}
}

.big {
	padding: 0.75rem 1rem;
}

.sitesSelect {
	position: relative;
	box-shadow: inset 0 0 0 1px #e5e5e5;
	background-color: white;
	color: #171717;
	overflow: hidden;
	font-size: 1rem;
	padding: 0.75rem 1rem;
	border-radius: 5px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-family: "Work Sans";
	line-height: 1.125rem;
	gap: 0.5rem;
	width: 156px;

	.buttonTextContainer {
		flex: 1;
		text-align: left;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
