.root {
	position: relative;
	overflow-y: auto;
}
.inputWrapper {
	height: 100%;
}

.searchWrapper {
	padding: 0;
}

div.searchInput {
	border-top: none;
	border-right: none;
	border-left: none;
	border-radius: 5px 5px 0 0;
	input {
		border-top: none;
		border-left: none;
		border-right: none;
		box-shadow: none;
		border-radius: 0;
		border-bottom: 1px solid #e5e5e5;
	}
}

button.button {
	font-size: 1rem;
	line-height: 1.125rem;
	padding: 0.5rem 0.75rem;

	img {
		height: 15px;
	}

	&:disabled {
		background: #f5f5f5;
	}
}

.footer {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #e5e5e5;
	padding: 0.5rem;
}
.addBtn {
	background: #f5f5f5;
	border-radius: 3px;
	margin-top: 7px;
	margin-left: 0;
	padding: 0.75rem;
	width: 37px;
	height: 38px;
}

.flexContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.textButton {
	color: #00857a;
	&:disabled {
		&:hover {
			background: none;
		}
	}
}
