.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	.headerTitle {
		display: flex;
		align-items: baseline;
		gap: 7px;

		.btn {
			background: transparent;
		}

		.title {
			color: #334155;
			font-family: "basiercircle";
			font-size: 23.04px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.5px;
		}
	}

	.btnContainer {
		display: flex;
		gap: 10px;

		.interrupt {
			display: flex;
			padding: 10px;
			justify-content: center;
			align-items: center;
			gap: 7px;
			align-self: stretch;
			border-radius: 8px;
			border: 1px solid #e2e8f0;
			background: #fff;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

			&Disable {
				color: #cbd5e1;
				border-radius: 8px;
				border: 1px solid #e2e8f0 !important;
				background: #f1f5f9 !important;
				cursor: not-allowed;
				svg {
					path: {
						stroke: #334155 !important;
					}
				}
			}

			&:hover {
				border-color: #e2e8f0;
				background: #f8fafc;
			}

			&:focus {
				border-color: #e2e8f0;
				background: #fff;
				box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
			}
		}
		.editManager {
			display: flex;
			align-items: center;
			border-radius: 8px;
			border: 1px solid #e2e8f0;
			background: #fff;
			width: 42px;
			height: 42px;
			padding: 10px;
			&:hover {
				background-color: #f5f5f5;
			}
		}

		.deleteManager {
			display: flex;
			align-items: center;
			border-radius: 8px;
			border: 1px solid #e2e8f0;
			background: #fff;
			width: 42px;
			height: 42px;
			padding: 10px;
			&:hover {
				background-color: #f5f5f5;
			}
		}
		.extend {
			display: flex;
			padding: 10px 16px 10px 12px;
			justify-content: center;
			align-items: center;
			gap: 7px;
			align-self: stretch;
			border-radius: 8px;
			border: 1px solid #00857a;
			background: #fff;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			color: #00857a;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 100%;

			&Disable {
				cursor: not-allowed;
				border-radius: 8px;
				border-color: #e2e8f0 !important;
				background: #f1f5f9 !important;
				color: #cbd5e1;
			}

			&:hover {
				border-color: #00857a;
				background: #e6f3f2;
			}

			&:focus {
				border-color: #00857a;
				background: #fff;
				box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
			}
		}
	}
}

.iconDisabled path {
	stroke: #cbd5e1;
}

.tooltipContent {
	word-break: normal;
}
