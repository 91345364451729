.loadingList {
	width: 100%;
	padding: 0px 20px 0 20px;
	gap: 12px;
	display: flex;
	flex-direction: column;

	.progressContainer {
		display: flex;
		align-items: center;
		gap: 12px;
		align-self: stretch;
		height: 77px;
		border-radius: 16px;
		border: 1px solid #f8fafc;
		background: #f1f5f9;
		justify-content: space-between;
		padding: 0 20px;

		.description {
			width: 206.99px;
			height: 10.801px;
			border-radius: 40px;
			background: #e2e8f0;
		}

		.icon {
			width: 32px;
			height: 32px;
			border-radius: 8px;
			background: #e2e8f0;
		}
	}
}
