.header {
	position: sticky;
	top: 0;
	z-index: 10;
	display: flex;
	padding: 21px;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid #f5f5f5;
	background-color: white;
	margin-bottom: 24px;
}

.header .title {
	flex-grow: 1;
	margin: 0;
	color: #334155;
	font-family: "BasierCircle";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.header .button {
	display: flex;
	width: 36px;
	height: 36px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;

	&:hover {
		background: #f8fafc;
	}
}

.footer {
	position: sticky;
	bottom: 0;
	z-index: 10;
	display: flex;
	padding: 16px 21px;
	align-items: center;
	gap: 8px;
	border-top: 1px solid #f5f5f5;
	background-color: white;
}

.footer .submitBtn {
	padding: 0px 24px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	color: #fff;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background-color: #006a62;
	}
}

.footer .cancelBtn {
	padding: 0px 16px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background: #f8fafc;
	}
}
.formContainer {
	padding: 20px;
	padding-top: 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	.policyTextContainer {
		margin-top: auto;
	}
	.formFields {
		:global {
			.input-box input {
				border-radius: 8px;
				border: 1px solid #cbd5e1;
				background: #fcfdfb;
				font-family: "Work Sans";
				font-size: 16px;
				color: #334155;
			}
			.form-group {
				label {
					color: #334155;
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					margin-bottom: 8px;
					text-transform: none;
					&::after {
						content: "*";
						color: #d92d20;
						font-family: "Work Sans";
						font-size: 16px;
					}
				}
			}
		}
	}
}
.steps {
	padding-bottom: 13px;
	padding-left: 20px;
	padding-right: 20px;
	border-bottom: 1px solid #e2e8f0;
	margin-bottom: 20px;
	:global {
		.rc-steps .rc-steps-item .rc-steps-item-content .rc-steps-item-title {
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			color: #94a3b8;
			text-transform: none;
		}
		.rc-steps
			.rc-steps-item.rc-steps-item-process
			.rc-steps-item-content
			.rc-steps-item-title {
			color: #00857a;
		}
		.rc-steps .rc-steps-item.rc-steps-item-finish .rc-steps-item-icon {
			border-color: #00857a;
			background-color: #00857a;
		}
	}
}
.policyText {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	a {
		color: #00857a;
		text-decoration: underline;
	}
}
.userFormContainer {
	display: flex;
	flex-direction: column;
	height: 100vh;
}
.containerAssignRole {
	flex: 1;
	padding: 20px;
}
.textInfo {
	color: #344054;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 16px;
}
.grayBlock {
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #f8fafc;
	padding: 12px;
	margin-bottom: 24px;
	&.previewText {
		p {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 20px;
			a {
				color: #00857a;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				text-decoration-line: underline;
			}
		}
	}
	h4 {
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 21px;
	}
	p {
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}
}
.userRoles {
	:global {
		.form-group.radio label {
			color: #344054;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			text-transform: none;
		}
		.form-group.radio label:before {
			border: 1px solid #d0d5dd;
			background: #fff;
		}
		.Select-menu {
			border-radius: 8px !important;
			border: 1px solid #e2e8f0 !important;
			background: #fff;
			box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.11);
		}
		.select-restyle {
			.Select-input {
				width: unset;
				height: unset;
			}
			.Select-menu-outer {
				top: auto !important;
				bottom: calc(100% + 12px) !important;
			}
			.Select-control {
				border-radius: 8px;
				border: 1px solid #cbd5e1;
				background: #fcfdfb;
			}
			.has-value.Select--single
				> .Select-control
				.Select-value
				.Select-value-label,
			.has-value.is-pseudo-focused.Select--single
				> .Select-control
				.Select-value
				.Select-value-label {
				color: #94a3b8;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
			}
			label {
				color: #334155;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				text-transform: none;
				&::after {
					content: "*";
					color: #d92d20;
					font-family: "Work Sans";
					font-size: 16px;
					right: -6px;
					position: relative;
				}
			}
		}
		.form-group.radio input[type="radio"]:checked + label:before {
			border: 8px solid #00857a;
		}
		.form-group.radio input[type="radio"]:checked + label:after {
			background-color: #fff;
			border-radius: 50%;
			content: "";
			width: 6px;
			height: 6px;
			position: absolute;
			left: 8px;
			top: 8px;
		}
		.form-container {
			margin-bottom: 12px;
		}
		.rolesContainer {
			overflow: hidden;
			margin-bottom: 0;
		}
		.form-group label.labelText {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			text-transform: none;
			&::after {
				content: "*";
				color: #d92d20;
				font-family: "Work Sans";
				font-size: 16px;
				right: -6px;
				position: relative;
			}
		}
	}
}

.imageCover {
	margin-bottom: 20px;
	img {
		display: block;
		width: 100%;
	}
}
.inviteSuccesContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	h3 {
		color: #101828;
		font-family: "Work Sans";
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
		margin-bottom: 8px;
	}
	p {
		color: #667085;
		text-align: center;
		font-family: "Work Sans";
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 34px;
		margin-bottom: 26px;
		padding: 0 20px;
		span {
			color: #121922;
		}
	}
	button {
		border-radius: 8px;
		border: 1px solid #007a6e;
		background: #007a6e;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		padding: 10px 18px;
		color: #fff;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 24px;
		min-width: 314px;
	}
}
.errorMsg {
	:global {
		.alert.alert-error {
			padding: 0;
			display: flex;
			align-items: baseline;
			gap: 2px;
			.icon-error {
				color: #d92d20;
			}
			p {
				color: #d92d20;
				font-family: "Work Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: -0.5px;
				.btn-link {
					color: #d92d20;
					padding: 0;
					font-weight: 500;
					text-decoration-line: underline;
				}
			}
		}
	}
}
.btnCreateNewDepartment {
	display: flex;
	border-radius: 8px;
	border: 1px solid #00857a;
	background: #00857a;
	padding: 10px 24px;
	gap: 10px;
	align-items: center;
	width: 100%;
	justify-content: center;
	span {
		color: #fff;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: -0.5px;
	}
}

.selectRole {
	margin-left: 10px !important;
	accent-color: #ec6446 !important;
	height: 16px;
	width: 16px !important;
}
