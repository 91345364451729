.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 50px 10px 20px;
	padding-right: 50px;
}
.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
	margin-bottom: 0px;
}
