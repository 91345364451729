.createMissionContainer {
	padding: 16px 20px 0px 20px;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	.headerCreateMission {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #e2e8f0;
		padding: 16px 0px;
		h3 {
			margin: 0;
			font-weight: 500;
		}
		.first {
			display: flex;
			gap: 24px;
			align-items: center;
			button {
				background-color: #f8fafc;
				cursor: pointer;
				padding: 0rem;
				margin: 0;
				display: flex;

				&:hover {
					background-color: #f5f5f5;
				}

				&svg {
					display: block;
				}
			}
		}
	}
	.createMissionForm {
		display: flex;
		flex-direction: column;
	}
}
