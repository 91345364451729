$cardsPadding: 16px;
$bodyPadding: 32px 16px;
$layoutMenuPadding: 8px;
$maxWidth: 1440px;
$paddingLeft: 18px;
$paddingRight: 18px;

@mixin tabsPadding {
	padding: 18px $paddingRight 18px $paddingLeft;
	@media screen and (min-width: 1920px) {
		padding: 18px 0;
	}
}
