@import "modules/SearchCandidate/TableVariables/fonts.scss";

ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
}

.menu {
	padding: 20px 0;
	width: 400px;
	margin: 0 auto;
	list-style: none;
}

.section {
	list-style: none;
	background-color: #ffff;
	&:focus-visible {
		outline: none;
	}
	&.focused {
		background-color: #f5f5f5;
	}

	&.loading {
		padding: 0.8125rem 1rem;
	}
}

.sectionSkeleton {
	width: 100%;
	height: 7px;
	display: block !important;
}

.btn {
	padding: 0.5rem 1rem;
	background: transparent;
	display: flex;
	align-items: center;
	width: 100%;
	color: #262626;
	@include SmallParagraph;
	font-weight: 500;
	text-transform: capitalize;
	border: none;
	cursor: pointer;
	font-family: "Work Sans";
	&:focus-visible {
		outline: none;
	}
	.green_span {
		color: #1a9187;
	}
}

.arrow {
	transition: all 200ms ease-in-out;
	margin-right: 0.5rem;

	&.expanded {
		transform: rotate(90deg);
	}
}

.item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5rem;
	@include SmallParagraph;
	text-align: left;
	padding: 0.5rem 1rem;
	overflow: hidden;
	background-color: white;
	outline: none;
	cursor: pointer;

	&:focus {
		background-color: #f5f5f5;
	}

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}
}

.tooltip {
	left: 125px;
	width: 250px;
}
.title {
	@include SmallParagraph;
	font-weight: 400;
	text-align: left;
	color: #171717;
	outline: none;
	cursor: pointer;
}

.check {
	width: 1.125rem;
	height: 1.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
