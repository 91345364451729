.container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 20px 0;
	font-family: "Work Sans";
}

.btn {
	font-family: "Work Sans";
	height: 36px;
	padding: 0 18px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #394858;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
	background-color: #fff;
	margin-left: 8px;
}

.save {
	border: 1px solid #00857a;
	background: #00857a;
	color: #fff;
	padding: 0 20px;
}
