.container {
	width: 504px;
	border-right: 1px solid #e2e8f0;
	background-color: white;
	padding: 20px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
		border-radius: 99px;
	}
}

.sectionTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	margin-bottom: 10px;
}

.textarea {
	padding-top: 8px !important;
}

@mixin inputStyle {
	width: 100%;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&::placeholder {
		color: #94a3b8;
	}

	&:focus {
		border-color: #00857a;
		box-shadow: 0px 0px 0px 5px rgba(105, 194, 188, 0.12);
	}

	&.hasError {
		border-color: #dc2626;
		&:focus {
			box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12);
		}
	}
}

.field {
	display: block;
	margin-bottom: 20px;
	.asterisk {
		color: #dc2626;
	}
}

.field .label {
	margin-bottom: 12px;
	color: #344054;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.field .input {
	min-height: 40px;
	padding: 0 12px;
}

.field .input {
	@include inputStyle;
}

.field {
	:global {
		.form-group {
			margin-bottom: 0;
		}

		.intl-tel-input {
			input {
				@include inputStyle;
				overflow: hidden;
				min-height: 40px;
				padding: 0;
			}

			.selected-flag {
				background-color: transparent !important;
				font-family: "Work Sans";
				font-size: 16px;

				&:hover {
					background-color: transparent !important;
				}
			}
		}

		.phone-has-error {
			.intl-tel-input {
				input {
					border-color: #dc2626 !important;
					&:focus {
						box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12) !important;
					}
				}
			}
		}

		.help-block.inline-error {
			min-height: 0;
			padding: 0;
		}
	}
}

.fieldError {
	color: #dc2626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin-top: 8px;
}

.socialInputContainer {
	display: flex;
	align-items: center;
	gap: 8px;
}

.avatarContainer {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 18px;

	.avatar {
		width: 46px;
		height: 46px;
		position: relative;
		border-radius: 4px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
		}
	}

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;

	button {
		all: unset;
		cursor: pointer;
		color: #dc2626;
		display: flex;
		align-items: center;
	}
}

.maxLength {
	margin-bottom: 12px;
	color: #344054;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
	display: flex;
	justify-content: flex-end;
}
