textarea.texarea {
	font-family: "Work Sans";
	border: 3px solid #e5e5e5;
	padding: 10px;
	border-radius: 3px;
	outline: 0ch;
	color: #262626;
	font-size: 1rem;
	line-height: 1.25rem;
	height: 10rem;
	background-color: #ffffff;
	width: 100%;
	font-weight: 400;
	resize: none;

	&:focus {
		border: 1px solid #66b6af;
	}

	&::placeholder {
		color: #a3a3a3;
		font-size: 1rem;
	}
}
