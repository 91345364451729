.emptyState {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.description {
		color: #64748b;
		text-align: start;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 400;
		line-height: 150%;
	}
}
