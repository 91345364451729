.warningDescription {
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.reasonContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-self: stretch;
}
.reasonLabel {
	color: #4b5563;
	font-family: "Work Sans";
	font-size: 15.75px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
}
.required {
	color: #ef4444;
	font-family: "Work Sans";
	font-size: 15.75px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
}

.deleteButton {
	background-color: #dc2626 !important;
	box-shadow: 0px 0px 1px #dc2626 inset;
	color: #fff;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.descriptionContainer {
	display: flex;
	flex-direction: column;
	gap: 36px;
	align-items: center;
	border-bottom: 1px solid #e2e8f0;
	border-top: 1px solid #e2e8f0;
	padding: 20px;
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	width: 580px;
}
.cancel > button {
	height: 100%;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
}

.cancel > button > div {
	line-height: 17px;
	color: #525252;
}
.error {
	color: #d92d20;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.allowSubmissionContainer {
	display: flex;
	gap: 16px;
}
.allowSubmissionCheckbox {
	width: fit-content !important;
	margin-top: 4px;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.addNewClosingReasonButtonText {
	color: #00857a;
}

button.addClosingReasonButton {
	padding: 14px;
}

button.addClosingReasonButton:hover {
	background-color: #fafafa;
}
