.summaryCard {
	display: flex;
	padding: 12px;
	gap: 12px;
	justify-content: center;
	align-self: stretch;
	border-radius: 12px;
	border: #e2e8f0;
	background: #f8fafc;
	align-items: center;
}
