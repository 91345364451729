.langTabs {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #e2e8f0;
	padding-left: 16px;
	padding-right: 16px;
	min-height: 40px;
}

.langDropdownTrigger {
	outline: none;
	display: flex;
	align-items: center;
	gap: 4px;

	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	.plusIcon {
		all: unset;
		cursor: pointer;
		width: 28px;
		height: 28px;
		border-radius: 3px;
		background-color: #f1f5f9;
		color: #00857a;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 4px;
	}
}

.popover {
	display: flex;
	flex-direction: column;
	width: 150px;
	overflow: hidden;
	margin-top: -4px;
	padding: 4px 0;

	color: #262626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	border-radius: 3px;
	border: 1px solid #e5e5e5;
	background: #fff;
	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
}

.menu {
	outline: none;
}

.menuItem {
	outline: none;
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	outline: none;

	label {
		margin-bottom: 0;
	}

	&:hover {
		background: #f8fafc;
	}
}

.tabs {
	display: flex;
	margin-left: 10px;
	gap: 10px;
}

.tab {
	display: flex;
	padding: 11px;
	justify-content: center;
	align-items: center;
	gap: 10px;

	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;

	cursor: pointer;
	border-radius: 7px 7px 0px 0px;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	border-bottom: 1px solid #e2e8f0;
	margin-bottom: -1px;
	background: #fff;
	user-select: none;

	&:hover {
		background-color: #f8fafc;
	}
}

.tab.active {
	border-color: #e2e8f0;
	border-bottom-color: white;
	color: #00857a;
	font-weight: 600;
}

.closeBtn {
	all: unset;
	cursor: pointer;
	color: #64748b;
	display: flex;
	align-items: center;
}

.langToDelete {
	font-weight: 600;
}
