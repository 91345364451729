@import "modules/SearchCandidate/TableVariables/fonts.scss";

.modal {
	&__text {
		@include SmallParagraph;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 21px;
		color: #404040;
		margin: 20px 0;
	}
}
