.dialogContent {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 28px;
	width: 450px;
	background-color: white;
	border-radius: 6px;
	box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px,
		rgb(14 18 22 / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 25px;
	animation: 150ms cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal none running
		k-dUePBQ;
}
