@import "modules/candidate/Profile/Variables/styles.scss";

.container {
	display: flex;
}
.centerElement {
	align-items: center;
}
.userDetail {
	display: flex;
	flex-direction: column;
	margin-left: 6px;
	.name {
		@include profileContent;
		color: $blackPearl;
	}
	.jobTitle {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: $tuna;
	}
}
.emptyText {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	margin-left: 8px;
	color: $aluminium;
}
.status {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: $tuna;
}
