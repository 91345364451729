$primary-color: #00857a;

div.card {
	width: 50vw;
}

.labelContainer {
	display: flex;
}

.row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 28px;
}

.label {
	height: 21px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 21px;
	color: #4b5563;
}

.fieldContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
}

.asterisk {
	color: red;
	margin-right: 7px;
}

.addNewDepartmentButtonText {
	color: $primary-color;
}

button.addDepartmentButton {
	padding: 14px;
}

button.addDepartmentButton:hover {
	background-color: #fafafa;
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

button.nextButton[type="submit"] {
	padding: 13px 21px;
	line-height: 16px;
	max-width: fit-content;
	border-radius: 8px;
}

.cardsList {
	grid-row: 2/3;
	grid-column: 2/3;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

button.backButton {
	box-shadow: 0px 0px 0px 1px #d1d5db inset;
	padding: 14px 21px;
	height: 44px;
	border-radius: 8px;
	background-color: #fafafa;
}

button.backButton:hover {
	background-color: #fafafa;
}

.backButtonText {
	color: #525252;
}
