.container {
	.header {
		display: flex;
		align-items: center;
		gap: 14px;
		margin-bottom: 14px;
	}

	.title {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 600;
		font-size: 15.75px;
		line-height: 21px;
		letter-spacing: -0.5px;
		color: #262626;
	}

	.dropDownLoader {
		display: flex;
		align-items: center;
		padding: 0 0.75rem;
		height: 30px;
		background-color: white;
		border: 1px solid #d4d4d4;
		border-radius: 50px;
		gap: 0.5rem;
	}

	.skeleton {
		border-radius: 3px;
	}

	.card {
		box-sizing: border-box;
		position: relative;
		width: 100%;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		padding: 1rem;
	}

	.buttonWrapper {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	.label {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #737373;
	}

	.lastEditedContainer {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.urlRead,
	.urlEdit {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
	}

	.urlEdit {
		gap: 17px;
	}

	.collaboratorsContainer {
		display: flex;
		gap: 1px;
		align-items: center;
	}

	.images {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.lastEditDate {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12.25px;
		line-height: 14px;
		color: #737373;
	}

	.avatarWrapper {
		width: fit-content;
		display: flex;
		align-items: center;
	}

	.iconWrapper {
		display: flex;
		align-items: center;
	}

	.row {
		display: grid;
		grid-template-columns: 170px 1fr;
		align-items: center;
		padding: 1rem 0;

		&:last-child {
			padding-bottom: 0;
		}
	}

	.row:not(:last-child) {
		border-bottom: 1px solid #f5f5f5;
	}

	.domainWrapper {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	.buttonsWrapper {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}
}
