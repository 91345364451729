.container {
	width: 100%;
	height: 110px;
	background: "red";
	display: flex;
	align-items: center;
	border-radius: 5px;
}
.centerItems {
	display: flex;
	align-items: center;
	gap: 6px;
	margin-left: 20px;
}
.flexColumn {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	justify-content: center;
}
.count {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	color: #181819;
}
.cardName {
	font-family: "basiercircle";
	font-size: 16px;
	line-height: 14px;
}
.cardBody {
	p {
		color: inherit;
	}
}
.green {
	@extend .cardBody;
	background-color: #f1f8f5;
	color: #00a0ac;
}

.purple {
	@extend .cardBody;
	background: #ede5ff;
	color: #4d13d1;
}
.orange {
	@extend .cardBody;
	background: #fff4f4;
	color: #ff6849;
}
.yellow {
	@extend .cardBody;
	background: #fff5ea;
	color: #d82c0d;
}
