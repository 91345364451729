.container {
	position: relative;
	width: 100%;
	height: 56px;
	max-height: 56px;
	border-radius: 12px;
	border: 1px solid #f1f5f9;
	background: white;
	display: flex;
	align-items: center;

	.monthContainer {
		width: 63.97px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-right: 1px solid #e2e8f0;
		.month {
			color: #64748b;
			font-family: "Work Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 12px;
		}

		.day {
			color: #334155;
			font-family: "Work Sans";
			font-size: 28px;
			font-style: normal;
			font-weight: 500;
			line-height: 28px;
			letter-spacing: -0.5px;
		}
	}
	.dataContainer {
		display: flex;
		align-items: center;
		gap: 10px;
		width: 100%;
		height: 44px;
		padding: 0px 0px 0px 10px;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;

		.moneyContainer,
		.tagContainer,
		.timeContainer,
		.commentContainer,
		.tags {
			display: flex;
			align-items: center;
			gap: 6px;
			border-right: 1px solid #f1f5f9;
			height: 37px;
			padding: 3px;
		}
		.docContainer {
			width: 46px;
			display: flex;
			align-items: center;
			border-right: 1px solid #f1f5f9;

			.doc {
				background-color: #fff;
				border: 1px solid #f1f5f9;
				padding: 3px;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 36px;
				height: 36px;
			}
		}
		.commentContainer {
			width: 220px;
			padding: 3px;
			.comment {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 185px;
			}
		}

		.timeContainer {
			width: 117px;
			padding: 3px;
			.timeContainerBody {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-right: 10px;
			}
		}
		.tagContainer {
			width: 100px;
			padding: 3px;
			.tagContainerBody {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-right: 10px;
			}
		}
		.moneyContainer {
			width: 130px;
			padding: 3px;
			.moneyContainerBody {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-right: 10px;
			}
		}

		.tags {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-right: 10px;
			width: 167px;
			padding: 3px;
			.label {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 120px;
			}
		}
		.actionsContainer {
			padding: 10px;
			position: absolute;
			right: 0;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
			.warning {
				width: 30px;
				height: 30px;
				background-color: #fffbeb !important;
			}
			.closeButton {
				width: 30px;
				height: 30px;
				background-color: #f8fafc !important;
			}
		}
	}
}

.docEmptyState {
	width: 20px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
}
