.root {
	display: flex;
	padding: 12px 12px 20px 12px;
	flex-direction: column;
	flex: 1 0 0;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #f8fafc;
	gap: 12px;
}

.hr {
	width: 100%;
	color: #e2e8f0;
}

.btnContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn {
	display: flex;
	height: 40px;
	padding: 12px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: #f8fafc;
}

.btnTitle {
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.card {
	margin-top: 0px;
	margin-bottom: 0px;
}

.displayNone {
	display: none !important;
}

.list {
	display: flex;
	padding: 12px 12px 12px 12px;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	border-radius: 12px;
	background: #f8fafc;
}

.evaluationOverview {
	overflow: hidden;
	max-height: 40px;
	opacity: 0.6;
}
