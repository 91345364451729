.pdfViewerContainer {
	flex-grow: 1;
	display: flex;
	padding-top: 24px;
	align-items: center;
	justify-content: center;

	iframe {
		width: 100%;
		height: 100%;
		border: none;
		overflow: hidden;
	}
}

.emptyStateContainer {
	gap: 16px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.title {
		font-size: 20px;
		color: #334155;
		font-weight: 500;
		line-height: 150%;
		font-style: normal;
		letter-spacing: -0.5px;
		font-family: "Work Sans";
	}

	.description {
		font-size: 16px;
		color: #64748b;
		font-weight: 400;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";
	}
}
