.locationPicker {
	padding: 16px;
	border-radius: 6px;
	background: #f8fafc;
}
.locationTypeTrigger {
	outline: none !important;
	display: flex;
	height: 40px;
	width: 100%;
	padding: 10px 12px 10px 16px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	gap: 8px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.locationTypeLabel {
	flex-grow: 1;
	display: flex;
}
.locationTypePopover {
	width: 594px;
	outline: none !important;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	overflow: hidden;
	margin-top: -5px;
	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
}
.locationTypeMenu {
	outline: none !important;
}
.locationTypeItem {
	outline: none !important;
	height: 40px;
	padding: 10px 12px;

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	display: flex;
	align-items: center;
	gap: 8px;

	&.locationTypeSelected {
		color: #00857a;
	}

	cursor: pointer;
	&:hover {
		background-color: #f3f4f6;
	}
}

.companyAddress {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	margin-top: 15px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;

	a {
		text-decoration: none;
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		line-height: 150%;
	}
}

.addressPickerContainer {
	margin-top: 10px;
	margin-bottom: -20px;
	.customLocationViewSwitcher {
		background: transparent;
	}
}

.select {
	margin-top: 8px;
	background: white;
	border-radius: 8px;
	height: 40px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

.option {
	display: flex;
	height: 36px;
	padding: 10px 12px;
	align-items: center;
	&:hover {
		background-color: #e6f3f2 !important;
	}
}

.optionLabel {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
