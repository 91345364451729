@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}
.root {
	label {
		@include font;
		font-weight: 400;
		line-height: 20px;
		color: #6d7175;
		display: flex;
		align-items: center;
		gap: 4px;
	}
	.select {
		width: 450px;
		[class$="-control"] {
			width: 450px;
			height: 38px;
		}
		[class$="-value"] {
			display: flex;
			align-items: center;
		}
		[class$="-placeholder"] {
			display: flex;
			align-items: center;
		}
		[class$="-multi-value-wrapper"] {
			display: flex;
			flex-wrap: wrap;
		}
	}
	.selectError {
		[class$="-control"] {
			border: 1px solid #ed2e7e;
		}
	}
	.error {
		@include font;
		color: #ed2e7e;
	}
}

.asyncSelect {
	[class$="-menu"] {
		width: 450px;
	}

	[class$="-control"]:hover {
		border: 1px solid #44b9fc;
		box-shadow: 0 0 transparent;
	}
}

.creatable [class="Select-menu-outer"] {
	display: none;
}

.creatable [class="Select-arrow"] {
	display: none;
}
