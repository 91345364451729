.container {
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
}

.header {
	display: flex;
	align-items: center;
	padding: 12px;
	background: var(--bg-hover, #f8fafc);
	border-bottom: 1px solid var(--gray-100, #f3f4f6);
}

.dragIcon {
	margin-inline-end: 8px;
	display: flex;
	align-items: center;
	cursor: grab;
}

.title {
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
	margin-inline-end: 18px;
}

.inputContainer {
	flex-grow: 1;
}

.input {
	all: unset;
	box-sizing: border-box;
	width: 470px;
	height: 40px;
	padding: 0 14px;
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--White, #fff);

	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;

	&:focus {
		border: 1px solid #00857a;
	}
}

.content {
	padding: 16px;
}

.titleError {
	margin-top: 8px;
}

.contentError {
	margin-bottom: 16px;
}

.addContainer {
	margin-top: 16px;
}

.shadow {
	box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
		0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	border-radius: 12px;
}
