$font-family: "Work Sans";
$primary-color: #00857a;

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 28px;
	background-color: #f9fafb;
	padding: 14px;
	border-radius: 0 0 12px 12px;
}

.buttonsContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: -19px;
	gap: 7px;
}

.deleteButton {
	color: red;

	disabled {
		color: #cbd5e1;
	}
}

.deleteIconDisabled path {
	stroke: #cbd5e1;
}

.setOriginalButton {
	color: $primary-color;
}

div.editor {
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	min-height: 215px;
	max-height: 530px;
	height: auto;
}

div.editor > div > div {
	min-height: 215px;
	max-height: 530px;
	height: auto;
}

.lengthContainer {
	display: flex;
	justify-content: flex-end;
	font-family: $font-family;
	grid-column: 2 / 3;
}

.errorContainer {
	display: grid;
}

div.editorFocused {
	border: 1px solid $primary-color;
}

.vacancyLabelContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.fieldHeaderContainer {
	display: flex;
	align-items: center;
}
