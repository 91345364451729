.floating-text-format-popup {
	display: flex;
	background: #fff;
	padding: 0.25rem;
	vertical-align: middle;
	position: absolute;
	z-index: 10;
	top: -10000px;
	left: -10000px;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	border: 1px solid #e5e5e5;
	transition: opacity 0.5s;
	gap: 0.25rem;
	z-index: 99997;
}

.floating-text-format-popup button.popup-item {
	border: 0;
	display: flex;
	background: none;
	border-radius: 3px;
	padding: 0.5rem;
	cursor: pointer;
	vertical-align: middle;
	display: flex;
	align-items: center;
}

.floating-text-format-popup button.popup-item:disabled {
	cursor: not-allowed;
}

.floating-text-format-popup button.popup-item i.format {
	background-size: contain;
	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	vertical-align: -0.25em;
	display: flex;
	opacity: 0.6;
}

.floating-text-format-popup button.popup-item:disabled i.format {
	opacity: 0.2;
}

.floating-text-format-popup button.popup-item.active {
	background-color: #f5f5f5;
}

.floating-text-format-popup button.popup-item.active i {
	opacity: 1;
}

.floating-text-format-popup .popup-item:hover:not([disabled]) {
	background-color: #f5f5f5;
}

.floating-text-format-popup select.popup-item {
	border: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	vertical-align: middle;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 70px;
	font-size: 14px;
	color: #777;
	text-overflow: ellipsis;
}

.floating-text-format-popup select.code-language {
	text-transform: capitalize;
	width: 130px;
}

.floating-text-format-popup .popup-item .text {
	display: flex;
	line-height: 20px;
	width: 200px;
	vertical-align: middle;
	font-size: 14px;
	color: #777;
	text-overflow: ellipsis;
	width: 70px;
	overflow: hidden;
	height: 20px;
	text-align: left;
}

.floating-text-format-popup .popup-item .icon {
	display: flex;
	width: 20px;
	height: 20px;
	user-select: none;
	margin-right: 8px;
	line-height: 16px;
	background-size: contain;
}

.floating-text-format-popup i.chevron-down {
	margin-top: 3px;
	width: 16px;
	height: 16px;
	display: flex;
	user-select: none;
}

.floating-text-format-popup i.chevron-down.inside {
	width: 16px;
	height: 16px;
	display: flex;
	margin-left: -25px;
	margin-top: 11px;
	margin-right: 10px;
	pointer-events: none;
}

.floating-text-format-popup .divider {
	width: 1px;
	background-color: #eee;
	margin: 6px 4px;
}

.floating-text-format-popup .placeholder-link {
	color: #525252;
}
