.root {
	padding: 16px 15px 0 15px;
}

.containerFooter {
	position: relative;
	width: 100%;
	min-height: 40px;
	margin-top: 1.5rem;
}

.horizontalLine {
	margin-top: 15px;
	background-color: #e5e5e5;
	height: 1px;
}
