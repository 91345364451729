.container {
	display: flex;
}
.centerElement {
	align-items: center;
}
.avatar {
	border-radius: 48px;
	width: 30px;
}
.userDetail {
	display: flex;
	flex-direction: column;
	margin-left: 6px;
	.name {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		font-family: "basiercircle";
		color: #48494a;
	}
	.jobTitle {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #48494a;
	}
}
.emptyText {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	margin-left: 8px;
	color: #8c9196;
}
.status {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #48494a;
}
.external {
	color: #fd5749;
}
