@import "./../../Variables/styles.scss";

.accordion {
	background-color: $white;
	border-radius: 4px;
	padding: 0.8rem;

	.accordionHeader {
		display: flex;
		justify-content: space-between;
		padding: 1.6rem;
		.header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 1rem;
			.title {
				display: flex;
				align-items: center;
				gap: 1.2rem;
				@include cardsTitle;
				color: $swamp;
				cursor: pointer;
				.arrowDown {
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid #5c5f62;
					transition: transform 0.3s ease;
					&.show {
						transform: rotate(-180deg);
					}
				}
			}
			.description {
				display: flex;
				align-items: center;
				gap: 1rem;
				margin-left: 2.2rem;
				p {
					@include profileContent;
					color: $raven;
					display: contents;
					text-transform: capitalize;
					&:not(:first-child) {
						&::before {
							display: flex;
							background-color: $raven;
							height: 6px;
							width: 6px;
							border-radius: 50%;
							content: "";
						}
					}
				}
			}
		}
		.action {
			button {
				@include profileContent;
				background: transparent;
				padding: 0;
				margin: 0;
				color: $atoll;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.accordionContent {
		max-height: 0;
		overflow: hidden;
		transition: all 0.3s ease;
		&.show {
			display: flex;
			flex-direction: column;
			transition: all 0.3s ease;
			max-height: 26.5rem;
		}
		.accordionBody {
			flex-grow: 1;
			border: 1px solid #dbdddf;
			box-sizing: border-box;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border-radius: 8px;
			.flex {
				display: flex;
				align-items: center;
				gap: 0.8rem;
			}
			.text {
				@include profileContent;
				color: $raven;
				margin: 0;
				padding: 0;
			}
			.status {
				@include profileContent;
				color: $blackPearl;
				margin: 0;
				padding: 0;
				span {
					font-weight: 500;
				}
			}
			.status::first-letter {
				text-transform: capitalize;
			}
			.avatar {
				border-radius: 50%;
				width: 3.2rem;
				height: 3.2rem;
			}
			.redText {
				@include profileContent;
				margin: 0;
				padding: 2px 8px;
				color: #f01c29;
				background-color: #ffe9e8;
				border-radius: 1rem;
			}
			.grisText {
				@include profileContent;
				margin: 0;
				padding: 2px 8px;
				background-color: #d2d7db;
				color: #58626b;
				border-radius: 1rem;
			}
			.titleRow {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: $cardsPadding;
				border-bottom: 1px solid $ghostWhite;
			}
			.body {
				display: flex;
				flex-direction: column;
				gap: 3.5rem;
				padding: $bodyPadding;
				.box {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					.textGras {
						font-weight: 500;
					}
					.description {
						@include profileContent;
						color: $blackPearl;
						display: flex;
						align-items: center;
						gap: 1rem;
						p {
							@include profileContent;
							display: contents;
							text-transform: capitalize;
							color: $blackPearl;
							&:not(:first-child) {
								&::before {
									display: flex;
									background-color: $raven;
									height: 6px;
									width: 6px;
									border-radius: 50%;
									content: "";
								}
							}
						}
					}
				}
				.information {
					display: flex;
					align-items: center;
					gap: 8rem;
				}
			}
		}
	}
}

.referralInfos {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.info {
	.label {
		color: #64748b;
		margin-right: 8px;
	}
	.value {
		color: #262626;
		.isLink {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.tooltip {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
	width: 850px;
}
