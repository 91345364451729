.items {
	padding: 0.5rem 00rem;
	max-height: 200px !important;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9ca3af;
		border-radius: 12px;
	}
}

.menuItemWrapper {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr max-content;
	gap: 0.5rem;
	padding: 0.5rem 10px;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}

	img {
		width: 1.125rem;
		height: 1.125rem;
	}

	.text {
		max-width: fit-content;
		white-space: nowrap;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.125rem;
		color: #262626;
		font-family: "Work Sans";
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	img.arrowIcon {
		transform: rotate(-90deg);
		width: 1.125rem;
		height: 1.125rem;
		stroke: 1.5;
	}
}
