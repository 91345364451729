@import "../../Variables/styles.scss";

@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

.secondaryButton {
	@include font;
	background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
		inset 0px -1px 0px rgba(0, 0, 0, 0.2);
	border-radius: $raduisButton;
	line-height: 17px;
	color: $white;
}

.container {
	padding: $spacing;
}
.internalContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
	.overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.8);
		.overlayContent {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 10%;
			position: sticky;
			left: 0;
			top: 150px;
			:global(.loading) {
				> span {
					width: 30px;
					height: 30px;
				}
			}
			h2 {
				@include font;
				font-weight: 500;
				font-size: 16px;
				color: $tomato;
			}
			h3 {
				@include font;
				color: $blackPearl;
				span {
					font-weight: bold;
				}
			}
		}
	}
}
.tabsContainer [data-reach-tab-list] {
	border-bottom: 2px solid $solitudeGrey;
	margin-bottom: $spacing;
	background: transparent;
}
.tabsContainer [data-reach-tab] {
	@include font;
	line-height: 20px;
	color: $greytabs;
	border: none;
	margin-right: 32px;
	padding: 16px 0;
	background: transparent;
}
.tabsContainer [data-reach-tab][data-selected] {
	color: #00857a;
	background: transparent;
	border-radius: 0;
	border: none;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #00857a;
		left: 0;
		bottom: -2px;
	}
}

.personalProfile {
	margin: 3rem 0 1rem 0;
	input,
	#input {
		@include font;
		line-height: 1.25;
		height: 36px;
		min-height: 36px !important;
		padding: 0 1rem;
		width: 100%;
		color: $blackPearl;

		&:hover {
			outline: none;
			border: 1px solid $jade;
			color: $blackPearl;
		}
		&:disabled {
			opacity: 1;
			box-shadow: none;
			background: #fafafb;
			cursor: default;
		}
	}
}
.iconBtn {
	position: relative;
	background: transparent;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	height: 3.6rem;
	margin-bottom: 0.8rem;
	padding: 0;
	margin-top: 5px;

	&:disabled {
		cursor: not-allowed;
	}
	span {
		@include profileContent;
		color: $swamp;
		font-weight: 500;
		font-weight: 500;
	}
}

.downloadLink {
	position: relative;
	background: transparent;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	height: 3.6rem;
	margin-bottom: -0.2rem;
	padding: 0;
}

.flexColumn {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	.label {
		@include font;
		color: $raven;
	}
}
.radio_input {
	position: absolute;
	@include font;
	display: flex;
	gap: 0.5rem;
	justify-content: flex-start;
	top: 4.5rem;
	input[type="radio"] {
		opacity: 0;
		width: 20px;
		position: absolute;
		height: 20px;
		cursor: pointer;
		&:checked + .radio_control {
			background: radial-gradient(#fd6950 30%, rgba(255, 0, 0, 0) 40%);
		}
	}
	.radio_control {
		display: block;
		width: 20px;
		height: 20px;
		background: #d9dbe9;
		border-radius: 50%;
		border: 1px solid #d9dbe9;
		box-sizing: border-box;
	}
}

.cvContainer {
	display: flex;
	flex-direction: column;

	h3 {
		@include cardsTitle;
		line-height: 1.25;
		color: $swamp;
	}
	img {
		width: 6rem;
	}
	p {
		@include font;
		color: $blackPearl;
		margin: 0;
	}
	button {
		margin: 0;
	}
}
.flex {
	display: flex;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
	.btnCv {
		@include profileContent;
		background-color: transparent;
		color: $tomato;
		padding: 1px;
		text-decoration: underline;
		text-decoration-color: $tomato;
	}
	span {
		@include profileContent;
		color: $blackPearl;
	}
}

.cvActions {
	position: absolute;
	background-color: $white;
	top: 0;
	z-index: 1;
	width: max-content;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	ul {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		margin: 0;
		padding: 1rem 3.2rem 1rem 1.6rem;
		li {
			list-style: none;
			button {
				@include font;
				color: $blackPearl;
				line-height: 2;
				position: relative;
				background: transparent;
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 0;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.personalBox {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	justify-content: center;
	min-height: auto;
	margin-bottom: 7.5rem;
}

.phoneBox,
.emailBox,
.externalLinkBox {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	justify-content: flex-start;
	min-height: auto;
	margin-bottom: 7.5rem;
}

.error {
	display: flex;
	align-items: center;
	position: absolute;
	top: 4.5rem;
	gap: 1rem;
	@include font;
	color: $criticalText;
	font-weight: 500;
}
.contentBanner {
	button {
		background: none;
		padding: 0;
		text-decoration: underline;
		&.primaryButton {
			color: $atoll;
		}
		&.secondaryButton {
			color: $tomatoOrange;
		}
	}
}
.deleteItem {
	position: absolute;
	top: 3rem;
	background: none;
	padding: 1rem 0;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	@include font;
	color: $blackPearl;
	margin-left: -4px;
	svg {
		width: 2.6rem;
		g {
			g {
				path {
					fill: $tomatoRed;
				}
			}
		}
	}
	&:disabled {
		color: $aluminium;

		svg {
			g {
				g {
					path {
						fill: $aluminium;
					}
				}
			}
		}
	}
}

.addBox {
	position: relative;
	margin-bottom: 3.5rem;
	margin-top: -0.3rem;
}

.personalLabel {
	position: absolute;
	top: -2.5rem;
}
.personalLink {
	position: absolute;
	top: -30px;
	.infoIcon {
		margin-left: 3px;
		margin-top: 2px;
	}
}

.MarginBottom2 {
	margin-bottom: 2rem;
}

.MarginBottom1 {
	margin-bottom: 1rem;
}
.editContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	@include profileContent;
	color: $blackPearl;
	.notice {
		width: max-content;
	}
	.textSeparator {
		&:not(:last-child) {
			::after {
				content: "";
			}
		}
	}
}
.profileCreation {
	display: flex;
	flex-direction: column;
	gap: 4px;
	flex-wrap: wrap;
	@include profileContent;
	color: $blackPearl;
}

.field {
	@include profileContent;
	color: $blackPearl;
	&:first-letter {
		text-transform: capitalize;
	}
}
.path {
	text-decoration: none;
	@include profileContent;
	color: $tomato;
	&:hover {
		text-decoration: underline;
		color: $tomato;
	}
}

.func {
	display: flex;
	align-items: center;
	gap: 5px;
	flex-wrap: wrap;
}
.extra::first-letter {
	text-transform: capitalize;
}
.alertInfo {
	@include font;
	color: $blackPearl;
}

.botIcon {
	margin: 0 3px 0 7px;
}

.withIcon {
	display: flex;
	align-items: center;
}
