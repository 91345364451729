#editableList .Select-menu-outer {
	width: 40%;
}

#editableList .form-group {
	margin: 0;
	margin-top: 10px;
}

#editableList .Select {
	padding: 0 2px;
}
