.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.4rem 1.5rem 1.4rem 1.5rem;

	border-bottom: 1px solid #e5e5e5;

	.title {
		font-size: 23.04px;
		color: #334155;
		font-weight: 500;
		font-family: "basiercircle";
		line-height: 24px;
		letter-spacing: -0.5px;
	}

	.icon {
		cursor: pointer;
		display: flex;
		align-items: center;
	}
}
.closeButton {
	border-radius: 10px;
	background: #f8fafc;
	display: flex;
	padding: 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	margin-left: 12px;
}
.coinIcon path,
.arrowIcon path,
.closeIcon path {
	stroke: #94a3b8;
}
