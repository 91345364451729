.evaluationCardInfos {
	display: flex;
}

.infos {
	margin: 0 5px;
}

.avatar {
	border-radius: 12px !important;
}

.evaluationCardAvatar {
	display: flex;
	width: 50px;
	height: 50px;
	flex-direction: column;
	justify-content: center;
	border: 1.5px solid #fff;
}

.name {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.date {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.evaluationCardDate {
	display: flex;
	flex-direction: row;
	align-items: center;
}
