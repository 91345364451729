.container {
	padding: 20px;
	font-family: "Work Sans" !important;
	width: 100%;
	height: 100%;

	.root {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
	}
	:global {
		.drawer-header-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 12px;
			border-bottom: 1px solid #e2e8f0;
			.title {
				color: #334155;

				font-size: 23.04px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				letter-spacing: -0.5px;
			}
			.icon {
				display: flex;
				padding: 10px;
				justify-content: center;
				align-items: center;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.candidateInfos {
		display: flex;
		padding: 16px;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 16px;
		border-radius: 12px;
		border: 1px solid #f1f5f9;
		background: #f8fafc;

		.firstSection {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #e2e8f0;
			padding-bottom: 12px;
			.infos {
				display: flex;
				justify-content: center;
				align-items: center;
				.avatar {
					border-radius: 8px;
				}
				.nameSquare {
					display: flex;
					width: 40px;
					height: 40px;
					padding: 11.111px 8.889px;
					justify-content: center;
					align-items: center;
					gap: 11.111px;
					border-radius: 8.889px;
					background: #f59e0b;
					color: #fff6d6;
					font-size: 15.556px;
					font-weight: 500;
					line-height: 100%;
					letter-spacing: -0.556px;
					margin-right: 8px;
				}
				.candidateName {
					h1 {
						color: #334155;
						font-size: 18px;
						font-style: normal;
						font-weight: 500;
						line-height: 100%;
						margin-bottom: 4px;
						text-transform: capitalize;
						cursor: pointer;
					}
					p {
						color: #4b5563;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 100%;
					}
					.userFunction {
						display: flex;
					}
				}
			}
			.button {
				display: flex;
				padding: 10px 12px;
				align-items: center;
				gap: 12px;
				border-radius: 8px;
				background: #00857a;
				color: #fff;
				font-size: 16px;

				font-weight: 500;
			}
		}
		.secondSection {
			display: flex;
			gap: 8px;
			padding-top: 12px;
			div {
				display: flex;
				padding: 8px 12px;
				align-items: center;
				gap: 12px;
				border-radius: 8px;
				border: 1px solid #e2e8f0;
				background: #fff;
				width: max-content;
			}
			.phone,
			.email {
				color: #4b5563;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 100%;
				max-width: 280px;
			}
			.linkedIn,
			.github {
				a {
					height: 20px;
				}
			}
		}
	}
	.noteContainer {
		margin-top: 24px;
		width: 100%;
		.noteSectionDrawer {
			box-shadow: none;
		}
		.noteHeader {
			padding: 12px;
			display: flex;
			align-items: center;
			width: 100%;

			.dot {
				display: flex;
				align-items: center;
				color: #64748b;
				font-size: 1.2rem;
				line-height: 1rem;
				margin: 0 0.375rem 0 0.5rem;
			}
			.noteInfos {
				width: 100%;
				margin-left: 7px;
				svg {
					margin-right: 8px;
					fill: none;
				}
				span {
					color: #64748b;
					text-align: right;
					font-variant-numeric: lining-nums tabular-nums;

					font-size: 16px;
					font-style: normal;
					font-weight: 400;
				}
				.firstContent {
					display: flex;

					.name {
						color: #262626;
						font-size: 18px;
						font-style: normal;
						font-weight: 500;
					}
					.vacancy {
						color: #00857a;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
					}
				}
				.secondContent {
					display: flex;
					justify-content: space-between;
					width: 100%;
					div {
						display: flex;
					}
				}
			}
		}
		.noteBody {
			padding: 16px;
			:global {
				p {
					color: #334155;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}

				.desc--action {
					color: #00857a;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
				}
			}
		}
	}
	.drawerFooter {
		height: 100%;
		display: flex;
		align-items: flex-end;

		button {
			display: flex;
			padding: 8px 12px;
			align-items: center;
			gap: 10px;
			border-radius: 8px;
			border: 1px solid #e2e8f0;
			background: #fff;
			color: #334155;
			margin-right: 8px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;

			letter-spacing: -0.5px;
		}
	}
}
