.container {
	background-color: white;
	border-top: 1px solid #e2e8f0;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-bottom: 1px solid #e2e8f0;
}

.emptyStateContainer {
	padding: 100px 0;
}

.icon {
	path {
		stroke: #fff;
	}
}

.iconDisabled {
	path {
		stroke: #d4d4d4;
	}
}

.newMailButton {
	gap: 8px;
	padding: 0 16px;
}

.newMailContainer {
	height: 32px;
}

.newMailText {
	line-height: unset;
}
