.searchResult {
	max-height: 200px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #737373;
		border-radius: 2px;
	}
}
.header {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.5rem 0.75rem;
	color: #737373;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
	background-color: white;
	font-family: "Work Sans";
}

.line {
	background-color: #e5e5e5;
	height: 1px;
	flex: none;
	flex-grow: 1;
}

.parent {
	.item {
		padding: 0.5rem 0.75rem;
		white-space: nowrap;
		font-size: 1rem;
		line-height: 1.125rem;
		font-family: "Work Sans";
		font-weight: 400;
		cursor: pointer;
		color: #262626;

		&:hover {
			background-color: #f5f5f5;
		}
	}
}
