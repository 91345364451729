.iconWrapper {
	width: 1.125rem;
	height: 1.125rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
}

.inputContainer {
	display: flex;
	align-items: center;
	gap: 18px;
	width: 100%;
	flex-wrap: wrap;
	padding: 0rem 0.75rem 1rem 0.75rem;
}

.unit {
	background-color: #fafafa;
	border-radius: 3px;
	padding: 8px;
	display: flex;
	align-items: center;
	gap: 5px;

	.km {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #262626;
	}
}

.header {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.5rem 0.75rem;
	color: #737373;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
	background-color: white;
	font-family: "Work Sans";

	.number {
		color: #339d95;
	}

	.line {
		background-color: #e5e5e5;
		height: 1px;
		flex: none;
		flex-grow: 1;
	}
}

.radiusFieldWrapper .input[type="number"] {
	width: 60%;
	flex-basis: auto;
	height: 30px;

	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type="number"] {
		-moz-appearance: textfield;
	}
}
