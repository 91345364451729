$font-family: "Work Sans";

.skillsList {
	display: flex;
	flex-wrap: wrap;
	row-gap: 12px;
	column-gap: 14px;
}

.skillTag {
	font-family: $font-family;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 21px;
	gap: 7px;
	border-radius: 8px;
	background: #f7f9fa;
}

.categoryLabel {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 12.25px;
	line-height: 14px;
	color: #8b94a6;
}

.skillsContainer {
	display: flex;
	flex-direction: column;
	gap: 7px;
}

.skillsRows {
	display: flex;
	flex-direction: column;
	gap: 21px;
}
