.headerEnd {
	display: flex;
	align-items: center;
	gap: 5px;
}

.headerStart {
	display: flex;
	align-items: center;
	gap: 5px;
	.label {
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 20px;
		color: #181819;
	}
	.counter {
		background: #00ba88;
		border-radius: 11px;
		color: #fff;
		padding: 0px 8px;
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
	}
}
.interviews {
	margin-top: 10px;
	.interview {
		padding: 10px;
		cursor: pointer;
		.itemsContainer {
			display: grid;
			grid-template-columns: 9fr 8fr 8fr 6fr;
			gap: 5px;
			margin-top: 10px;
			.label {
				font-family: "basiercircle";
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
				color: #1a1c1d;
			}
			.value {
				display: flex;
				align-items: center;
				gap: 5px;
			}
		}
	}
}
.has_bg {
	background: #fafbfb;
	border-radius: 10px;
}
.loader_container {
	width: 100%;
	height: 330px;
	display: flex;
	align-items: center;
	justify-content: center;
}
