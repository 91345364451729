.container {
	height: 100%;
}

.root {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.5px;
}

.count {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 33px */
	letter-spacing: -0.5px;
}

.body {
	margin: 1.5rem;
	flex-basis: 80%;
}
