.notes {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--6, 24px);
	height: 100%;

	.note {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 7px;
		align-self: stretch;

		.noteHeader {
			display: flex;
			align-items: stretch;
			gap: 7px;
			align-self: stretch;

			.avatar {
				width: 33.5px;
				height: 33.5px;
				border-radius: 3px;
			}

			.info {
				display: flex;
				flex-direction: column;
				gap: 3.5px;
				justify-content: space-between;
				font-family: "Work Sans";
				font-style: normal;

				.name {
					color: var(--neutral-800, #262626);
					font-size: 14px;
					font-weight: 600;
					line-height: 15.75px;
				}

				.status {
					display: flex;
					align-items: center;
					color: var(--neutral-500, #737373);
					font-variant-numeric: lining-nums tabular-nums;
					font-size: 12.25px;
					font-weight: 400;
					line-height: 14px;
				}

				.dot {
					color: #a3a3a3;
					font-size: 1rem;
					line-height: 1.125rem;
					margin: 0 0.375rem 0 0.5rem;
				}
			}
		}

		.content {
			display: flex;
			padding: 10.5px 17.5px 10.5px 14px;
			flex-direction: column;
			align-items: flex-start;
			gap: 7px;
			align-self: stretch;
			border-radius: 0px 16px 16px 16px;
			background: #f3f4f6;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
				0px 1px 3px 0px rgba(0, 0, 0, 0.1);

			* {
				font-family: "Work Sans";
			}
			p,
			span {
				color: var(--neutral-900, #171717);
				font-size: 14px;
				line-height: 21px;
			}

			span[data-lexical-mention="true"] {
				font-weight: bold;
			}
		}
	}
}

span[data-lexical-mention="true"] {
	font-weight: bold !important;
}

.view__note__btn {
	margin-top: 5px;
	background: none;
	padding: 0;
	color: #108c82;
	text-decoration: none;
	font-weight: 500;
	background-color: #f3f4f6;
	z-index: 10;
	margin-right: 0px;
	&:hover {
		text-decoration: underline;
		color: #006a62;
	}
}

.noteContent {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.backButton {
	display: flex;
	align-items: center;
	gap: 9px;
	background-color: #fff;
	color: #525252;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
}
