.headerColumnContainer {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;

	.headerContainer {
		display: flex;
		align-items: center;
		max-width: 70%;
		min-width: 15%;
		span {
			font-family: "Work Sans", sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 16px;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
			overflow: hidden;
		}
	}
	.iconsActionsContainer {
		position: absolute;
		right: 3px;
		display: flex;
		align-items: center;
		gap: 3px;
	}

	.dropDownDisplay {
		position: absolute;
		right: 11.5rem;
		top: 19px;
		width: 1px;
		height: 5px;
	}
}

.sixDots {
	min-width: 1rem;
	opacity: 0;
	transition: all 150ms ease;
}

.headerColumnContainer:hover .sixDots {
	opacity: 1;
}

.marginLeft {
	margin-left: 1rem;
}

.title {
	font-family: "Work Sans", sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	cursor: default;
	width: 100%;
}
