.header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	position: absolute;
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding: 0.5rem;
	font-family: inherit;
	font-size: 100%;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: #fafafa;
	border-radius: 8px;
}

.chevronIcon {
	width: 44px;
	height: 44px;
	display: block;
	color: #898f96;
	cursor: pointer;
	color: #404040;
}

.buttonLeft {
	left: 0px;
}

.buttonRight {
	right: 0px;
}

.text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 14px;
}

.span {
	margin-right: 10px;
	text-transform: capitalize;
}
