.accordionRoot {
	width: 100%;
	padding: 12px;
	border-radius: 12px;
	background: #f8fafc;
	border: 1px solid #e2e8f0;
}

.accordionHeader {
	display: flex;
	margin: 0;
}
.accordionTrigger {
	padding: 0;
	width: 100%;
	display: flex;
	border-radius: 0%;
	background: #f8fafc;
	h3 {
		margin: 0;
	}
}

.accordionContent {
	font-size: 16px;
	font-weight: 400;
	overflow: hidden;
	color: #334155;
	line-height: 150%;
	padding-top: 12px;
	font-style: normal;
	font-family: "Work Sans";

	&[data-state="open"] {
		margin-top: 12px;
		border-top: 1px solid var(--gray-200, #e2e8f0);
	}
}
