.container {
	border: 1px solid #e5e5e5;
	box-shadow: none;
	height: 80%;
	color: #4b5563;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	min-height: auto;
	padding: 13px;
	border-radius: 8px;
}
