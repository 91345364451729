@mixin scrollStyle {
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.container {
	background-color: #fff;
	padding: 10px 0px;
	flex-grow: 1;
	overflow-y: auto;
	@include scrollStyle;
}

.body {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.header {
	background: #334155;
	display: flex;
	padding: 10px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	color: #fff;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	button {
		all: unset;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.note {
	color: #64748b;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	padding: 10px 16px;
}

.placeholders {
	padding: 10px 16px;
	overflow-y: auto;
	overflow-x: hidden;
	@include scrollStyle;
	button {
		cursor: pointer;
		padding: 8px 10px;
		border-radius: 7px;
		border: 1px solid #e2e8f0;
		margin-bottom: 5px;
		background: #fff;

		color: #334155;
		font-family: "Work Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-right: 5px;
		text-transform: capitalize;
		&:hover {
			background-color: #f3f4f6;
		}
	}
}

.selectContainer {
	padding: 10px 16px;
}

.select {
	background: white;
	border-radius: 8px;
	height: 40px;

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.option {
	display: flex;
	height: 36px;
	padding: 10px 12px;
	align-items: center;
	&:hover {
		background-color: #e6f3f2 !important;
	}
}

.optionLabel {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.skeletonPlaceholder {
	display: block;
	margin-top: 5px;
}

.optionsWrapper {
	max-height: 437px;
	overflow: auto;
	@include scrollStyle;
}

.placeholderButton {
	max-width: 220px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
