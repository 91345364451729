@import "styles/sass/hireme/_colors";

.header {
	background-color: #fff;
	position: sticky;
	z-index: 10;
	top: 0;
	.flex {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.divider {
		border-bottom: 1px solid #fafafa;
		margin: 0 20px;
	}
}

.header .title {
	color: #2a3644;
	font-family: BasierCircle;
	font-size: 26.25px;
	font-style: normal;
	font-weight: 500;
	line-height: 31.5px;
	letter-spacing: -0.5px;
}

.closeBtn {
	display: flex;
	padding: 6px 12px;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid #eceff3;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 15.75px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
	&:hover {
		background: #f8fafc;
	}
}

.drawerContent {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.body {
	flex-grow: 1;
	padding: 20px;
	display: flex;
	flex-direction: column;
	.flex1 {
		flex-grow: 1;
	}
}

.form_field {
	:global {
		margin-bottom: 24px;

		label::first-letter {
			text-transform: capitalize;
		}

		label {
			color: $gray700;
			text-transform: lowercase;
			font-style: normal;
			font-weight: 500;

			margin-bottom: 0;
			font-size: 17px;
		}

		textarea.form-control,
		input.form-control {
			font-family: "Work Sans";
			font-weight: 400;
			font-size: 16px;
			border-radius: 8px;
			margin-top: 12px !important;
			border: 1px solid $gray300;
			padding: 12px 14px;
			box-shadow: none;
			min-height: 48px;
			&:focus {
				border: 1px solid $primary300;
				box-shadow: 0 0 0 4px $primary100;
			}
		}

		textarea.form-control ~ .input-maxlength {
			position: absolute;
			right: 14px;
			bottom: 10px;
		}

		textarea.form-control {
			min-height: 330px !important;
		}

		.help-block {
			padding: 0;
			min-height: 0;
		}

		.has-error {
			input,
			textarea {
				border-color: $red400;
				&:focus {
					box-shadow: 0 0 0 4px $red100;
				}
			}
			.inline-error {
				margin-top: 12px;
			}
		}
	}
}

.form_field_min_height {
	:global {
		textarea.form-control {
			min-height: 200px !important;
		}
	}
}

.intro {
	padding: 10px;
	border-radius: 8px;
	background: #f8fafc;
	color: #334155;
	font-family: "Work Sans";
	font-size: 15.75px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.5px;
	margin-bottom: 24px;
}

.privacy_notice {
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: -0.5px;
	color: #667085;
	margin-bottom: 30px;
	:global {
		a {
			color: $primary500;
			font-size: 14px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.button + .button {
	margin-left: 8px;
}

.button {
	background-color: $primary500;
	color: $white;
	border-radius: 8px;
	height: 40px;
	font-size: 16px;
	font-family: "Work Sans";
	font-weight: 500;
	border: 1px solid;
	border-color: $primary500;
	white-space: nowrap;
	padding: 0 24px;
	&:hover {
		background: #006a62;
	}
}

.button_outline {
	color: $gray500;
	border: 1px solid;
	border-color: #eceff3;
	background-color: $white;
	&:hover {
		background: #f5f5f5;
	}
}

.in_consultancy {
	:global {
		input.form-control,
		textarea.form-control {
			&:focus {
				border-color: #ff6849 !important;
				box-shadow: 0 0 0 4px #ff684922 !important;
			}
		}
	}
	.button {
		background-color: #ff6849;
		border-color: #ff6849;
		&:hover {
			opacity: 0.9;
		}
	}
	.button_outline {
		color: $gray500;
		border: 1px solid;
		border-color: #eceff3;
		background-color: $white;
		&:hover {
			background: #f5f5f5;
		}
	}
	.privacy_notice {
		a {
			color: #ff6849;
		}
	}
}
