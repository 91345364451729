@import "modules/SearchCandidate/TableVariables/fonts.scss";

.optionsWrapper {
	display: flex;
	flex-direction: column;
	padding: 4.5px 0;
	max-height: 100px !important;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9ca3af;
		border-radius: 12px;
	}

	.option {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0.5rem 0.75rem;
		font-size: 1rem;
		line-height: 1.125rem;
		cursor: pointer;
		color: #262626;
		font-weight: 400;
		@include SmallParagraph;

		&:hover {
			background-color: #f5f5f5;
		}
	}

	.iconWrapper {
		width: 1.125rem;
		height: 1.125rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.separator {
	width: 100;
	height: 1px;
	background-color: #d1d5db;
}

.buttonContainer {
	display: flex;
	justify-content: center;
	padding: 0px;
}

.paper {
	position: absolute;
	left: 100%;
	top: 0%;
}
