$font-family: "Work Sans";

.closeButtonItem {
	display: flex;
	align-items: flex-start;
	background-color: transparent;
	padding: 0px;
}

.notificationDescription {
	flex-grow: 1;
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #262626;
}

.notificationItem {
	grid-column: 1 / 3;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 14px;
	gap: 16px;

	background: #fffbeb;

	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
	border-radius: 6px;
}
