.root {
	display: flex;
	align-items: center;
	gap: 10px;
	div[class^="react-rater-star"] {
		color: #edeeef;
		font-size: 21px;
		line-height: 1rem;
	}

	div[class^="react-rater-star will-be-active"],
	div[class^="react-rater-star is-active"] {
		color: #339d95;
	}

	div[class^="react-rater"] {
		display: flex;
		align-items: center;
	}

	.text {
		font-size: 14px;
		padding-top: 4px;
	}
}
