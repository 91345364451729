.drawerContent {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 24px;
	color: #1e293b;
	font-weight: 500;
	line-height: 150%;
	font-style: normal;
	font-family: Work Sans;
	letter-spacing: -0.5px;
}

.closeBtn {
	gap: 12px;
	display: flex;
	border-radius: 8px;
	background: #fff;
	align-items: center;

	color: #1e293b;
	font-weight: 500;
	line-height: 21px;
	font-size: 15.75px;
	font-style: normal;
	letter-spacing: -0.5px;
	font-family: "Work Sans";

	&:hover {
		background: #f8fafc;
	}
}

.header {
	top: 0;
	position: sticky;
	background-color: #fff;
	.flex {
		display: flex;
		padding: 16px 24px;
		justify-content: space-between;
	}
	.dividerHeader {
		border-bottom: 1px solid #e2e8f0;
	}
}

.main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.body {
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;

	.headerCard {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		padding: 12px;
		gap: 10px;
		border-radius: 12px;
		border: 1px solid #e2e8f0;
		background: #f8fafc;

		.left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.label {
				color: #64748b;
				font-family: "Work Sans";
				font-size: 15.75px;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: -0.5px;
			}

			.name {
				color: #334155;
				font-family: "Work Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
			}

			h3 {
				margin: 0;
				padding: 0;
			}
		}
	}

	.content {
		display: flex;
		padding: 12px 12px 12px 0px;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		border-radius: 12px;
		background: #fff;

		.info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
			align-self: stretch;
		}
	}

	.supplierMode {
		display: flex;
		flex-direction: column;
		gap: 10px;
		.item {
			display: flex;
			align-items: center;
			gap: 15px;

			&.longItem {
				flex-wrap: wrap;
			}
			.name,
			.emailManager {
				width: 185px;
			}
		}
	}

	.item {
		display: flex;
		align-items: center;
		gap: 21px;
		align-self: stretch;
		.name {
			display: flex;
			width: 174px;
			align-items: center;
			gap: 12px;

			svg {
				width: 20px;
				height: 20px;
				flex-shrink: 0;
			}

			.textName {
				color: #334155;
				font-variant-numeric: lining-nums tabular-nums;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				white-space: nowrap;
			}
		}

		.value {
			display: flex;
			align-items: center;
			gap: 12px;

			.text {
				color: #334155;
				font-variant-numeric: lining-nums tabular-nums;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				white-space: nowrap;
			}
			&.fixedWidth {
				width: 100px;
			}
		}
	}

	.title {
		display: flex;
		height: 30px;
		align-items: center;
		gap: 12px;
		align-self: stretch;

		.titleText {
			color: #334155;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
	}

	.commentCard {
		display: flex;
		padding: 16px;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;
		border-radius: 12px;
		border: 1px solid #e2e8f0;
		background: #f8fafc;

		p {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 15;
			flex: 1 0 0;
			overflow: hidden;
			color: #334155;
			text-overflow: ellipsis;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			letter-spacing: -0.5px;
		}
	}
}

.divider {
	height: 1px;
	align-self: stretch;
	background: var(--border-gray, #e2e8f0);
}

.picture {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	box-shadow: 0px 0px 0px 2px #ffffff;
	border-radius: 50% !important;
	background: white;
	cursor: pointer;
}

.avatar {
	display: flex;
	align-items: center;
	border-radius: 50% !important;

	> div {
		position: relative;
		font-size: 13px;
	}
}

.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
}

.collaboratorPicture {
	border-radius: 50%;
	border: 3px solid white;
	margin-left: -8px;
}

.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
}

.values {
	display: flex;
	gap: 21px;
	align-items: center;
}

.tooltip {
	display: flex;
	flex-direction: column;
	.text {
		display: block;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}

.icon {
	path {
		fill: #64748b;
	}
}

.description {
	p {
		word-break: normal;
	}
}

.companyAddress {
	width: 170px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tagIcon,
.departmentIcon {
	path {
		stroke: #64748b;
	}
}
