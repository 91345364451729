:root {
	--fc-border-color: #e2e8f0;
	--fc-today-bg-color: #fff;
	--fc-event-text-color: #334155;
}

/* FullCalendar */

table.fc-scrollgrid {
	overflow: hidden;
}

td.fc-day-past {
	background: #f8fafc;
}

td.fc-day-today {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: var(--height);
		background: #f8fafc;
	}
}

.fc {
	height: 100%;
}
.fc .fc-view-harness {
	height: 100%;
}
/* Column Header  */
.fc .fc-col-header-cell-cushion {
	padding: 0px !important;
	text-decoration: none;
	width: 100%;
}
.fc-popover-body {
	max-height: 300px;
	overflow-y: auto;
}
.fc-popover-body::-webkit-scrollbar {
	width: 6px;
}

.fc-popover-body::-webkit-scrollbar-track {
	margin-block: 3px;
	border-radius: 99px;
	background: #f4f4f433;
}

.fc-popover-body::-webkit-scrollbar-thumb {
	width: 100%;
	background: #cacaca;
	border-radius: 99px;
}

/* Body scroll */
.fc .fc-scroller {
	overflow: hidden !important;
}

.fc .fc-scroller-liquid-absolute {
	overflow: hidden scroll !important;
}
.fc-scroller-liquid-absolute::-webkit-scrollbar {
	width: 6px;
}

.fc-scroller-liquid-absolute::-webkit-scrollbar-track {
	margin-block: 3px;
	border-radius: 99px;
	background: #f4f4f433;
}

.fc-scroller-liquid-absolute::-webkit-scrollbar-thumb {
	width: 100%;
	background: #cacaca;
	border-radius: 99px;
}
.fc .fc-daygrid-day-top {
	max-height: 38px;
}
.fc .fc-daygrid-day-frame {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 166px;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
	margin-bottom: 7px;
}

.fc-direction-ltr .fc-timegrid-col-events {
	margin: 0px 12px 0px 2px;
}
/* Time slot */
.fc .fc-timegrid-slot-minor {
	border-top: none;
}
.fc .fc-timegrid-slot-label {
	padding: 10px;
}

.fc .fc-timegrid-slot-label-cushion {
	color: #64748b;
	text-align: center;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-transform: uppercase;
}

/* Indicator */
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
	display: none;
}
.fc .fc-timegrid-now-indicator-line {
	border-width: 2px 0px 0px;
}

.fc .fc-daygrid-day-number {
	text-decoration: none;
	color: #000;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.fc .fc-daygrid-day-top {
	justify-content: flex-end;
	padding: 10px;
}

.fc-event-main {
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #cbd5e1;
}

.fc-popover {
	border-radius: 6px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.fc .fc-popover-close {
	display: none;
}
.fc-popover-title {
	color: #334155;
	text-overflow: ellipsis;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.fc-highlight {
	opacity: 1 !important;
	border-radius: 4px;
	border: 1px solid #00857a;
	background: #e6f3f2 !important;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
