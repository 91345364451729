.textareaField {
	width: 100%;
	padding: 10.5px;
	font-size: 14px;
	color: #262626;
	font-weight: 400;
	line-height: 150%;
	font-style: normal;
	border-radius: 8px;
	font-family: "Work Sans";
	resize: none;
	&:focus {
		border-color: #00857a;
	}
	&::placeholder {
		color: #94a3b8;
	}
}
