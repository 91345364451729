@import "../../Variables/styles.scss";

.section {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-right: 0.6rem;
	&:not(:last-child) {
		margin-bottom: 1.7rem;
		padding-bottom: 1.7rem;
		border-bottom: 1px solid $ghostWhite;
	}
	.title {
		@include profileContent;
		line-height: 1.43;
		color: $raven;
	}
	.row {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
		.textGras,
		.textNormal {
			&:not(:first-child) {
				::before {
					height: 6px;
					width: 6px;
					border-radius: 50%;
					content: "";
				}
			}
		}
		.textGras {
			p {
				font-weight: 500;
			}
			&:not(:first-child) {
				::before {
					background: $raven;
					content: "";
				}
			}
		}
		.textNormal {
			display: flex;
			.text {
				color: $raven;
				text-transform: none;
			}
			.textSpan {
				color: $blackPearl;
				text-transform: capitalize;
			}
			p {
				font-weight: normal;
				text-transform: capitalize;
			}
			&:not(:first-child) {
				::before {
					background: $raven;
					content: "";
				}
			}
		}
		p {
			position: relative;
			display: flex;
			align-items: center;
			gap: 0.9rem;
			@include profileContent;
			line-height: 1.43;
			margin: 0;
			color: $blackPearl;
		}
	}
	.justifyBetween {
		display: flex;
		justify-content: space-between;
	}
}

.emptyState {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	p {
		@include profileContent;
		color: $blackPearl;
		margin-left: 3px;
	}
}
