.dropdown {
	position: absolute;
	top: 45px;
	left: 84px;
	width: 650px;
	max-height: 385px;
	overflow: auto;

	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 0 0 12px 12px;
	background: #fff;

	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
		0px 2px 4px -2px rgba(16, 24, 40, 0.06);

	z-index: 1;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.listItem {
	display: flex;
	padding: 5px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	text-decoration: none;
	background: #fff;
	text-align: start;
	width: 100%;
}

.userInfo {
	display: flex;
	flex-direction: column;
}

.username {
	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.email {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	.body {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 586px;
	}
}

.emails {
	border-top: 1px solid #e2e8f0;
}
