.subMenuItem {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0.5rem 0.75rem;
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125rem;
	color: #262626;
	font-family: "Work Sans";
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}
}
