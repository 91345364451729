.custom_new_select {
	.Select-control {
		height: 35px !important;
		margin-top: 0 !important;
	}
	.Select-multi-value-wrapper {
		display: flex !important;
		gap: 0.5rem !important;
		padding: 5px !important;
	}
	.Select-placeholder,
	.Select-input,
	.Select--single > .Select-control .Select-value {
		line-height: 32px !important;
		display: flex;
		align-items: center;
	}
	.Select,
	.Select div,
	.Select input,
	.Select span {
		margin-top: 0 !important;
	}

	.Select-input > input {
		box-sizing: border-box !important;
	}
}
