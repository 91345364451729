.downloadBtn {
	all: initial;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 8px;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid var(--gray-100, #f1f5f9);
	background: var(--white, #fff);
	&:hover {
		background-color: var(--gray-100, #f1f5f9);
	}
}

.loadingIcon {
	color: #119797;
	inset: 0;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tagIcon {
	path {
		stroke: #64748b;
	}
}
