@import "modules/candidate/Profile/Variables/styles.scss";

.btnContainer {
	padding: 8px 0;
}

.btnAdd {
	display: flex;
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	span {
		@include profileContent;
		color: $swamp;
		font-weight: 500;
		margin-left: 6px;
	}
}
.field {
	margin-bottom: 41px;
	.label {
		@include profileContent;
		line-height: 20px;
		color: $labelform;
		margin-bottom: 4px;
	}
	.errorMsg {
		@include profileContent;
		color: #df006b;
		margin-top: 10px;
	}

	.flex {
		display: flex;
		align-items: center;
		gap: 34px;
		flex-wrap: wrap;
		&.itemCenterNone {
			align-items: initial;
		}
	}
	.box {
		[class^="Select-control"] {
			border: 1px solid $borderInput;
			width: 247px;
			height: 36px;
			border-radius: $raduisButton;
		}
		[class^="Select-placeholder"],
		[class^="Select-value"] {
			line-height: 40px;
		}
		[class^="form-group"] {
			margin: 0;
		}

		.info {
			:global(.Select-value-label) {
				color: $blackPearl !important;
				font-family: "basiercircle";
			}
			&.error {
				> input,
				input[class^="form-control"] {
					border: 1px solid $tomatoRed;
				}
			}
			> input,
			input[class^="form-control"] {
				border: 1px solid #babfc3;
				width: 247px;
				height: 36px;
				border-radius: $raduisButton;
				padding: 0 30px 0 12px;
				min-height: inherit;
				@include profileContent;
				color: $blackPearl;
				overflow: hidden;
				text-overflow: ellipsis;
				&:disabled {
					opacity: 1;
				}
			}
		}
	}
}
@-moz-document url-prefix('') {
	.field {
		.box {
			[class^="Select-placeholder"],
			[class^="Select-value"] {
				line-height: 44px;
			}
		}
	}
}
.extraBenifits {
	position: relative;

	.titleExtra {
		color: #58626b;
		font-weight: 500;
		margin-bottom: 12px;
	}
	h3 {
		@include layoutItems;
		color: $swamp;
		margin-bottom: 12px;
	}
	.extraBenifitItems {
		display: flex;
		align-items: center;
		gap: 6px;
		flex-wrap: wrap;
		.item {
			@include profileContent;
			color: $blackPearl;
			border: 1px solid #babfc3;
			padding: 3px 6px;
			border-radius: $raduisButton;
			display: flex;
			align-items: center;
			background-color: #fafafb;
			button {
				background: transparent;
				padding: 0;
				display: flex;
				align-items: center;
				margin-left: 12px;
			}
		}
	}
}

.listExtraBenifits {
	width: 288px;
	background: $white;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 10px;
	z-index: 10;
	#arrow {
		position: absolute;
		&:after {
			background-color: white;
			box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
			position: absolute;
			top: -25px; // padding + popper height
			left: 0;
			transform: rotate(45deg);
		}
	}
	&[data-popper-placement^="top"] > #arrow {
		bottom: -30px;
		// :after {
		//   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
		// }
	}
	.searchBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		input {
			border: 1px solid $borderInput;
			width: 100%;
			height: 36px;
			line-height: 36px;
			padding: 0 12px;
		}
		button {
			background: $white;
			@include layoutItems;
			font-size: 14px;
			border: 1px solid $borderInput;
			padding: 13px 12px;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin-top: 12px;

		li {
			display: flex;
			align-items: center;
			padding: 8px;
			cursor: pointer;

			&:hover {
				background-color: $ghostWhite;
			}
			label {
				@include profileContent;
				color: $swamp;
				margin: 0;
				cursor: pointer;
			}
			input {
				margin-right: 10px;
				margin-top: 0;
				width: 18px;
				height: 18px;
				cursor: pointer;
				accent-color: $tomatoRed;
			}
		}
	}
}

.deleteItem {
	top: 3rem;
	background: none;
	padding: 1rem 0;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	@include font;
	color: $blackPearl;
	margin-left: -4px;
	svg {
		width: 2.6rem;
		g {
			g {
				path {
					fill: $tomatoRed;
				}
			}
		}
	}
	&:disabled {
		color: $aluminium;

		svg {
			g {
				g {
					path {
						fill: $aluminium;
					}
				}
			}
		}
	}
	.prefLoc {
		display: flex;
		gap: 10px;
	}
}
