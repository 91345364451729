@import "modules/candidate/Profile/Variables/colors.scss";

@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

.container {
	width: 850px;
	margin: auto;
	background: white;
	padding: 24px 120px 60px 120px;
	h6 {
		@include font;
		line-height: 16px;
		color: $greyChateau;
		margin-bottom: 8px;
	}
	h4 {
		@include font;
		font-weight: 600;
		font-size: 20px;
		line-height: 25px;
		color: $blackRussian;
	}
	.doneAction {
		float: right;
		margin: 20px 0;
	}
	.uploadBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		background: #ffffff;
		border: 2px dashed #d8dbdd;
		border-radius: 4px;
		padding: 40px 0;
		margin-top: 56px;
		svg {
			margin-bottom: 12px;
		}
		span {
			@include font;
			line-height: 17px;
		}
		.black {
			color: $blackTune;
		}
		.grey {
			color: $greyChateau;
		}
		button {
			background: $tomato;
			color: white;
			padding: 5px 12px;
			border-radius: 4px;
		}
	}
}

.fileCardContainer {
	border: 1px solid #f0f3f5;
	border-radius: 4px;
	padding: 8px 0;
	position: relative;
	margin: 8px 0;
	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 24px;
		button {
			background: transparent;
			padding: 6px 2px;
		}
	}
	.select {
		width: 200px;
		[class$="-control"] {
			width: 200px;
			height: 38px;
		}
		[class$="-value"] {
			display: flex;
			align-items: center;
		}
		[class$="-placeholder"] {
			display: flex;
			align-items: center;
		}
	}
	.selectError {
		[class$="-control"] {
			border: 1px solid #ed2e7e;
		}
	}
	.progressLine {
		position: absolute;
		bottom: 0;
	}
}

.isDragActive {
	border: 2px dashed #fd6950 !important;
}

.shortUploaderBox {
	border: 2px dashed #f0f3f5;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	margin-top: 56px;
	align-items: center;
	justify-content: center;
	gap: 10px;
	svg {
		height: 20px;
		width: 16px;
	}
	span {
		@include font;
		color: $blackTune;
	}
	.browse {
		font-weight: 600;
		color: #ff6849;
	}
}
.error {
	@include font;
	color: #ed2e7e;
}
