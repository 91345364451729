.container {
	position: relative;
	.btn {
		background-color: white;
		color: black;
		box-shadow: none;
		padding: 0;
	}
	.menu {
		position: absolute;
		z-index: 1;
		top: 20px;
		right: 1px;
		border: 1px solid #dadee2;
		background-color: white;
		width: max-content;
		padding: 10px;
		box-shadow: 0 0 5px 0 #dadee2;
		.value {
			display: flex;
			flex-direction: column;
			gap: 8px;
			color: #202223;
			font-size: 14px;
			font-family: "basiercircle";
		}
	}
}
