@import "modules/SearchCandidate/TableVariables/fonts.scss";

.title {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #344054;
	gap: 12px;
	h3 {
		font-weight: 500;
		font-size: 26px;
		line-height: 28px;
		color: inherit;
	}
	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: inherit;
		text-align: center;
		strong {
			font-weight: 500;
		}
	}
}

.users {
	margin-top: 30px;
	margin-bottom: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 25px;
	&__avatar {
		display: flex;
		position: relative;
		justify-content: flex-end;
		svg {
			position: absolute;
			left: 50%;
			bottom: -10px;
			transform: translateX(-50%);
		}
	}
}

.form {
	padding-top: 0;
	color: #344054;
	&__password {
		margin-bottom: 0;
		label {
			color: inherit;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			text-transform: none;
			margin-bottom: 12px;
		}
		input {
			color: #344054 !important;
			border-radius: 8px !important;
			&:focus {
				border-color: #e2e2e2 !important;
				box-shadow: 0px 0px 0px 5px rgba(222, 218, 218, 0.12) !important;
			}
		}
		&_error {
			span {
				display: block;
				font-size: 14px;
				color: #dc2626;
				padding-top: 6px;
			}
			input {
				color: #dc2626 !important;
				box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12) !important;
			}
		}
	}

	&__info {
		display: flex;
		align-items: center;
		margin: 20px 0;
		color: inherit;
		background: #fafafb;
		border: 1px solid #f5f5f5;
		border-radius: 8px;
		padding: 10px;
		gap: 10px;
		font-weight: 400;
		font-size: 14px;
		span {
			padding-top: 3px;
			display: block;
		}
	}

	&__buttons {
		display: flex;
		gap: 20px;
		button {
			width: 50%;
			padding: 12px 0;
			border-radius: 8px;
		}
	}
}
