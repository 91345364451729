.items {
	padding: 0.25rem 00rem;
}

.menuItemWrapper {
	display: grid;
	align-items: center;
	grid-template-columns: max-content auto max-content;
	gap: 0.5rem;
	padding: 0.5rem 10px;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}

	img {
		width: 1.125rem;
		height: 1.125rem;
	}

	.text {
		width: fit-content;
		white-space: nowrap;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.125rem;
		color: #262626;
		font-family: "Work Sans";
	}

	img.arrowIcon {
		transform: rotate(-90deg);
		width: 1.125rem;
		height: 1.125rem;
		stroke: 1.5;
	}
}
