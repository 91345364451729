@import "../../Variables/styles.scss";
.title {
	@include profileContent;
	line-height: 1.25;
	color: #303131;
	margin-left: 1rem;
}

.selectContainer {
	width: 150px;
	margin-right: 2rem;
	[class$="-control"] {
		width: 150px;
		height: 38px;
		border: none;
	}
	[class$="-value"] {
		display: flex;
		align-items: center;
		padding-right: 23px !important;
	}
	[class$="-placeholder"] {
		display: flex;
		align-items: center;
	}
	[class$="-zone"] {
		padding-left: 2rem;
		padding-right: 0;
	}
	[class$="-outer"] {
		min-width: 150px;
	}
}
