.container {
	margin-right: 60px;
}

.alert__info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	padding: 14px;
	border-radius: 8px;
	background: white;
	border: 1px solid #e5e7eb;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

	p {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.5px;
		color: #262626;
		display: flex;
		align-items: center;
		gap: 12px;
	}

	svg {
		cursor: pointer;
		&:hover path {
			stroke: #4b5563;
		}
	}
}
