.table {
	border-spacing: 0;
	width: 100%;
	tr {
		cursor: pointer;

		th {
			&:first-child {
				width: 250px;
			}
			&:nth-child(2) {
				width: 160px;
			}
			&:nth-child(6),
			&:nth-child(7) {
				width: 250px;
			}
		}

		:last-child {
			td {
				border-bottom: 0;
				width: 25%;
			}
		}
	}

	th,
	td {
		margin: 0;
		padding: 1rem;
	}
	.tableHead {
		background: white;
		position: sticky;
		top: 0;
		z-index: 99;
	}
	.tableBody {
		border-top: 10px solid #f8f9fa;
		background: white;
		box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
	}
	.highlitedRow {
		background: #fff8f8;
	}
}
.minHeight {
	min-height: 555px;
}
.columnsHeadline {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: #202223;
}
.emtyStateWrraper {
	background: white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
	min-height: 500px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.sortedField {
	color: #fd5749;
}
