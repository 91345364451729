.container {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.list {
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.banner {
	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f1f1f1;
	background: #e6f3f2;
	padding: 10px;
	.cta {
		display: flex;
		padding: 2px 6px;
		align-items: flex-start;
		gap: 10px;
		border-radius: 4px;
		background: #fff;

		color: #00857a;
		font-family: "Work Sans";
		font-size: 10px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		margin-left: 10px;
		width: fit-content;
	}
}
.emailItem {
	&:hover {
		.actions {
			.ctaList {
				display: flex;
				align-items: center;
				gap: 12px;
			}
		}
		.time,
		.attachments {
			display: none;
		}
	}
}

.actions {
	display: flex;
	justify-content: flex-end;
	flex: 1;
	height: 36px;
}

.ctaList {
	display: none;
}

.cta {
	background: transparent;
	padding: 0;
	width: 22px;
	height: 22px;
	color: #94a3b8;
	&:hover {
		color: #64748b;
	}
}

.time {
	display: block;
	color: #000;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-left: 8px;
	white-space: nowrap;
	&.red {
		color: #d92d20;
		font-weight: 600;
	}
}

.attachments {
	display: inline-block;
	flex-shrink: 0;
}

.emptyState {
	margin-top: 45px;
}
