.container {
	box-shadow: rgb(0 0 0 / 11%) 0px 0.5px 3.5px 0px;
}

.boxLabel {
	font-size: 20px;
	font-weight: 500;
	margin-left: 5px;
	margin-bottom: 15px;
}

.details {
	display: flex;
	.vacancy {
		width: 70%;
	}
	.sidebar {
		width: 30%;
	}
}
@media only screen and (max-width: 1024px) {
	.details {
		.vacancy {
			width: 76%;
		}
		.sidebar {
			width: 24%;
		}
	}
}

.jobContainer {
	.firstRow {
		display: flex;
		flex-direction: row;
		padding-left: 5px;
	}
	.jobTitle {
		color: #a8aaac;
		font-size: 14px;
		line-height: 16px;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		margin-left: 13px;
	}
	.jobValue {
		color: #181819;
		width: 100%;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		padding-left: 35px;
		margin-top: 5px;
		margin-bottom: 15px;
	}
}
.countContainer {
	display: flex;
	flex-direction: row;
	margin-left: 35px;
	align-items: baseline;
	margin-top: -19px;
	.countText {
		font-family: "basiercircle";
		font-size: 12px;
		font-weight: 600;
		color: #a8aaac;
		margin-bottom: 15px;
		margin-top: 10px;
	}
	.countBtn {
		margin-bottom: 15px;
		margin-top: 10px;
		margin-left: 5px;
		font-size: 12px;
		background-color: white;
		border: none;
		color: blue;
	}
}
.aboutJobTitle {
	font-size: 20px;
	font-weight: 500;
	color: #181819;
}

.descriptionStyle {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #a8aaac;
	border-bottom: 1px solid #dde3e8;
	padding-bottom: 16px;
}
.jobTitle {
	font-family: "basiercircle";
	font-size: 14px;
	line-height: 16px;
	color: #6d7175;
	margin-bottom: 8px;
}
.title {
	font-family: "basiercircle";
	font-weight: 600;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	color: #303131;
	margin-bottom: 24px;
}
@media only screen and (max-width: 1024px) {
	.countContainer {
		align-items: center;
		.countBtn {
			margin-right: 15px;
		}
	}
}

.featuredImage {
	width: 85.07px;
	height: 57px;
	border-radius: 10.209px;
}

.featuredVideo {
	color: #2563eb;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-decoration-line: underline;
	margin-right: 7px;
}

.smallMarginBottom {
	margin-bottom: 24px;
}

.laptopIcon {
	margin-top: -2px;
}
