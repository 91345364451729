$red-color: #dc2626;
$font-family: "Work Sans";

.wrapper {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.container {
	width: auto;
	border-radius: 12px;
	background-color: white;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e2e8f0;
}

.body {
	display: flex;
	gap: 21px;
	align-items: center;
}

.title {
	color: var(--text-dark, #334155);
	font-family: $font-family;
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 104.167% */
	letter-spacing: -0.5px;
	margin-bottom: 0px;
}

.description {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 24px;
	color: #262626;
}

.deleteButton {
	background-color: $red-color !important;
	box-shadow: 0px 0px 1px $red-color inset;
}

.trashIcon path {
	stroke: white;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 14px;
	border-top: 1px solid #e2e8f0;
}

.notificationIcon {
	margin-top: 3px;
	height: 72px;
	width: 72px;
}

.cancel > button {
	height: 100%;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
}

.cancel > button > div {
	line-height: 17px;
	color: #525252;
}

.closeButton {
	background-color: transparent;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px;
}
.secondaryDescription {
	color: #334155;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding-right: 30px;
}

.buttons {
	padding: 12px 12px;
}

.body,
.header {
	padding: 20px 12px;
}
