@import "modules/SearchCandidate/TableVariables/fonts.scss";

$font-family: "Work Sans";
$red-color: #dc2626;

.container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(3, min-content);
	row-gap: 24px;
	padding: 24px;
}

.tableItem {
	grid-row: 3 / 4;
	grid-column: 1 / 3;
}

.searchInputItem {
	grid-column: 1 / 2;
	width: 298px;
}

.deleteButtonItem {
	grid-column: 2 / 3;
	justify-self: end;
}

.deleteButtonItem,
.searchInputItem {
	grid-row: 2 / 3;
}

.searchInput input {
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
}

div > div > button.deleteButtonDisabled {
	height: 100%;
	background-color: #f0f0f0 !important;
}

.deleteButton {
	background-color: $red-color !important;
	box-shadow: 0px 0px 1px $red-color inset;
}

.deleteButtonDisabled div {
	color: #bdbdbd;
}

.trashIcon path {
	stroke: white;
}

.trashIconDisabled path {
	stroke: #bdbdbd;
}

.containerFooter {
	position: relative;
	width: 100%;
	min-height: 40px;
	margin-top: 1.5rem;
}

.results_count {
	@include SmallParagraph;
	color: #737373;
	font-weight: 400;
	line-height: 1.125rem;
	padding: 0;
	.greenText {
		color: #00857a;
		font-weight: 500;
	}
	.grayText {
		color: #737373;
		font-weight: 400;
	}
	margin: 0rem 0 0.75rem 0.25rem;
}
