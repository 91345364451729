.root {
	padding: 16px 15px 0 15px;
	:global {
		.visibility_render {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
}
.containerFooter {
	position: relative;
	width: 100%;
	min-height: 40px;
	margin-top: 1.5rem;
}
.countLabel {
	// @include SmallParagraph;
	color: #737373;
	font-weight: 500;
	line-height: 1.125rem;
	padding: 0;

	.greenText {
		color: #00857a;
		font-weight: 500;
	}
}
