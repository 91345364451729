.container {
	padding: var(--4, 16px);
	border-radius: var(--lg, 12px);
	border: 1px solid #e2e8f0;
	background: #fff;
	width: 100%;
	height: fit-content;

	.header {
		display: flex;
		justify-content: space-between;
		width: inherit;
		cursor: pointer;

		.leftHeader {
			display: flex;
			align-items: center;
			gap: var(--3, 12px);
			align-self: stretch;

			.headerTitle {
				color: var(--slate-700, #334155);
				font-family: "Work Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 24.5px;
				letter-spacing: -0.5px;
			}

			.badge {
				display: flex;
				width: 26px;
				height: 26px;
				justify-content: center;
				align-items: center;
				gap: 8px;
				flex-shrink: 0;
				color: #334155;
				font-family: "Work Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%;
				border-radius: 16px;
				border: 1px solid #e2e8f0;
				background: #fff;
			}

			.greenBadge {
				display: flex;
				width: 26px;
				height: 26px;
				justify-content: center;
				align-items: center;
				gap: 8px;
				flex-shrink: 0;
				border-radius: 15px;
				background: #00857a;
				color: #fff;
				font-family: "Work Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%;
			}
		}

		.rotate {
			transform: rotate(180deg);
		}
	}

	.divider {
		margin: var(--4, 16px) 0;
		height: 1px;
		background: var(--border-gray, #e2e8f0);
		width: inherit;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: var(--3, 12px);
		align-self: stretch;
	}

	.hidden {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}

	.visible {
		opacity: 1;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}
}

.headerAction {
	display: flex;
	padding: 6px 10px 6px 12px;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	&:hover {
		background: #f5f5f5;
	}
}

.actionText {
	color: #334155;
	font-family: "Work Sans";
	font-size: 15.75px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
}
