.button {
	all: unset;
	color: var(--text-dark, #334155);
	height: 36px;
	padding: 0 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	gap: var(--3, 12px);
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	cursor: pointer;

	color: #394858;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;

	&:hover {
		background: var(--bg-hover, #f8fafc);
	}
}

.popover {
	display: flex;
	flex-direction: column;
	width: 217px;
	padding-bottom: 4px;
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--white, #fff);
	box-shadow: 0px 4px 16px 0px rgba(16, 24, 40, 0.03);
	color: #344054;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.popoverTitle {
	padding: 10px 16px;
}

.divider {
	border-top: 1px solid var(--border-gray, #e2e8f0);
	margin-bottom: 4px;
}

.menu {
	outline: none;
}

.menuItem {
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	outline: none;

	&:hover {
		background: var(--bg-hover, #f8fafc);
	}
}
