@import "modules/candidate/Profile/Variables/styles.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 25px;
}
.sectionUpdated {
	border: 2px solid #f01c29;
}
.paymentType {
	margin-left: 10px;
}
.container {
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background-color: $white;
	width: 100%;
	@mixin button {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 8px 16px;
		border-radius: 4px;
	}
	.flex {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 5rem;
		padding: $layoutMenuPadding $cardsPadding;
		width: 100%;
		border-bottom: 1px solid $solitudeGrey;
		&.sticky {
			position: sticky;
			top: 64px;
			background: $white;
			z-index: 10;
		}
		h3 {
			@include cardsTitle;
			margin: 0;
		}
		.firstAction {
			@include button;
			background: $button;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
				inset 0px -1px 0px rgba(0, 0, 0, 0.2);
			color: $white;
		}
		.secondAction {
			@include button;
			background: $white;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border: 1px solid $heather;
		}
	}
	.content {
		padding: $cardsPadding;
		.box {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			margin-bottom: 3rem;
			justify-content: center;
			min-height: 9rem;
			.label {
				@include profileContent;
				color: $raven;
			}
			.info {
				position: relative;
				@include profileContent;
				font-weight: 500;
				color: $blackPearl;
			}
		}

		.cvContainer {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: 10px;
			.resumerLabel {
				@include cardsTitle;
				color: $swamp;
			}
			.resumerRow {
				display: flex;
				align-items: center;
				gap: 10px;
				.resumerCol {
					display: flex;
					flex-direction: column;
					gap: 3px;
					.resumerCloud {
						display: flex;
						gap: 5px;
						.resumerInfo {
							@include profileContent;
							font-weight: 500;
							color: $blackPearl;
						}
					}
					.resumeType {
						display: flex;
						align-items: baseline;
						gap: 8px;
						p {
							@include profileContent;
						}
					}
				}
			}
		}
		.personalContainer {
			display: flex;
			// flex-direction: column;
			gap: 60px;
			.personalRow {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				gap: 40px;
			}
			.profileBox {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.profileLabel {
					@include profileContent;
					color: $raven;
				}

				.profileInfo {
					@include profileContent;
					font-weight: 500;
					color: $blackPearl;
				}
			}
		}
		.profileContainer {
			display: flex;
			flex-direction: column;
			gap: 40px;
			.secondRow {
				display: flex;
				gap: 150px;
			}
			.profileRow {
				display: flex;
				flex-wrap: wrap;
				gap: 160px;
			}

			.profileBox {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				.profilePreferedLoc {
					display: flex;
					gap: 50px;
					@include profileContent;
					font-weight: 500;
					color: $blackPearl;
				}
				.profileEmpType {
					display: flex;
					gap: 20px;
					@include profileContent;
					font-weight: 500;
					color: $blackPearl;
				}
				.text::first-letter {
					text-transform: uppercase;
				}
				.profileLabel {
					@include profileContent;
					color: $raven;
				}

				.profileInfo {
					@include profileContent;
					font-weight: 500;
					color: $blackPearl;
				}

				.profileBenefit {
					display: flex;
					gap: 5px;
					span {
						@include profileContent;
						font-weight: 500;
						color: $blackPearl;
						border: 1px solid $solitudeGrey;
						border-radius: 4px;
						padding: 5px 7px;
						width: max-content;
					}
				}
				.profileBenefit::first-letter {
					text-transform: uppercase;
				}
			}
		}

		.row {
			display: flex;
			gap: 10px;
			align-items: center;
			.iconFlex {
				margin-top: 10px;
			}
		}

		.grid {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			column-gap: 3.5rem;
		}
	}
}

.emptyContainer {
	display: flex;
	gap: 1rem;
	justify-content: flex-start;
	align-items: center;
	.img {
		width: 20px;
	}
	p {
		@include profileContent;
		line-height: 1.43;
		margin: 0;
		color: $blackPearl;
	}
}

.motivationTitle {
	margin-top: 1.5rem;
	@include profileContent;
	color: $raven;
}

.motivationText {
	@include profileContent;
	font-weight: 500;
	color: $blackPearl;
	white-space: initial;
}
