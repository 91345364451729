.calendarContainer {
	max-height: 358px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	display: flex;
	gap: 26px;
	& > div {
		&:first-child {
			padding: 16px;
			width: 50%;
		}
		&:last-child {
			padding: 16px 16px 16px 0;
			width: 50%;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-track {
				margin-block: 3px;
				border-radius: 99px;
			}

			&::-webkit-scrollbar-thumb {
				width: 100%;
				background: #00000022;
				border-radius: 99px;
			}
		}
	}
	.calendar {
		padding: 0;
		margin-top: 8px;
	}
	.slotsHeader {
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 10px;
	}
	.slotItem {
		.slotDateValue {
			color: #64748b;
			font-family: "Work Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			margin-bottom: 6px;
		}
		.timeSlotSeparator {
			color: #000;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
		& > div {
			display: flex;
			gap: 12px;
			align-items: center;
		}
		.addTimeSlotBtn {
			all: unset;
			cursor: pointer;
			margin-left: auto;
			display: flex;
			padding: 4px;
			align-items: center;
			gap: 10px;
			border-radius: 3px;
			background: #f1f5f9;
			&:hover {
				background: #dce0e4;
			}
		}
	}
}

.cellClassName {
	margin-bottom: 4px;
	box-shadow: none;
}

.disabledIcon {
	path {
		stroke: #cbd5e1;
	}
}

.findBestTimesRootButton {
	margin-left: auto;
}

.findBestTimesButton {
	padding: 4px 8px;
	font-size: 12px;
	line-height: 150%;
	border-radius: 6px;
	border: 1px solid #00857a;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06);
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
		box-shadow: 0px 0px 0px 1px #cbd5e1 inset;
		color: #cbd5e1;
		border: none;
	}
}
