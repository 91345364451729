.menu__container {
	position: relative;
}

.menu__active {
	visibility: visible;
	opacity: 100;
	transform: translateY(0px);
}

.menu__inactive {
	visibility: hidden;
	opacity: 0;
	transform: translateY(-20px);
}

.dropdown__menu {
	top: 45px;
	z-index: 999;
	position: absolute;
	transition: all 0.25s ease-in-out;
	ul li {
		a,
		a:hover {
			text-decoration: none;
			color: inherit;
		}
	}
}
