$headingColor: #1e293b;
$textColor: #334155;

.title {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;
	color: $headingColor;
}

.form {
	color: $textColor;
	&__fieldsContainer {
		display: flex;
		flex-direction: column;
		margin: 25px 0;
		gap: 15px;
	}
	&__field {
		display: flex;
		flex-direction: column;
		label {
			color: inherit;
			font-weight: 400;
			font-size: 14px;
			text-transform: none;
			margin-bottom: 4px;
		}
		input {
			height: 40px !important;
			min-height: 40px !important;
			color: #344054 !important;
			border-radius: 8px !important;
			&:focus {
				border-color: #e2e2e2 !important;
				box-shadow: 0px 0px 0px 5px rgba(222, 218, 218, 0.12) !important;
			}
		}
		&_error {
			input {
				color: #dc2626 !important;
				border-color: #ffc6c6 !important;
			}
			span {
				display: block;
				font-size: 12px;
				color: #dc2626;
				padding-top: 6px;
			}
		}
	}
	&__buttons {
		gap: 15px;
		display: flex;
		justify-content: flex-end;
	}
}

.modal__close {
	top: 28px;
	right: 24px;
	z-index: 100;
	cursor: pointer;
	position: absolute;
	&:hover svg path {
		stroke: #4b5563;
	}
}
