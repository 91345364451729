.list {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 260px);
	overflow: hidden;
}

.listItem {
	display: flex;
	align-items: flex-start;

	padding: 10px;
	border-bottom: 1px solid #f1f1f1;
}

.column {
	display: flex;
	flex-direction: column;
}
.userInfo {
	margin-left: 10px;
}

.subject {
	margin-left: 40px;
}

.actions {
	margin-left: auto;
}
