@font-face {
	font-family: "icomoon";
	src: url("fonts/icomoon.eot?pptwqk");
	src: url("fonts/icomoon.eot?pptwqk#iefix") format("embedded-opentype"),
		url("fonts/icomoon.ttf?pptwqk") format("truetype"),
		url("fonts/icomoon.woff?pptwqk") format("woff"),
		url("fonts/icomoon.svg?pptwqk#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-pin-alt:before {
	content: "\ebcb";
	color: #0c94ff;
}

.icon-temp:before {
	content: "\ebc5";
	color: #696969;
}

.icon-settings2:before {
	content: "\ebc6";
	color: #696969;
}

.icon-search2:before {
	content: "\ebc7";
	color: #696969;
}

.icon-Perm:before {
	content: "\ebc8";
	color: #696969;
}

.icon-off-button:before {
	content: "\ebc9";
	color: #696969;
}

.icon-dashboard:before {
	content: "\ebca";
	color: #696969;
}

.icon-Public .path1:before {
	content: "\eb91";
	color: rgb(227, 231, 242);
}

.icon-Public .path2:before {
	content: "\eb92";
	margin-left: -1.279296875em;
	color: rgb(164, 194, 247);
}

.icon-Public .path3:before {
	content: "\eb93";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path4:before {
	content: "\eb94";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path5:before {
	content: "\eb95";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path6:before {
	content: "\eb96";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path7:before {
	content: "\eb97";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path8:before {
	content: "\eb98";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path9:before {
	content: "\eb99";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path10:before {
	content: "\eb9a";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path11:before {
	content: "\eb9b";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Public .path12:before {
	content: "\eb9c";
	margin-left: -1.279296875em;
	color: rgb(37, 112, 183);
}

.icon-Inactive .path1:before {
	content: "\eb9d";
	color: rgb(227, 231, 242);
}

.icon-Inactive .path2:before {
	content: "\eb9e";
	margin-left: -1.046875em;
	color: rgb(164, 194, 247);
}

.icon-Inactive .path3:before {
	content: "\eb9f";
	margin-left: -1.046875em;
	color: rgb(37, 112, 183);
}

.icon-Inactive .path4:before {
	content: "\eba0";
	margin-left: -1.046875em;
	color: rgb(37, 112, 183);
}

.icon-Inactive .path5:before {
	content: "\eba1";
	margin-left: -1.046875em;
	color: rgb(255, 19, 19);
	opacity: 0.65;
}

.icon-Inactive .path6:before {
	content: "\eba2";
	margin-left: -1.046875em;
	color: rgb(255, 19, 19);
	opacity: 0.65;
}

.icon-Passive .path1:before {
	content: "\eba3";
	color: rgb(164, 194, 247);
}

.icon-Passive .path2:before {
	content: "\eba4";
	margin-left: -1.06640625em;
	color: rgb(164, 194, 247);
}

.icon-Passive .path3:before {
	content: "\eba5";
	margin-left: -1.06640625em;
	color: rgb(37, 112, 183);
}

.icon-Passive .path4:before {
	content: "\eba6";
	margin-left: -1.06640625em;
	color: rgb(164, 194, 247);
}

.icon-Passive .path5:before {
	content: "\eba7";
	margin-left: -1.06640625em;
	color: rgb(37, 112, 183);
}

.icon-Anynoymous .path1:before {
	content: "\eba8";
	color: rgb(227, 231, 242);
}

.icon-Anynoymous .path2:before {
	content: "\eba9";
	margin-left: -1.046875em;
	color: rgb(164, 194, 247);
}

.icon-Anynoymous .path3:before {
	content: "\ebaa";
	margin-left: -1.046875em;
	color: rgb(37, 112, 183);
}

.icon-Anynoymous .path4:before {
	content: "\ebab";
	margin-left: -1.046875em;
	color: rgb(37, 112, 183);
}

.icon-Anynoymous .path5:before {
	content: "\ebac";
	margin-left: -1.046875em;
	color: rgb(37, 112, 183);
}

.icon-Anynoymous .path6:before {
	content: "\ebad";
	margin-left: -1.046875em;
	color: rgb(37, 112, 183);
}

.icon-needed-element .path1:before {
	content: "\eb90";
	color: rgb(96, 96, 96);
}

.icon-needed-element .path2:before {
	content: "\ebae";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path3:before {
	content: "\ebaf";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path4:before {
	content: "\ebb0";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path5:before {
	content: "\ebb1";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path6:before {
	content: "\ebb2";
	margin-left: -1.1875em;
	color: rgb(252, 79, 86);
}

.icon-needed-element .path7:before {
	content: "\ebb3";
	margin-left: -1.1875em;
	color: rgb(255, 255, 255);
}

.icon-needed-element .path8:before {
	content: "\ebb4";
	margin-left: -1.1875em;
	color: rgb(255, 255, 255);
}

.icon-needed-element .path9:before {
	content: "\ebb5";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path10:before {
	content: "\ebb6";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path11:before {
	content: "\ebb7";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path12:before {
	content: "\ebb8";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path13:before {
	content: "\ebb9";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path14:before {
	content: "\ebba";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path15:before {
	content: "\ebbb";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path16:before {
	content: "\ebbc";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path17:before {
	content: "\ebbd";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path18:before {
	content: "\ebbe";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path19:before {
	content: "\ebbf";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path20:before {
	content: "\ebc0";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path21:before {
	content: "\ebc1";
	margin-left: -1.1875em;
	color: rgb(96, 96, 96);
}

.icon-needed-element .path22:before {
	content: "\ebc2";
	margin-left: -1.1875em;
	color: rgb(252, 79, 86);
}

.icon-needed-element .path23:before {
	content: "\ebc3";
	margin-left: -1.1875em;
	color: rgb(252, 79, 86);
}

.icon-needed-element .path24:before {
	content: "\ebc4";
	margin-left: -1.1875em;
	color: rgb(252, 79, 86);
}

.icon-play-btn:before {
	content: "\eb8f";
}

.icon-add-to-pipeline:before {
	content: "\eb20";
}

.icon-invite-for-interview:before {
	content: "\eb1f";
}

.icon-add-shortlist:before {
	content: "\eb1e";
}

.icon-empty-pipline .path1:before {
	content: "\eb1b";
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-empty-pipline .path2:before {
	content: "\eb21";
	margin-left: -1.0009765625em;
	color: rgb(96, 96, 96);
}

.icon-empty-pipline .path3:before {
	content: "\eb22";
	margin-left: -1.0009765625em;
	color: rgb(96, 96, 96);
}

.icon-empty-pipline .path4:before {
	content: "\eb23";
	margin-left: -1.0009765625em;
	color: rgb(241, 104, 77);
}

.icon-empty-pipline .path5:before {
	content: "\eb24";
	margin-left: -1.0009765625em;
	color: rgb(255, 255, 255);
}

.icon-empty-pipline .path6:before {
	content: "\eb86";
	margin-left: -1.0009765625em;
	color: rgb(255, 255, 255);
}

.icon-external-posting .path1:before {
	content: "\eb44";
	color: rgb(127, 172, 250);
	opacity: 0.3;
}

.icon-external-posting .path2:before {
	content: "\eb45";
	margin-left: -1.169921875em;
	color: rgb(37, 112, 183);
}

.icon-external-posting .path3:before {
	content: "\eb46";
	margin-left: -1.169921875em;
	color: rgb(255, 255, 255);
}

.icon-external-posting .path4:before {
	content: "\eb47";
	margin-left: -1.169921875em;
	color: rgb(255, 255, 255);
}

.icon-external-posting .path5:before {
	content: "\eb48";
	margin-left: -1.169921875em;
	color: rgb(37, 112, 183);
}

.icon-external-posting .path6:before {
	content: "\eb49";
	margin-left: -1.169921875em;
	color: rgb(37, 112, 183);
}

.icon-external-posting .path7:before {
	content: "\eb4a";
	margin-left: -1.169921875em;
	color: rgb(255, 255, 255);
}

.icon-apply-canceled .path1:before {
	content: "\eb4b";
	color: rgb(247, 83, 86);
}

.icon-apply-canceled .path2:before {
	content: "\eb4c";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path3:before {
	content: "\eb4d";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path4:before {
	content: "\eb4e";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path5:before {
	content: "\eb4f";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path6:before {
	content: "\eb50";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path7:before {
	content: "\eb51";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path8:before {
	content: "\eb52";
	margin-left: -2.275390625em;
	color: rgb(158, 43, 42);
}

.icon-apply-canceled .path9:before {
	content: "\eb53";
	margin-left: -2.275390625em;
	color: rgb(255, 255, 255);
}

.icon-been-poked .path1:before {
	content: "\eb54";
	color: rgb(91, 196, 163);
}

.icon-been-poked .path2:before {
	content: "\eb55";
	margin-left: -2.6220703125em;
	color: rgb(255, 255, 255);
}

.icon-been-poked .path3:before {
	content: "\eb56";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-been-poked .path4:before {
	content: "\eb57";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-been-poked .path5:before {
	content: "\eb58";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-been-poked .path6:before {
	content: "\eb59";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-been-poked .path7:before {
	content: "\eb5a";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-been-poked .path8:before {
	content: "\eb5b";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-been-poked .path9:before {
	content: "\eb5c";
	margin-left: -2.6220703125em;
	color: rgb(37, 112, 183);
}

.icon-applied .path1:before {
	content: "\eb5d";
	color: rgb(91, 196, 163);
}

.icon-applied .path2:before {
	content: "\eb5e";
	margin-left: -2.275390625em;
	color: rgb(255, 255, 255);
}

.icon-applied .path3:before {
	content: "\eb5f";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-applied .path4:before {
	content: "\eb60";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-applied .path5:before {
	content: "\eb61";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-applied .path6:before {
	content: "\eb62";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-applied .path7:before {
	content: "\eb63";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-applied .path8:before {
	content: "\eb64";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-applied .path9:before {
	content: "\eb65";
	margin-left: -2.275390625em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path1:before {
	content: "\eb66";
	color: rgb(255, 255, 255);
}

.icon-selected-folder-1 .path2:before {
	content: "\eb67";
	margin-left: -0.9970703125em;
	color: rgb(227, 231, 242);
}

.icon-selected-folder-1 .path3:before {
	content: "\eb68";
	margin-left: -0.9970703125em;
	color: rgb(165, 221, 255);
}

.icon-selected-folder-1 .path4:before {
	content: "\eb69";
	margin-left: -0.9970703125em;
	color: rgb(165, 221, 255);
}

.icon-selected-folder-1 .path5:before {
	content: "\eb6a";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path6:before {
	content: "\eb6b";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path7:before {
	content: "\eb6c";
	margin-left: -0.9970703125em;
	color: rgb(91, 196, 163);
}

.icon-selected-folder-1 .path8:before {
	content: "\eb6d";
	margin-left: -0.9970703125em;
	color: rgb(255, 255, 255);
}

.icon-selected-folder-1 .path9:before {
	content: "\eb6e";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path10:before {
	content: "\eb6f";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path11:before {
	content: "\eb70";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path12:before {
	content: "\eb71";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path13:before {
	content: "\eb72";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path14:before {
	content: "\eb73";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-selected-folder-1 .path15:before {
	content: "\eb74";
	margin-left: -0.9970703125em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path1:before {
	content: "\eb75";
	color: rgb(255, 255, 255);
}

.icon-nonselected-folder--1 .path2:before {
	content: "\eb76";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path3:before {
	content: "\eb77";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path4:before {
	content: "\eb78";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path5:before {
	content: "\eb79";
	margin-left: -0.900390625em;
	color: rgb(227, 231, 242);
}

.icon-nonselected-folder--1 .path6:before {
	content: "\eb7a";
	margin-left: -0.900390625em;
	color: rgb(165, 221, 255);
}

.icon-nonselected-folder--1 .path7:before {
	content: "\eb7b";
	margin-left: -0.900390625em;
	color: rgb(165, 221, 255);
}

.icon-nonselected-folder--1 .path8:before {
	content: "\eb7c";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path9:before {
	content: "\eb7d";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path10:before {
	content: "\eb7e";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path11:before {
	content: "\eb7f";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path12:before {
	content: "\eb80";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path13:before {
	content: "\eb81";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path14:before {
	content: "\eb82";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-nonselected-folder--1 .path15:before {
	content: "\eb83";
	margin-left: -0.900390625em;
	color: rgb(37, 112, 183);
}

.icon-application-icon .path1:before {
	content: "\eb43";
	color: rgb(144, 154, 163);
}

.icon-application-icon .path2:before {
	content: "\eb84";
	margin-left: -0.67578125em;
	color: rgb(255, 104, 73);
}

.icon-application-icon .path3:before {
	content: "\eb85";
	margin-left: -0.67578125em;
	color: rgb(255, 104, 73);
}

.icon-close-vacancy:before {
	content: "\eb42";
}

.icon-mailbox .path1:before {
	content: "\eb2e";
	color: rgb(240, 246, 255);
}

.icon-mailbox .path2:before {
	content: "\eb2f";
	margin-left: -0.9765625em;
	color: rgb(159, 198, 255);
}

.icon-mailbox .path3:before {
	content: "\eb30";
	margin-left: -0.9765625em;
	color: rgb(119, 179, 252);
}

.icon-mailbox .path4:before {
	content: "\eb31";
	margin-left: -0.9765625em;
	color: rgb(0, 83, 204);
}

.icon-mailbox .path5:before {
	content: "\eb32";
	margin-left: -0.9765625em;
	color: rgb(119, 179, 252);
}

.icon-mailbox .path6:before {
	content: "\eb33";
	margin-left: -0.9765625em;
	color: rgb(159, 198, 255);
}

.icon-mailbox .path7:before {
	content: "\eb34";
	margin-left: -0.9765625em;
	color: rgb(159, 198, 255);
}

.icon-mailbox .path8:before {
	content: "\eb35";
	margin-left: -0.9765625em;
	color: rgb(182, 215, 255);
}

.icon-mailbox .path9:before {
	content: "\eb36";
	margin-left: -0.9765625em;
	color: rgb(0, 83, 204);
}

.icon-mailbox .path10:before {
	content: "\eb37";
	margin-left: -0.9765625em;
	color: rgb(220, 233, 255);
}

.icon-mailbox .path11:before {
	content: "\eb38";
	margin-left: -0.9765625em;
	color: rgb(159, 198, 255);
}

.icon-mailbox .path12:before {
	content: "\eb39";
	margin-left: -0.9765625em;
	color: rgb(220, 233, 255);
}

.icon-mailbox .path13:before {
	content: "\eb3a";
	margin-left: -0.9765625em;
	color: rgb(159, 198, 255);
}

.icon-mailbox .path14:before {
	content: "\eb3b";
	margin-left: -0.9765625em;
	color: rgb(220, 233, 255);
}

.icon-mailbox .path15:before {
	content: "\eb3c";
	margin-left: -0.9765625em;
	color: rgb(159, 198, 255);
}

.icon-mailbox .path16:before {
	content: "\eb3d";
	margin-left: -0.9765625em;
	color: rgb(182, 215, 255);
}

.icon-mailbox .path17:before {
	content: "\eb3e";
	margin-left: -0.9765625em;
	color: rgb(255, 104, 73);
}

.icon-mailbox .path18:before {
	content: "\eb3f";
	margin-left: -0.9765625em;
	color: rgb(255, 255, 255);
}

.icon-mailbox .path19:before {
	content: "\eb40";
	margin-left: -0.9765625em;
	color: rgb(255, 255, 255);
}

.icon-mailbox .path20:before {
	content: "\eb41";
	margin-left: -0.9765625em;
	color: rgb(255, 255, 255);
}

.icon-generate-svg:before {
	content: "\eb1c";
}

.icon-xls-download .path1:before {
	content: "\eb1d";
	color: rgb(209, 212, 214);
}

.icon-xls-download .path2:before {
	content: "\eb87";
	margin-left: -0.87109375em;
	color: rgb(194, 194, 194);
}

.icon-xls-download .path3:before {
	content: "\eb88";
	margin-left: -0.87109375em;
	color: rgb(203, 202, 203);
}

.icon-xls-download .path4:before {
	content: "\eb89";
	margin-left: -0.87109375em;
	color: rgb(132, 189, 90);
}

.icon-xls-download .path5:before {
	content: "\eb8a";
	margin-left: -0.87109375em;
	color: rgb(255, 255, 255);
}

.icon-xls-download .path6:before {
	content: "\eb8b";
	margin-left: -0.87109375em;
	color: rgb(255, 255, 255);
}

.icon-xls-download .path7:before {
	content: "\eb8c";
	margin-left: -0.87109375em;
	color: rgb(255, 255, 255);
}

.icon-xls-download .path8:before {
	content: "\eb8d";
	margin-left: -0.87109375em;
	color: rgb(255, 104, 73);
}

.icon-xls-download .path9:before {
	content: "\eb8e";
	margin-left: -0.87109375em;
	color: rgb(255, 255, 255);
}

.icon-pdf-download .path1:before {
	content: "\eb25";
	color: rgb(209, 213, 215);
}

.icon-pdf-download .path2:before {
	content: "\eb26";
	margin-left: -0.8701171875em;
	color: rgb(195, 195, 196);
}

.icon-pdf-download .path3:before {
	content: "\eb27";
	margin-left: -0.8701171875em;
	color: rgb(204, 204, 205);
}

.icon-pdf-download .path4:before {
	content: "\eb28";
	margin-left: -0.8701171875em;
	color: rgb(255, 104, 73);
}

.icon-pdf-download .path5:before {
	content: "\eb29";
	margin-left: -0.8701171875em;
	color: rgb(255, 255, 255);
}

.icon-pdf-download .path6:before {
	content: "\eb2a";
	margin-left: -0.8701171875em;
	color: rgb(233, 62, 52);
}

.icon-pdf-download .path7:before {
	content: "\eb2b";
	margin-left: -0.8701171875em;
	color: rgb(255, 255, 255);
}

.icon-pdf-download .path8:before {
	content: "\eb2c";
	margin-left: -0.8701171875em;
	color: rgb(255, 255, 255);
}

.icon-pdf-download .path9:before {
	content: "\eb2d";
	margin-left: -0.8701171875em;
	color: rgb(255, 255, 255);
}

.icon-empty-folder .path1:before {
	content: "\eafe";
	color: rgb(212, 216, 221);
	opacity: 0.13;
}

.icon-empty-folder .path2:before {
	content: "\eaff";
	margin-left: -1.2294921875em;
	color: rgb(255, 255, 255);
}

.icon-empty-folder .path3:before {
	content: "\eb00";
	margin-left: -1.2294921875em;
	color: rgb(213, 217, 223);
}

.icon-empty-folder .path4:before {
	content: "\eb01";
	margin-left: -1.2294921875em;
	color: rgb(213, 217, 223);
}

.icon-down-arrow:before {
	content: "\eafc";
}

.icon-location-virtual-room:before {
	content: "\eb13";
}

.icon-adresse-propose:before {
	content: "\eb14";
}

.icon-date-time:before {
	content: "\eb15";
}

.icon-building:before {
	content: "\eb12";
}

.icon-pen-2:before {
	content: "\eaf2";
}

.icon-edit-2:before {
	content: "\eaf3";
}

.icon-save-template:before {
	content: "\eaf4";
}

.icon-clear-filter:before {
	content: "\eb11";
}

.icon-reset-3:before {
	content: "\eaf1";
}

.icon-arrow-right1:before {
	content: "\eaef";
}

.icon-reset:before {
	content: "\eaf0";
}

.icon-lockfiled-open:before {
	content: "\eaee";
}

.icon-pdf .path1:before {
	content: "\e9d8";
	color: rgb(210, 214, 216);
}

.icon-pdf .path2:before {
	content: "\e9d9";
	margin-left: -0.955078125em;
	color: rgb(196, 197, 198);
}

.icon-pdf .path3:before {
	content: "\e9da";
	margin-left: -0.955078125em;
	color: rgb(204, 205, 206);
}

.icon-pdf .path4:before {
	content: "\e9db";
	margin-left: -0.955078125em;
	color: rgb(255, 104, 73);
}

.icon-pdf .path5:before {
	content: "\e9dc";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-pdf .path6:before {
	content: "\e9dd";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-pdf .path7:before {
	content: "\e9de";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-pdf .path8:before {
	content: "\e9df";
	margin-left: -0.955078125em;
	color: rgb(210, 212, 214);
}

.icon-doc .path1:before {
	content: "\e90f";
	color: rgb(209, 213, 215);
}

.icon-doc .path2:before {
	content: "\e95e";
	margin-left: -0.9697265625em;
	color: rgb(195, 195, 196);
}

.icon-doc .path3:before {
	content: "\e9b6";
	margin-left: -0.9697265625em;
	color: rgb(204, 204, 205);
}

.icon-doc .path4:before {
	content: "\eae9";
	margin-left: -0.9697265625em;
	color: rgb(42, 102, 176);
}

.icon-doc .path5:before {
	content: "\eaea";
	margin-left: -0.9697265625em;
	color: rgb(209, 211, 213);
}

.icon-doc .path6:before {
	content: "\eaeb";
	margin-left: -0.9697265625em;
	color: rgb(255, 255, 255);
}

.icon-doc .path7:before {
	content: "\eaec";
	margin-left: -0.9697265625em;
	color: rgb(255, 255, 255);
}

.icon-doc .path8:before {
	content: "\eaed";
	margin-left: -0.9697265625em;
	color: rgb(255, 255, 255);
}

.icon-jpg .path1:before {
	content: "\eaf5";
	color: rgb(209, 213, 215);
}

.icon-jpg .path2:before {
	content: "\eaf6";
	margin-left: -0.9697265625em;
	color: rgb(195, 195, 196);
}

.icon-jpg .path3:before {
	content: "\eaf7";
	margin-left: -0.9697265625em;
	color: rgb(204, 204, 205);
}

.icon-jpg .path4:before {
	content: "\eaf8";
	margin-left: -0.9697265625em;
	color: rgb(35, 159, 149);
}

.icon-jpg .path5:before {
	content: "\eaf9";
	margin-left: -0.9697265625em;
	color: rgb(255, 255, 255);
}

.icon-jpg .path6:before {
	content: "\eafa";
	margin-left: -0.9697265625em;
	color: rgb(255, 255, 255);
}

.icon-jpg .path7:before {
	content: "\eafb";
	margin-left: -0.9697265625em;
	color: rgb(255, 255, 255);
}

.icon-png .path1:before {
	content: "\eb0a";
	color: rgb(209, 212, 214);
}

.icon-png .path2:before {
	content: "\eb0b";
	margin-left: -0.955078125em;
	color: rgb(194, 194, 194);
}

.icon-png .path3:before {
	content: "\eb0c";
	margin-left: -0.955078125em;
	color: rgb(203, 202, 203);
}

.icon-png .path4:before {
	content: "\eb0d";
	margin-left: -0.955078125em;
	color: rgb(252, 177, 43);
}

.icon-png .path5:before {
	content: "\eb0e";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-png .path6:before {
	content: "\eb0f";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-png .path7:before {
	content: "\eb10";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-xls .path1:before {
	content: "\eafd";
	color: rgb(209, 212, 214);
}

.icon-xls .path2:before {
	content: "\eb02";
	margin-left: -0.955078125em;
	color: rgb(194, 194, 194);
}

.icon-xls .path3:before {
	content: "\eb16";
	margin-left: -0.955078125em;
	color: rgb(203, 202, 203);
}

.icon-xls .path4:before {
	content: "\eb17";
	margin-left: -0.955078125em;
	color: rgb(132, 189, 90);
}

.icon-xls .path5:before {
	content: "\eb18";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-xls .path6:before {
	content: "\eb19";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-xls .path7:before {
	content: "\eb1a";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-ppt .path1:before {
	content: "\eb03";
	color: rgb(209, 212, 214);
}

.icon-ppt .path2:before {
	content: "\eb04";
	margin-left: -0.955078125em;
	color: rgb(194, 194, 194);
}

.icon-ppt .path3:before {
	content: "\eb05";
	margin-left: -0.955078125em;
	color: rgb(203, 202, 203);
}

.icon-ppt .path4:before {
	content: "\eb06";
	margin-left: -0.955078125em;
	color: rgb(240, 87, 66);
}

.icon-ppt .path5:before {
	content: "\eb07";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-ppt .path6:before {
	content: "\eb08";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-ppt .path7:before {
	content: "\eb09";
	margin-left: -0.955078125em;
	color: rgb(255, 255, 255);
}

.icon-add-note:before {
	content: "\eae8";
}

.icon-profilep:before {
	content: "\eadb";
}

.icon-matches:before {
	content: "\eadc";
}

.icon-markasfilled:before {
	content: "\eadd";
}

.icon-handshake .path1:before {
	content: "\eada";
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-handshake .path2:before {
	content: "\eade";
	margin-left: -1.501953125em;
	color: rgb(229, 229, 228);
}

.icon-handshake .path3:before {
	content: "\eadf";
	margin-left: -1.501953125em;
	color: rgb(229, 229, 228);
}

.icon-handshake .path4:before {
	content: "\eae0";
	margin-left: -1.501953125em;
	color: rgb(229, 229, 228);
}

.icon-handshake .path5:before {
	content: "\eae1";
	margin-left: -1.501953125em;
	color: rgb(222, 222, 222);
}

.icon-handshake .path6:before {
	content: "\eae2";
	margin-left: -1.501953125em;
	color: rgb(96, 96, 96);
}

.icon-handshake .path7:before {
	content: "\eae3";
	margin-left: -1.501953125em;
	color: rgb(255, 255, 255);
}

.icon-handshake .path8:before {
	content: "\eae4";
	margin-left: -1.501953125em;
	color: rgb(255, 255, 255);
}

.icon-handshake .path9:before {
	content: "\eae5";
	margin-left: -1.501953125em;
	color: rgb(96, 96, 96);
}

.icon-handshake .path10:before {
	content: "\eae6";
	margin-left: -1.501953125em;
	color: rgb(222, 222, 222);
}

.icon-handshake .path11:before {
	content: "\eae7";
	margin-left: -1.501953125em;
	color: rgb(96, 96, 96);
}

.icon-lockfiled:before {
	content: "\ead9";
}

.icon-euro:before {
	content: "\ead8";
}

.icon-system .path1:before {
	content: "\ea15";
	color: rgb(222, 222, 222);
}

.icon-system .path2:before {
	content: "\ea16";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path3:before {
	content: "\ea17";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path4:before {
	content: "\ea18";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path5:before {
	content: "\ea19";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path6:before {
	content: "\ea1a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path7:before {
	content: "\ea1b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path8:before {
	content: "\ea1c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system .path9:before {
	content: "\ea1d";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path1:before {
	content: "\ea1e";
	color: rgb(255, 104, 73);
}

.icon-company-hover .path2:before {
	content: "\ea1f";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-company-hover .path3:before {
	content: "\ea20";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-company-hover .path4:before {
	content: "\ea21";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-company-hover .path5:before {
	content: "\ea22";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path6:before {
	content: "\ea23";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path7:before {
	content: "\ea24";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path8:before {
	content: "\ea25";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path9:before {
	content: "\ea26";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path10:before {
	content: "\ea27";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path11:before {
	content: "\ea28";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path12:before {
	content: "\ea29";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path13:before {
	content: "\ea2a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path14:before {
	content: "\ea2b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path15:before {
	content: "\ea2c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path16:before {
	content: "\ea2d";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path17:before {
	content: "\ea2e";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company-hover .path18:before {
	content: "\ea2f";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path1:before {
	content: "\ea30";
	color: rgb(222, 222, 222);
}

.icon-company .path2:before {
	content: "\ea31";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-company .path3:before {
	content: "\ea32";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-company .path4:before {
	content: "\ea33";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-company .path5:before {
	content: "\ea34";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path6:before {
	content: "\ea35";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path7:before {
	content: "\ea36";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path8:before {
	content: "\ea37";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path9:before {
	content: "\ea38";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path10:before {
	content: "\ea39";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path11:before {
	content: "\ea3a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path12:before {
	content: "\ea3b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path13:before {
	content: "\ea3c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path14:before {
	content: "\ea3d";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path15:before {
	content: "\ea3e";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path16:before {
	content: "\ea3f";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path17:before {
	content: "\ea40";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-company .path18:before {
	content: "\ea41";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path1:before {
	content: "\ea42";
	color: rgb(255, 104, 73);
}

.icon-contract-hover .path2:before {
	content: "\ea43";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-contract-hover .path3:before {
	content: "\ea44";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-contract-hover .path4:before {
	content: "\ea45";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path5:before {
	content: "\ea46";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path6:before {
	content: "\ea47";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path7:before {
	content: "\ea48";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path8:before {
	content: "\ea49";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path9:before {
	content: "\ea4a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path10:before {
	content: "\ea4b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract-hover .path11:before {
	content: "\ea4c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path1:before {
	content: "\ea4d";
	color: rgb(222, 222, 222);
}

.icon-contract .path2:before {
	content: "\ea4e";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-contract .path3:before {
	content: "\ea4f";
	margin-left: -1em;
	color: rgb(230, 233, 237);
}

.icon-contract .path4:before {
	content: "\ea50";
	margin-left: -1em;
	color: rgb(245, 247, 250);
}

.icon-contract .path5:before {
	content: "\ea51";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path6:before {
	content: "\ea52";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path7:before {
	content: "\ea53";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path8:before {
	content: "\ea54";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path9:before {
	content: "\ea55";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path10:before {
	content: "\ea56";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path11:before {
	content: "\ea57";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-contract .path12:before {
	content: "\ea58";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path1:before {
	content: "\ea59";
	color: rgb(255, 104, 73);
}

.icon-group-hover .path2:before {
	content: "\ea5a";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-group-hover .path3:before {
	content: "\ea5b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path4:before {
	content: "\ea5c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path5:before {
	content: "\ea5d";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path6:before {
	content: "\ea5e";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path7:before {
	content: "\ea5f";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path8:before {
	content: "\ea60";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path9:before {
	content: "\ea61";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group-hover .path10:before {
	content: "\ea62";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path1:before {
	content: "\ea63";
	color: rgb(222, 222, 222);
}

.icon-group .path2:before {
	content: "\ea64";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-group .path3:before {
	content: "\ea65";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path4:before {
	content: "\ea66";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path5:before {
	content: "\ea67";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path6:before {
	content: "\ea68";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path7:before {
	content: "\ea69";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path8:before {
	content: "\ea6a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path9:before {
	content: "\ea6b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-group .path10:before {
	content: "\ea6c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview-hover .path1:before {
	content: "\ea6d";
	color: rgb(255, 104, 73);
}

.icon-interview-hover .path2:before {
	content: "\ea6e";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview-hover .path3:before {
	content: "\ea6f";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview-hover .path4:before {
	content: "\ea70";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview-hover .path5:before {
	content: "\ea71";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview-hover .path6:before {
	content: "\ea72";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview-hover .path7:before {
	content: "\ea73";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview .path1:before {
	content: "\ea74";
	color: rgb(222, 222, 222);
}

.icon-interview .path2:before {
	content: "\ea75";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview .path3:before {
	content: "\ea76";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview .path4:before {
	content: "\ea77";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview .path5:before {
	content: "\ea78";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview .path6:before {
	content: "\ea79";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-interview .path7:before {
	content: "\ea7a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path1:before {
	content: "\ea7b";
	color: rgb(255, 104, 73);
}

.icon-invoices-hover .path2:before {
	content: "\ea7c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path3:before {
	content: "\ea7d";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path4:before {
	content: "\ea7e";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path5:before {
	content: "\ea7f";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path6:before {
	content: "\ea80";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path7:before {
	content: "\ea81";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path8:before {
	content: "\ea82";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices-hover .path9:before {
	content: "\ea83";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path1:before {
	content: "\ea84";
	color: rgb(222, 222, 222);
}

.icon-invoices .path2:before {
	content: "\ea85";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path3:before {
	content: "\ea86";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path4:before {
	content: "\ea87";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path5:before {
	content: "\ea88";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path6:before {
	content: "\ea89";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path7:before {
	content: "\ea8a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path8:before {
	content: "\ea8b";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-invoices .path9:before {
	content: "\ea8c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-profile-hover .path1:before {
	content: "\ea8d";
	color: rgb(81, 93, 111);
}

.icon-profile-hover .path2:before {
	content: "\ea8e";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-profile-hover .path3:before {
	content: "\ea8f";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-profile .path1:before {
	content: "\ea90";
	color: rgb(81, 93, 111);
}

.icon-profile .path2:before {
	content: "\ea91";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-profile .path3:before {
	content: "\ea92";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating-hover .path1:before {
	content: "\ea93";
	color: rgb(81, 93, 111);
}

.icon-rating-hover .path2:before {
	content: "\ea94";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating-hover .path3:before {
	content: "\ea95";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-rating-hover .path4:before {
	content: "\ea96";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating-hover .path5:before {
	content: "\ea97";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-rating-hover .path6:before {
	content: "\ea98";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating-hover .path7:before {
	content: "\ea99";
	margin-left: -1em;
	color: rgb(255, 104, 73);
}

.icon-rating-hover .path8:before {
	content: "\ea9a";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating .path1:before {
	content: "\ea9b";
	color: rgb(81, 93, 111);
}

.icon-rating .path2:before {
	content: "\ea9c";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating .path3:before {
	content: "\ea9d";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-rating .path4:before {
	content: "\ea9e";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating .path5:before {
	content: "\ea9f";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-rating .path6:before {
	content: "\eaa0";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-rating .path7:before {
	content: "\eaa1";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-rating .path8:before {
	content: "\eaa2";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-skills-hover:before {
	content: "\eaa3";
	color: #ff6849;
}

.icon-skills:before {
	content: "\eaa9";
	color: #dedede;
}

.icon-system-hover .path1:before {
	content: "\eaaf";
	color: rgb(255, 104, 73);
}

.icon-system-hover .path2:before {
	content: "\eab0";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path3:before {
	content: "\eab1";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path4:before {
	content: "\eab2";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path5:before {
	content: "\eab3";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path6:before {
	content: "\eab4";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path7:before {
	content: "\eab5";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path8:before {
	content: "\eab6";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-system-hover .path9:before {
	content: "\eab7";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path1:before {
	content: "\eab8";
	color: rgb(255, 104, 73);
}

.icon-timesheet-hover .path2:before {
	content: "\eab9";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path3:before {
	content: "\eaba";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path4:before {
	content: "\eabb";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path5:before {
	content: "\eabc";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path6:before {
	content: "\eabd";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path7:before {
	content: "\eabe";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path8:before {
	content: "\eabf";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path9:before {
	content: "\eac0";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path10:before {
	content: "\eac1";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path11:before {
	content: "\eac2";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path12:before {
	content: "\eac3";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path13:before {
	content: "\eac4";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path14:before {
	content: "\eac5";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path15:before {
	content: "\eac6";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet-hover .path16:before {
	content: "\eac7";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path1:before {
	content: "\eac8";
	color: rgb(222, 222, 222);
}

.icon-timesheet .path2:before {
	content: "\eac9";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path3:before {
	content: "\eaca";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path4:before {
	content: "\eacb";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path5:before {
	content: "\eacc";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path6:before {
	content: "\eacd";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path7:before {
	content: "\eace";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path8:before {
	content: "\eacf";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path9:before {
	content: "\ead0";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path10:before {
	content: "\ead1";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path11:before {
	content: "\ead2";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path12:before {
	content: "\ead3";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path13:before {
	content: "\ead4";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path14:before {
	content: "\ead5";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path15:before {
	content: "\ead6";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-timesheet .path16:before {
	content: "\ead7";
	margin-left: -1em;
	color: rgb(81, 93, 111);
}

.icon-nobid .path1:before {
	content: "\ea11";
	color: rgb(96, 96, 96);
	opacity: 0.08;
}

.icon-nobid .path2:before {
	content: "\ea12";
	margin-left: -1.623046875em;
	color: rgb(96, 96, 96);
	opacity: 0.2;
}

.icon-nobid .path3:before {
	content: "\ea13";
	margin-left: -1.623046875em;
	color: rgb(222, 222, 222);
}

.icon-nobid .path4:before {
	content: "\ea14";
	margin-left: -1.623046875em;
	color: rgb(96, 97, 97);
}

.icon-departement:before {
	content: "\ea10";
}

.icon-warning:before {
	content: "\ea0f";
}

.icon-extend:before {
	content: "\ea0d";
}

.icon-interupt:before {
	content: "\ea0e";
}

.icon-newtab-arrow:before {
	content: "\ea0c";
}

.icon-view:before {
	content: "\ea0b";
}

.icon-type-docx:before {
	content: "\ea07";
}

.icon-type-jpeg:before {
	content: "\ea08";
}

.icon-type-pdf:before {
	content: "\ea09";
}

.icon-type-png:before {
	content: "\ea0a";
}

.icon-clientcomm:before {
	content: "\ea06";
}

.icon-close2:before {
	content: "\ea05";
}

.icon-pdf_file:before {
	content: "\ea04";
}

.icon-skip:before {
	content: "\ea03";
}

.icon-contract-refused:before {
	content: "\ea02";
}

.icon-fillcv .path1:before {
	content: "\e9ee";
	color: rgb(0, 0, 0);
}

.icon-fillcv .path2:before {
	content: "\e9ef";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path3:before {
	content: "\e9f0";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path4:before {
	content: "\e9f1";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path5:before {
	content: "\e9f2";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path6:before {
	content: "\e9f3";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path7:before {
	content: "\e9f4";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path8:before {
	content: "\e9f5";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-fillcv .path9:before {
	content: "\e9f6";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-fillcv .path10:before {
	content: "\e9f7";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path1:before {
	content: "\e9f8";
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path2:before {
	content: "\e9f9";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path3:before {
	content: "\e9fa";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path4:before {
	content: "\e9fb";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path5:before {
	content: "\e9fc";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path6:before {
	content: "\e9fd";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path7:before {
	content: "\e9fe";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path8:before {
	content: "\e9ff";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path9:before {
	content: "\ea00";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-uploadcv .path10:before {
	content: "\ea01";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-arrow-left-new:before {
	content: "\e9ec";
}

.icon-arrow-right-new:before {
	content: "\e9ed";
}

.icon-trashcan:before {
	content: "\e9eb";
}

.icon-notificationset .path1:before {
	content: "\e9e4";
	color: rgb(0, 0, 0);
}

.icon-notificationset .path2:before {
	content: "\e9e5";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-notificationset .path3:before {
	content: "\e9e6";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-togglecercle .path1:before {
	content: "\e9e7";
	color: rgb(255, 255, 255);
}

.icon-togglecercle .path2:before {
	content: "\e9e8";
	margin-left: -1em;
	color: rgb(231, 231, 231);
}

.icon-togglecercle .path3:before {
	content: "\e9e9";
	margin-left: -1em;
	color: rgb(231, 231, 231);
}

.icon-togglecercle .path4:before {
	content: "\e9ea";
	margin-left: -1em;
	color: rgb(231, 231, 231);
}

.icon-calendarerror:before {
	content: "\e9e1";
}

.icon-waitingicon:before {
	content: "\e9e2";
}

.icon-check2:before {
	content: "\e9e0";
}

.icon-education:before {
	content: "\e9d6";
}

.icon-experience:before {
	content: "\e9d7";
}

.icon-Posted:before {
	content: "\e9d5";
}

.icon-postedby:before {
	content: "\e9d2";
}

.icon-requestreference:before {
	content: "\e9d3";
}

.icon-posted:before {
	content: "\e9d4";
}

.icon-archive .path1:before {
	content: "\e9cb";
	color: rgb(0, 0, 0);
}

.icon-archive .path2:before {
	content: "\e9cc";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-archive .path3:before {
	content: "\e9cd";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-archive .path4:before {
	content: "\e9ce";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-archive .path5:before {
	content: "\e9cf";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-archive .path6:before {
	content: "\e9d0";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-pause-cycle:before {
	content: "\e9d1";
}

.icon-pen:before {
	content: "\e9ca";
}

.icon-block:before {
	content: "\e9c7";
}

.icon-send:before {
	content: "\e9c8";
}

.icon-unblock:before {
	content: "\e9c9";
}

.icon-get-help:before {
	content: "\e9c6";
}

.icon-download:before {
	content: "\e9c4";
}

.icon-image .path1:before {
	content: "\e9ba";
	color: rgb(0, 0, 0);
}

.icon-image .path2:before {
	content: "\e9bb";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-image .path3:before {
	content: "\e9bc";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-image .path4:before {
	content: "\e9bd";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-image .path5:before {
	content: "\e9be";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-image .path6:before {
	content: "\e9bf";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-image .path7:before {
	content: "\e9c0";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-image .path8:before {
	content: "\e9c1";
	margin-left: -1em;
	color: rgb(35, 31, 32);
}

.icon-retakepic:before {
	content: "\e9c2";
}

.icon-webcam:before {
	content: "\e9c3";
}

.icon-capture:before {
	content: "\e9b8";
}

.icon-retakepic2:before {
	content: "\e9b9";
}

.icon-softwarning:before {
	content: "\e991";
}

.icon-phone-handle:before {
	content: "\e977";
}

.icon-inverted-watch:before {
	content: "\e976";
}

.icon-local:before {
	content: "\e903";
}

.icon-facebook:before {
	content: "\e901";
}

.icon-link:before {
	content: "\e902";
}

.icon-twitter:before {
	content: "\e905";
}

.icon-info:before {
	content: "\e972";
}

.icon-search:before {
	content: "\e971";
}

.icon-logo:before {
	content: "\e970";
}

.icon-search-error:before {
	content: "\e9b7";
}

.icon-watch2:before {
	content: "\e9b5";
}

.icon-admin:before {
	content: "\e900";
}

.icon-arrow-cercle .path1:before {
	content: "\e904";
	color: rgb(0, 0, 0);
}

.icon-arrow-cercle .path2:before {
	content: "\e91b";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-arrow-cercle .path3:before {
	content: "\e91c";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}

.icon-arrow-left:before {
	content: "\e906";
}

.icon-arrow-right:before {
	content: "\e907";
}

.icon-blog:before {
	content: "\e908";
}

.icon-bookmark:before {
	content: "\e909";
}

.icon-calendar:before {
	content: "\e90a";
}

.icon-calendar2:before {
	content: "\e9e3";
}

.icon-camera:before {
	content: "\e90b";
}

.icon-cash-money:before {
	content: "\e90c";
}

.icon-chart:before {
	content: "\e90d";
}

.icon-check:before {
	content: "\e90e";
}

.icon-close:before {
	content: "\e910";
}

.icon-coins:before {
	content: "\e911";
}

.icon-comment:before {
	content: "\e912";
}

.icon-companies:before {
	content: "\e913";
}

.icon-credit-card-back:before {
	content: "\e914";
}

.icon-credit-card:before {
	content: "\e915";
}

.icon-delete:before {
	content: "\e916";
}

.icon-download-file:before {
	content: "\e917";
}

.icon-Duplicate:before {
	content: "\e918";
}

.icon-edit:before {
	content: "\e919";
}

.icon-empty-expence .path1:before {
	content: "\e91a";
	color: rgb(222, 222, 222);
}

.icon-empty-expence .path2:before {
	content: "\e91d";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-empty-expence .path3:before {
	content: "\e91e";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-expence .path4:before {
	content: "\e91f";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-expence .path5:before {
	content: "\e973";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-expence .path6:before {
	content: "\e974";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-expence .path7:before {
	content: "\e975";
	margin-left: -1em;
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-empty-invoice .path1:before {
	content: "\e920";
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-empty-invoice .path2:before {
	content: "\e921";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-invoice .path3:before {
	content: "\e922";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-invoice .path4:before {
	content: "\e923";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-invoice .path5:before {
	content: "\e924";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-invoice .path6:before {
	content: "\e925";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-invoice .path7:before {
	content: "\e926";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path1:before {
	content: "\e927";
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path2:before {
	content: "\e928";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path3:before {
	content: "\e929";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path4:before {
	content: "\e92a";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path5:before {
	content: "\e92b";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path6:before {
	content: "\e92c";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path7:before {
	content: "\e92d";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path8:before {
	content: "\e92e";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path9:before {
	content: "\e92f";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path10:before {
	content: "\e930";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path11:before {
	content: "\e931";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path12:before {
	content: "\e932";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path13:before {
	content: "\e933";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path14:before {
	content: "\e934";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path15:before {
	content: "\e935";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path16:before {
	content: "\e936";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path17:before {
	content: "\e937";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path18:before {
	content: "\e938";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path19:before {
	content: "\e939";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path20:before {
	content: "\e93a";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path21:before {
	content: "\e93b";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path22:before {
	content: "\e93c";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path23:before {
	content: "\e93d";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path24:before {
	content: "\e93e";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path25:before {
	content: "\e93f";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path26:before {
	content: "\e940";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path27:before {
	content: "\e941";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path28:before {
	content: "\e942";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path29:before {
	content: "\e943";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path30:before {
	content: "\e944";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path31:before {
	content: "\e945";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path32:before {
	content: "\e946";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path33:before {
	content: "\e947";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path34:before {
	content: "\e948";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path35:before {
	content: "\e949";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path36:before {
	content: "\e94a";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path37:before {
	content: "\e94b";
	margin-left: -1em;
	color: rgb(97, 97, 97);
}

.icon-empty-worktime .path38:before {
	content: "\e94c";
	margin-left: -1em;
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-Envelope-verification .path1:before {
	content: "\e94d";
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-Envelope-verification .path2:before {
	content: "\e94e";
	margin-left: -1em;
	color: rgb(222, 222, 222);
}

.icon-Envelope-verification .path3:before {
	content: "\e94f";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-Envelope-verification .path4:before {
	content: "\e950";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-Envelope-verification .path5:before {
	content: "\e951";
	margin-left: -1em;
	color: rgb(241, 104, 77);
}

.icon-expense-empty .path1:before {
	content: "\e952";
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-expense-empty .path2:before {
	content: "\e953";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path3:before {
	content: "\e954";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path4:before {
	content: "\e955";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path5:before {
	content: "\e956";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path6:before {
	content: "\e957";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path7:before {
	content: "\e958";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path8:before {
	content: "\e959";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path9:before {
	content: "\e95a";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-expense-empty .path10:before {
	content: "\e95b";
	margin-left: -1em;
	color: rgb(96, 96, 96);
}

.icon-fax:before {
	content: "\e95c";
}

.icon-features:before {
	content: "\e95d";
}

.icon-grid:before {
	content: "\e95f";
}

.icon-help:before {
	content: "\e960";
}

.icon-jobs:before {
	content: "\e961";
}

.icon-linkedin:before {
	content: "\e962";
}

.icon-list:before {
	content: "\e963";
}

.icon-loader .path1:before {
	content: "\e964";
	color: rgb(56, 58, 57);
}

.icon-loader .path2:before {
	content: "\e965";
	margin-left: -1em;
	color: rgb(236, 238, 238);
}

.icon-loader .path3:before {
	content: "\e966";
	margin-left: -1em;
	color: rgb(119, 128, 128);
}

.icon-loader .path4:before {
	content: "\e967";
	margin-left: -1em;
	color: rgb(242, 244, 245);
}

.icon-loader .path5:before {
	content: "\e968";
	margin-left: -1em;
	color: rgb(159, 170, 169);
}

.icon-loader .path6:before {
	content: "\e969";
	margin-left: -1em;
	color: rgb(242, 247, 247);
}

.icon-loader .path7:before {
	content: "\e96a";
	margin-left: -1em;
	color: rgb(178, 187, 186);
}

.icon-loader .path8:before {
	content: "\e96b";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}

.icon-loader .path9:before {
	content: "\e96c";
	margin-left: -1em;
	color: rgb(197, 204, 203);
}

.icon-loader .path10:before {
	content: "\e96d";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}

.icon-loader .path11:before {
	content: "\e96e";
	margin-left: -1em;
	color: rgb(217, 221, 221);
}

.icon-loader .path12:before {
	content: "\e96f";
	margin-left: -1em;
	color: rgb(17, 17, 17);
}

.icon-logout:before {
	content: "\e978";
}

.icon-mail:before {
	content: "\e979";
}

.icon-menu:before {
	content: "\e97a";
}

.icon-minus:before {
	content: "\e97b";
}

.icon-mobile:before {
	content: "\e97c";
}

.icon-more-dotts:before {
	content: "\e97d";
}

.icon-myjob:before {
	content: "\e97e";
}

.icon-notifications:before {
	content: "\e97f";
}

.icon-password:before {
	content: "\e980";
}

.icon-pdf-file:before {
	content: "\e981";
}

.icon-refresh:before {
	content: "\e982";
}

.icon-report-file:before {
	content: "\e983";
}

.icon-reset-password .path1:before {
	content: "\e984";
	color: rgb(224, 225, 226);
}

.icon-reset-password .path2:before {
	content: "\e985";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path3:before {
	content: "\e986";
	margin-left: -1em;
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-reset-password .path4:before {
	content: "\e987";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-reset-password .path5:before {
	content: "\e988";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path6:before {
	content: "\e989";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path7:before {
	content: "\e98a";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path8:before {
	content: "\e98b";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path9:before {
	content: "\e98c";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path10:before {
	content: "\e98d";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path11:before {
	content: "\e98e";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path12:before {
	content: "\e98f";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-reset-password .path13:before {
	content: "\e990";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-settings:before {
	content: "\e992";
}

.icon-Spark-1st-login:before {
	content: "\e993";
}

.icon-star:before {
	content: "\e994";
}

.icon-stop:before {
	content: "\e995";
}

.icon-system_notifs .path1:before {
	content: "\e996";
	color: rgb(251, 211, 3);
}

.icon-system_notifs .path2:before {
	content: "\e997";
	margin-left: -1em;
	color: rgb(110, 177, 225);
}

.icon-system_notifs .path3:before {
	content: "\e998";
	margin-left: -1em;
	color: rgb(54, 186, 144);
}

.icon-system_notifs .path4:before {
	content: "\e999";
	margin-left: -1em;
	color: rgb(220, 222, 226);
}

.icon-system_notifs .path5:before {
	content: "\e99a";
	margin-left: -1em;
	color: rgb(54, 186, 144);
}

.icon-system_notifs .path6:before {
	content: "\e99b";
	margin-left: -1em;
	color: rgb(100, 107, 121);
}

.icon-system_notifs .path7:before {
	content: "\e99c";
	margin-left: -1em;
	color: rgb(94, 166, 209);
}

.icon-system_notifs .path8:before {
	content: "\e99d";
	margin-left: -1em;
	color: rgb(94, 166, 209);
}

.icon-system_notifs .path9:before {
	content: "\e99e";
	margin-left: -1em;
	color: rgb(94, 166, 209);
}

.icon-tva:before {
	content: "\e99f";
}

.icon-upload:before {
	content: "\e9a0";
}

.icon-user:before {
	content: "\e9a1";
}

.icon-wallet:before {
	content: "\e9a2";
}

.icon-watch:before {
	content: "\e9a3";
}

.icon-web-down .path1:before {
	content: "\e9a4";
	color: rgb(224, 225, 226);
}

.icon-web-down .path2:before {
	content: "\e9a5";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}

.icon-web-down .path3:before {
	content: "\e9a6";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-web-down .path4:before {
	content: "\e9a7";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-web-down .path5:before {
	content: "\e9a8";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-web-down .path6:before {
	content: "\e9a9";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-web-down .path7:before {
	content: "\e9aa";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-web-down .path8:before {
	content: "\e9ab";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-web-down .path9:before {
	content: "\e9ac";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-web-down .path10:before {
	content: "\e9ad";
	margin-left: -1em;
	color: rgb(124, 125, 128);
}

.icon-web-down .path11:before {
	content: "\e9ae";
	margin-left: -1em;
	color: rgb(212, 212, 212);
}

.icon-web-down .path12:before {
	content: "\e9af";
	margin-left: -1em;
	color: rgb(212, 212, 212);
}

.icon-web-down .path13:before {
	content: "\e9b0";
	margin-left: -1em;
	color: rgb(212, 212, 212);
}

.icon-web-down .path14:before {
	content: "\e9b1";
	margin-left: -1em;
	color: rgb(212, 212, 212);
}

.icon-web-down .path15:before {
	content: "\e9b2";
	margin-left: -1em;
	color: rgb(96, 96, 96);
	opacity: 0.13;
}

.icon-web-down .path16:before {
	content: "\e9b3";
	margin-left: -1em;
	color: rgb(224, 225, 226);
}

.icon-website:before {
	content: "\e9b4";
}

.icon-error:before {
	content: "\e9c5";
}

.drawer-content {
	background: #fff;
	overflow-y: auto !important;
}
