@import "modules/SearchCandidate/TableVariables/fonts.scss";

.root {
	padding: 16px 15px 0 15px;
}

.freelancersContainer {
	gap: 1rem;
	display: flex;
	flex-direction: column;
	.first {
		display: flex;
		gap: 10px;
		align-items: baseline;
		button {
			background-color: #f8fafc;
			cursor: pointer;
			padding: 0.75rem;
			margin: 0;

			&:hover {
				background-color: #f5f5f5;
			}

			&svg {
				display: block;
			}
		}
	}
}

.freelancersContent {
	margin-top: 120px;
}
.panel {
	width: 100%;
	margin-bottom: 14px;
}

.tabs {
	display: flex;
	align-items: center;
	gap: 25px;
	margin: 0;
	padding: 0 3px;
	list-style: none;
}

.tab {
	all: unset;
	position: relative;
	text-align: center;
	display: block;
	padding: 10px 0;
	text-decoration: none;
	@include SmallParagraph;
	color: #262626;
	font-weight: 500;
	&.active {
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #00857a;
			bottom: -2px;
			left: 0;
		}
	}
	&:hover {
		color: #222;
		cursor: pointer;
	}

	.candidateCount {
		background-color: #1a9187;
		color: #ffffff;
		@include SmallParagraph;
		border-radius: 12px;
		padding: 2px 8px 2px 5px;
	}
}

.presentationSlider {
	width: 100%;
	height: 2px;
	margin-left: 3px;
	background-color: #e5e5e5;
}
