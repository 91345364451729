.container {
	margin-top: 12px;
	margin-bottom: 12px;
	padding: 12px;
	border-radius: 12px;
	background: #f8fafc;
	position: relative;
	display: flex;
	flex-direction: column;
	.close {
		position: absolute;
		right: 0;
		top: 0;
		background: none;
	}

	.visibleSection,
	.notVisibleSection {
		display: flex;
		label {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			flex: 1;
		}
		.listTags {
			display: flex;
			flex: 3;
			flex-wrap: wrap;
			gap: 8px;
			.tag {
				border-radius: 8px;
				border: 1px solid #e2e8f0;
				background: #fff;
				padding: 4px 8px;
				color: #334155;
				font-family: "Work Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				max-height: 29px;
				display: flex;
				align-items: center;
				white-space: nowrap;
				z-index: unset;
				.tagName {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: 200px;
				}
				.departementIcon {
					display: flex;
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}
			}
		}
	}
	.notVisibleSection {
		margin-top: 10px;
	}
}

.images {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
}
div.firstCollaboratorPicture {
	margin-left: -3px;
}

.collaboratorPicture {
	border-radius: 50%;
	border: 3px solid white;
	margin-left: -8px;
}

.picture {
	max-width: 100%;
	max-height: 100%;
	border-radius: 50% !important; // gets replaced in merge
}

.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
}
.avatar {
	display: flex;
	align-items: center;
	border-radius: 50% !important;
	cursor: pointer;

	> div {
		position: relative;
		font-size: 13px;
	}
}
