.logo {
	width: 57px;
	height: 57px;
	border-radius: 8px;
}
.logoPlaceholder {
	svg {
		width: 57px;
		height: 57px;
		fill: #c3c3c3;
	}
}
.jobHeader {
	gap: 14px;
	width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	background-color: white;
	z-index: 1;
	top: 76px;
	position: sticky;
	// border-bottom: 1px solid #e2e8f0;
}
.jobTitle {
	gap: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	h1 {
		font-size: 20px;
		font-weight: 500;
		color: #334155;
		line-height: 28px;
		font-style: normal;
		font-family: Roboto;
	}
	span {
		font-size: 16px;
		font-weight: 400;
		color: #64748b;
		line-height: 20px;
		text-align: center;
		font-style: normal;
		font-family: Roboto;
	}
}
