.root {
	font-family: "basiercircle";
	.input {
		display: inline-flex;
		align-items: center;
		gap: 1rem;
		span {
			width: 30px;
			height: 30px;
		}
	}
	h5 {
		font-style: normal;
		font-size: 13px;
		line-height: 24px;
		color: #8c9196;
		margin-bottom: 2rem;
		text-transform: uppercase;
	}
	.fieldsContainer {
		display: flex;
		align-items: center;
		gap: 5rem;
		h4 {
			font-size: 13px;
			margin-bottom: 11px;
		}
		.error {
			color: red;
			font-size: 13px;
		}
	}
}
