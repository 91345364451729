.statementOfWork {
	display: flex;
	align-items: flex-start;
	gap: 20px;
	flex: 1 0 0;
	border-radius: 8px;
	border: 1px solid #f1f5f9;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	margin-left: -2px;

	&:hover {
		background: #f1f5f9;
	}
}

.fileInfos {
	display: flex;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	white-space: nowrap;
	min-width: 0;
	max-width: 100%;
}

.fileName {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	width: 220px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: start;
}

.tooltip {
	max-width: unset !important;
}

.tagIcon {
	path {
		stroke: #64748b;
	}
}
