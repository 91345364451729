.container {
	display: flex;
	gap: 20px;
	align-items: center;
	border-radius: 8px;
	padding: 8px;
	margin: -9px;
	border: 1px solid transparent;
	&.dragActive {
		border: 1px dashed #00857a;
		background-color: #00857a05;
	}
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.image {
	width: 78px;
	height: 78px;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: inherit;
		pointer-events: none;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}

.indicator {
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	svg {
		display: none;
		width: 24px;
		height: 24px;
	}
	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
		svg {
			display: block;
		}
	}
}

.description {
	.title {
		font-family: "BasierCircle";
		font-size: 18px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.5px;
		color: #334155;
		margin-bottom: 10px;
	}
	.helper {
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: -0.5px;
		color: #475569;
	}
}
