.emptyState {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 9.14px;

	.text {
		color: #334155;
		text-align: center;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 120%;
	}
}
