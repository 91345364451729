.content {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
}

.workingDays {
	display: flex;
	align-items: center;
	gap: 10px;
	align-self: stretch;
}

.shortNameDay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;

	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #64748b;
	text-align: center;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-transform: uppercase;
	cursor: pointer;
	&.selected {
		background: #00857a;
		color: #fff;
	}
}

.timeSlots {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
}

.field {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 20px;
}
.day {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-transform: capitalize;
	min-width: 77px;
	margin-top: 9px;
}
.copyLabel {
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 40px;
	color: #00857a;
	gap: 8px;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.description {
	display: block;
	margin-bottom: 6px;
	color: #64748b;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.enableWorkingContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
