.sidebar {
	width: 30%;
	display: flex;
	padding: 16px;
	background: #fff;
	border-radius: 8px;
	flex-direction: column;
	align-items: flex-start;
	border: 1px solid #e2e8f0;
}
.sidebarActions {
	gap: 12px;
	width: 100%;
	display: flex;
	padding-bottom: 20px;
	flex-direction: column;
	border-bottom: 1px solid #e2e8f0;
}
.sidebarDescription {
	gap: 12px;
	width: 100%;
	display: flex;
	padding-top: 20px;
	flex-direction: column;
}
.sidebarDescriptionItem {
	gap: 7px;
	display: flex;
	flex-direction: column;
	h6 {
		color: #64748b;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		font-family: "Work Sans";
		text-transform: capitalize;
	}
	span {
		color: #334155;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		font-family: "Work Sans";
	}
}

.timeCommit {
	border-radius: 2px;
	color: #909aa3;
	border: solid 2px #909aa3;
	font-size: 11px;
	text-transform: uppercase;
	line-height: 13px;
	display: inline-block;
	padding: 3px 8px;
	font-weight: 500;
	width: fit-content;
}
