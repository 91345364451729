.searchContainer {
	display: flex;
	justify-content: space-between;
	float: left;
	width: 100%;
	margin: 50px 0px;
}

.searchInput {
	line-height: 24px;
	font-size: 16px;
	padding: 0px 12px;
	border-radius: 8px;
	width: 350px;
	height: 100%;
}

.pagination li > a {
	background-color: transparent;
}
