.textAreaWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.textarea {
	resize: none;
	width: 100%;
	display: flex;
	height: 100px;
	padding: 10.5px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	background: #fff;
	&::placeholder {
		color: #a3a3a3;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
	&::-webkit-scrollbar-track {
		background: none;
	}
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background: #cacaca;
		border-radius: 3px;
	}
}

.textarea:focus {
	border: 1px solid #66b6af;
}

.length {
	align-self: flex-end;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	color: #333;
}
