@import "modules/SearchCandidate/TableVariables/fonts.scss";

.root {
	p {
		font-weight: 400;
		font-size: 14px;
		color: #4a4a4a;
		line-height: 16px;
		margin: 0;
		word-break: break-all;

		span {
			color: #00857a;
			font-weight: 400;
		}
	}
}

.filters_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 10px;
}

.filters_div {
	display: flex;
	align-items: center;
	overflow: hidden;
	gap: 0.5rem;
	margin: 0;
}

.filter_op {
	@include SmallParagraph;
	line-height: 1;
	color: #00857a;
	background-color: #e6f3f2;
	border-radius: 3px;
	padding: 0 0.25rem;
	text-transform: capitalize;
}

.pill_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00857a;
	padding: 4px 8px;
	border-radius: 50px;
	font-weight: 500;
	font-size: 13.3333px;
	line-height: 16px;
	color: white;
	font-family: "Work Sans";

	span {
		margin-right: 0.25rem;
	}
}

.close_btn {
	background-color: transparent;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: rotate(180deg);
}

.btns_container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.popper {
	background: #ffffff;
	border: 1px solid #99ceca;
	z-index: 10000002;
	box-sizing: border-box;
	box-shadow: 2px 7px 10px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	padding: 1rem 1.125rem;
	display: none;

	&.expanded {
		display: block;
	}

	.filters_div {
		font-family: "Work Sans";
		text-align: left;
		flex-wrap: wrap;
		white-space: normal;
		align-items: center;
		gap: 0.5rem;
	}

	.filter_value {
		color: #00857a;
		font-weight: 400;
	}

	.title_container {
		border-bottom: 1px solid #f5f5f5;
		margin-bottom: 7px;
		padding-bottom: 11px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.delete_btn_container {
		display: flex;
		color: #d4d4d4;
		padding-left: 9px;
		border-left: 1px solid #f5f5f5;

		button {
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0;
			background-color: transparent;
			color: inherit;
		}

		&:hover {
			color: #00433d;
		}
	}

	.title_btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 9px;
	}

	h5 {
		@include SmallParagraph;
		font-weight: 500;
		line-height: 15.75px;
		color: #262626;
		text-transform: capitalize;
	}

	.date {
		@include SmallParagraph;
		color: #a3a3a3;
	}
}
