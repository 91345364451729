.container {
	overflow: hidden;
	display: flex;
}

.dragHandler {
	border-radius: 12px 0 0 12px;
	border-top: 1px solid var(--border-gray, #e2e8f0);
	border-inline-start: 1px solid var(--border-gray, #e2e8f0);
	border-bottom: 1px solid var(--border-gray, #e2e8f0);
	padding: 12px;
	display: flex;
	align-items: center;
	background: var(--text-white, #fff);
	cursor: grab;
}

.subContainer {
	flex-grow: 1;
}

.header {
	border-radius: 0 12px 0 0;
	border-top: 1px solid var(--border-gray, #e2e8f0);
	border-inline-start: 1px solid var(--border-gray, #e2e8f0);
	border-inline-end: 1px solid var(--border-gray, #e2e8f0);
	display: flex;
	padding: 4px 12px;
	align-items: center;
	background: #f8fafc;
}

.title {
	flex-grow: 1;
	color: var(--gray-700, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	border-radius: 0 0 12px 0;
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	&:focus-within {
		border: 1px solid #00857a;
	}
}

.input {
	all: unset;
	flex-grow: 1;
	height: 48px;
	padding: 0 12px;
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.error {
	padding: 0 12px 12px 12px;
}

.shadow {
	box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
		0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	border-radius: 12px;
}
