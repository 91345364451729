.places {
	display: flex;
}

.valueWrapper {
	display: flex;
	gap: 5px;
}

.resultContainer {
	display: flex;
	gap: 5px;
	width: 100%;
	flex-wrap: wrap;
	padding: 0rem 0.75rem 0.5rem 0.75rem;
}

.resultChip {
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropDownInput {
	padding: 0.2rem 0.5rem;
	height: 100%;
}

.header {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.5rem 0.75rem;
	color: #737373;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
	background-color: white;
	font-family: "Work Sans";

	.number {
		color: #339d95;
	}

	.line {
		background-color: #e5e5e5;
		height: 1px;
		flex: none;
		flex-grow: 1;
	}
}

.container {
	margin-top: 7px;
	margin-bottom: 7px;
}

.loaderContainer {
	margin: auto;
	width: 10px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader div {
	border-right-color: #00857a;
}
