.tableWithdrawn {
	width: 100%;
	background-color: white;
	margin-left: auto;
	margin-right: auto;
	border-radius: 5px;

	thead {
		border-bottom: 1px solid #f0f3f5;
		th {
			align-items: center;
			padding: 15px 15px 15px 15px;
			color: black;
			font-size: 14px;
			font-style: normal;
			line-height: 20px;
			font-family: "BasierCircle";
		}
	}
	tbody {
		tr {
			border: none;
			height: 60px;
			&:hover {
				background-color: #fafbfb;
			}
			cursor: pointer;
			th {
				font-weight: normal;
				padding: 0px 15px 0px 15px;
				border: none;
				font-size: 14px;
				line-height: 20px;
				color: #3c3e3e;
			}
			td {
				padding: 0px 10px 0px 10px;
				border: none;
				font-size: 14px;
				line-height: 20px;
				color: #3c3e3e;
			}
			.avatar {
				margin-left: 10px;
			}
			.notes {
				display: flex;
				flex-direction: row;
			}
			button {
				border: none;
				background-color: transparent;
				display: flex;
				flex-direction: row;
			}
		}
	}
}

.select {
	width: 350px;
	margin-bottom: 25px;
	color: transparent;
}

.reasonOfRejection {
	overflow-x: hidden;
	text-overflow: ellipsis;
	width: 380px;
	padding: 16px;
}
