@import "modules/candidate/Profile/Variables/styles.scss";

.container {
	@include tabsPadding;
	.interactionsTitle {
		@include tabsTitle;
		margin-left: 2px;
		padding-bottom: 18px;
		color: $swamp;
	}
}

.title {
	@include profileContent;
	color: $blackPearl;
	line-break: anywhere;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
	width: 500px;
}
.button {
	padding: 0;
	background: transparent;
	border: unset;
	span {
		text-transform: capitalize;
	}
}
.paginationContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	& > div {
		display: flex;
		align-items: center;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: $tuna;
	}
}

.filterBox {
	background: $white;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	padding: 10px;
}

.tooltip {
	left: 125px;
	width: 250px;
}
