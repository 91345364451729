.employerBranding {
	padding: 35px;

	.title {
		width: 1125px;
		height: 24px;
		margin-bottom: 30px;
		/* Header/H3 */

		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 23.04px;
		line-height: 24px;
		/* identical to box height, or 104% */

		display: flex;
		align-items: center;
		letter-spacing: -0.5px;

		/* neutral/800 */

		color: #262626;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	.tabHeader {
		height: 19px;
		margin-top: 23px;
		margin-bottom: 30px;

		> div {
			border-bottom: 2px solid #e5e5e5;
		}
	}
}
