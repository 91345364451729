.historyHeader {
	display: flex;
	gap: 6px;
	color: #334155;
	margin-bottom: 20px;
}
.historyTitle {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-top: -2px;
}
.historyInterviewer {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	.interviewerName {
		color: #00857a;
	}
}
.historyList {
	display: flex;
	flex-direction: column;
	gap: 16px;

	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	max-height: 490px;
	overflow-y: auto;
	margin: -16px;
	padding: 16px;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.05);
		border-radius: 99px;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 99px;
	}
}
.historyItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.historyDate {
	display: flex;
	align-items: center;
	gap: 6px;
}
