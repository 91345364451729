@import "modules/candidate/Profile/Variables/styles.scss";

.statusContainer {
	border-radius: 10px;
	padding: 3px 10px;
	min-width: 70px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	gap: 5px;
	margin-right: 10px;
	button {
		background: transparent;
		padding: 0px;
		.icon {
			fill: currentColor;
		}
	}
}
.statusLabel {
	font-family: "basiercircle";
	text-transform: capitalize;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
