.container {
	padding: 20px 20px 0 20px;
	margin-bottom: -60px;
}

.contentLayout {
	display: flex;
	flex: 1 0 0;
	border-radius: var(--lg, 12px) var(--lg, 12px) 0px 0px;
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--white, #fff);
	min-height: calc(100vh - 153px);
	max-height: calc(100vh - 153px);
	margin-top: 20px;
}

.sidebar {
	border-right: 1px solid var(--border-gray, #e2e8f0);
	width: 460px;
}

.content {
	flex-grow: 1;
}
