.container {
	padding: 20px 16px 16px 16px;
}

.title {
	color: var(--gray-600, #475569);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
	margin-bottom: 10px;
}

.input {
	all: unset;
	box-sizing: border-box;
	width: 100%;
	height: 40px;
	padding: 0 14px;
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--White, #fff);

	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;

	&:focus {
		border: 1px solid #00857a;
	}
}
