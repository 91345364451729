.container {
	display: flex;
	padding: 12px;
	flex-direction: row;
	align-items: center;
	border-radius: 8px;
	border: 1px solid rgba(245, 158, 11, 0.16);
	background: #fffbeb;
	gap: 10px;
	margin-bottom: 16px;
}

.description {
	color: #955a00;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.3px;
}

.infoIcon {
	color: #f59e0b;
}
