@import "styles/sass/hireme/_colors";

.profile_container {
	margin: 20px;
	padding: 20px;
	border-radius: 20px;
	background-color: white;
	border: 1px solid #f3f4f6;
}

.title {
	font-family: BasierCircle;
	font-size: 23px;
	font-weight: 500;
	line-height: 24px;
	margin: 7px 0;
	color: #334155;
}

.divider {
	border-top: 1px solid #e5e7eb;
	margin: 20px 0;
}

.intro {
	.heading {
		font-family: "Work Sans";
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.5px;
		color: #334155;
		margin-bottom: 16px;
	}
	.description {
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.5px;
		color: #64748b;
	}
}

.submit_btn {
	background-color: $primary500;
	color: $white;
	border-radius: 8px;
	height: 40px;
	font-size: 16px;
	font-family: "Work Sans";
	font-weight: 500;
	border: 1px solid;
	border-color: $primary500;
	white-space: nowrap;
	padding: 0 24px;
	&_disabled {
		pointer-events: none;
		background-color: #f5f5f5;
		border-color: #f5f5f5;
		color: #d4d4d4;
	}
	&:hover {
		background-color: #006a62;
	}
}

.form_field {
	:global {
		margin-bottom: 24px !important;

		label::first-letter {
			text-transform: capitalize;
		}

		label {
			text-transform: lowercase;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 400;
			line-height: 21px;
			letter-spacing: -0.5px;
			color: #475569;
			margin: 0;
		}

		input.form-control {
			font-family: "Work Sans";
			font-weight: 400;
			font-size: 16px;
			border-radius: 8px;
			margin-top: 12px !important;
			border: 1px solid $gray300;
			padding: 12px 14px;
			box-shadow: none;
			min-height: 48px;
			&:not([readonly]):focus {
				border: 1px solid $primary300;
				box-shadow: 0 0 0 4px $primary100;
			}
		}

		.help-block {
			padding: 0;
			min-height: 0;
		}

		.has-error {
			input.form-control {
				border-color: $red400;
				&:focus {
					box-shadow: 0 0 0 4px $red100;
				}
			}
			.inline-error {
				margin-top: 12px;
			}
		}

		.role {
			font-family: "Work Sans";
			font-weight: 400;
			font-size: 16px;
			margin-top: 12px;
			padding: 12px 14px;
			min-height: 48px;
			color: #334155;
			border: 1px solid #d1d5db;
			border-radius: 8px;
			background-color: #fafafb;
		}
	}
}

.role_container {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.role_tag {
	gap: 8px;
	display: flex;
	padding: 8px 16px;
	background: #fff;
	border-radius: 38px;
	align-items: center;
	justify-content: center;
	border: 1px solid #e2e8f0;
	font-size: 14px;
	font-weight: 400;
	color: #334155;
	font-family: "Work Sans";

	&.owner {
		color: #f59e0b;
	}

	.role {
		font-weight: 500;
		color: #334155;
	}
}
.banner {
	width: 100%;
	display: flex;
	padding: 10px 14px;
	align-items: center;
	border-radius: 12px;
	background: #f8fafc;
	border: 1px solid #e2e8f0;

	gap: 8px;
	font-size: 14px;
	font-weight: 400;
	color: #334155;
	font-family: "Work Sans";
}

.avatar_container {
	width: 78px;
	height: 78px;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
}

.avatar_label {
	.label {
		font-family: BasierCircle;
		font-size: 18px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.5px;
		margin-bottom: 10px;
		color: #334155;
	}
	.helper {
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: -0.5px;
		color: #475569;
	}
}

.cancel_btn,
.upload_btn,
.mode_btn {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 0 16px;
	height: 40px;
	background-color: white;
	cursor: pointer;
	border: 1px solid #f3f4f6;
	border-radius: 8px;
	color: #394858;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.5px;
	&:hover {
		background: #f5f5f5;
	}
}

.edit_profile {
	:global {
		.avatar-uploader {
			&__menu-tab {
				&--active {
					background-color: $primary500;
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}
}

.info {
	.key {
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: -0.5px;
		color: #475569;
	}
	.value {
		font-family: "Work Sans";
		font-weight: 500;
		font-size: 16px;
		margin-top: 12px;
		color: #334155;
		margin-bottom: 24px;
	}
}

.wiggliMail {
	display: flex;
	padding: 5px 12px;
	align-items: center;
	gap: 8px;
	width: fit-content;
	margin-bottom: 15px;

	border-radius: 6px;
	border: 1px solid #e2e8f0;
}

.mailValue {
	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.copyButton {
	background: transparent;
	padding: 0;
	height: 20px;
}

.syncParagraph {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.providerWrapper {
	display: flex;
	padding: 12px;
	align-items: center;

	border-radius: 8px;
	border: 1px solid #e5e7eb;
}

.providerEmail {
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 17.5px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	letter-spacing: -0.5px;
}
.syncButtonRoot {
	margin-left: auto;
}
.syncButton {
	border: 1px solid #d1d5db;
	box-shadow: none;
	&.connected {
		color: #d92d20;
	}
	&.disconnected {
		color: #6b7280;
	}
}

.syncStatus {
	margin-left: 15px;

	color: #039855;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 200%;
	&.notSynced {
		color: #d92d20;
	}
}

.providerOptions {
	display: flex;
	flex-direction: column;
	.option {
		display: flex;
		padding: 7px 10.5px;
		align-items: center;

		color: #262626;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		cursor: pointer;
		&:hover {
			background-color: #f5f5f5;
		}
	}
}
.providerValue {
	display: flex;
	align-items: center;
	gap: 10px;

	color: #6b7280;
	font-family: "Work Sans";
	font-size: 17.5px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	letter-spacing: -0.5px;
}
.providerIcon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
.providerValueIcon {
	width: 32px;
	height: 32px;
}

.paperClassName {
	width: 226px !important;
}

.notes {
	display: flex;
	padding: 15px;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
	align-self: stretch;

	border-radius: 6px;
	border: 1px solid #e2e8f0;
	background: #f8fafc;
}
.notesTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.5px;
	margin: 0;
}
.notesList {
	margin: 0;
	margin-left: 15px;
}
.notesItem {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 200%;
}

.checkIcon {
	margin-left: auto;
}

.syncEmailBanner {
	display: flex;
	padding: 8px;
	align-items: center;
	border-radius: 5px;
	background: rgba(254, 205, 202, 0.6);

	color: #d92d20;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.dropDownInputClassName {
	&.disabled {
		pointer-events: none;
	}
}
