.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
button {
	padding: 5px 12px;
	border: none;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	border-radius: 4px;
}
.timeChooser {
	position: absolute;
	top: 5px;
	left: 0px;
	.input_date_time {
		position: relative;
		input {
			background: #ffffff;
			border-radius: 4px;
			height: 38px;
			border: 1px solid #a7acb1;
			min-height: inherit;
			width: 100%;
			padding-left: 44px;
			&:focus {
				border: 1px solid #a7acb1 !important;
			}
		}
		span {
			position: absolute;
			left: 14px;
			top: 8px;
		}
	}
	.container_due_date {
		position: absolute;
		transition: transform 350ms;
		display: none;
		top: 0;
		left: 0;
		z-index: 10;
		background: #ffffff;
		box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		width: 500px;
		padding: 15px;
		padding-bottom: 0;
		&.open {
			display: block;
		}
		.date_picker_container {
			display: flex;
			.dateTime {
				width: 55%;
				margin-right: 20px;
			}
			.timer {
				width: 30%;
			}
		}
		.block_buttons {
			@extend .flex_end;
			height: 62px;
			.buttonCancel {
				@extend button;
				background: #ffffff;
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
				color: #202223;
				margin-right: 10px;
				border: 1px solid #babfc3;
			}
			.buttonSave {
				@extend button;
				background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
					inset 0px -1px 0px rgba(0, 0, 0, 0.2);
				color: #ffffff;
				&:disabled {
					cursor: not-allowed;
					background: #babec3;
					box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
						inset 0px -1px 0px rgba(0, 0, 0, 0.2);
				}
			}
		}
	}
}
