.popover {
	border-radius: 0.375rem;
	right: 0px !important;
	left: auto !important;
	z-index: 10;
	overflow: hidden;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	outline: 0ch;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
