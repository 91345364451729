$primary-color: #00857a;

.paper {
	position: absolute;
	left: 100%;
	margin-left: 10px;
	top: 0%;
	max-width: 168px;
	padding: 0.25rem 0px;
	z-index: 10;
}

.input {
	border-color: $primary-color;
}
