.wrapper {
	display: flex;
	color: "#3d4b60";
	font-weight: 400;
	line-height: 1.33;
	font-size: 13.3px;
	align-items: center;
	margin: 14px 30px 0px 30px;
}

.wrapper #do-not-show-again {
	z-index: 10;
	margin: unset;
	width: 171px;
	height: 20px;
	cursor: pointer !important;
}

.wrapper label {
	padding-left: 30px !important;
	cursor: pointer !important;
}
