.dots {
	display: flex;
	align-items: center;
	color: #334155;
	padding: 0px 2px;
	border-radius: 3px;
	height: 20px;

	border-radius: 6px;
	border: 1px solid #e2e8f0;
	background: #fff;
}

.dotsIcon {
	transform: rotate(90deg);
	path {
		stroke: #334155;
	}
}

.actionsMenuItem {
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	align-self: stretch;
	background: none;
	border: none;
	color: #334155;
}

.actionsMenuItemText {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.actionButton {
	font-size: 12px;
	line-height: 150%;
	padding: 2px 6px;
	height: 22px;
	color: #00857a;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&:hover {
		background: none;
	}
}
.decline {
	color: #d92d20;
	box-shadow: 0px 0px 0px 1px #d92d20 inset;
}
