@import "modules/SearchCandidate/TableVariables/fonts.scss";

.filters_pill {
	padding: 0.25rem 0.75rem;
	background: #ffffff;
	border: 1px solid #66b6af;
	border-radius: 50px;
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	white-space: wrap;

	&.no_margin {
		margin: 0;
	}
}

.filters_text {
	@include SmallParagraph;
	white-space: nowrap;
	font-weight: 400;
	margin: 0;
	margin-right: 0.25rem;
	color: #262626;
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		@include SmallParagraph;
		color: #262626;
		font-weight: 500;
		line-height: 1.125rem;
		margin: 0;
	}
}

.filter_op {
	@include SmallParagraph;
	line-height: 1;
	font-weight: 400 !important;
	background-color: #e6f3f2;
	color: #00433d !important;
	border-radius: 3px;
	padding: 0 0.25rem;
	text-transform: lowercase;
}

.removeBtn {
	color: transparent;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	color: #d4d4d4;
	border-radius: 50%;

	&:hover {
		color: #00857a;
		background: #e6f3f2;
	}
}
