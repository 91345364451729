.card {
	height: 217px;
	padding: var(--6, 24px);
	border-radius: var(--lg, 12px);
	border: 1px solid var(--gray-100, #f3f4f6);
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
		0px 1px 3px 0px rgba(0, 0, 0, 0.1);

	display: flex;
	flex-direction: column;
	gap: 8px;

	cursor: pointer;
}

.checkboxContainer {
	display: flex;
	justify-content: flex-end;
}

.logo {
	width: 100%;
	height: 53px;
	object-fit: contain;
	margin-bottom: 8px;
}

.title {
	color: var(--text-dark, #334155);
	font-family: BasierCircle;
	font-size: 13.216px;
	font-style: normal;
	font-weight: 600;
	line-height: 18.88px;
}

.description {
	color: var(--text-dark, #334155);
	font-family: BasierCircle;
	font-size: 13.216px;
	font-style: normal;
	font-weight: 400;
	line-height: 18.88px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.tooltip {
	:global {
		.rc-tooltip-inner {
			color: #fff;
			font-family: BasierCircle;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18.488px;
			background-color: #334155 !important;
			border-color: #334155 !important;
			border-radius: 4px;
			padding: 5px 12px !important;
			overflow-wrap: break-word;
		}
		.rc-tooltip-arrow {
			background-color: #334155 !important;
			border-color: #334155 !important;
		}
	}
}
