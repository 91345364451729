input.input[type="text"],
input.input[type="number"] {
	border: 1px solid #e5e5e5;
	padding: 0px 9px;
	border-radius: 9px;
	outline: 0ch;
	width: 100%;
	min-height: auto;
	border-radius: 5px;
	font-size: 14px;
	font-family: "Work Sans";
	flex-basis: 80%;
	outline: 0px;
	color: #262626;
	font-size: 1rem;
	line-height: 1.125rem;
	padding: 0.5rem 0.75rem;
	font-weight: 400;

	&:focus {
		border: 1px solid #00857a;
	}

	&::placeholder {
		color: #a3a3a3;
	}
}
