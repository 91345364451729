.tasksItems {
	padding: 0;
	margin-bottom: 0;
	gap: 21px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
}
.loader_container {
	width: 100%;
	height: 330px;
	display: flex;
	align-items: center;
	justify-content: center;
}
