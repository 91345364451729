.container {
	width: 100%;
	display: grid;
	grid-template-rows: max-content max-content;
	grid-template-columns: max-content max-content;
	row-gap: 1px;
	column-gap: 10px;
	padding: 8px 12px;
	border-bottom: 1px solid #e2e8f0;
}

.fullName {
	overflow: hidden;
	color: #1e293b;
	text-overflow: ellipsis;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 21px */
}

.freelancerRoleContainer {
	display: flex;
	padding: 4px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	border-radius: 3px;
	background: #e0f2fe;
}

.freelancerRoleText {
	color: #1570ef;
	font-family: "Work Sans";
	font-size: 10.5px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.avatar {
	margin-top: 6px;
	display: flex;
	width: 40px;
	height: 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	justify-content: center;
	align-items: center;
	border-radius: 19px;
	grid-column: 1 / 2;
	grid-row: 1 / 3;
	img {
		width: 40px;
		height: 40px;
		border-radius: 12px;
	}
}

.role {
	display: flex;
	padding: 2px 6px;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 21px */
}

.nameContainer {
	display: flex;
	gap: 5px;
}

.matchingContainer {
	display: flex;
	align-items: center;
	padding: 2px 6px;
	align-items: center;
	gap: 7px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
}

.score {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 21px */
}

.flex {
	display: flex;
	gap: 10px;
}

.customAvatar {
	width: 40px !important;
	height: 40px !important;
	border-radius: 12px !important;
}

.customAvatar div {
	height: 100% !important;
	align-items: center !important;
	font-size: 13px !important;
}
