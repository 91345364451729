.header {
	max-height: 114px;

	display: flex;
	padding: 25px 18px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;

	border-bottom: 1px solid #f1f1f1;
}

.banner {
	display: flex;
	width: 100%;
	padding: 8px;
	align-items: center;
	gap: 10px;

	max-height: 35px;
	border-radius: 5px;
	background: #ebf7f6;

	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	&.inProgress {
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #f8fafc;
		.bannerCloseIcon {
			svg {
				path {
					stroke: #64748b;
				}
			}
		}
	}
	&.finished {
		color: #00857a;
		font-family: "Work Sans";
		font-size: 14px;
		letter-spacing: -0.3px;

		border-radius: 8px;
		border: 1px solid #dbeef7;
		background: #e6f3f2;
	}
	&.failed {
		color: #955a00;
		font-family: "Work Sans";
		font-size: 14px;
		letter-spacing: -0.3px;

		border-radius: 8px;
		border: 1px solid rgba(245, 158, 11, 0.16);
		background: #fffbeb;
		.bannerCloseIcon {
			svg {
				path {
					stroke: #f59e0b;
				}
			}
		}
	}
}

.bannerCloseIcon {
	width: 21px;
	height: 20px;
	margin-left: auto;
	background: none;
	padding: 0;
	svg {
		path {
			stroke: #339d95;
		}
	}
}

.syncButton {
	text-decoration: none;
	padding: 2px 6px;

	border-radius: 4px;
	background: #fff;

	color: #000;
	font-family: "Work Sans";
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.section {
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
}

.headerTitle {
	margin: 0;
	max-width: 68px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26.8px;
}

.searchInputContainer {
	max-width: 650px;
	flex-grow: 1;
	margin-left: 20px;
}

.searchInput {
	width: 100%;
	padding-right: 6rem;
	padding-left: 36px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	background: #fff;
	border-radius: 12px;
	height: 44px;
	box-shadow: 0px 0px 0px 1px #e2e8f0, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	&::placeholder {
		color: #94a3b8;
		font-weight: 400;
	}

	&:focus {
		box-shadow: 0px 0px 0px 1px #e2e8f0, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		background: #f8fafc;
	}

	&.withDropdownOpen {
		border-radius: 12px 12px 0 0;
	}
}

.newMailButton {
	margin-left: auto;
}

.button {
	max-height: 32px;
	padding: 8px 16px;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.icon {
	cursor: pointer;
}

.searchIcons {
	display: flex;
	align-items: center;
	gap: 15px;
	position: absolute;
	right: 10px;
}

.checkIcon {
	color: #039855;
}

.searchIconClassName {
	left: 10px;
	right: 0;
}

.editIcon {
	path {
		stroke: #fff;
	}
}
