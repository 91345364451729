.root {
	h4 {
		margin-bottom: 1rem;
	}
	p {
		margin-bottom: 2rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 1rem;
	}
}
