button.cancelButton {
	box-shadow: 0px 0px 0px 1px #d1d5db inset;
	padding: 14px 21px;
	height: 44px;
	border-radius: 8px;
}

button.cancelButton:hover {
	background-color: #fafafa;
}

.cancelButtonText {
	color: #525252;
}
