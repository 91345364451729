$textColor: #334155;
$headingColor: #1e293b;
$primaryColor: #00857a;

.title {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;
	color: $headingColor;
}

.subtitle {
	font-weight: 600;
	font-size: 19px;
	line-height: 28px;
	display: flex;
	align-items: center;
	color: $headingColor;
}

.flex_col {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.list_item {
	gap: 11px;
	display: flex;
	align-items: center;
	p {
		font-size: 16px;
		font-weight: 400;
		color: $textColor;
	}
	svg {
		min-width: 18px;
	}
}

.buttons {
	gap: 15px;
	display: flex;
}

.form {
	margin: 24px 0;
	color: $textColor;
	&__password {
		margin-bottom: 0 !important;
		label {
			color: inherit !important;
			font-weight: 400 !important;
			font-size: 16px !important;
			text-transform: none !important;
			margin-bottom: 8px !important;
		}
		input {
			color: $textColor !important;
			border-radius: 8px !important;
			&:focus {
				border-color: #e2e2e2 !important;
				box-shadow: 0px 0px 0px 5px rgba(222, 218, 218, 0.12) !important;
			}
		}
		&_error {
			span {
				display: block !important;
				font-size: 14px !important;
				color: #dc2626 !important;
				padding-top: 6px !important;
			}
			input {
				color: #dc2626 !important;
				box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12) !important;
			}
		}
	}

	&__textarea {
		margin-bottom: 0 !important;
		textarea {
			color: $textColor !important;
			border-radius: 8px !important;
			&:focus {
				border-color: #e2e2e2 !important;
				box-shadow: 0px 0px 0px 5px rgba(222, 218, 218, 0.12) !important;
			}
		}
		&_error {
			span {
				display: block !important;
				font-size: 14px !important;
				color: #dc2626 !important;
				padding-top: 6px !important;
			}
			input {
				color: #dc2626 !important;
				box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12) !important;
			}
		}
	}

	&__checkbox {
		margin-bottom: 8px !important;
		input[type="checkbox"]:checked + label::before {
			background-color: $primaryColor !important;
			border: 1px solid $primaryColor !important;
		}
	}
	&__container_checkbox {
		display: flex;
		label {
			color: $textColor !important;
			font-weight: 400 !important;
			font-size: 16px !important;
			padding-left: 14px !important;
			margin-top: -2px;
		}
		input {
			margin-top: -25px !important;
			margin-left: 3px !important;
			z-index: 999 !important;
			scale: 1.3 !important;
		}
	}
}

.toaster {
	font-family: "Roboto";
	font-style: normal;
	&__heading {
		display: block;
		font-weight: 600;
		font-size: 18px;
		line-height: 28px;
	}
	&__body {
		display: block;
		font-weight: 400;
		font-size: 15px;
		line-height: 21px;
	}
}

.modal__close {
	top: 28px;
	right: 24px;
	z-index: 100;
	cursor: pointer;
	position: absolute;
	&:hover svg path {
		stroke: #4b5563;
	}
}
