@import "../../../shared/shared-components";

.row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 50px;
	grid-gap: 1rem;
	margin-bottom: 1.5rem;
	.errorField {
		color: red;
		font-size: 13px;
	}
}

.removeButton {
	border: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
}

.form {
	margin-top: 2rem;
}

.addButton {
	@extend .secondaryButton;
	margin-top: 2rem;
}
