.container {
	padding: 12px;
	display: flex;
	justify-content: space-between;
	gap: 73px;
	align-items: center;
}

.text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
}

.link {
	display: flex;
	align-items: center;
	cursor: pointer;
}
