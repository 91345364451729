.container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	gap: 12px;
	min-width: 410px;
	max-width: 410px;
}

.container2 {
	min-width: 410px;
	max-width: 410px;
	width: 100%;
}

.timePicker {
	display: grid !important;
	grid-template-columns: 1fr 1fr;
	gap: 12px;
	max-width: 410px;
	input {
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: -0.5px;
		min-width: 100%;
		display: flex;
		min-height: 44px;
		padding: 8px 16px;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		border: 1px solid #e2e8f0;
		background: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}

	input[readonly] {
		border: 1px solid #f1f5f9;
		background: #f8fafc;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #94a3b8;
	}
}

.comboxBoxInput {
	width: 100%;
	background-color: white;
	&:hover {
		input {
			background-color: #fafafa !important;
		}
	}
	input {
		background-color: white !important;
		&:hover {
			background-color: #fafafa !important;
		}
	}
}

.separator {
	width: 1px;
	height: 100%;
	background-color: #e2e8f0;
}
