.vacancyContainer {
	background: #ffffff;
	border: 1px solid #d2d5d8;
	border-radius: 4px;
	padding: 12px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	.menuLabel {
		display: flex;
		align-items: center;
	}
	> img {
		height: 32px;
		width: 32px;
	}
	.icon {
		fill: #575959;
	}
}

.listVacancy {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 8px;
	background: #e4e5e7;
	border-radius: 2px;
	height: 26px;
	margin-right: 2px;
	span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
	}
	button {
		background: transparent;
	}
}
.otherVacancy {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f0f3f5;
	border: none;
	margin-right: 8px;
}

.addVacancy {
	display: flex;
	align-items: center;
}

.positionRelative {
	position: relative;
}
