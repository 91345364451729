input[type="text"].input,
input[type="number"].input {
	padding: 13px;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	line-height: 16px;
}

input.input[type="number"]::-webkit-outer-spin-button,
input.input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
