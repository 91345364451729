.button {
	border-radius: var(--md, 8px);
	background: var(--bg-brand, #00857a) !important;
	color: var(--text-white, #fff);
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	text-align: center;

	&:hover {
		background: var(--bg-brand-hover, #006a62) !important;
	}

	&:focus {
		background: var(--bg-brand, #00857a) !important;
		box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22) !important;
	}

	&.disable {
		padding: var(--3, 12px) var(--4, 16px);
		background: var(--bg-disabled, #f1f5f9);
		color: var(--text-disabled, #cbd5e1);
		font-style: normal;
		opacity: 0.5;
		cursor: not-allowed;
	}
}
