.job_details_permanent {
	.expired-title {
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 25px;
	}

	.expired-button {
		margin-top: 60px;
	}

	.my-jobs-filter {
		.select-style {
			float: right;
			width: 200px;
		}

		.Select-menu {
			max-height: 250px;
		}

		.Select-menu-outer {
			max-height: 252px;
			text-transform: uppercase;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 1px;
			width: 200px;
		}

		.Select-value {
			font-size: 12px !important;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 0.5px;
		}
	}

	#job-details-permanent-container {
		&.view-job-details {
			.container {
				clear: both;
			}

			.tenPaddingTop {
				margin-top: 30px;
				&.toRight {
					float: right;
					width: 48%;
					margin-bottom: 20px;
					margin-top: 72px;
				}
			}
			.noPadding {
				clear: both;
				margin-bottom: 20px;
			}

			.topbar {
				margin-bottom: 10px;
				padding: 30px 0 10px 20px;
				overflow: hidden;
				font-weight: 400;
				position: relative;
				display: inherit;
				justify-content: space-between;
				align-items: center;
				padding-left: 0;
				margin-top: 40px;
				&.topbar_newcampaign {
					margin-bottom: 0px;
					i.fa {
						margin-right: 5px;
					}
				}
				.logoCompany {
					width: 56px;
					height: 56px;
					> img {
						display: block;
						width: 100%;
						border-radius: 4px;
					}
				}
				.title {
					font-size: 24px;
					font-weight: 300;
					color: #394047;
					overflow: hidden;
					margin-bottom: 10px;
					margin-top: -30px;
					width: 75%;
				}
				.category {
					background-color: #ff6849;
					padding: 10px;
					color: #fff;
					border-radius: 4px;
					text-align: center;
					font-weight: 400;
				}
				.function {
					color: #91989e;
					padding: 10px;
					font-weight: 400;
					text-transform: uppercase;
				}
				.pull-right {
					max-width: 40%;
					position: absolute;
					right: 0;
					padding-top: 14px;
					.item {
						float: left;
						margin-left: 20px;
						&:first-child {
							margin-left: 0;
						}
					}
					h5 {
						font-size: 13px;
						font-weight: 600;
						color: #91989e;
						text-transform: uppercase;
						margin-bottom: 8px;
					}
					.value {
						font-size: 24px;
						color: #3d4b60;
					}
					&.consultant {
						max-width: 45%;
						.item {
							.value {
								font-size: 20px;
							}
						}
					}
				}

				.section-title {
					display: block;
					margin-bottom: 8px;
					clear: both;
				}

				.request-title {
					font-size: 24px;
					font-weight: 300;
					line-height: 1.28;
					color: #394047;
					display: block;
				}
				.section-profile-request {
					margin-top: 30px;
				}
				.profile-title {
					font-size: 13px;
					color: #394047;
				}

				.seniority {
					margin-left: 10px;
				}

				.total-bids {
					margin-top: -14px;
					span {
						font-size: 29px;
						font-weight: 400;
					}
				}

				.avatars {
					overflow: hidden;
					.avatar {
						float: left;
						width: 22px;
						height: 22px;
						margin-right: 5px;
						img {
							width: 100%;
							height: 100%;
							display: block;
						}
					}
					.bid-avatar {
						border: solid 1px #f1f2f5;
						margin-right: 5px;
						float: left;
					}
					.more-avatars {
						border: solid 1px #f1f2f5;
						background-color: #dddee2;
						position: relative;
						left: -28px;
						color: #3d4b60;
						font-weight: 500;
						width: 24px;
						height: 24px;
						border-radius: 50%;
						font-size: 16px;
						float: left;
						text-align: center;
						line-height: 15px;
					}
					.back {
						position: relative;
						left: -14px;
					}
				}
				.consultant-avatar {
					width: 36px;
					height: 36px;
					margin-right: 5px;
					float: left;
					img {
						width: 36px;
						height: 36px;
						border-radius: 4px;
					}
				}

				.potential-container {
					.btn-primary {
						font-size: 12.3px;
						padding-right: 42px;
						padding-left: 57.5px;
						position: relative;
						line-height: normal;
						width: 237px;
						margin-right: 10px;
						i {
							font-size: 18px;
							position: absolute;
							left: 18px;
							top: 12px;
						}
					}
				}
				.create__new__campaign {
					padding: 10px 13px;
					font-size: 14px;
					float: right;
					width: 224px;
					height: 40px;
					line-height: 0.5;
					font-size: 13px;
					margin-top: 15px;
				}
			}

			.section-title {
				font-size: 15px;
				line-height: 15px;
				text-transform: uppercase;
				color: #58626b;
				font-weight: 500;
				margin-bottom: 15px;
			}
			.content-inner {
				margin-bottom: 50px;
				padding-right: 10px;
				&.jobdetails {
					margin: 0;
					padding: 0;
				}
				#can-do {
					ul.crumbs {
						list-style: none;
						overflow: hidden;
						margin-bottom: 21px;
						padding: 0;
						li {
							float: left;
							color: #fff;
							text-decoration: none;
							padding: 0 0 0 65px;
							background-color: #b3bcc6;
							position: relative;
							display: block;
							font-weight: normal;

							&:first-child {
								padding-left: 20px;
								border-radius: 3px 0 0 3px;
							}
							&:last-child {
								text-transform: capitalize;
								padding-right: 20px;
								border-radius: 0 3px 3px 0;
								background-color: #8a95a1;
							}
							&:not(:last-child) {
								&:after {
									content: " ";
									display: block;
									width: 0;
									height: 0;
									border-top: 50px solid transparent;
									border-bottom: 50px solid transparent;
									border-left: 50px solid #b3bcc6;
									position: absolute;
									top: 50%;
									margin-top: -50px;
									left: 100%;
									z-index: 2;
								}
								&:before {
									content: " ";
									display: block;
									width: 0;
									height: 0;
									border-top: 50px solid transparent;
									border-bottom: 50px solid transparent;
									border-left: 50px solid #fff;
									position: absolute;
									top: 50%;
									margin-top: -50px;
									margin-left: 4px;
									left: 100%;
									z-index: 1;
								}
							}
						}
						&.main {
							li:first-child {
								svg {
									height: 12px;
									width: 14px;
									margin-top: 11px;
									margin-right: 10px;
								}
								.color-icon {
									color: white;
								}
								background-color: #ff6849;
								&:after {
									border-left-color: #ff6849 !important;
								}
							}
						}
					}
				}
				.section {
					background-color: #fff;
					padding: 25px 20px;
					line-height: 36px;
					border-radius: 4px;
					margin-bottom: 8px;
					overflow: hidden;
					&.appliedBlock {
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						flex-direction: row;
						-webkit-flex-wrap: nowrap;
						-ms-flex-wrap: nowrap;
						flex-wrap: nowrap;
						-webkit-justify-content: space-between;
						-ms-flex-pack: justify;
						justify-content: space-between;
						-webkit-align-content: space-between;
						-ms-flex-line-pack: justify;
						align-content: space-between;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						border-radius: 4px;
						border: solid 1px rgb(37, 112, 183);
						background-color: rgb(231, 243, 255);
						&.cancelled {
							border: solid 1px rgb(247, 83, 86);
							background-color: rgb(255, 246, 249);
							align-items: flex-start;
							-webkit-align-items: flex-start;
							-ms-flex-align: flex-start;
							.infoCancel {
								margin-right: 160px;
								.description-content {
									p {
										margin-bottom: 0;
									}
								}
								.desc--action {
									color: #3d4b60;
									text-decoration: underline;
								}
								p {
									color: #394047;
									margin: 0;
									font-size: 14px;
									margin-top: 0;
									line-height: 1.74;
									font-weight: 400;
									margin-bottom: 16px;
								}
								h3 {
									font-size: 14px;
									font-weight: bold;
									margin-bottom: 16px;
								}
							}
							.icon {
								margin-top: 13px;
							}
							.flagwrapper {
								margin-top: 13px;
							}
						}
						> p {
							color: #394047;
							margin: 0;
							font-size: 14px;
							margin-top: 0;
							line-height: 1.74;
							font-weight: 400;
							margin-right: 140px;
						}
						.icon {
							font-size: 30px;
							margin-right: 46px;
							height: 30px;
						}
					}
					.description {
						.description-content {
							word-break: break-word;
						}
					}

					p {
						font-weight: 300;
						color: #394047;
						font-size: 14px;
					}
					.address-formatter {
						font-size: 13px;
						line-height: 1.7;
						color: #58626b;
					}

					&.description {
						.section-title {
							margin-bottom: 25px;
						}
					}
				}
				.label {
					background-color: #fff;
					color: #3d4b60;
					font-weight: 400;
					border: 2px solid #d0d5d9;
					font-size: 12px;
					font-style: normal;
					text-transform: capitalize;
					&.must_have {
						height: 34px;
						padding: 4px 13px;
						.must_have_star {
							width: 17px;
							top: 2px;
						}
					}
				}

				& + .col-md-3 {
					padding-left: 0;
				}
			}

			.btn-outline {
				color: #58626b;
				border: 2px solid #58626b;
				background: none;
				font-size: 12px;
				padding: 11px 25px;
				.icon-arrow-left {
					float: left;
					line-height: 16px;
					font-weight: 700;
					color: #9ea0a3;
					font-size: 22px;
					margin-right: 20px;
				}

				&:hover,
				&.active,
				&:focus {
					background-color: #58626b;
					color: white;
					.icon-arrow-left {
						color: white;
					}
				}
			}

			.bottom-actions,
			.top-actions {
				.btn-outline {
					width: 200px;
				}
			}

			.top-actions {
				margin-bottom: 35px;
			}

			.sidebar {
				overflow: hidden;
				margin-bottom: 30px;
				width: 100%;
				left: 0;
				box-shadow: 0 0 6.4px 0.1px rgba(218, 224, 242, 0.25);
				z-index: 5;
				.placeholder {
					width: 70%;
				}
				.section {
					border-radius: 4px;
					background-color: #fff;
					padding: 25px 10px;
					padding-bottom: 30px;
					border: 1px solid #e4e7ee;
				}

				.section-status {
					padding-bottom: 15px;
					padding-top: 15px;
					margin-bottom: -1px;
					span:first-child {
						font-size: 12.5px;
						font-weight: 600;
						text-transform: uppercase;
						color: #58626b;
						margin-right: 15px;
					}
				}
				.complete-profile {
					background-color: #ff6849;
					color: #fff;
					padding: 40px 40px 15px 20px;
					margin-bottom: 6px;
					line-height: 30px;
					font-weight: 300;
					a {
						color: #fff;
						text-decoration: underline;
						font-size: inherit;
					}
					h5 {
						color: #fff;
						margin-bottom: 30px;
					}
				}
				.btn {
					font-weight: bold;
					margin-bottom: 30px;
					&[disabled] {
						background-color: #d2d7db;
					}
					& + .btn {
						margin-left: 0;
					}
					&.btn-proposal {
						line-height: 1 !important;
					}
				}
				h5 {
					font-weight: 500;
					margin-bottom: 10px;
					text-transform: uppercase;
					font-size: 13px;
				}
				.divider {
					height: 4px;
					width: 50px;
					background-color: #f0f1f4;
					margin: 20px 0;
				}

				.btn-proposal {
					font-size: 12.3px;
					margin-bottom: 30px;
					&.btn-proposal-disabled {
						background-color: #d2d7db;
						color: white;
						cursor: not-allowed;
					}
				}
			}
			.label {
				&.label-primary {
					background-color: #ff6849;
					border: none;
				}
			}
			.total {
				margin-bottom: 30px;
				text-align: left;
				h5 {
					color: #58626b;
					text-transform: uppercase;
					display: inline;
				}
				.label {
					display: inline;
					margin-left: 15px;
				}
			}
			.stats {
				text-align: left;
				ul {
					list-style: none;
					padding-left: 0;
					li {
						display: inline-block;
						margin-left: 30px;
						.title {
							text-transform: uppercase;
							font-weight: 500 !important;
							font-size: 15px;
						}
						.value {
							color: #3d4b60;
							font-size: 25px;
						}
						&:first-child {
							margin-left: 0;
						}
					}
				}
			}

			.job-info {
				padding: 0 !important;
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					li {
						clear: both;
						padding: 15px 20px;
						border-top: 1px solid #e4e7ee;
						overflow: hidden;
						i {
							color: #ff6849;
							font-size: 20px;
							float: left;
							padding: 9px;
							border: 1px solid #ff6849;
							border-radius: 50%;
							margin-right: 17px;
						}
						h5 {
							font-size: 13px;
							font-weight: 500;
							line-height: 1.7;
							color: #969a9e;
							margin-bottom: 5px;
							text-transform: uppercase;
						}
						p {
							font-weight: 400 !important;
							line-height: 15px !important;
							margin: 0 !important;
							color: #394047 !important;
							font-size: 13px !important;
						}

						.avatar {
							width: 40px;
							height: 40px;
							float: left;
							margin-right: 17px;
							img {
								display: block;
								width: 100%;
								height: 100%;
							}
						}

						&:first-child {
							border-top: 0;
						}
					}
				}
			}

			.placeholder {
				width: 30%;
				display: inline-block;
				position: relative;
				top: 3px;
				&:before {
					content: "";
					height: 5px;
					width: 94%;
					background-color: #f1f2f5;
					border-radius: 10px;
					display: block;
				}
				&:after {
					content: "";
					height: 5px;
					width: 54%;
					background-color: #f1f2f5;
					border-radius: 10px;
					display: block;
					margin-top: 3px;
				}
			}
			.button-link {
				color: #ff6849;
				background: none;
				border: none;
				padding: 0 !important;
				font: inherit;
				cursor: pointer;
				outline: none;
				text-decoration: none;
			}
		}
	}

	.seniority {
		display: inline-block;
		color: #4eb2d7;
		text-transform: uppercase;
		border: 2px solid #4eb2d7;
		font-weight: 500;
		font-size: 11px;
		line-height: 1;
		padding: 3px 7px;
		border-radius: 4px;
	}

	.formFieldStyle {
		.form-group {
			position: relative;
			.intl-tel-input {
				&.allow-dropdown.separate-dial-code .selected-flag {
					background-color: transparent;
				}
				&.allow-dropdown .flag-container {
					left: 7px;
					top: 26px;
				}
				> input {
					height: 64px;
					padding-top: 40px;
				}
			}
			> label {
				position: absolute;
				z-index: 9;
				left: 15px;
				top: 10px;
				text-transform: none;
				line-height: inherit;
			}
			.input-box {
				input {
					padding-top: 36px;
					height: 64px;
				}
			}
		}
	}
	.job-view {
		.droppable-holder .file-container .fake_file .placeholder-btn {
			padding-left: 20px;
		}
		.droppable-holder .file-container {
			border: dashed 1px #c4cfde;
			height: 63px;
			.fake_file {
				padding-top: 15px;
			}
		}
	}
}

.questionnairePoKeView * {
	font-family: "BasierCircle" !important;
	font-size: 14px !important;
}
