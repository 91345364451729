$primary-color: #00857a;

div.card {
	width: 50vw;
}

.cardsList {
	grid-row: 2/3;
	grid-column: 2/3;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.nextButtonContainer {
	display: flex;
	justify-content: flex-end;
}

.stepper {
	height: 32px;
	grid-column: 2/3;
	grid-row: 1/2;
	margin: 50px auto 60px;
}

.skillsSkeletonContainer,
.languagesSkeletonContainer {
	display: flex;
	gap: 7px;
}

.containedIcon {
	fill: $primary-color;
	path {
		stroke: $primary-color;
	}
}

.grid2Columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 30px;
	width: 100%;
}
