.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 21px;
	border-bottom: 1px solid #e2e8f0;
	.title {
		margin: 0;
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
	.closeBtn {
		display: flex;
		width: 36px;
		height: 36px;
		padding: 8px;
		justify-content: center;
		align-items: center;
		gap: 8px;

		border-radius: 8px;
		border: 1px solid #fff;
		background: #f8fafc;
	}
}

.body {
	padding: 20px 20px 10px 20px;
	flex-grow: 1;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.footer {
	position: unset;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 16px 13px;
	background: #fff;
	border-top: 1px solid #e2e8f0;
	.rightActions {
		display: flex;
		gap: 10px;
	}
	.btn {
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		padding: 8px 16px;
		border-radius: 8px;
		height: 40px;
		&.saveAsLoggedBtn {
			background: #fff;
			color: #00857a;
			&:hover {
				background: #00857a25;
			}
		}
		&.outlined {
			color: #334155;
			border: 1px solid #e2e8f0;
			box-shadow: none;
		}
		&.borderless {
			border: none;
		}
	}
}
