.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--4, 16px);
	align-self: stretch;

	.divider {
		height: 1px;
		align-self: stretch;
		background: var(--border-gray, #e2e8f0);
	}

	.card {
		padding: var(--4, 16px);
		align-self: stretch;
		border-radius: var(--lg, 12px);
		border: 1px solid var(--border-gray, #e2e8f0);
		background: var(--text-white, #fff);
		width: 100%;

		.content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: var(--2, 8px);
			align-self: stretch;

			.item {
				display: flex;
				height: 35px;
				align-items: center;
				align-self: stretch;

				.labelHead {
					display: flex;
					width: 180px;
					align-items: center;
					gap: var(--3, 12px);

					.label {
						color: var(--text-dark, #334155);
						font-variant-numeric: lining-nums tabular-nums;
						font-family: "Work Sans";
						font-size: 16px;
						font-weight: 500;
						line-height: 150%;
					}
				}

				.value {
					flex: 1 0 0;
					color: var(--text-dark, #1e293b);
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					display: flex;
					gap: var(--2, 8px);
				}

				&__message {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					gap: var(--3, 12px);
					flex: 1 0 0;

					.labelHead {
						width: unset;
					}
				}

				.viewMore {
					color: var(--border-brand, #00857a);
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
				}
			}

			&_item {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: var(--3, 12px);
				flex: 1;

				&_labelHead {
					display: flex;
					align-items: center;
					gap: var(--2, 8px);

					svg {
						width: 16px !important;
						height: 20px !important;
					}

					&_label {
						color: var(--text-dark, #334155);
						font-variant-numeric: lining-nums tabular-nums;
						font-family: "Work Sans";
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 150%;
						text-wrap: nowrap;
					}
				}

				&_value {
					color: var(--text-dark, #334155);
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}
			}

			.files {
				display: flex;
				align-items: flex-start;
				gap: var(--2, 8px);
				align-self: stretch;
				flex-wrap: wrap;

				.file {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					align-self: stretch;
					padding: var(--2, 8px);
					gap: 10px;
					flex: 1;
					border-radius: var(--md, 8px);
					border: 1px solid var(--border-gray, #e2e8f0);
					background: var(--text-white, #fff);
					max-width: 32%;
					min-width: 32%;
					cursor: pointer;

					&:hover,
					&:focus {
						border-color: var(--border-brand, #00857a);
						box-shadow: 0px 0px 0px 2px rgba(0, 133, 122, 0.2);
					}

					span {
						color: var(--text-dark, #1e293b);
						font-family: "Work Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
						text-overflow: ellipsis;
						overflow: hidden;
						max-height: 24px;
						margin-right: auto;
					}

					svg {
						flex: none;
					}
				}
			}
		}
	}
}
