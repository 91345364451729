$font-family: "Work Sans";

h2.container {
	/* Header/H3 */

	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 23.04px;
	line-height: 24px;
	/* identical to box height, or 104% */

	display: flex;
	align-items: center;
	letter-spacing: -0.5px;

	/* gray/600 */

	color: #4b5563;
}
