.container {
	display: flex;
	align-items: center;
	gap: 5px;
}

.date {
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
