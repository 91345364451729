.input_container {
	padding-bottom: 1.5rem;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 1.5rem;
}
.viewAllContainer {
	display: flex;
	padding: 12px 10.5px;
	justify-content: space-between;
	align-items: center;
	justify-content: center;
	align-self: stretch;
	color: #00857a;
	gap: 8px;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	border-top: 1px solid #e2e8f0;
	background: transparent;
	width: 100%;
}
