// ** card container
.container {
	gap: 40px;
	display: flex;
	width: 100%;
	padding: 20px;
	height: 784px;
	border-radius: 12px;
	align-items: center;
	flex-direction: column;
	background-color: white;
	border: 1px solid #f1f5f9;
}

// ** card header
.header {
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	justify-content: space-between;
	border-bottom: 1px solid #f1f5f9;

	h3 {
		margin: 0;
		font-size: 22px;
		color: #2a3644;
		font-weight: 500;
		font-style: normal;
		line-height: 150%;
		font-family: "Work Sans";
		letter-spacing: -0.5px;
	}

	.badge {
		gap: 10px;
		display: flex;
		padding: 8px 16px;
		border-radius: 8px;
		align-items: center;
		background: #fff8f4;
		justify-content: center;
		border: 1px solid #ea4c08;

		font-size: 16px;
		color: #ea4c08;
		font-weight: 500;
		line-height: 21px;
		font-style: normal;
		font-family: "Work Sans";

		&Success {
			color: #119588;
			background: white;
			border: 1px solid #119588;
		}
	}
}

// ** card body
.body {
	gap: 40px;
	width: 776px;
	display: flex;
	flex-direction: column;
}
.banner {
	width: 100%;
	display: flex;
	padding: 14px;
	align-items: center;
	border-radius: 12px;
	background: #f8fafc;
	border: 1px solid #e2e8f0;

	gap: 12px;
	font-size: 16px;
	font-weight: 400;
	color: #334155;
	letter-spacing: 0.5px;
	font-family: "Work Sans";
	letter-spacing: -0.5px;
}
.stepsContainer {
	gap: 8px;
	display: flex;
	flex-direction: column;
}

.btn {
	height: 37px;
	display: flex;
	min-width: 154px;
	padding: 8px 32px;
	border-radius: 8px;
	align-items: center;
	justify-content: center;

	font-weight: 600;
	line-height: 21px;
	font-style: normal;
	font-size: 15.75px;
	font-family: "Work Sans";
	letter-spacing: -0.5px;

	&:focus-visible {
		outline: none !important;
	}

	&Success {
		color: white;
		background-color: #119588;
		&:hover {
			background-color: #006a62;
		}
	}

	&Link {
		color: #64748b;
		border: 1px solid #eceff3;
		background-color: transparent;
		&:hover {
			background-color: #f9fafb;
		}
	}
}
