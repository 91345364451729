.content {
	display: flex;
	padding: 30px 40px;
	flex-direction: column;
	align-items: center;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.icon {
	margin-top: 10px;
	margin-bottom: 40px;
}

.title {
	width: 554px;
	color: #334155;
	text-align: center;
	font-family: "Work Sans";
	font-size: 20px;
	font-weight: 700;
	line-height: 150%;
	margin-bottom: 10px;
}

.description {
	width: 554px;
	color: #64748b;
	text-align: center;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 32px;
}

.btn {
	cursor: pointer;
	height: 40px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-decoration: none;
	svg {
		path {
			stroke: #1e293b !important;
		}
		height: 20px;
		width: 20px;
		margin-right: 8px;
	}
	margin-bottom: 8px;
	&:hover {
		color: #1e293b;
	}
}
