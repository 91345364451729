.blockCta {
	gap: 6px;
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #e5e5e5;
	padding-top: 20px;
	button {
		padding: 0.75rem 1rem;
		border-radius: 5px;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		background: transparent;
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		&.savelBtn {
			background: #00857a;
			color: #ffffff;
			border: 1px solid #00857a;

			&:hover {
				background-color: #006a62;
			}
		}

		&.cancelBtn {
			&:hover {
				background: #f5f5f5;
			}
		}
	}
}
