.container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.body {
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.header {
	flex-shrink: 0;
	border-bottom: 1px solid #e2e8f0;
	height: 78px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	.title {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
	.closeButton {
		all: unset;
		cursor: pointer;
		display: flex;
		width: 36px;
		height: 36px;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		background: #f8fafc;
		color: #334155;
		svg {
			width: 14px;
			height: 14px;
		}
		&:hover {
			background: #eef4fa;
		}
	}
}

.footer {
	flex-shrink: 0;
	border-top: 1px solid #e2e8f0;
	height: 78px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	.group {
		display: flex;
		gap: 10px;
	}
	button {
		height: 40px;
	}
	.saveAsLoggedBtn {
		background: #fff;
		color: #00857a;
		&:hover {
			background: #00857a25;
		}
	}
	.backBtn,
	.cancelBtn {
		box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
		background: #fff;
		color: #334155;
		&:hover {
			background: #e2e8f0;
		}
	}
	&.disabled {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			inset: 0;
			background: #fff;
			opacity: 0.3;
			cursor: not-allowed;
		}
	}
}

.modalFooter {
	display: flex;
	gap: 10px;
	align-items: stretch;
	button {
		height: 100%;
	}
}

.discardButton {
	background: #fff;
	color: #d92d20;
	box-shadow: 0px 0px 0px 1px #d92d20 inset;
	&:hover {
		background: #d92d2033;
	}
}

.cancelButton {
	background: #fff;
	color: #334155;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
	&:hover {
		background: #e2e8f0;
	}
}
