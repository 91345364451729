.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	width: 100%;
}
.icon {
	width: 32px;
	height: 32px;
}
.loaderContainer {
	width: 100%;
	height: 330px;
	display: flex;
	align-items: center;
	justify-content: center;
}
