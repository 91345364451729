$font-family: "Work Sans";

.languagesList {
	display: flex;
	flex-wrap: wrap;
	row-gap: 12px;
	column-gap: 14px;
}

.languageTag {
	font-family: $font-family;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 21px;
	gap: 7px;
	border-radius: 8px;
	height: 44px;
	background: #f7f9fa;
}
