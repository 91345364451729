$font-family: "Work Sans";
$primary-color: #00857a;

div.dialogContent {
	min-width: 1050px;
}

.contentContainer {
	display: grid;
	grid-template-columns: min-content 1fr 1fr 1fr 1fr min-content;
	grid-auto-rows: min-content;
	align-items: center;
	gap: 10px;
	width: 100%;
}

.modalTitle {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 23.04px;
	line-height: 24px;
	align-items: center;
	letter-spacing: -0.5px;
	color: #171717;
	margin-bottom: 12px;
}

.addFunctionButton {
	color: $primary-color;
	line-height: 16px;
}

.addButton {
	padding: 14px;
}

.addButton:hover {
	background-color: #fafafa;
}

.addFunctionButtonContainer {
	margin-top: 10px;
	grid-column: -1 / 1;
}

.label {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 21px;
	display: flex;
	align-items: center;
	letter-spacing: -0.5px;
	color: #1f2937;
}

.saveButtonDisabled {
	cursor: not-allowed;
}

.modalFooter {
	display: grid;
	grid-template-columns: 1fr max-content max-content;
	align-items: center;
}

.infoMessageContainer {
	display: flex;
	align-items: center;
	gap: 7px;
	grid-column: -1 / 1;
	margin-top: 10px;
}

.infoMessage {
	font-family: $font-family;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #6b7280;
}
