@import "modules/SearchCandidate/TableVariables/fonts.scss";

.pill {
	@include SmallParagraph;
	font-weight: 500;
	padding: 4px 7px;
	background: #ffffff;
	border-radius: 50px;
	display: flex;
	align-items: center;
}

.icon {
	margin-left: 0.5rem;
}
