@import "modules/SearchCandidate/TableVariables/fonts.scss";

.results_count {
	@include SmallParagraph;
	color: #737373 !important;
	font-weight: 400;
	line-height: 1.125rem;
	padding: 0;
	.greenText {
		color: #00857a;
		font-weight: 500;
	}
	margin: 0.75rem 0 0.75rem 0.25rem;
}
