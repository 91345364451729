.hr {
	width: 100%;
	color: #e2e8f0;
}

.evaluationCandidate {
	background: #f8fafc;
	margin: 0px 15px;
}

.displayNone {
	display: none !important;
}

.marginTop {
	margin-top: 14px;
}
