@import "../../../../Variables/styles.scss";

.emailErrorContainer {
	display: grid;
	grid-template-columns: max-content auto;
	grid-template-rows: auto;
	grid-column-gap: 15px;
	grid-row-gap: 5px;
	position: absolute;
	top: 4.5rem;
	@include font;
	color: $criticalText;
	align-items: center;

	.primaryError {
		@include font;
		color: $criticalText;
		font-weight: 500;
	}

	.mergeError,
	.errorAddToPool,
	.errorCandidateInPool {
		grid-area: 2 / 2 / 3 / 3;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #202223;

		.link {
			color: #41848c;
			text-decoration: underline;
			cursor: pointer;
			display: inline;
		}
	}
}

.phoneContainer {
	.phoneInput {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 1.25;
		height: 36px;
		width: 100%;
		color: #202223;
		border: 1px solid #e2e2e2;
		padding-left: 48px !important;
	}

	.phoneInput:hover {
		outline: none;
		border: 1px solid $jade;
		color: $blackPearl;
	}

	.phoneInput:disabled {
		opacity: 1;
		box-shadow: none;
		background: #fafafb;
		cursor: default;
	}

	[class^="flag-dropdown"],
	[class^="flag-dropdown"]:hover {
		background-color: rgba(0, 0, 0, 0.05) !important;
		background: rgba(0, 0, 0, 0.05) !important;
		border-color: #e2e2e2;
		border: none;
	}

	[class^="selected-flag"] {
		background: none !important;
	}
}

.radioButtonLabel {
	margin-bottom: auto;
	font-weight: normal;
	cursor: pointer;
}
