.container {
	display: flex;
	padding: 0px 15px;
}

.subContainer {
	display: flex;
	flex-direction: column;
	gap: 80px;
}

.panelContainer {
	padding-top: 52px;
}
