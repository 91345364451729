.actionButton {
	all: unset;
	margin-inline-start: 4px;
	width: 36px;
	height: 36px;
	color: #334155;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 8px;
	&:hover {
		background: #e9f0fa;
	}
}
