.form {
	padding: 20px;
	width: 65%;
	.formTitle {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-bottom: 20px;
	}
	.candidateInfos {
		display: flex;
		padding: 10px;
		gap: 10px;
		align-items: center;
		border-radius: 8px;
		background: #f8fafc;
		.candidateName {
			color: #334155;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
		.candidatePosition {
			color: #64748b;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
	}

	.fields {
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin-top: 32px;
	}

	.label {
		color: #6b7280;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 12px;
	}
}

.calendarContainer {
	border-radius: 8px;
	border: 1px solid var(--border-gray, #e2e8f0);
	padding: 16px;
	display: flex;
	gap: 26px;
	& > div {
		width: 50%;
	}
	.calendar {
		padding: 0;
		margin-top: 8px;
	}
	.slotsHeader {
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 10px;
	}
	.slotItem {
		.slotDateValue {
			color: #64748b;
			font-family: "Work Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			margin-bottom: 6px;
		}
		.timeSlotSeparator {
			color: #000;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
		& > div {
			display: flex;
			gap: 12px;
			align-items: center;
		}
		.addTimeSlotBtn {
			all: unset;
			cursor: pointer;
			margin-left: auto;
			display: flex;
			padding: 4px;
			align-items: center;
			gap: 10px;
			border-radius: 3px;
			background: #f1f5f9;
			&:hover {
				background: #dce0e4;
			}
		}
	}
}

.locationPicker {
	padding: 16px;
	border-radius: 6px;
	background: #f8fafc;
}

.sidebar {
	padding: 16px;
	width: 35%;
}

.timesList {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.noteContainer {
	margin: 5px 0px 20px 0px;
}

.textarea {
	resize: none;
	width: 100%;
	display: flex;
	height: 100px;
	padding: 10.5px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	background: #fff;
	&::placeholder {
		color: #a3a3a3;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}

.textarea:focus {
	border: 1px solid #66b6af;
}

.error {
	padding-top: 10px;
}

.phoneIcon {
	height: 19px;
	width: 19px;
	path {
		stroke-width: 1.5;
		stroke: #64748b;
	}
}

div.phoneContainer {
	[class^="flag-dropdown"] {
		border-radius: 8px 0px 0px 8px;
	}

	input[type="tel"] {
		height: 44px !important;
		font-size: 16px !important;
		font-style: normal !important;
		font-weight: 400 !important;
		line-height: 150% !important;
		color: #334155 !important;
	}
}

.cellClassName {
	margin-bottom: 4px;
	box-shadow: none;
}
