.root {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2rem;

	h4 {
		margin-bottom: 2px;
	}
	ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		background-color: #f5f5f7;
		padding: 1rem;
		border-radius: 5px;
		li {
			font-size: 13px;
			display: grid;
			grid-template-columns: 150px 1fr;
		}
	}
}
