.dateWrraper {
	display: flex;
	align-items: center;
}

.calendarDate {
	display: flex;
	align-items: center;

	> span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		color: #202223;
	}
}
