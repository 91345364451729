.assignedByContainer {
	padding: 0;
	display: flex;
	align-items: center;

	.title {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: hsl(200, 5%, 11%);
	}
	.detail {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		color: #48494a;
		margin: 0 0 5px 0;
	}
}
.userContainer {
	display: flex;
	align-items: center;
	.title {
		display: block;
		margin-bottom: 0;
	}
}
.separator {
	background: #00ba88;
	height: 18px;
	width: 2px;
	border-radius: 4px;
	margin: 0 10px;
}
.avatar {
	margin-right: 9px;
	img {
		width: 32px;
		height: 32px;
	}
}
