.ulMenu {
	background: #fff;
	padding: 0.25rem 0;
	list-style: "none";
	min-width: 12rem;
	margin: inherit;
	border-radius: 8px;
}
.listMenu {
	color: #d4d4d4;
	padding: 0.5rem 0.75rem;
	outline: none;
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	font-size: 1rem;
	align-items: center;

	&:hover {
		color: #525252;
	}
}
.headerItem {
	margin-left: 0.5rem;
	font-weight: 400;
	line-height: 1.125rem;
	font-family: "Work Sans", sans-serif;
	color: #262626;
}

.licontent {
	margin-left: 15px;
	font-weight: 500;
}
.root {
	border-radius: 3px 3px 3px 3px;
	display: flex;
	align-items: center;
}
.inputStyleColumn {
	width: 12rem;
	overflow: hidden;
	border-radius: 3px;
	background: #eeeff1;
}
.icon {
	right: 10px;
	position: absolute;
}

.input {
	border-color: transparent;
	width: 100%;
	outline: none;
	color: #262626;
	font-size: 1rem;
	line-height: 1.125rem;
	padding: 0.5rem 2rem 0.5rem 0.75rem;
	font-weight: 400;
	min-width: 12rem;

	&::placeholder {
		color: #a3a3a3;
		font-size: 1rem;
	}
}

.dotsHorizontal {
	all: unset;
	display: flex;
	align-items: center;
	padding: 0.5rem;
}

.popperContainer {
	background: #ffffff;
	border: 1px solid #e5e5e5;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	min-width: 204px;
}

.quickView {
	background: transparent;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
		svg path {
			stroke: #737373;
		}
	}
}

.selectIconsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.headerItem {
	margin-left: 0.5rem;
	font-weight: 400;
	line-height: 1.125rem;
	font-family: "Work Sans", sans-serif;
	color: #262626;
}
