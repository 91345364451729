.container {
	background-color: #f8f9fa;
}
.dashbordBox {
	background: #ffffff;
	padding-top: 30px;
	margin-bottom: 30px;
	padding-bottom: 30px;
}

.listTasks {
	min-height: 555px;
	margin-right: -15px;
	margin-left: -15px;
}
.title {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #48494a;
	line-break: anywhere;
}
.linkTo {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #fd5749;
	&:hover {
		text-decoration: underline;
	}
}
.linkToText {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 1;
	text-transform: capitalize;
	text-decoration: none;
}
.button {
	padding: 0;
	background: transparent;
	border: unset;
	span {
		text-transform: capitalize;
	}
}
.linkToButton {
	@extend .button;
}
.paginationContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-right: -15px;
	margin-left: -15px;
	.paginationText {
		display: flex;
		align-items: center;
		width: 100%;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: #48494a;
	}
}

.filterBox {
	background: white;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	padding: 10px;
	margin-right: -15px;
	margin-left: -15px;
}
