.similarJobsContainer {
	gap: 32px;
	display: flex;
	margin: 0px 20px;
	margin-top: 20px;
	padding-top: 20px;
	flex-direction: column;
	border-top: 1px solid #e2e8f0;
}
.similarJobsHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	h3 {
		margin: 0;
		font-size: 20px;
		color: #334155;
		font-weight: 600;
		line-height: 28px;
		font-style: normal;
		font-family: Roboto;
	}
	a {
		font-size: 14px;
		font-weight: 500;
		color: #334155;
		line-height: 20px;
		font-style: normal;
		text-decoration: none;
		font-family: "Work Sans";
	}
}
.similarJobsGrid {
	gap: 16px;
	display: grid;
	// padding: 0 16px;
	grid-template-columns: 1fr;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
