.error {
	color: #e8634a !important;
	height: auto;
	line-height: 16px;
	min-height: 31px;
	padding: 5px 0 10px 0;
	text-transform: none;
	white-space: normal !important;
	clear: both;
	display: flex;
	align-items: flex-start;
	> svg {
		width: 11px;
		height: 11px;
		margin-right: 5px;
	}
}
