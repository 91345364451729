.root {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 1rem;
}

.categorySelect {
	grid-column: span 5 / 5;
}

.search {
	grid-column: span 8 / span 8;
	> input {
		width: 100%;
		min-height: unset !important;
		max-height: 35px;
		border-color: #ccc;
	}
}

.categorySkills {
	grid-column: span 9 / span 9;
	background-color: #eff0f3;
	padding: 1rem;
	border-radius: 8px;
	max-height: 350px;
	overflow-y: auto;
	p {
		font-family: BasierCircle, sans-serif;
		font-size: 14px;
		margin: 1rem;
		font-weight: 600;
	}
	button {
		border: none;
		font-family: BasierCircle, sans-serif;
		background-color: hsl(225deg 14% 85%);
		padding: 3px 6px;
		border-radius: 5px;
		margin: 3px;
	}
}

.cart {
	grid-column: span 4 / span 4;
	background-color: #eff0f3;
	padding: 1rem;
	border-radius: 8px;
	font-family: BasierCircle, sans-serif;
	max-height: 350px;
	overflow-y: auto;
	label {
		font-size: 15px;
	}
	ul {
		list-style: none;
		padding-left: 10px;
	}
	button {
		border: none;
		background-color: transparent;
	}
	.skillItem {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
