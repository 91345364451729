.reactRater {
	position: relative;
	display: inline-block;
	line-height: normal;
	> * {
		display: inline-block;
	}
}

.input {
	opacity: 0;
	position: absolute;
	width: 100%;
}

.tooltipContainer {
	visibility: hidden;
}
