.root {
	font-family: "basiercircle";
	h5 {
		font-style: normal;
		font-size: 13px;
		line-height: 24px;
		color: #8c9196;
		margin-bottom: 2rem;
		text-transform: uppercase;
	}
	.box {
		display: flex;
		align-items: center;
		gap: 5rem;
		h4 {
			font-size: 13px;
			color: #8c9196;
			margin: 0;
			text-transform: uppercase;
			margin-bottom: 8px;
		}
		p {
			font-size: 14px;
			color: #202223;
		}
	}
}
