@import "src/modules/consultancy/shared/shared-components";

$gap: 1.5rem;

.root {
	display: flex;
	flex-direction: column;
	gap: $gap;
	padding: 2rem;
	* {
		font-family: BasierCircle, sans-serif;
	}
	.backButton {
		@extend .linkButton;
		align-self: flex-start;
		display: flex;
		align-items: center;
		gap: 1rem;
	}
}

.heading {
	display: flex;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;

	.contactsBlock {
		justify-content: space-between;
		display: flex;
		.blockCta {
			margin-left: 90px;
			button {
				font-family: "basiercircle";
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 16px;
				display: flex;
				align-items: center;
				text-align: center;
				color: #fd5749;
				background-color: #fff;
				border: 1px solid #fd5749;
				svg {
					margin-right: 6px;
					path {
						fill: #fd5749;
					}
				}
			}
		}
	}

	.companyInfo {
		display: flex;
		align-items: center;
		gap: $gap;
		h3 {
			color: #202223;
			font-size: 15px;
			font-weight: 600;
			margin-bottom: 0;
		}
		img {
			border-radius: 10px;
		}
		span {
			color: #fd5749;
		}
		.info {
			display: flex;
			flex-direction: column;
		}
	}
	.contacts {
		display: flex;
		gap: 20px;
		list-style: none;
		padding: 0;
		margin: 0;
		h4 {
			color: #5c5f62;
			font-weight: 600;
			font-size: 14px;
			text-transform: uppercase;
			font-size: 12px;
			margin-bottom: 4px;
		}
		span {
			color: #fd5749;
			font-size: 12px;
			font-family: "BasierCircle";
			margin-bottom: 6px;
			display: flex;
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			gap: 5px;
			flex-direction: column;
			li {
				display: flex;
				align-items: center;
				gap: 10px;
				font-size: 12px;
				color: #202223;

				svg {
					path {
						stroke: rgba(186, 190, 195, 1);
					}
				}
			}
		}
	}
}

.content {
	display: grid;
	grid-template-columns: 4fr 2fr;
	grid-gap: $gap;
	.container {
		display: flex;
		flex-direction: column;
		gap: $gap;
		.group {
			margin-bottom: 4rem;
			> h4 {
				color: #8c9196;
				font-weight: 600;
				font-size: 14px;
			}
			> div {
				display: flex;
				gap: 1rem;
				flex-wrap: wrap;
			}
		}
	}
}

.billingGrid {
	display: grid;
	grid-template-columns: 20% 1fr 1fr;
	grid-gap: $gap;
	width: 100%;
	h4 {
		margin-top: 1.5rem;
		color: #8c9196;
		font-weight: 600;
		font-size: 14px;
	}
}

.sidebar {
	background-color: white;
	width: 100%;
	gap: 4rem !important;
	h4 {
		color: #202223;
		font-size: 16px;
		font-weight: 600;
	}
}

.contactsItem {
	border: 1px solid #e1e3e5;
	box-sizing: border-box;
	padding: 10px 20px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
}
