.wrapper {
	display: flex;
	padding: 10px;
	align-items: center;

	min-width: 0;
	width: 100%;
	border-bottom: 1px solid #e2e8f0;
	cursor: pointer;
	text-decoration: none;
	text-align: start;
	background: #f1f5f9;
	border-radius: 0;
	&.unread {
		background: #fff;
	}
	&.selected {
		background: #e6f3f2;
	}
	&:hover {
		box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
			0px 1px 2px 0px rgba(16, 24, 40, 0.06);
	}
}

.userInfo {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-right: 40px;
}

.to {
	color: #000;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.draft {
	color: #d92d20;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-right: 30px;
	text-transform: capitalize;
}

.username {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	text-transform: capitalize;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 175px;
}

.proficiency {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 175px;
}

.emailInfo {
	flex-grow: 1;
	min-width: 0;
}

.subject {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 95%;
	.countReplies {
		width: 16px;
		height: 18px;
		margin-left: 10px;
		color: #64748b;
		font-family: "Work Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		padding: 0px 4px;
		border-radius: 3px;
		background: #f8fafc;
	}
}

.emailText {
	color: #888;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 95%;
}

.actions {
	margin-left: auto;
}

.checkbox {
	width: auto;
	margin-right: 20px;
}
