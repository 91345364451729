@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

.detailsInterviewCalendar {
	padding: 0;
}

.times {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	.title {
		display: flex !important;
		align-items: center !important;
		gap: 5px;
		color: #334155 !important;
		font-family: "Work Sans";
		font-size: 12.472px !important;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
	.slots {
		flex-grow: 1;
		overflow-y: auto;

		.desciption {
			color: #64748b;
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 10.69px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			margin-block: 3px;
			border-radius: 99px;
			background: #f4f4f433;
		}

		&::-webkit-scrollbar-thumb {
			width: 100%;
			background: #cacaca;
			border-radius: 99px;
		}
	}
}

.slot {
	min-width: 100px;
	display: flex;
	padding: 4px 10px;
	justify-content: center;
	align-items: center;
	gap: 8.908px;
	border-radius: 3.563px;
	background: #f1f5f9;
	margin-top: 8px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 12.472px;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 150% !important;
	&.selected {
		color: #00857a;
		background: #e6f3f2;
	}
}

.detailsInfo {
	@include font;
	color: #202223;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.timeSlots {
	display: flex;
	gap: 6px;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.date {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 8px;
}

.time {
	display: flex;
	padding: 4px 10px;
	justify-content: center;
	align-items: center;
	gap: 10px;

	border-radius: 4px;
	background: #f1f5f9;

	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.infosContainer {
	margin: 32px 0;
}

.dateAndLocationContainer {
	display: flex;
	& > div:first-child {
		width: 60%;
	}
	& > div:last-child {
		width: 40%;
	}
}

.attendeesContainer {
	margin-top: 24px;
	border-radius: 10px;
	background: #f8fafc;
	padding: 16px;
}

.descriptionSection {
	margin-top: 24px;
}

.sectionTitle {
	display: flex;
	align-items: center;
	gap: 6px;
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 14px;
}
