.drawerContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.drawerHeaderContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 20px 12px 20px;
	border-bottom: 1px solid #e2e8f0;
}

.drawerHeaderTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.drawerHeaderIconWrapper {
	display: flex;
	width: 36px;
	height: 36px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #fff;
	background: #f8fafc;
}

.drawerBodyContainer {
	gap: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	height: 100%;
}

.divider {
	width: 100%;
	height: 1px;
	background: #e2e8f0;
}

.drawerFooterContainer {
	gap: 12px;
	display: flex;
	width: 100%;
	padding: 12px 20px;
}

.backBtn {
	display: flex;
	padding: var(--2, 6px);
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: var(--md, 8px);
	border: 1px solid var(--bg-white, #fff);
	background: var(--bg-hover, #f8fafc);

	&:hover {
		background: #f1f5f9;
	}
}

.titleContainer {
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	justify-content: space-between;
	border-bottom: 1px solid #e2e8f0;
	padding: 16px 23px;

	.flexContainer {
		gap: 12px;
		display: flex;
		align-items: center;
	}

	.title {
		font-size: 24px;
		font-weight: 500;
		color: #334155;
		line-height: 24px;
		font-style: normal;
		font-family: Work Sans;
		letter-spacing: -0.5px;
	}

	.closeBtn {
		padding: 8px;
		display: flex;
		outline: none;
		border-radius: 8px;
		align-items: center;
		background: white;
		border: 1px solid #e2e8f0;

		&:hover {
			background: #f8fafc;
		}
	}
}

.parcelStyle {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	background: #f8fafc;
	height: 100%;
	flex-grow: 1;
	padding-top: 20px;
}
