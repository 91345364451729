.container {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 32px;
	border-radius: 6px;
	background: #f8fafc;
	overflow-y: auto;
	flex-grow: 1;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}
