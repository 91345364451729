@import "modules/candidate/Profile/Variables/styles.scss";
@import "modules/SearchCandidate/TableVariables/fonts.scss";

.container {
	display: flex;
	gap: 8px;
	.infosLayout {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}
	.textGras {
		.textParag {
			@include profileContent;
			color: $blackTune;
			padding-bottom: 10px;
		}
		button {
			all: unset;
			@include profileContent;
			color: $tomato;
			cursor: pointer;
			margin-left: 5px;
		}
	}
	.title {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		.userName {
			@include userName;
			color: $swamp;
		}
		p {
			margin: 0;
			@include profileContent;
			color: $raven;
		}
		svg {
			circle {
				fill: $raven;
			}
		}
	}

	.linkedTo {
		display: flex;
		flex-direction: row;
		gap: 5px;
		// padding: 2px 8px;
		width: max-content;
		padding: 0;
		align-items: baseline;
		p {
			@include profileContent;
			color: $raven;
		}
		button,
		span {
			@include profileContent;
			background-color: transparent;
			color: $atoll;
			border: none;
			box-shadow: none;
			padding: 0;
		}
	}
	.date {
		align-items: baseline !important;
	}
}
.notesContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	.paginationContainer {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		.pagination {
			display: flex;
			flex-direction: column;
			.text {
				@include userName;
				color: $tomato;
			}
			span {
				@include paginationText;
				color: $blackPearl;
			}
		}
		.addNote {
			@include profileContent;
			height: 30px;
			display: flex;
			align-items: center;
			background: $button;
			box-shadow: $box-shadow;
			border-radius: 4px;
			color: $white;
			margin-right: 30px;
		}
	}
}

.section__empty__note {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	height: 100%;
}
.drawer__empty__note {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.btnView {
	display: flex;
	flex-direction: column;
	.section_see_all {
		display: flex;
		justify-content: center;
		.btn_see_all {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			padding: 0.5rem 0.75rem;
			margin-bottom: 0.5rem;
			background: none;
			border: none;
			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
	.devider {
		padding-top: 1.5rem;
	}
}

.backButton {
	display: flex;
	align-items: center;
	gap: 9px;
	background-color: #fff;
	color: #525252;
	border: 1px solid #e5e5e5;
	@include SmallParagraph;
	border-radius: 3px;
	margin: 1.2rem 0rem 0 1rem;
}
