.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 385px;
	height: 100%;
	margin: auto;
	.title {
		margin-bottom: 8px;
		color: #334155;

		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
	.description {
		color: #64748b;
		text-align: center;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}
