.modalContainer {
	.headerModal {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e2e8f0;
		align-items: center;
		padding: 20px;
		h3 {
			color: #334155;
			font-family: "Work Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 33px;
			letter-spacing: -0.5px;
			margin-bottom: 0;
		}
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
	}
	.contenuModal {
		padding: 20px;
		border-bottom: 1px solid #e2e8f0;
		.title {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 0;
		}
		.text {
			color: #64748b;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
		}
		p {
			color: #334155;
			font-family: "Work Sans";
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 27px;
			strong {
				font-weight: 600;
			}
		}
	}
}

.footer {
	display: flex;
	padding: 16px;
	align-items: center;
	gap: 8px;
	background-color: white;
	justify-content: flex-end;
}

.footer .submitBtn {
	padding: 0px 24px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;

	display: flex;
	align-items: center;
	gap: 12px;
	> span {
		color: #fff;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	&:hover {
		background-color: #006a62;
	}
}

.footer .cancelBtn {
	padding: 0px 16px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background: #f8fafc;
	}
}
