@import "./_flex-helpers.scss";

.capitalize {
	text-transform: capitalize;
}
.col_2 {
	width: 50%;
}
.root {
	padding: 30px;
	min-height: calc(100% - 62px);

	button {
		padding: 8px 16px;
		border: none;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		border-radius: 4px;
	}

	.header_drawer {
		margin-bottom: 40px;
		@extend .flexSpaceBetween;
		.title_drawer {
			font-family: "basiercircle";
			font-weight: 600;
			font-size: 24px;
			line-height: 20px;
			color: #181819;
			margin-bottom: 0;
			display: flex;
			align-items: center;
		}
		button {
			background: transparent;
		}
	}
	h3 {
		margin-bottom: 10px;
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #181819;
	}
	p {
		font-family: "basiercircle";
		font-size: 14px;
		line-height: 18px;
		color: #202223;
	}
	.assigned_block {
		@extend .flexSpaceBetween;
		> div:first-child {
			margin-right: 56px;
		}
	}
	.date_priority {
		margin: 40px 0;
		border-top: 1px solid #edeeef;
		border-bottom: 1px solid #edeeef;
		padding: 40px 0;
		.date_reminder {
			@extend .flexSpaceBetween;
			margin-bottom: 22px;
		}
		.date_block {
			border-left: 2px solid #00ba88;
			padding-left: 8px;
			display: flex;
			align-items: center;
			span {
				font-family: "basiercircle";
				font-size: 14px;
				line-height: 16px;
				color: #8c9196;
				display: flex;
				align-items: center;
				margin-right: 12px;
				> svg {
					margin-right: 6px;
				}
			}
		}
		.priority_status {
			> div {
				display: inline-block;
				text-align: center;
				line-height: 25px;
			}
		}
	}
	.block_status {
		@extend .flexSpaceBetween;
		.checkbox_completed_task {
			display: flex;
			align-items: center;
			margin-bottom: 21px;
			margin-top: 20px;
			input {
				margin: 0;
				margin-right: 6px;
				width: 18px;
				height: 18px;
				cursor: pointer;
				accent-color: #bdc1cc;
			}
			label {
				margin: 0;
				font-family: "basiercircle";
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				color: #181819;
				cursor: pointer;
			}
		}
	}
	.block_link_to {
		@extend .flexSpaceBetween;
		p {
			margin-bottom: 0;
			&::first-letter {
				text-transform: capitalize;
			}
		}
		button {
			padding: 0;
			background: transparent;
		}
	}
}
.footer_drawer {
	@extend .flexEnd;
	height: 62px;
	bottom: 0;
	padding: 14px 18px;
	gap: 5px;
	.reminderButton {
		position: relative;
	}
	.buttonFooterDrawer {
		@extend button;
		border: 1px solid #babfc3;
		background-color: #fff;
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 14px;
		color: #202223;
		display: flex;
		align-items: center;
		> svg {
			margin-right: 6px;
		}
	}
	.reminderContainer {
		> div {
			top: inherit;
			bottom: 42px;
			right: 0;
		}
	}
}
.loaderContainer {
	margin-left: 20px;
	> div {
		margin-bottom: 0;
	}
	span {
		width: 25px;
		height: 25px;
	}
}
.loader {
	width: 153px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		margin-bottom: 0;
		padding: 5px 0;
		> span {
			width: 20px;
			height: 20px;
			border: 2px solid rgba(0, 0, 0, 0);
			border-top-color: #ff6849;
		}
	}
}

.linkToButton {
	padding: 0;
	background: transparent;
	border: unset;
	span {
		text-transform: capitalize;
	}
}

.linkTo {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #fd5749;
	&:hover {
		text-decoration: underline;
	}
}
.linkToText {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 1;
	text-transform: capitalize;
	text-decoration: none;
}
