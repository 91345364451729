@import "../shared/shared-components";

.form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	label {
		color: #8c9196;
		font-size: 14px;
	}
}

.submitButton {
	@extend .button;
}
