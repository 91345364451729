.pagination {
	display: flex;
	align-items: center;
	gap: var(--2, 8px);

	button {
		display: flex;
		padding: var(--2, 8px);
		align-items: center;
		gap: var(--3, 12px);
		border-radius: var(--md, 8px);
		border: 1px solid var(--gray-200, #e2e8f0);
		background: var(--white, #fff);

		&:hover {
			border: 1px solid var(--border-gray, #e2e8f0);
			background: var(--bg-hover, #f8fafc);
		}

		&:focus {
			border: 1px solid var(--border-gray, #e2e8f0);
			box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
		}
		&:disabled {
			border: 1px solid var(--border-gray, #e2e8f0);
			background: var(--bg-disabled, #f1f5f9);
		}

		.rotate {
			transform: rotate(180deg);
		}
	}
}
