.search__input {
	margin-right: 15px;
	input {
		width: 300px;
		height: 40px;
		box-shadow: none;
		border-radius: 8px;
		background: #ffffff;
		border: 1px solid #e2e8f0;

		font-size: 16px;
		color: #1f2937;
		font-weight: 400;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";

		&::placeholder {
			color: #94a3b8;
		}

		&:focus {
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
				0px 1px 2px rgba(0, 0, 0, 0.06);
		}
	}
	&_icon {
		top: 0;
		right: 0;
		bottom: 0;
		width: 44px;
		display: flex;
		color: white;
		position: absolute;
		align-items: center;
		justify-content: center;
		&:hover.icon_close path {
			stroke: #334155;
		}
		&:hover.icon_close {
			cursor: pointer;
		}
	}
}
