.tooltip {
	.container {
		word-break: normal;
	}

	.content {
		padding: var(--3, 12px);

		.functions {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: var(--2, 8px);
			font-family: "Work Sans";
			font-size: 14px;
			font-style: normal;
			line-height: 150%;
			color: var(--white, #fff);

			h5 {
				color: var(--white, #fff);
				font-weight: 600;
			}

			ul {
				font-weight: 400;
				padding: 0 var(--3, 12px);
			}
		}
	}
}
