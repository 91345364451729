.cardsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}
.flex {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding: 10px 0;
}
.overviewFont {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 28px;
	line-height: 32px;
}
.count {
	color: #fd5749;
}
.type {
	color: #270a69;
	text-transform: uppercase;
}
.loaderContainer {
	width: 100%;
	height: 260px;
	display: flex;
	align-items: center;
	justify-content: center;
}
