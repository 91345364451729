@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}

.header {
	padding: 18px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	@include font;
	color: #f01c29;
}

.quitButton {
	padding: 0;
	background: white;
}

.subHeader {
	@include font;
	font-size: 24px;
	line-height: 30px;
	padding-bottom: 8px;
	margin: 20px 18px 8px 24px;
	border-bottom: 1px solid #e1e3e5;
	margin-bottom: 41px;
}

.isBlur {
	filter: blur(1px);
}

.inputContainer {
	padding: 0px 24px;
	margin: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.buttonsContainer {
	margin-left: 24px;
	display: flex;
	align-items: center;
	gap: 10px;

	.addButton {
		color: white;
		background-color: #f01c29;
		font-weight: 500;
		font-family: "basiercircle";
	}

	.cancelButton {
		background: #ffffff;
		box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
		border-radius: 4px;
		border: 1px solid #babfc3;
		font-family: "basiercircle";
		color: #202223;
	}
}

.loaderContainer {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 9999;
}
