.containerDate {
	position: relative;
	&:before {
		content: "";
		height: 1px;
		width: 100%;
		margin: auto;
		position: absolute;
		background: #e1e3e5;
		top: 0;
		bottom: 0;
	}
}
.date {
	display: flex;
	gap: 7px;
	margin-bottom: 6px;
	align-items: center;
	background-color: #fff;
	z-index: 1;
	width: max-content;
	position: relative;
	padding-right: 10px;
	span {
		display: flex;
		line-height: 1;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: #8c9196;
	}
}
