$primary-color: #00857a;

.root {
	height: 100%;
}

.greyCard {
	border-radius: 12px;
	padding: 32px;
	align-self: stretch;
	background-color: #f7f8fa;
}

.container {
	background-color: white;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 12px;
	margin: 16px 0px;
	padding: 24px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	border-radius: 20px;
}

.requestName {
	color: #262626;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.5px;
}

.grid {
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	grid-template-rows: auto auto;
	border-top: 1px solid #e2e8f0;
	border-bottom: 1px solid #e2e8f0;
	padding: 24px 0px;
}

.companyName,
.country,
.availability {
	color: "64748b";
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
}

.sendButton {
	width: 100%;
}

.button {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 8.5px 1rem;
	cursor: auto;
}

.button > div {
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.BuildingIcon,
.companyName,
.addressIcon,
.country,
.clockIcon,
.availability {
	justify-self: center;
}

.message {
	color: #262626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 25.6px */
}

.addRequestButtonText {
	color: $primary-color;
}

button.addRequestButton {
	padding: 14px;
}

button.addRequestButton:hover {
	background-color: #fafafa;
}

.requestContainer {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 16px;
}

.requestLabel {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.logo {
	border-radius: 26px;
	width: 54px;
}

.inputRequest {
	height: 38px;
}

.inputRequest input[type="text"] {
	font-size: 14px;
}

.description * {
	overflow: hidden;
	color: var(--neutral-800, #262626);
	text-overflow: ellipsis;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.descriptionhtml * {
	color: var(--neutral-800, #262626);
	font-family: "Work Sans";
	font-size: 14px;
	line-height: 160%;
}

.descriptionhtml {
	display: -webkit-box;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
