@import "../../Variables/styles.scss";

.tooltip {
	left: 125px;
	width: 250px;
}
.title {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #48494a;
	line-break: anywhere;
}
.avatar {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}
.radio_input {
	display: flex;
	position: relative;
	justify-content: flex-start;
	input[type="radio"] {
		opacity: 0;
		width: 20px;
		position: absolute;
		height: 20px;
		cursor: pointer;
		&:checked + .radio_control {
			background: radial-gradient(#fd6950 30%, rgba(255, 0, 0, 0) 40%);
		}
	}
	.radio_control {
		display: block;
		width: 20px;
		height: 20px;
		background: #d9dbe9;
		border-radius: 50%;
		border: 1px solid #d9dbe9;
		box-sizing: border-box;
	}
}
.container {
	@include tabsPadding;
}
.filtersContainer {
	background: white;
	padding: 10px 10px 24px 10px;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}
.uploadSection {
	display: flex;
	justify-content: space-between;
	margin-bottom: 17px;
	align-items: flex-start;
	button {
		background: white;
		display: flex;
		align-items: center;
		border: 1px solid #babfc3;
		padding: 6px 13px;
		gap: 5px;
		border-radius: 4px;
	}
	span {
		font-family: "basiercircle";
		font-size: 16px;
		color: #303131;
	}
}
.paginationContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 3.2rem;
	> div {
		display: flex;
		align-items: center;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: #48494a;
	}
	ul {
		justify-content: end;
	}
}
.paginationContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
	.paginationText {
		display: flex;
		align-items: center;
		width: 100%;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: #48494a;
	}
}

.avatar {
	border-radius: 50%;
}
