$primary-color: #00857a;
$font-family: "Work Sans";

div.radioButtonContainer {
	display: grid;
	grid-template-columns: repeat(3, 33%);
	gap: 0px;
}

.radioButtonContainer span:before {
	margin-right: 8px !important;
}

input[type="text"].daysPerWeekInput {
	padding: 14px;
}

input[type="text"].daysPerWeekInput {
	padding: 13px;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0px;
}

.daysInOfficeLabel {
	font-family: $font-family;
	font-weight: 400;
	font-size: 13px;
	line-height: 12px;
	border: 1px solid #e5e5e5;
	display: flex;
	border-radius: 8px;
	align-items: center;
	background-color: #fafafa;

	color: #1f2937;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-left-width: 0.5px;
	padding: 14px;
}

.daysPerWeekContainer {
	display: grid;
	grid-template-columns: 50px max-content;
}

.currency {
	border-radius: 8px;
	margin-bottom: 0px !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0px;
}

.container .currency input {
	border-radius: 8px !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0px !important;
	padding: 13px !important;
	border: 1px solid #e5e5e5 !important;
	line-height: 16px !important;
}

.hourlyRateContainer {
	display: grid;
	grid-template-columns: 100px max-content;
}

.container input.timePicker {
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	padding: 16px;
	color: #1f2937;
	text-align: right;
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	width: 54.008px;
	min-height: 44px;
}

.container input.timePicker:focus {
	border: 1px solid $primary-color;
	box-shadow: none;
}

.allowSubmissionContainer {
	display: flex;
	gap: 5px;
	align-items: center;
}

.allowSubmissionText {
	color: #1f2937;
	text-align: right;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.allowSubmissionCheckbox {
	width: fit-content !important;
}

.rateFieldsContainer {
	display: flex;
	width: 100%;
	gap: 20px;
}

.currencyFieldContainer {
	width: 40%;
}
