.container {
	display: flex;
	padding: 10px 16px;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #f8fafc;
	width: auto;
}

.textContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.text {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.buttonContainer {
	display: flex;
	align-items: flex-start;
}

.closeButton {
	display: flex;
	padding: 6px;
	align-items: center;
	height: 32px;
	width: 32px;
	gap: 10px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
}

.bulb {
	// margin-top: 5px;
}
