$font-family: "Work Sans";

.languagesList {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	border-radius: 8px;
	opacity: 1;
	background: #f8fafc;
	padding: 14px;
}

.languageTag {
	font-family: $font-family;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 8px;
	text-transform: capitalize;
	height: 35px;
	padding: 6px 8px;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #e2e8f0;
	opacity: 1;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
