@import "modules/candidate/Profile/Variables/styles.scss";
@import "modules/consultancy/shared/shared-components.scss";

.btnAdd {
	display: flex;
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	span {
		@include profileContent;
		color: $swamp;
		font-weight: 500;
		margin-left: 6px;
	}
}

.dialogFooter {
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	margin-top: 2rem;
	position: absolute;
	bottom: 13px;
	right: 25px;
	.mainButton {
		@extend .button;
		text-transform: capitalize;
	}
	.cancelButton {
		@extend .secondaryButton;
	}
}
.dialogClose {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 15px;
	font-weight: 500;
	background-color: white;
	border: none;
	border-radius: 999px;
	width: 24px;
	height: 24px;
	line-height: 1;
	display: grid;
	justify-content: center;
	align-content: center;
	&:hover {
		background-color: rgb(237, 237, 237);
	}
	&:focus {
		box-shadow: 0 0 0 2px rgb(219, 219, 219);
	}
}
.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.botIcon {
	margin: 0 0 0 7px;
}
