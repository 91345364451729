.wrapper {
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	padding: 20px;
	margin-top: 10px;
}
.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
}
.row {
	display: flex;
	align-items: flex-start;
	width: 100%;
	&.divider {
		padding-top: 32px;
		border-top: 1px solid #e2e8f0;
	}
}
.section {
	flex-grow: 2;
	&.col {
		width: 50%;
	}
	.title {
		display: flex;
		align-items: center;
		margin-bottom: 6px;
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		svg {
			margin-right: 6px;
		}
	}
}

.description {
	display: block;
	margin-bottom: 6px;
	color: #64748b;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.section:first-child {
	flex-grow: 1;
}

.subtitle {
	color: #64748b;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 20px;
}

.checks {
	display: flex;
	flex-direction: column;
}

.checkContainer {
	display: flex;
	align-items: center;
	margin-bottom: 6px;
	.check {
		width: fit-content;
	}
	.text {
		cursor: pointer;
		display: block;
		margin: 0;
		margin-left: 10px;
		color: #64748b;
		font-size: 14px;
		font-weight: 400;
		line-height: 150%;
	}
}

.reminderContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.reminderField {
	display: flex;
	gap: 3px;
	max-width: 235px;
}

.reminderField {
	display: flex;
	gap: 8px;
	max-height: 40px;
}

.reminderInput {
	border-radius: 8px !important;
	max-width: 76px;
	padding: 12px 14px !important;

	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	&.error {
		border: 1px solid #d92d20 !important;
	}
}

.select {
	background: white;
	border-radius: 8px;
	height: 40px;
	max-width: 150px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: -0.5px;
}

.option {
	display: flex;
	height: 36px;
	padding: 10px 12px;
	align-items: center;
	&:hover {
		background-color: #e6f3f2 !important;
	}
}

.optionLabel {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}
