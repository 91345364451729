.container {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
	.inputContainer {
		display: flex;
		position: relative;
		width: 100%;
		.multiInput {
			font-family: "Work Sans";
			font-weight: 16px;
			line-height: 16px;
			letter-spacing: -0.5px;
			min-height: 44px !important;
			max-height: 44px !important;
			width: 100%;
			border-radius: 8px;
			color: #334155;
			padding: 10px !important;
			&::placeholder {
				color: #94a3b8;
			}
			&:focus {
				border: 1px solid #00857a;
			}
		}
		.xButton {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			border: none;
			background: transparent;
			cursor: pointer;
			width: 20px;
			height: 20px;
			border-radius: 8px;
		}
	}
}

.buttonAdd {
	width: 44px;
	height: 44px;
	background-color: #ffffff;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
}
.marginBottom {
	margin-bottom: 15px;
}
