.labelContainer {
	display: flex;
}

.breacrumbsContainer {
	display: flex;
	flex-direction: column;
	justify-content: 5px;
	gap: 14px;
	width: 100%;
}

.breadcrumbWithMain {
	grid-template-columns: 1fr max-content;
}

.breadcrumbNotMain {
	grid-template-columns: 1fr;
}

.breadcrumbWithMain,
.breadcrumbNotMain {
	width: 100%;
	display: grid;
	align-items: center;
	column-gap: 16.57px;
}
