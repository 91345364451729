.container {
	display: flex;
	flex-direction: column;
	gap: 12px;
	height: 100%;
}

.row {
	width: 100%;
	&.editor {
		flex-grow: 1;
	}
}
.bodyClassName {
	height: 100% !important;
}
.label {
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	display: flex;
	align-items: center;
	gap: 6px;
	margin-bottom: 12px;
}

.rowIcon {
	path {
		stroke: #6b7280;
	}
}

.banner {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	display: flex;
	padding: 12px;
	gap: 10px;

	border-radius: 8px;
	border: 1px solid #dbeef7;
	background: #eff8ff;
}

.newMailContainer {
	height: 100%;
	display: flex;
	overflow: hidden;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.body {
	flex-grow: 1;
	padding: 10px;
	background-color: #fff;
}

.placeholdersAndTemplates {
	display: flex;
	flex-direction: column;
	min-width: 258px;
	max-width: 258px;
	border-left: 1px solid #e2e8f0;
	border-radius: 0;
	&.hide {
		display: none;
	}
	.header {
		background: #334155;
	}
}

.confirmButtonClassName {
	background: #d92d20;
	box-shadow: none;
	&:hover {
		background: #d92d20;
	}
	svg {
		path {
			stroke: #fff;
		}
	}
}
.headerButton {
	background: transparent;
	padding: 0;
	width: 1.125rem;
	height: 1.125rem;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #262626;
	padding: 10px;
}

.title {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;
	background-color: transparent;
	padding: 0;
	color: #fff;
	text-transform: capitalize;
}

.icon {
	width: 1.125rem;
	height: 1.125rem;
	path {
		stroke: #fff;
		width: 1.125rem;
		height: 1.125rem;
	}
}
