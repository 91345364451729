.close-icon {
	width: 20px;
	height: 27px;
	position: absolute;
	left: 85%;
	top: 22%;
	transition: display 0.5s;
	cursor: pointer;
	display: none;
}
.set-display path {
	fill: #6b6b6b;
}

.set-display {
	display: block;
}
