.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: none;
}

.headerLeft {
	display: flex;
	align-items: center;
	gap: 12px;
}

.toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.headerTitle {
	color: #334155;

	font-family: "Work Sans";
	font-size: 15.75px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	margin: 0;
}

.tooltip {
	position: relative;
	border-radius: 8px;
	padding: 3px;
	background-color: #1f2937 !important;
	max-width: 485px;
}

.tooltipContent {
	color: #fff;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 0;
}

.infoCircle {
	outline: none;
}
