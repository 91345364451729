.container {
	display: flex;
	min-width: 330px;
	overflow: hidden;
	align-items: center;
	border-radius: 12px;
	flex-direction: column;
	background-color: #f8fafc;
}

.stageHeaderContainer {
	width: 100%;
	display: flex;
	min-height: 57px;
	max-height: 57px;
	padding: 12px 10px;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e2e8f0;

	&:hover {
		.dragDots path {
			stroke: rgba(0, 0, 0, 0.5);
		}
	}

	.stageTitle {
		gap: 8px;
		display: flex;
		font-size: 16px;
		font-weight: 500;
		color: #334155;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";

		&Bid {
			text-transform: capitalize;
		}

		.stageCardCount {
			height: 24px;
			display: flex;
			color: #334155;
			min-width: 24px;
			font-size: 12px;
			padding: 0px 6px;
			line-height: 100%;
			align-items: center;
			border-radius: 99px;
			background: #f1f5f9;
			justify-content: center;
		}

		.dragDotsActive path {
			stroke: rgba(0, 0, 0, 0.4);
		}
	}
}

.cardsContainer {
	width: 100%;
	.scrollbarContainer {
		display: flex;
		padding: 10px;
		flex-direction: column;
	}
}

.emptyStageContainer {
	width: 100%;
	height: 500px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 150%;
		font-style: normal;
		letter-spacing: -0.5px;
		font-family: "Work Sans";
		color: var(--text-dark, #334155);
		font-variant-numeric: lining-nums tabular-nums;
	}
}

.cardDotted {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CBD5E1FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	background-color: #f7f9fb;
	border-radius: 12px;
	position: absolute;
}
