@import "src/modules/consultancy/shared/shared-components";

.actions {
	display: flex;
	gap: 1rem;
	width: 100%;
	justify-content: flex-end;
	min-height: 29px;
	align-items: center;
	.save {
		@extend .button;
		padding: 4px 8px;
		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
	.cancel {
		@extend .linkButton;
		box-shadow: none;
		padding: 4px 8px;
		&:hover {
			text-decoration: none;
			background-color: lighten(#fd5749, 32%);
		}
	}
}

.body {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
	h4 {
		margin-bottom: 2rem;
	}
	p {
		font-weight: 400;
		font-size: 13px;
		color: #505050;
		white-space: pre-wrap;
	}
	textarea {
		width: 100%;
		font-size: 13px;
		resize: vertical;
		max-height: 150px;
	}
	.info {
		display: flex;
		gap: 1rem;
		align-items: center;
		background-color: #ebf9fc;
		border: 1px solid #98c6cd;
		border-radius: 10px;
		padding: 1rem;
		width: fit-content;
		color: #00a0ac;
	}
	input {
		@extend .input;
	}
	div {
		margin-bottom: 0;
	}
	input[type="tel"] {
		padding-left: 50px !important;
	}
}
