@import "modules/SearchCandidate/TableVariables/fonts.scss";

.emptyState {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 70px;
	.textGras {
		@include MediumParagraph;
		font-weight: 500;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		color: #262626;
	}
	.textNormal {
		@include SmallParagraph;
		font-weight: 400;
		color: #a3a3a3;
	}
	.btnClear {
		all: unset;
		margin-top: 0.5rem;
		cursor: pointer;
		text-decoration: underline;
		@include SmallParagraph;
		font-weight: 500;
		color: #1a9187;
	}
}
