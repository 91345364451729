@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.root {
	label {
		@include font;
		line-height: 20px;
		color: #6d7175;
		display: flex;
		align-items: center;
		gap: 4px;
		text-transform: capitalize;
	}
	input {
		display: block;
		padding: 5px 12px !important;
		min-height: 0 !important;
		width: 450px;
		margin-top: 4px;
	}
	.inputError {
		border-color: #ed2e7e;
	}
	.error {
		@include font;
		font-weight: normal;
		font-size: 14px;
		color: #ed2e7e;
	}
}
