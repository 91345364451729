.menu {
	display: flex;
	padding: 12px !important;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 6px !important;
	background: #fff;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
	border: none !important;
}

.menuItem {
	display: flex !important;
	align-items: center;
	gap: 10px;
	padding: 0 !important;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 150%;
	text-transform: capitalize !important;

	&Active {
		color: #00857a !important;
	}
}

.icon {
	color: #64748b;
	&Active {
		color: #00857a;
	}
}
