.container {
	padding: 16px 20px;
	.title {
		color: #334155;
		font-family: "BasierCircle";
		font-size: 23px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.5px;
		margin-bottom: 4px;

		height: 40px;
		display: flex;
		align-items: center;
		gap: 12px;

		.backBtn {
			all: unset;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			border: 1px solid #e2e8f0;
			background: #fff;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			min-width: 40px;
			height: 40px;
			color: #334155;
			&:hover {
				background: #f1f5f9;
			}
			& svg > path {
				stroke: #334155;
			}
		}
	}
}

.tabs {
	display: flex;
	gap: 16px;
	border-bottom: 2px solid #e2e8f0;
	height: 66px;
}

.tab {
	all: unset;
	position: relative;
	text-align: center;
	display: block;
	padding: 0 16px;
	text-decoration: none;
	color: #667085;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&.active {
		color: #00857a;
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #00857a;
			bottom: -2px;
			left: 0;
		}
	}
	&:hover {
		color: #222;
		cursor: pointer;
	}
}

.navigation {
	position: sticky;
	top: 70px;
	z-index: 10;
	background-color: #f7f8fa;
	margin-right: -20px;
	margin-left: -20px;
	padding-right: 20px;
	padding-left: 20px;
	margin-bottom: 14px;
}

.headerActions {
	margin-left: auto;
	display: flex;
	align-items: center;
	.saveBtn {
		height: 40px;
		border-radius: 10px;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}
