.root {
	padding: 35px 35px 0 35px;
}
.containerMyCompany {
	display: flex;
	gap: 35px;
}
.containerMyCompany > div {
	width: 50%;
}
.titlePage {
	font-family: "basierCircle";
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 24px;
	letter-spacing: -0.5px;
	color: #262626;
	margin-bottom: 21px;
}
.headerBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 14px;
	.ctaModeEdit {
		display: flex;
		gap: 14px;
		align-items: center;
		.cancelBtn {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #525252;
			border: 1px solid #d4d4d4;
			border-radius: 5px;
			background-color: #fff;
			width: inherit;
			&:hover {
				background-color: transparent;
			}
		}
	}
	h3 {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.5px;
		color: #262626;
		margin-bottom: 0;
	}
	button {
		padding: 7px 10.5px;
		border: 1px solid #66b6af;
		border-radius: 5px;
		display: flex;
		align-items: center;
		gap: 6px;
		background: transparent;
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #00857a;
		max-width: 78px;
		&:hover {
			background: #e6f3f2;
		}

		&.save {
			background: #00857a;
			color: #ffffff;
			border: 1px solid #00857a;
			&:disabled {
				background: #f1f1f1;
				color: #8c9196;
				box-shadow: none;
				border: none;
				svg {
					path {
						//fill: #A3A3A3;
						stroke: #a3a3a3;
					}
				}
			}
			&:hover {
				background: #006a62;
			}
			&.loadingBtn:disabled {
				opacity: 1;
				background: #00857a;
			}
		}
	}
}
.card {
	background: #ffffff;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 14px;
	margin-bottom: 40px;
	min-height: 60px;
	.field {
		padding: 24px 0;
		border-bottom: 1px solid #f5f5f5;
		display: flex;
		align-items: center;
		&.fieldOffices {
			padding: 16px 0;
		}
		&.avatar {
			padding: 14px 0;
			.infoLabel {
				width: 84px;
				height: 84px;
				border: 2px solid #e5e5e5;
				border-radius: 168px;
				overflow: hidden;
			}
		}
		&:last-child {
			border-bottom: 0;
		}
		.label {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #737373;
			width: 30%;
		}
		.infoLabel {
			width: 70%;
			font-family: "Work Sans";
			&.flex {
				display: flex;
				align-items: center;
			}
			&.fullWidth {
				width: 100%;
				padding-left: 10px;
			}
			.phoneNumber {
				font-family: "Work Sans";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #262626;
			}
			&.editMode {
				display: flex;
				gap: 6px;
				.editInput {
					position: relative;
					border: 1px solid #e5e5e5;
					border-radius: 3px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width: 100%;
					.deletBtn {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 10px;
						background: transparent;
						border: none;
						padding: 0;
						display: flex;
					}
				}
			}
			:global(.Select-control) {
				height: 36px;
				line-height: 36px;
				border: 1px solid #e5e5e5;
				border-radius: 3px;
				:global(.Select-arrow-zone .Select-arrow) {
					background: url(../../static/icons/chevron-down2.svg) no-repeat;
					width: 17px;
					height: 12px;
					border: 0;
				}
				:global(.Select-value) {
					line-height: 36px;
					font-family: "Work Sans";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					color: #262626;
					:global(.Select-value-label) {
						line-height: 40px;
						font-family: "Work Sans";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						color: #262626;
					}
				}
			}
			:global(.react-tel-input .form-control) {
				width: 100%;
				border: 1px solid #e5e5e5;
				border-radius: 3px;
				height: 36px;
				padding-top: 0;
				padding-bottom: 0;
				min-height: inherit;
				font-family: "Work Sans";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #262626;
				padding-left: 48px;
			}
			:global(.react-tel-input .flag-dropdown) {
				background-color: #fff;
				border: 1px solid #e5e5e5;
			}
			.adresse {
				font-family: "Work Sans";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #262626;
			}
			.textLabel {
				font-family: "Work Sans";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #262626;
				height: 36px;
				min-height: 36px;
				padding: 10px;
			}
			.inputStyle {
				background: #ffffff;
				font-family: "Work Sans";
				border: 1px solid #e5e5e5;
				border-radius: 3px;
				padding: 10px;
				width: 100%;
				height: 36px;
				min-height: 36px;
				color: #262626;
			}
		}
	}
}
.chipsContainer {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}
.industriesChip {
	padding: 7px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #262626;
	border-radius: 84px;
}
.btn {
	background: #f5f5f5;
	border-radius: 3px;
	svg {
		display: block;
	}
}
.error {
	color: #dc2626;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	margin-top: 10px;
	display: inline-block;
}
.inputStyleContainer {
	position: relative;
	.deletBtn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
		background: transparent;
		border: none;
		padding: 0;
		display: flex;
	}
}
.addBtn {
	background: #f5f5f5;
	border-radius: 3px;
	margin-top: 7px;
	margin-left: 0;
	padding: 0.75rem;

	svg {
		display: block;
	}
}

.deleteBtnContainer {
	display: flex;
	justify-content: flex-end;
}
.noOffices {
	font-family: "Work Sans", serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #262626;
	line-height: 30px;
}
.multiSitesContainer {
	display: flex;
	width: 100%;
	margin-bottom: 40px;
	justify-content: space-between;
	.tabs {
		display: flex;
		gap: 30px;
		width: 90%;
		border-bottom: 2px solid #e5e5e5;
	}
}

.siteAddress {
	position: relative;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 70%;
	padding: 0.75rem;
	font-family: "Work Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #262626;
	margin-right: 5px;
	height: 36px;
}

.inputsContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 0.5rem;
}

.industryInput:focus {
	outline: none;
	box-shadow: 0px 0px 0px 1px transparent inset;
}

.companyImage {
	border-radius: 4px;
	width: 84px;
	height: 84px;
}
