$font-family: "Work Sans";
$primary-color: #00857a;

.container {
	border-radius: 12px;
	background-color: white;
}

.closeButton {
	top: 13px;
	right: 20px;
	z-index: 100;
	cursor: pointer;
	position: absolute;
	stroke: #4b5563;

	padding: 4px;
	display: flex;
	outline: none;
	border-radius: 8px;
	align-items: center;
	background: white;
	&:hover {
		background: #f8fafc;
	}
}

div.modal {
	max-width: fit-content;
	max-height: fit-content;
}

.grid {
	display: grid;
	grid-template-rows: auto 1fr auto;
	gap: 24px;
	width: 550px;
	height: 258px;
}

.description {
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
	color: #4b5563;
}

input.input[type="email"] {
	width: 100%;
	min-height: 40px;
}

.modalContent {
	display: grid;
	grid-template-rows: min-content auto;
	row-gap: 35px;
	min-height: 200px;
}

.error {
	width: 100%;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 16px;
}

.viewProfileLink {
	color: $primary-color;
	text-decoration: underline;
	cursor: pointer;
	display: inline;
	margin-left: 3px;
}

.footer {
	margin-bottom: 0px;
}
