.videoContainer {
	width: 992px;
	height: 521px;
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	iframe {
		width: 100%;
		height: 100%;
	}
}
.modal__close {
	position: absolute;
	z-index: 9999;
	right: 0;
	top: -58px;
	cursor: pointer;
}
