.container {
	display: flex;
	padding: 20px;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;

	border-top: 1px solid #e2e8f0;
	background: #fff;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.avatarWrapper {
	&.clickable {
		cursor: pointer;
	}
}
.avatar {
	width: 36px;
	overflow: visible !important;
}
.header {
	display: flex;
	justify-content: space-between;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 4px;
	.username {
		margin: 0;
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		&.clickable {
			cursor: pointer;
		}
	}
}

.date {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.receiver {
	display: flex;
	gap: 4px;
	color: #000;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	position: relative;
	.chevronIcon {
		width: 14px;
		height: 14px;
	}
}

.dropdown {
	width: 400px;
	max-height: 300px;
	padding: 5px 10px;
	position: absolute;
	top: 39px;
	background-color: white;
	border: 1px solid #e5e7eb;
	border-radius: 8px;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06);
	overflow: auto;
	transform: translateY(-20px);
	transition: transform 0.2s, opacity 0.2s;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: 6px;
	.field {
		display: flex;
		gap: 4px;
	}
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.key,
.value {
	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.clickable {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.cta {
	background: transparent;
	padding: 0;
	display: flex;
	align-items: center;
}
.message {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.attachments {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
}

.attachementFile {
	max-width: 456px;
	display: flex;
	align-items: center;
	padding: 12px 16px;

	border-radius: 8px;
	border: 1px solid #f3f4f6;
	background: #f9fafb;
}

.fileInfo {
	display: flex;
	flex-direction: column;
	gap: 8px;

	margin-left: 15px;

	.name {
		color: #475569;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		max-width: 337px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.size {
		color: #475569;
		font-family: "Work Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
	}
}

.fileIcon {
	color: #d1d5db;
}
.uploadButtonWrapper {
	margin-left: auto;
	.uploadButton {
		cursor: pointer;
		padding: 0;
		&:hover {
			background: transparent;
		}
	}
}

:global {
	.--replyCollapseTrigger {
		all: unset;
		cursor: pointer;
		display: flex;
		height: 13px;
		align-items: center;
		border-radius: 3px;
		background: #f1f5f9;
		color: #00857a;
		margin-bottom: 10px;
		& > svg > path {
			fill: currentColor;
		}
		&:hover {
			color: #00857a;
		}
		&.--replyCollapsed {
			color: #334155;
		}
		&.--replyCollapsed + div {
			display: none;
		}
	}
}
