.container {
	margin-bottom: -70px;
}

.contentLayout {
	display: flex;
	flex-direction: column;
	background: #e2e8f0;
	min-height: calc(100vh - 70px);
	max-height: calc(100vh - 70px);
}

.header {
	color: #334155;
	font-family: "Work Sans";
	font-size: 23px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
	padding: 0 20px;
	background: #fff;
	border-bottom: 1px solid #e2e8f0;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
}

.saveBtn {
	all: unset !important;
	cursor: pointer !important;
	display: flex !important;
	padding: 0 12px !important;
	height: 36px !important;
	align-items: center !important;
	gap: 8px !important;
	border-radius: 8px !important;
	background: #00857a !important;
	color: #fff !important;
	font-family: "Work Sans" !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 13px !important;
	&:hover {
		background-color: #006a62 !important;
	}
	&:disabled {
		cursor: not-allowed !important;
		opacity: 0.6 !important;
	}
}

.content {
	flex-grow: 1;
	display: flex;
	overflow-y: auto;
}
