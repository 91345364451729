$primary-color: #00857a;
$font-family: "Work Sans";

.container {
	height: 100%;
	grid-row: 2 / 3;
	grid-column: 1 / 2;
}

.menu {
	display: flex;
	gap: 6px;
	justify-content: center;
	height: fit-content;
	position: sticky;
	top: 94px;
}

.inactifBar {
	background: #e5e7eb;
	height: 140px;
	position: relative;
}

.actifBar {
	position: absolute;
	top: 0px;
	left: 0px;
	background: $primary-color;
	transition: height 0.4s;
	transition-timing-function: ease-in-out;
}

.inactifBar,
.actifBar {
	border-radius: 31px;
	width: 7px;
}

.itemsList {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

button.greenLink {
	color: $primary-color;
}

.link {
	display: flex;
	justify-content: flex-start;
	padding-top: 5px;
	padding-bottom: 5px;
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-decoration: none;
	font-weight: 500;
	color: #9ca3af;
	background-color: transparent;
}
