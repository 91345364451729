.container {
	display: flex;
	padding: 12px;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	align-self: stretch;
	border-radius: 12px;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.3px;
	&_success {
		color: #006a62;
		border: 1px solid #e6f3f2;
		background: #f5fcfc;
	}
	&_warning {
		color: #955a00;
		border: 1px solid rgba(245, 158, 11, 0.16);
		background: #fffbeb;
	}
	&_danger {
		color: #d92d20;
		border: 1px solid #ffe7e4;
		background: #fef3f2;
	}
	&_info {
		color: #0b62a1;
		border: #eff8ff;
		background: #dbeef7;
	}
}
.content {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
}
.closeIcon {
	&:hover {
		background: transparent !important;
	}
}
.closeButton {
	border-radius: 10px;
	background: transparent !important;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 12px;
	margin-left: 12px;
	height: 100%;
}

.infoIcon {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
