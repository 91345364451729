.spinnerContainer {
	width: 100%;
	text-align: center;
}
.spinner {
	margin: auto;
	border: 2px solid #ffffff00;
	width: 1.125rem;
	height: 1.125rem;
	display: block;
	border-radius: 50%;
	border-right: 2px solid #fff;
	text-align: center;
	animation-name: spin;
	animation-duration: 900ms;
	animation-iteration-count: infinite;
	animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
