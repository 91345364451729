@import "modules/candidate/Profile/Variables/margin.scss";
@import "modules/SearchCandidate/TableVariables/fonts.scss";

.container {
	display: grid;
	column-gap: 10.5px;
	grid-template-columns: [line1] 298px [line2] max-content [line3] max-content[line4] auto [line5] max-content [line6] max-content [line7];
	align-self: center;

	.error {
		input:focus-within,
		input {
			box-shadow: 0px 0px 0px 1px red inset;
		}
	}

	div.search {
		width: 100%;

		input {
			width: 100%;
			padding: 0.75rem 2rem 0.75rem 1rem;
			flex-grow: 1;
			border-radius: $buttonsRadius;
		}

		input:focus-within {
			box-shadow: 0px 0px 0px 1px #66b6af inset;
		}
	}

	.sortIcon {
		grid-column: line5 / line6;
	}

	.addIcon {
		grid-column: line6 / line7;
	}

	.buttonText {
		font-size: 1rem;
		line-height: 1.125rem;
	}

	.recap_btn {
		min-height: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50px;
		gap: 0.25rem;
		@include SmallParagraph;
		color: #fff;
		font-weight: 500;
		padding: 0.5rem 0.75rem;
		background-color: #00857a;
	}

	.arrow {
		transition: all 200ms ease-in-out;

		&.up {
			transform: rotate(180deg);
		}
	}

	.recapButtonContainer {
		display: flex;
		align-items: center;
	}
}
