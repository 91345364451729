$font-family: "Work Sans";
$primary-color: #00857a;

.createVacancyModal {
	width: 599px;

	.modalTitle {
		font-family: $font-family;
		font-style: normal;
		font-weight: 500;
		font-size: 23.04px;
		line-height: 24px;
		align-items: center;
		letter-spacing: -0.5px;
		color: #171717;
		margin-bottom: 12px;
	}

	.modalContentContainer {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.modalFooter {
		display: grid;
		justify-content: end;
		grid-template-columns: max-content max-content;
		align-items: center;
	}

	.label {
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
	}

	textarea.prompt {
		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22.8px;
		height: 94px;
		border: 1px solid #e2e8f0;
		border-radius: 6px;
	}

	.grid {
		display: grid;
		grid-template-rows: repeat(3, max-content);
		gap: 16px;
	}

	.textTone {
		width: 160px;
	}
}

.newButton {
	display: flex;
	padding: 2px 6px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	background: linear-gradient(270deg, #00857a 0%, #24d3c4 100%);
	box-shadow: none;
	cursor: auto;
}

.newButtonText {
	color: #fff;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.titleContainer {
	display: flex;
	gap: 10px;
}

.note {
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	border-radius: 8px;
	background: #e6f3f2;
}

.noteText {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22.8px;
}

.header {
	display: flex;
	padding: 4px 0px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}

.closeBtn {
	padding: 8px;
	display: flex;
	outline: none;
	border-radius: 8px;
	align-items: center;
	background: #fff;

	&:hover {
		background: #f8fafc;
	}
}

.error {
	color: #dc2626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: -4px 4px;
	flex-grow: 1;
}
.input {
	color: #525252;
}
