.phoneContainer {
	.phoneInput[type="tel"] {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 1.25;
		min-height: 40px;
		width: 100%;
		color: #202223;
		padding-left: 48px !important;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
	}

	.phoneInput[type="tel"]:focus {
		border-color: #00857a;
		box-shadow: 0px 0px 0px 5px rgb(105 194 188 / 12%);
		&.error {
			border-color: #ff0000;
			box-shadow: 0px 0px 0px 5px rgb(255 0 0 / 12%);
		}
	}

	.phoneInput:hover {
		outline: none;
		border: 1px solid #10b373;
		color: #202223;
	}

	.phoneInput:disabled {
		opacity: 1;
		box-shadow: none;
		background: #fafafb;
		cursor: default;
	}

	[class^="flag-dropdown"],
	[class^="flag-dropdown"]:hover {
		background-color: rgba(0, 0, 0, 0.05) !important;
		background: rgba(0, 0, 0, 0.05) !important;
		border-color: #e2e2e2 !important;
		border: none !important;
	}

	[class^="selected-flag"] {
		background: none !important;
	}
}
