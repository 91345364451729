.container {
	display: flex;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;

	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #f8fafc;

	width: 100%;
	margin-top: 10px;
}
