.container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.newLabelContainer {
	display: flex;
	padding: 2px 6px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	background: linear-gradient(270deg, #00857a 0%, #24d3c4 100%);
	color: #fff;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}
.enhanceBtnContainer {
	display: flex;
	padding: 8px 12px;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 0.999px solid #e2e8f0;
	background: #fff;
	color: #00857a;
}
.textBtn {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 13.628px;
}
