$primary-color: #00857a;

.root {
	position: relative;
	color: #a3a3a3;
	overflow: hidden;
	font-size: 1rem;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-family: "Work Sans";
	line-height: 1.125rem;
	margin-right: 0px;
	height: 44px;
}

.open {
	border-color: $primary-color;
}

.opn {
	box-shadow: 0px 0px 0px 1px #66b6af inset;

	&:hover {
		background-color: #ffffff;
	}
}

.content {
	color: #262626;
}

div.disabled {
	line-height: 1.125rem;
	background-color: #f5f5f5;
	cursor: default;
	position: relative;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	color: #a3a3a3;
	overflow: hidden;
	font-size: 1rem;
	padding: 0.75rem 1rem;

	.root {
		position: relative;
		box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
		color: #a3a3a3;
		border-radius: 3px;
		overflow: hidden;
		font-size: 1rem;
		padding: 0.75rem 1rem;
		border-radius: 5px;
		font-weight: 400;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		font-family: "Work Sans";
		line-height: 1.125rem;
		gap: 0.5rem;

		&:hover {
			background-color: #fafafa;
		}
	}

	.open {
		box-shadow: 0px 0px 0px 1px #66b6af inset;

		&:hover {
			background-color: #ffffff;
		}
	}

	.content {
		color: #262626;
	}

	div.disabled {
		line-height: 1.125rem;
		background-color: #f5f5f5;
		cursor: default;
		position: relative;
		box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
		color: #a3a3a3;
		overflow: hidden;
		font-size: 1rem;
		padding: 0.75rem 1rem;
		border-radius: 5px;
		font-weight: 400;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: "Work Sans";

		&:hover {
			background-color: #f5f5f5;
		}
	}

	.value {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.button {
		width: 1.125rem;
		height: 1.125rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0px;
		background: transparent;
	}

	.iconDown {
		color: #525252;
		stroke: 1.5;
	}

	.iconTop {
		transform: rotate(180deg);
	}

	border-radius: 5px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Work Sans";

	&:hover {
		background-color: #f5f5f5;
	}
}

.button {
	width: 20%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background: transparent;
	padding: 0.75rem 14px 0.75rem 0rem;
}

.iconDown {
	color: #525252;
	stroke: 1.5;
}

.iconTop {
	transform: rotate(180deg);
}

.input[type="text"] {
	border: none;
	width: 100%;
	height: 80%;
	margin-left: 5px;
	color: #4b5563;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	min-height: auto;
	padding: 14px 0rem 14px 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&::placeholder {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;

		color: #9ca3af;
	}
}

.root:hover {
	& .input {
		background-color: #fafafa;
	}
	background-color: #fafafa;
}

.tooltipContent {
	word-break: break-word;
}
