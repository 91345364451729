@mixin breakpoint($point) {
	@if $point == desktopU {
		@media (min-width: 2000px) {
			@content;
		}
	}
	@if $point == desktopL {
		@media (min-width: 1920px) {
			@content;
		}
	} @else if $point == desktop {
		@media (min-width: 1440px) {
			@content;
		}
	} @else if $point == laptop {
		@media (min-width: 1024px) {
			@content;
		}
	}
}
