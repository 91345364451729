.container {
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
	background: white;
	position: absolute;
	z-index: 99999;
	max-height: 220px;
	z-index: 99999;
	width: 340px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.header {
	padding: 10px;
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: white;
}

.body {
	padding: 0 10px 10px 10px;
}

.item {
	height: 40px;
	display: flex;
	align-items: center;
	padding: 10px 12px;
	gap: 8px;
	border-radius: 8px;
	cursor: pointer;
	&:hover {
		background-color: #e6f3f2;
	}

	&.selected {
		background-color: #e6f3f2;
	}

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.decorator {
	text-decoration: none;
	.decoratorText {
		padding: 0 4px;
		border-radius: 5px;
		background-color: #e6f3f2;
		font-size: 14px;
		color: #00857a !important;
		text-decoration: underline !important;
		text-decoration-color: #00857a !important;
		font-weight: 600 !important;
		text-underline-offset: 2px !important;
		&.empty {
			color: #f59e0b !important;
			text-decoration-color: #f59e0b !important;
			background: #fff2d5 !important;
		}
	}
	svg {
		vertical-align: middle;
		margin-right: 2px;
	}
}
