@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #202223;
}

.spinnerContainer div {
	border-right-color: #00857a;
}

.gridLayout {
	display: flex;
	gap: 24px;
	padding: 16px;
}

.filters {
	width: 22%;
	position: sticky;
	top: 80px;
	height: fit-content;
}

.results {
	width: 77%;
}

.actionsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 25px 60px 2px 17px;
}

.backButton {
	background: #ffffff;
	box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
	border-radius: 4px;
	@include font;
}

.publishButton {
	@include font;
	border-radius: var(--md, 8px);
	border: 1px solid var(--text-brand, #00857a);
	color: #00857a;
	background: var(--text-white, #fff);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	display: flex;
	align-items: center;
	gap: 6px;
}

.actionsWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 18px;
	position: fixed;
	right: 80px;
	z-index: 20;
}

.publishDisableButton {
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--bg-disabled, #f1f5f9);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.publishEnableButton {
	border-radius: var(--md, 8px);
	border: 1px solid #e59000;
	background: var(--text-white, #fff);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #e59000;
	display: flex;
	.buttonText {
		margin-left: 8px;
	}
}
