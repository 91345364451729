$primaryColor: #00857a;

/* Accessible outline */
/* &:focus-within {outline: .125em solid $primary-color;} */

.radio-container {
	label {
		display: flex;
		cursor: pointer;
		font-weight: 500;
		position: relative;
		overflow: hidden;
		margin-bottom: 0.375em;

		input {
			position: absolute;
			left: -9999px;
			&:checked + .custom-radio {
				&:before {
					box-shadow: inset 0 0 0 0.4em $primaryColor;
				}
			}
		}
		.custom-radio {
			gap: 14px;
			display: flex;
			align-items: flex-start;
			// padding: 0.375em 0.75em 0.375em 0.375em;
			border-radius: 99em;
			transition: 0.25s ease;
			&:before {
				display: flex;
				flex-shrink: 0;
				content: "";
				background-color: #fff;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				// margin-right: 0.375em;
				transition: 0.25s ease;
				box-shadow: inset 0 0 0 0.125em #e5e7eb;
			}
		}
		.description {
			display: flex;
			margin-top: -3px;
			flex-direction: column;
			.description-title {
				display: flex;
				align-items: center;
				gap: 10px;
				font-weight: 400;
				font-size: 16px;
				color: #111827;
			}
			p {
				font-weight: 400;
				font-size: 14px;
				color: #6b7280;
			}
		}
	}
}
