.container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 16px;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
}

.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.5px;
}

.description {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
}
