ul.pagination.green-pagination {
	margin-top: 24px !important;
	margin-bottom: 0px !important;
	li a {
		width: 35px !important;
		height: 35px !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		color: #525252 !important;
	}
	li.active a,
	li.active a:hover,
	li a:hover {
		width: 35px !important;
		height: 35px !important;
		background: #00857a !important;
		border-radius: 112px !important;
		color: white !important;
	}
	li.next {
		margin-left: 10px !important;
		margin-right: 0px !important;
	}
	li.previous {
		margin-left: 0px !important;
		margin-right: 10px !important;
	}
	li.next a:hover,
	li.previous a:hover {
		width: 35px !important;
		height: 35px !important;
		background: transparent !important;
		color: #525252 !important;
	}
	li.next::after,
	li.previous::before {
		display: none !important;
	}
}

.active-chevron path {
	stroke: #525252;
}

.inactive-chevron path {
	stroke: #d4d4d4;
}

.search-loader {
	margin-bottom: 0px !important;
	span {
		width: 20px !important;
		height: 20px !important;
		border: 2px solid #ffff !important;
		border-top-color: #9ca3af !important;
	}
}
