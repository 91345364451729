.container {
}

.compoundInput {
	display: flex;
}

input[type="text"].durationInput,
.durationSelect {
	max-width: 120px;
}

input[type="text"].comboxInput,
input[type="text"].durationInput,
.durationSelectInput {
	padding: 14px;
}

input[type="text"].durationInput {
	padding: 13px;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0px;
}

.durationSelectInput {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-left-width: 0.5px;
	padding: 14px;
}

.expectedDurationErrors {
	display: grid;
	grid-template-columns: max-content;
}

.durationType {
	grid-column: 2 / 3;
}

div.radioButtonContainer {
	display: grid;
	grid-template-columns: repeat(2, 50%);
	gap: 0px;
}

div.applicantLocationInput,
.applicantLocationInputDisabled {
	margin-top: 16px;
	height: 44px;
}
