@import "src/modules/consultancy/shared/shared-components";

$gap: 1rem;

.container {
	display: flex;
	flex-direction: column;
	gap: $gap;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.companyInfo {
	display: grid;
	grid-template-columns: 1fr 7fr;
	width: 100%;
	gap: $gap;
	.avatar {
		height: 135px;
		filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.05))
			drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
	}
	.accountInfoContainer {
		display: grid;
		grid-template-columns: 3fr 2fr 3fr;
		.companyName {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding-left: 2rem;
		}
		.accountInfo {
			ul {
				list-style: none;
				margin: 0;
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
				padding: 0;
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					h4 {
						margin-right: 2rem;
					}
				}
			}
			.accountType {
				background-color: #fd5749;
				color: white;
				padding: 1px 5px;
				border-radius: 10px;
				font-size: 13px;
				text-transform: capitalize;
			}
		}
	}
}

.box {
	display: flex;
	background-color: white;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 1rem 2rem 1rem 2rem;
	h4 {
		font-size: 13px;
		color: #8c9196;
		margin: 0;
		text-transform: uppercase;
	}
	button {
		@extend .secondaryButton;
		display: flex;
		align-items: center;
		gap: 5px;
		align-self: flex-end;
		padding: 4px 8px;
	}
}
