.button {
	touch-action: manipulation;
	background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	border: none;
	color: white;
	padding: 8px 16px;
	font-family: BasierCircle, sans-serif;
	font-weight: 600;
	&:disabled {
		opacity: 0.5;
	}
	&:focus {
		outline: solid rgb(94, 176, 239) 2px;
		outline-offset: 2px;
	}
}

.secondaryButton {
	touch-action: manipulation;
	text-transform: capitalize;
	padding: 8px 16px;
	background: #ffffff;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	font-family: BasierCircle, sans-serif;
	font-weight: 600;
	border: 1px solid #babfc3;
	transition: background-color 0.25s ease-in-out;
	&:focus {
		outline: solid rgb(94, 176, 239) 2px;
		outline-offset: 2px;
	}
	&:hover {
		background-color: rgb(243, 243, 243);
	}
}

.linkButton {
	background-color: transparent;
	border: none;
	color: #fd5749;
	&:hover {
		text-decoration: underline;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
		text-decoration: none;
		user-select: none;
	}
}

.input {
	border: 1px solid #a7acb1 !important;
	padding: 5px !important;
	border-radius: 4px !important;
	min-height: unset !important;
	width: 100% !important;
	font-family: BasierCircle, sans-serif !important;
	&:focus {
		border-color: #fd5749 !important;
		box-shadow: 0 0 0 2px rgb(250, 199, 190);
	}
}

.tableStatus {
	display: flex;
	justify-content: center;
	border-radius: 10px;
	color: white;
	font-size: 13px;
	padding: 1px 15px;
	width: fit-content;
	&.accepted {
		background-color: #00a47c;
	}
	&.sent,
	&.draft {
		background-color: #ffea8a;
		color: #b98900;
	}
	&.refused {
		background-color: #fd5749;
	}
	&.created {
		@extend .refused;
		background-color: #00a47c;
	}
}
