.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	height: 566px;
	padding: 16px;
	border-radius: 16px;
	border: 1px solid #f1f5f9;
	background: #fdfeff;
}

.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
	margin-top: -10px;
}

.description {
	color: #64748b;
	text-align: center;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
