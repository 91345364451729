.container {
	border-radius: 16px;
	border: 1px solid #e2e8f0;
	display: block;
	width: 100%;
	table-layout: auto;
	border-collapse: collapse;
	display: block;
	max-height: 410px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9ca3af;
		border-radius: 12px;
	}

	&::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 48px;
	}

	thead {
		position: sticky;
		top: 0;
		background-color: #fff;
		border-bottom: 1px solid #e2e8f0;
		z-index: 1;
	}

	tbody {
		background-color: #f8fafc;
	}

	td,
	th {
		white-space: nowrap;
		width: 1%;
	}
	th {
		padding: 12px 0;
	}

	td {
		padding: 10px 0;
		// border-bottom: 1px solid #e2e8f0;
	}

	td:first-child,
	th:first-child {
		padding-left: 16px;
	}
	td:last-child,
	th:last-child {
		width: 100%;
		white-space: unset;
		padding-right: 16px;
	}

	tr {
		&:not(:last-child) {
			position: relative;
		}
		&:not(:last-child)::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 16px; /* Start the line 26px from the left */
			right: 16px; /* End the line 26px before the right */
			border-bottom: 1px solid #e2e8f0; /* Adjust color and thickness as needed */
		}
	}
}

.titles th {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}

.row {
	.colUser {
		padding-right: 50px;
	}

	.colPermission {
		padding-right: 28px;
	}

	.colClose {
		margin-left: auto;
		height: 36px;
		width: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		border: 1px solid #e2e8f0;
		background: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		svg {
			height: 20px;
			width: 20px;
			path {
				stroke: #64748b !important;
			}
		}
	}

	.select {
		height: 36px;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		width: fit-content;
		input {
			background: #fff;
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 400;
			line-height: 150%;
			cursor: pointer;
			&:hover {
				background: #fff;
			}
		}
		button {
			width: max-content;
			svg {
				width: 20px !important;
				height: 20px !important;
				stroke: #334155;
			}
		}
	}
	.pending {
		border-color: #f1f5f9 !important;
		background: #f8fafc !important;
		box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
			0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
		input {
			cursor: default !important;
			opacity: 1 !important;
			color: #cbd5e1 !important;
			background: #f8fafc !important;
		}
		button {
			opacity: 1 !important;
			svg path {
				stroke: #cbd5e1 !important;
			}
		}
	}

	.roleSelect input {
		width: 65px;
	}

	.permissionSelect input {
		width: 175px;
	}
}

.avatarImg {
	border-radius: 8px !important;
	height: 40px !important;
	width: 40px !important;
	div {
		font-size: 18px !important;
	}
}

.collaborator {
	display: flex;
	align-items: center;
	min-width: 244px;
	.info {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
	}
	.name {
		color: #334155;
		font-size: 16px;
		font-weight: 500;
		line-height: 120%;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.headline {
		color: #64748b;
		font-size: 14px;
		line-height: 120%;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.emptyState {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	margin-top: 26px;
	.icon {
		margin-bottom: 24px;
	}
	.title {
		color: #334155;
		font-size: 20px;
		font-weight: 600;
		line-height: 21px;
		letter-spacing: -0.5px;
		margin-bottom: 8px;
	}
	.description {
		color: #64748b;
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
		width: 570px;
	}
}

.pointer {
	cursor: pointer;
}

.loadingState {
	height: fit-content;
	.titles {
		display: flex;
		align-items: center;
	}
	.row {
		width: 100%;
		margin-top: 16px;
		display: flex;
		align-items: center;
	}

	.colUser {
		min-width: 170px;
		margin-right: 50px;
	}

	.colPermission {
		width: 190px;
		margin-right: 50px;
		margin-left: 12px;
	}
	.colRole {
		width: 100px;
		margin-right: 50px;
	}

	.colClose {
		margin-left: auto;
	}
}

.pendingBadge {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-radius: 6px;
	background: #e2e8f0;
	padding: 0 6px;
	height: 20px;
	margin-left: 10px;
}

.nameFlex {
	display: flex;
	align-items: center;
}
.meIndicator {
	color: #334155;
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
	margin-left: 4px;
}

.permissionsPlaceholder {
	width: 216px;
}
.rolesPlaceholder {
	width: 106px;
}
