.recapContainer {
	display: flex;

	align-items: center;
	max-height: 55px;
	padding: 8px;
	margin-top: 24px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #f1f5f9;
	.querySchemaRecap {
		display: flex;
		overflow: auto;
		padding-right: 8px;
		gap: 8px;
		.querySchemaRecapItem {
			white-space: nowrap;
		}
		scroll-padding-top: 10px;

		&::-webkit-scrollbar {
			height: 5px;
		}

		&::-webkit-scrollbar-track {
			background: #e2e8f0;
			border-radius: 5px;
			margin-left: 8px;
			margin-top: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background: #94a3b8;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
		&::-webkit-scrollbar-thumb:active {
			background: #555;
		}
	}
	.actions {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 0 8px;
		margin-left: auto;
		.divider {
			width: 1px;
			height: 39px;
			margin: 0;
			background: #cbd5e1;
		}
		.cta {
			display: flex;
			align-items: center;
			height: 36px;
			padding: 8px;
			border-radius: 8px;
			border: 1px solid #e2e8f0;
			background: #fff;

			/* Shadow/XS */
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			.iconCta {
				color: #334155;
			}
		}
	}
}
