@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css?family=Kristi&display=swap");
@font-face {
	font-family: "basiercircle";
	src: local("basiercircle"),
		url("../../static/fonts/basiercircle-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-semibold-webfont.woff")
		format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-semibolditalic-webfont.woff")
		format("woff");
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-regularitalic-webfont.woff")
		format("woff");
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-medium-webfont.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-mediumitalic-webfont.woff")
		format("woff");
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-bold-webfont.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "basiercircle";
	src: url("../../static/fonts/basiercircle-bolditalic-webfont.woff")
		format("woff");
	font-weight: 700;
	font-style: italic;
}

/* https://font-display.glitch.me/ */
