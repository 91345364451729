.step {
	width: 100%;
	padding: 16px;
	display: flex;
	cursor: pointer;
	background: #fff;
	border-radius: 12px;
	align-items: center;
	justify-content: space-between;

	// content
	&Content {
		gap: 16px;
		display: flex;
		align-items: center;
	}
	&Counter {
		width: 52px;
		display: flex;
		aspect-ratio: 1/1;
		padding: 14px 22px;
		align-items: center;
		border-radius: 30px;
		background: #f8fafc;

		font-size: 16px;
		color: #4f5a66;
		font-weight: 500;
		text-align: center;
		font-style: normal;
		line-height: 23.8px;
		font-family: "Work Sans";

		&__checked {
			color: #94a3b8;
			background: white;
		}
	}
	&Description {
		color: #4f5a66;
		font-style: normal;
		font-family: "Work Sans";

		&__checked {
			h5,
			p {
				color: #94a3b8;
			}
		}

		h5 {
			font-weight: 600;
			font-size: 17.5px;
			line-height: 24.5px;
			letter-spacing: -0.5px;
			font-family: "Work Sans";
		}
		p {
			font-weight: 400;
			line-height: 21px;
			font-size: 15.75px;
			letter-spacing: -0.5px;
		}
	}

	// actions
	&Actions {
		gap: 7px;
		display: flex;
		align-items: center;

		.restartBtn {
			background: #f1f5f9;
			border: 1px solid transparent;
			&:focus-visible {
				outline: none !important;
			}
			&:hover {
				border: 1px solid #eceff3;
			}
		}

		.checkIcon {
			background: #18b168;
			border: 1px solid transparent;
		}
	}
}

// rounded box
.roundedBox {
	gap: 10px;
	width: 45px;
	height: 43px;
	display: flex;
	padding: 12px;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
}

// gradient hover
.gradientBox {
	border-radius: 12px;
	border: 2px solid transparent;
	background: linear-gradient(white, white) padding-box,
		linear-gradient(transparent, transparent) border-box;

	&:hover {
		box-shadow: 0px 0px 0px 5px rgba(92, 206, 197, 0.18);
		background: linear-gradient(white, white) padding-box,
			linear-gradient(#24ccbf, #009387) border-box;
	}
}
