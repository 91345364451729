.usersContainer {
	background: #ffffff;
	border: 1px solid #d2d5d8;
	border-radius: 4px;
	padding: 12px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	.avatar {
		margin-right: 2px;
		border-radius: 50%;
		height: 26px;
		width: 26px;
	}
	.menuLabel {
		display: flex;
		align-items: center;
	}
	> img {
		height: 32px;
		width: 32px;
	}
	.icon {
		fill: #575959;
	}
}

.otherUsers {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f0f3f5;
	border: none;
	margin-right: 2px;
}

.addUsers {
	height: 26px;
	width: 26px;
}

.positionRelative {
	position: relative;
}
