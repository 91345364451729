.container {
	display: grid;
	align-items: center;
	gap: 12px;
	grid-template-columns: auto 1fr auto;
	width: 100%;
	background-color: #f9fafb;
	padding: 12px;
	border: 1px solid #f1f5f9;
	border-radius: 10px;
}

.grid4Columns {
	grid-template-columns: auto 1fr auto auto;
}

.text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.5px;
	color: #394858;
}

div.copyButton {
	box-shadow: 0px 0px 0px rgba(16, 24, 40, 0.05);
	border: 1px solid #eceff3;
	background-color: #ffffff;
}

.enterRoomButton div {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
}

.enterRoomButton div {
	line-height: 20px;
}
