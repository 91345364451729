.checkboxContainer {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	margin-bottom: 0px;
	&.isReadOnly {
		cursor: default;
	}
}

.checkboxContainer input[type="checkbox"] {
	display: none;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 12px;
}

.checkmark {
	position: relative;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 4px;
	transition: background-color 0.2s ease-in-out;
	border: 1px solid #e2e8f0;
	background: #fff;
}

.checkboxContainer input[type="checkbox"]:checked + .checkmark {
	background-color: #00857a;
	border-color: #00857a;
}

.checkmark:after {
	content: "";
	display: none;
}

.checkboxContainer input[type="checkbox"]:checked + .checkmark:after {
	display: block;
	position: absolute;
	content: "";
	left: 5px;
	top: 1px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
.checkboxContainer input[type="checkbox"]:disabled + .checkmark {
	cursor: default;
}

.label {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	p {
		display: inline;
	}
	.required {
		color: red;
		font-family: "BasierCircle";
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		margin-top: 8px;
	}
}

.checkboxContainer input[type="checkbox"]:checked:disabled + .checkmark {
	background-color: #cbd5e0;
	border-color: #cbd5e0;
	cursor: default;
}
.questionnaireContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
