@import "modules/SearchCandidate/TableVariables/fonts.scss";

.container {
	@include MediumParagraph;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 1.125rem;
	font-weight: 500;
	color: #262626;
	cursor: pointer;

	.arrow {
		transition: 200ms all ease-in-out;
	}

	.iconBottom {
		transform: rotate(90deg);
	}

	.count {
		color: #00857a;
	}
}
