.menuContainer {
	position: relative;
}
.opener {
	cursor: pointer;
}
.itemsContainer {
	position: absolute;
	background: #ffffff;
	box-shadow: 0px 0px 2px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 10%);
	border-radius: 8px;
	padding: 8px 0;
	right: 0;
	left: 0;
	z-index: 99;
	width: 200px;
}
.menuItem {
	padding: 10px;
	margin: 0 8px;
	cursor: pointer;
	font-family: "basiercircle";
	color: #202223;
	&:hover {
		background: #f6f6f7;
		border-radius: 4px;
	}
}
