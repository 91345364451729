@import "modules/SearchCandidate/TableVariables/fonts.scss";

.root {
	padding: 16px 15px 0 15px;
}

.requestsContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 14px;
	position: fixed;
	left: 100px;
	right: 0;
	z-index: 1000;
	top: 64px;
	background-color: #f7f8fa;
	padding: 16px 15px 0 15px;
	.first {
		display: flex;
		gap: 10px;
		align-items: baseline;
		.goBackButton {
			background-color: #f8fafc;
			cursor: pointer;
			padding: 0.75rem;
			margin: 0;

			&:hover {
				background-color: #f5f5f5;
			}

			&svg {
				display: block;
			}
		}
	}

	.headerRequests {
		display: flex;
		justify-content: space-between;
		align-items: center;
		h3 {
			color: var(--text-dark, #334155);
			/* Header/H3 */
			font-size: 23.04px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 104.167% */
			letter-spacing: -0.5px;
		}
		.btnContainer {
			display: flex;
			gap: 0.75rem;
		}
		.countContainer {
			border-radius: 18px;
			background-color: white;
			color: #00857a;
			padding: 1px 4px 1px 4px;
		}
	}
}

.requestsContent {
	margin-top: 120px;
}
.panel {
	width: 100%;
	margin-bottom: 14px;
}

.tabs {
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 0;
	padding: 0 3px;
	list-style: none;
}

.tab {
	all: unset;
	position: relative;
	text-align: center;
	display: block;
	padding: 10px 16px;
	text-decoration: none;
	@include SmallParagraph;
	color: var(--text-light, #64748b);
	font-weight: 500;
	&.active {
		color: var(--text-brand, #00857a);
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #00857a;
			bottom: -2px;
			left: 0;
		}
	}
	&:hover {
		color: var(--text-brand, #00857a);
		cursor: pointer;
	}

	.candidateCount {
		background-color: #1a9187;
		color: #ffffff;
		@include SmallParagraph;
		border-radius: 12px;
		padding: 2px 8px 2px 5px;
	}
}

.presentationSlider {
	width: 100%;
	height: 2px;
	margin-left: 3px;
	background-color: #e5e5e5;
}
