/* utilities classes */
.flex {
	display: flex;
}
.flex_col {
	display: flex;
	flex-direction: column;
}
.justify_between {
	justify-content: space-between;
}
.items_center {
	align-items: center;
}
.gap_16 {
	gap: 16px;
}
.line_clamp {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/* companies layout styles */
.alert__info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	padding: 14px;
	border-radius: 8px;
	background: white;
	border: 1px solid #e5e7eb;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

	p {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.5px;
		color: #262626;
		display: flex;
		align-items: center;
		gap: 12px;
	}

	svg {
		cursor: pointer;
		&:hover path {
			stroke: #4b5563;
		}
	}
}
.btn {
	font-size: 16px;
	font-weight: 600;
	padding: 8px 16px;
	border-radius: 8px;
	&:focus-visible {
		outline: none !important;
	}

	// without border
	&__xl {
		font-size: 20px;
		font-weight: 600;
		line-height: 20px;
		padding: 14px 32px;
	}
	&__lg {
		padding: 12px 16px;
	}
	&__green {
		color: white;
		background-color: #00857a;
		&:hover {
			background-color: #006a62;
		}
	}

	// with border
	&__success {
		color: #00857a;
		background-color: white;
		border: 1px solid #99ceca;
		&:hover {
			background-color: #fbfbfb;
		}
	}
	&__danger {
		color: #f87171;
		background-color: white;
		border: 1px solid #fecaca;
		&:hover {
			background-color: #fef2f2;
		}
	}
	&__danger_bordered {
		color: #f87171;
		background-color: white;
		border: 1px solid #fca5a5;
		&:disabled {
			color: #d1d5db;
			background: #f9fafb;
			border: 1px solid #f3f4f6;
		}
		&:hover {
			background-color: #fef2f2;
		}
	}

	&__link {
		color: #64748b;
		padding: 9px 16px;
		background-color: transparent;
		&:hover {
			background-color: #f9fafb;
		}
	}
}
.companies__count {
	font-size: 14px;
	line-height: 16px;
	color: #64748b;
	span {
		font-weight: 700;
		color: #00857a;
	}
}
.companies__container {
	gap: 24px;
	display: grid;
	grid-template-columns: 1fr;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.search__input {
	input {
		color: #1f2937;
		font-family: "Roboto";
		width: 300px;
		height: 44px;
		background: #ffffff;
		border-radius: 6px;
		font-size: 16px;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
		&:focus {
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
				0px 1px 2px rgba(0, 0, 0, 0.06);
		}
	}
	&_icon {
		top: 0;
		right: 0;
		bottom: 0;
		width: 44px;
		color: white;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover.icon_close path {
			stroke: #4b5563;
		}
		&:hover.icon_close {
			cursor: pointer;
		}
	}
}
.empty_box {
	height: 4px;
	width: 80px;
	border-radius: 64px;
	background: #e2e8f0;
}

/* company card styles  */
.card__container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #ffffff;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
	padding: 20px;
	gap: 20px;

	// header
	.card__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		.card__title {
			display: flex;
			align-items: center;
			gap: 12px;
			.card__avatar {
				width: 56px;
				height: 56px;
				border-radius: 8px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
			h3 {
				font-size: 18px;
				font-weight: 600;
				color: #1e293b;
				font-style: normal;
				max-width: 150px;
				@media (min-width: 1200px) {
					max-width: 190px;
				}
			}
		}

		.card__icons {
			display: flex;
			align-items: center;
			gap: 16px;
			svg {
				cursor: pointer;
			}
		}
	}

	// body
	.card__body {
		display: flex;
		flex-direction: column;
		gap: 11px;
		div {
			gap: 14px;
			display: flex;
			align-items: center;
			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.01em;
				color: #475569;
				.green__text {
					padding-left: 4px;
					color: #16a34a;
					font-weight: 500;
					font-size: 12px;
				}
				span {
					padding-left: 4px;
					color: #475569;
				}
			}
			svg {
				min-width: 20px;
				width: 20px;
				scale: 1.2;
			}
		}
	}

	// footer
	.card__footer {
		gap: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.links {
			&__dropdown {
				border-radius: 8px;
				width: max-content;
				background-color: white;
				border: 1px solid #e5e7eb;
				box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.03),
					0px 2px 4px -1px rgba(0, 0, 0, 0.03);
				ul {
					list-style-type: none !important;
					margin: 6px 0;
				}
				&_items {
					display: flex;
					color: #00857a;
					&:hover {
						background-color: #e6f3f2;
						font-weight: 500;
					}
					a {
						padding: 6px 10px;
					}
					a::after {
						height: 0;
						display: block;
						font-weight: 500;
						overflow: hidden;
						visibility: hidden;
						content: attr(title);
					}
				}
				&_btn {
					gap: 7px;
					display: flex;
					align-items: center;
					border-radius: 8px;
					font-weight: 600;
					font-size: 16px;
					padding: 9px 16px;
					color: #00857a;
					background-color: #e6f3f2;
					svg {
						scale: 1.3;
					}
					&:hover,
					&_active {
						color: #ffff;
						background-color: #00857a;
						svg path {
							stroke: #ffff;
						}
					}
				}
			}
		}
	}
}
