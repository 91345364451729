.item {
	background: #fff;
	padding: 14px 20px;
	border-top: 1px solid #e2e8f0;
	cursor: pointer;
	display: flex;
	gap: 6px;
	align-items: center;
	&:hover {
		background-color: #f1f5f9;
	}
	&.active {
		background-color: #f1f5f9;
	}
	min-height: 68px;
}

.content {
	flex-grow: 1;
	min-width: 0;
}

.item:nth-child(1) {
	border-color: transparent;
}

.title {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.infos {
	display: flex;
	align-items: center;
	gap: 10px;
}

.infoItem {
	display: flex;
	align-items: center;
	gap: 4px;
}

.icon {
	color: #334155;
	display: flex;
	align-items: center;
}

.label {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.actions {
	flex-shrink: 0;
	display: flex;
	gap: 4px;
}

.actionButton {
	all: unset;
	color: #334155;
	height: 36px;
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	cursor: pointer;

	display: none;

	&:hover {
		background: #f8fafc;
	}
}

.item:hover {
	.actionButton {
		display: flex;
	}
}

.categoryTag {
	color: #00857a;
	font-family: "Work Sans";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;

	display: flex;
	align-items: center;
	gap: 4px;

	border-radius: 3px;
	background: #e6f3f2;
	padding: 4px;
}
