.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26.8px;
	padding: 20px;
	padding-left: 10px;
	margin-bottom: 20px;
}
.menu {
	width: 250px;
	a {
		text-decoration: none;
	}
	li {
		padding: 4px 10px;
		color: #64748b;
		font-size: 14px;
		font-weight: 500;
		line-height: 150%;
		border-radius: 6px;
		list-style: none;
	}
	.active li {
		color: #00857a;
		background: #f8fafc;
	}
}
