.modalTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
	margin-bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.closeIcon {
	height: 19px;
	width: 19px;
}
