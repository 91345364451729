$primary-color: #00857a;
$grey-color: #9ca3af;

.stepsContainer {
	display: flex;
	align-items: center;
	margin: 50px auto 60px;
	grid-column: 2/3;
	grid-row: 1/2;
}

.line {
	// background-color: $primary-color;
	width: 200px;
	height: 3px;
}

.lineGreen {
	background-color: $primary-color;
}

.lineGrey {
	background-color: $grey-color;
}

.button {
	background-color: transparent;
	padding: 0px;
	position: relative;
	display: flex;
}

.profileLabel,
.vacancyLabel,
.questionnaireLabel {
	position: absolute;
	top: 45px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.profileLabel {
	left: -7px;
}

.vacancyLabel {
	left: -13px;
}

.questionnaireLabel {
	left: -19px;
}

.vacancyLabelGrey,
.questionnaireLabelGrey {
	color: $grey-color;
}

.profileLabel,
.vacancyLabelGreen,
.questionnaireLabelGreen {
	color: $primary-color;
}

.vacancyButton,
.profileButton,
.questionnaireButton {
	cursor: pointer;
}
