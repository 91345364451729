.container {
	display: flex;
	flex-direction: row;
	.label {
		height: 21px;
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 15.75px;
		line-height: 21px;
		color: #4b5563;
		margin-bottom: 10px;
	}

	.asterisk {
		color: red;
		font-family: "Work Sans";
		font-size: 15.75px;
	}
}
