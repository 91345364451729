.container {
	display: block;
	width: 100%;
	table-layout: auto;
	border-collapse: collapse;
	display: block;
	max-height: 310px;
	overflow-y: scroll;
	margin-top: -8px;
	padding-right: 6px;
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9ca3af;
		border-radius: 12px;
	}

	td {
		white-space: nowrap;
		width: 1%;
		padding: 8px 0;
	}

	td:last-child {
		width: 100%;
		white-space: unset;
	}
}

.row {
	.colUser {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding-right: 50px;
	}

	.colPermission {
		display: flex;
		justify-content: flex-end;
	}

	.select {
		height: 36px;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		width: fit-content;
		input {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 400;
			line-height: 150%;
			cursor: pointer;
			background: #fff !important;
			&:hover {
				background: #fff;
			}
		}
		button {
			width: max-content;
			svg {
				width: 20px !important;
				height: 20px !important;
				stroke: #334155;
			}
		}
	}

	.permissionSelect input {
		width: 160px;
	}
}

.avatarImg {
	border-radius: 8px !important;
	height: 40px !important;
	width: 40px !important;
	div {
		font-size: 18px !important;
	}
}
.collaborator {
	display: flex;
	align-items: center;
	height: 46px;

	.info {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
	}
	.name {
		color: #334155;
		font-size: 16px;
		font-weight: 500;
		line-height: 150%;
		font-family: "Work Sans";
	}
	.headline {
		color: #64748b;
		font-size: 14px;
		line-height: 150%;
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		font-family: "Work Sans";
	}
}
