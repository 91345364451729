.autoComplete {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-left: 5px;
	width: 100%;
	overflow: auto;
	position: relative;
	&::-webkit-scrollbar {
		position: absolute;
		top: 10px;
		height: 6px;
		width: auto;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		background: #cacaca;
		border-radius: 99px;
	}
}

.inputClassName {
	padding: 0;
	margin-left: 8px;
	border-bottom: none !important;
}

.inputWrapper {
	width: 100%;
	min-width: 100px;
}
