.flex {
	display: flex;
}
.alignCenter {
	align-items: center;
}
.spaceBetween {
	justify-content: space-between;
}

/*End Default Classes */

.header_kanban {
	padding-top: 10px;
	background-color: #fff;
	position: fixed;
	width: calc(100% - 100px);
	left: 100px;
	z-index: 99;
	border-bottom: 1px solid #e2e8f0;
}
.panelContainer {
	padding-top: 116px;
}
.pt_160 {
	padding-top: 160px;
}
.processSelection {
	margin-top: 157px;
}
.title_vacancy_container {
	margin-bottom: 5px;
}

.info_vacancy {
	margin-bottom: 10px;
	color: #64748b;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
}
.tabs_container [data-reach-tab-list] {
	background-color: #fff;
	padding-left: 15px;
	display: flex;
	gap: 4px;
}
.tabs_container [data-reach-tab] {
	color: #334155;
	text-align: center;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 24px */
	padding: 6px 16px;
	border-radius: 8px;
}

.tabs_container [data-reach-tab]:hover {
	background-color: #f5f5f5;
}

.tabs_container [data-reach-tab][data-selected] {
	color: #006a62;
	background: #e6f3f2;
	border-radius: 4px;
	border: none;
}
.tabs_container_header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 12px;
	align-items: center;
	height: 52px;
}
.infoIcone {
	margin-right: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.Vacancy_Status {
	background: #f8f9fa;
	border: 1px solid #d8dbdd;
	border-radius: 44px;
	padding: 8px;
	min-width: 110px;
	font-family: "BasierCircle";
	font-size: 14px;
	color: #000000;
}
.circleSeparator {
	width: 6px;
	height: 6px;
	display: block;
	background: #6d7175;
	border-radius: 6px;
	margin-right: 11px;
	margin-left: 10px;
}
.publishedOn {
	margin-left: 16px;
	display: flex;
	align-items: center;
}
.publishedOn > span {
	font-family: "BasierCircle";
	font-size: 14px;
	line-height: 16px;
	color: #000000;
	margin-right: 8px;
}
.channels img:nth-child(2) {
	position: relative;
	left: -6px;
}
.channels img:nth-child(3) {
	position: relative;
	left: -12px;
}
.channels {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.channels span {
	position: relative;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: #ffffff;
	position: absolute;
	top: 3px;
	right: 15px;
}
.navigationContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #ffffff;
	box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
	border: 1px solid rgb(0 0 0 / 5%);
	backdrop-filter: blur(4px);
	padding: 5px;
	margin-bottom: 10px;
}
.nextPrevContainer {
	display: flex;
	gap: 10px;
	margin-right: 20px;
}
.navigationButton {
	outline: none;
	background: white;
	background: #ffffff;
	font-weight: 600;
	color: #202223;
	border: 1px solid #d8dbdd;
	box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
	border-radius: 4px;
	font-family: "basier_circlesemibold";
}
.disabledButton {
	background: #fafbfb;
	border: 1px solid #d2d5d8;
	box-sizing: border-box;
	border-radius: 4px;
	color: #8c9196;
}

.muteButton {
	background: transparent;
	outline: none;
	border: none;
	width: auto;
	margin: 0 1rem;
	padding: 0;
}
.muteIcon {
	width: 2rem;
}

.menu {
	width: 151px !important;
}

.sidebar {
	width: 30%;
	margin-top: 18px;
}

.tabs_container [data-reach-tab][data-selected] {
	display: flex;
	padding: 6px 16px;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: #e6f3f2;
	font-family: "Work Sans";
}

.disabledTab {
	color: #cbd5e1;
	text-align: center;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.jobTtitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%; /* 30px */
	margin-right: 10px;
}

.requestStatus {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
}

.container input[type="text"].search {
	color: #006a62;
	background: #e6f3f2;
	font-family: "Work Sans";
}

.disabledTab {
	color: #cbd5e1;
	text-align: center;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.jobTtitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%; /* 30px */
	margin-right: 10px;
}

.requestStatus {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
}

.container input[type="text"].search {
}
