@import "modules/candidate/Profile/Variables/styles.scss";

.searchBox {
	display: flex;
	align-items: center;
	position: relative;
}
.paramsWrapper {
	position: relative;
}

.searchInput {
	border-radius: 4px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	border: 1px solid #e1e3e5;
	padding: 10px 8px;
	.icon {
		fill: #e1e3e5;
	}
	> input {
		border: none;
		background-color: #ffffff;
		margin-left: 10px;
		&::placeholder {
			@include profileContent;
			color: #6d7175;
		}
		:focus-visible {
			outline: none;
		}
	}
}
.focus {
	border: 1px solid #303131;
	.icon {
		fill: #303131;
	}
}

.paramsContainer {
	position: absolute;
	background: #ffffff;
	box-shadow: 0px 0px 2px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 10%);
	border-radius: 8px;
	z-index: 999999;
	width: 220px;
	padding: 18px 16px;
	top: 45px;
}

.title {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
	color: #fd5749;
	margin-bottom: 20px;
	display: block;
}

.taskParams {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.searchParamsButton {
	padding: 8px;
	background: #fafbfb;
	border: 1px solid #d2d5d8;
	border-radius: 4px;
	margin-left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
}

button {
	&::before {
		font-size: 18px;
	}
}
