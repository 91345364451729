.container {
	padding: 3rem;
}

.rootButton {
	width: 100%;
}

.button {
	width: 100%;
	display: flex;
	justify-content: center;
}

.description {
	display: flex;
	height: 100px;
	padding: 10.5px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: var(--md, 8px);
	border: 1px solid e5e5e5;
	background: #fff;
}

.footer {
	gap: 16px;
	display: flex;
	margin-top: auto;
	padding: 16px 24px;
	border-top: 1px solid #e2e8f0;

	.btn {
		gap: 8px;
		height: 40px;
		display: flex;
		padding: 10px 12px;
		border-radius: 8px;
		align-items: center;
		justify-content: center;

		font-size: 16px;
		font-weight: 500;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";

		&:focus-visible {
			outline: none !important;
		}

		&Danger {
			color: white;
			background: #00857a;
			border: 1px solid #00857a;

			&:hover {
				background-color: #006a62;
			}
		}

		&Link {
			color: #334155;
			border: 1px solid #e2e8f0;
			background-color: transparent;

			&:hover {
				background-color: #f9fafb;
			}
		}
	}
}
