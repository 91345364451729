.drawerContent {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.header {
	position: sticky;
	top: 0;
	z-index: 10;
	display: flex;
	padding: 21px;
	align-items: flex-start;
	gap: 8px;
	border-bottom: 1px solid #f5f5f5;
	background-color: white;
}

.header .title {
	flex-grow: 1;
	margin: 6px 0 0 0;
	color: #334155;
	font-family: "BasierCircle";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.header .closeBtn {
	flex-shrink: 0;
	display: flex;
	width: 36px;
	height: 36px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;

	&:hover {
		background: #f8fafc;
	}
}

.content {
	padding: 24px;
	flex-grow: 1;
}

.sectionTitle {
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 12px;
	padding: 0 14px;
}

.sectionDescription {
	color: var(--text-light, #64748b);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 0 14px;
}

.itemsContainer {
	margin-top: 16px;
}

.itemsTitle {
	color: var(--gray-700, #334155);
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.item {
	display: flex;
	align-items: center;
	margin-top: 16px;
	padding: 0 14px;
}

.channelName {
	flex-grow: 1;
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-inline-end: 12px;
}

.priceValue {
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	white-space: nowrap;
	margin-right: 12px;
}

.itemDivider {
	border-left: 1px solid var(--border-gray, #e2e8f0);
	margin: 0 12px;
	align-self: stretch;
}

.sectionDivider {
	border-top: 1px solid var(--border-gray, #e2e8f0);
	margin: 24px 14px;
	align-self: stretch;
}

.totalContainer {
	display: flex;
	padding: 12px 14px;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: var(--lg, 12px);
	border: 1px solid var(--gray-100, #f1f5f9);
	background: var(--gray-50, #f8fafc);

	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.unselectBtn {
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	color: #334155;
	&:hover {
		background: #f8fafc;
	}
}

.divider {
	border-bottom: 1px solid #e2e8f0;
	margin: 16px 0px;
}

.footer {
	position: sticky;
	bottom: 0;
	padding: 24px;
	background-color: white;
}

.note {
	display: flex;
	gap: 8px;
	border-radius: 8px;
	background: #f8fafc;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 8px;
	margin-bottom: 24px;
}

.noteIcon {
	padding: 2px;
}

.publishBtn {
	display: flex;
	height: 40px;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: var(--md, 8px);
	background: var(--bg-brand, #00857a);
	color: var(--text-white, #fff);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&:hover {
		background-color: #006a62;
	}
}

.cancelBtn {
	display: flex;
	height: 40px;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: var(--text-white, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&:hover {
		background: #f8fafc;
	}
}

.publishBtnDisabled {
	opacity: 0.6;
	pointer-events: none;
}

.loaderContainer {
	position: absolute;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #ffffff50;
}
