.icon {
	align-items: center;
	display: flex;

	path {
		stroke: #334155;
	}
}

.button {
	display: flex;
	padding: var(--2, 8px);
	align-items: center;
	gap: var(--3, 12px);
	border-radius: var(--md, 8px);
	border: var(--Question, 1px) solid var(--border-gray, #e2e8f0);
	opacity: var(--Question, 1);
	background: var(--text-white, #fff);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	&:hover {
		background: #f1f5f9;
	}
}
