.valueWrapper {
	display: flex;
	gap: 5px;
	&.modeInline {
		flex-wrap: wrap;
	}
}

.dropDownInput {
	height: 100%;
	padding: 0.2rem 0.5rem;
}

.loaderContainer {
	margin: auto;
	width: 10px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader div {
	border-right-color: #00857a;
}

.emptyState {
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
	text-underline-position: "from-font";
	text-decoration-skip-ink: none;
	padding: 30px 10px;
	color: #64748b;
}
