.root {
	position: relative;
	display: flex;
	align-items: center;

	&.fixed_width {
		width: 495px;
	}

	&.borderless {
		.input {
			border-top: none;
			border-left: none;
			border-right: none;
			box-shadow: none;
			border-bottom: 1px solid #e5e5e5;
			border-radius: 5px 5px 0 0;
		}
	}
}

.input {
	font-family: "Work Sans";
	border: none;
	border-radius: 5px;
	padding: 10px;
	padding-right: 2rem;
	box-shadow: 0px 0px 0px 1px #d4d4d4 inset;
	width: 100%;
	outline: 0px;
	color: #262626;
	font-size: 1rem;
	line-height: 1.125rem;
	font-weight: 400;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 0px 1px #00857a inset;
		// border: none !important;
	}

	&::placeholder {
		color: #a3a3a3;
		font-size: 1rem;
	}
}

.iconRight {
	right: 10px;
	position: absolute;
}
.clearIcon {
	right: 10px;
	position: absolute;
	cursor: pointer;
	height: 20px;
	width: 20px;
	color: #94a3b8;
}

.iconLeft {
	left: 10px;
	position: absolute;
}

.paddingLeft {
	padding-left: 40px;
}
