.container {
	background: #f1f2f3;
	border-radius: 5px;
	padding: 2px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;

		color: #8c9196;
	}
}
