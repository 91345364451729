@import "src/modules/consultancy/shared/shared-components";

@mixin box {
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
	background-color: white;
	border-radius: 5px;
	padding: 2rem;
}

.root {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	.infoContainer {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		align-items: center;
		gap: 2rem;
		position: sticky;
		top: 75px;
		@include box;
		h4 {
			font-size: 20px;
			color: gray;
		}
	}
	.pdfContainer {
		@include box;
	}
	.actionsContainer {
		display: flex;
		justify-content: flex-end;
		gap: 1.5rem;
	}
	.iframeContainer {
		height: 100vh;
		> iframe {
			height: 100%;
		}
	}
}

.containerInfo {
	display: flex;
	width: 100%;
	padding: 20px 20px;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;
	position: sticky;
	top: 75px;
}

.headerContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #e2e8f0;
}
.btnContainer {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.5px;
}
.btnAccept {
	display: flex;
	padding: 7px 12px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: #00857a;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #fff;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&:focus-visible {
		outline: none !important;
	}
	&:hover {
		background: #00857a;
	}
}
.btnRefuse {
	display: flex;
	padding: 7px 12px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	&:focus-visible {
		outline: none !important;
	}
	&:hover {
		background: #f1f5f9;
	}
	&:disabled {
		border: none;
		border: 1px solid #cbd5e1;
		color: #cbd5e1;
		background: #f1f5f9;
	}
}

.infoBodyContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	align-items: space-between;
	gap: 2rem;
	width: 100%;
}

.text {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.header {
	display: flex;
	align-items: center;
	gap: 6px;
	justify-content: center;
	color: #64748b;
}
.gridContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
}
.value {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	text-overflow: ellipsis;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 13.628px;
	margin-top: 12px;
}
.categoryValue {
	display: flex;
	padding: 6px 12px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.divider {
	width: 63%;
	height: 1px;
	background-color: #334155;
	margin: 11px 0px;
}
.status {
	padding-top: 6px;
}
