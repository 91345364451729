.container {
	font-family: "Work Sans";
	.title {
		width: 57px;
		height: 21px;
		margin-bottom: 14px;

		/* Paragraph/Medium/600 */

		font-family: "Work Sans";
		font-style: normal;
		font-weight: 600;
		font-size: 15.75px;
		line-height: 21px;
		/* identical to box height, or 133% */

		display: flex;
		align-items: center;
		letter-spacing: -0.5px;

		/* neutral/800 */

		color: #262626;

		/* Inside auto layout */

		flex: none;
		order: 0;
		flex-grow: 0;
	}

	.card {
		box-sizing: border-box;
		position: relative;

		/* Auto layout */

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		gap: 2rem;

		width: 100%;

		/* white */

		background: #ffffff;
		/* neutral/200 */

		border: 1px solid #e5e5e5;
		/* shadow/base */

		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
		border-radius: 8px;

		/* Inside auto layout */

		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;

		&:before {
			content: "";
			border: 1px solid #e5e5e5;
			align-self: stretch;
		}
	}
	.generalSection {
		box-sizing: border-box;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		gap: 2rem;
		width: 100%;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;
	}
	.socialMediaSection {
		order: -1;
	}

	.socialMediaSection,
	.companySection {
		flex-basis: 50%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 32px;
	}

	.companySection {
		gap: 5.5rem;
	}

	.url {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;
		height: 100%;
	}

	.label {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		gap: 0.25rem;
		display: flex;
		align-items: center;
		color: #737373;
		flex: none;
		order: 0;
		flex-grow: 0;
	}

	.socialMediaImg {
		width: 112px;
		height: 84px;
		border-radius: 8px;
		overflow: hidden;
		border: 1px solid #d4d4d4;

		img {
			width: 112px;
			height: 84px;
			object-fit: fill;
		}
	}

	.uploadContainer {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}

	.link {
		color: #2563eb;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		text-decoration-line: underline;
	}

	.copyIcon {
		cursor: pointer;
	}

	.icon {
		outline: none;
	}
}

.notificationContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 7px;

	.item {
		display: grid;
		grid-template-columns: 15px auto;

		.description {
			white-space: pre-wrap;
			word-break: break-word;
			font-family: "Work Sans";
			font-style: normal;
			font-size: 14px;
			line-height: 16px;
		}

		.circle {
			background-color: white;
			border-radius: 50%;
			height: 5px;
			width: 5px;
			margin-top: 5px;
		}
	}
}

.socialMediaImgSkeleton {
	border: 1px solid #d4d4d4;
}
