.mask {
	padding: 0 !important;
	opacity: 0 !important;
}

.popover {
	background: transparent !important;
	color: #fff !important;
	padding: 0 !important;
	box-shadow: none !important;
	border-radius: 12px;
	width: 400px !important;
	max-width: 400px !important;

	color: #fff;
	font-family: BasierCircle;
}

.popoverContentContainer {
	background: #282f38;
	padding: 18px;
	&.radius-for-top-right,
	&.radius-for-right-top {
		border-radius: 12px 0 12px 12px;
	}
	&.radius-for-top-left,
	&.radius-for-left-top {
		border-radius: 0 12px 12px 12px;
	}
	&.radius-for-bottom-right,
	&.radius-for-right-bottom {
		border-radius: 12px 12px 0 12px;
	}
	&.radius-for-bottom-left,
	&.radius-for-left-bottom {
		border-radius: 12px 12px 12px 0;
	}
}

.arrow {
	color: #282f38;
	position: absolute;
	&.arrow-top-left {
		top: -16px;
		left: 0;
	}
	&.arrow-top-right {
		top: -16px;
		right: 0;
		transform: scaleX(-1);
	}
	&.arrow-right-top {
		top: 1px;
		right: -16px;
		transform: rotate(90deg);
	}
	&.arrow-right-bottom {
		bottom: 1px;
		right: -16px;
		transform: rotate(90deg) scaleX(-1);
	}
	&.arrow-bottom-right {
		bottom: -16px;
		right: 0;
		transform: rotate(180deg);
	}
	&.arrow-bottom-left {
		bottom: -16px;
		left: 0;
		transform: rotate(180deg) scaleX(-1);
	}
	&.arrow-left-bottom {
		bottom: 1px;
		left: -16px;
		transform: rotate(270deg);
	}
	&.arrow-left-top {
		top: 1px;
		left: -16px;
		transform: rotate(270deg) scaleX(-1);
	}
}

.title {
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 10px;
}

.description {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: 10px;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5;
}

.control {
	display: flex;
	gap: 10px;
}

.nextBtn,
.skipBtn {
	padding: 0 16px;
	height: 32px;
	background: transparent;
	border-radius: 6px;

	font-family: BasierCircle;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5;
}

.nextBtn {
	background: #fff;
	color: #282f38;
}

.highlighted {
	animation: highlightAnimation 1.5s infinite;
}

@keyframes highlightAnimation {
	50% {
		box-shadow: 0px 0px 4px 2px rgba(255, 141, 7, 0.47);
	}
}
