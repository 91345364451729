.searchInput {
	border-radius: 4px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	border: 1px solid #e1e3e5;
	gap: 10px;
	padding: 0 10px;
	width: 630px;
	.icon {
		fill: #5c5f62;
	}
	> input {
		border: none;
		background-color: #ffffff;
		padding: 8px 2px !important;
		min-height: 0;
		width: 100%;
		&::placeholder {
			font-size: 14px;
			font-family: "basiercircle";
			font-weight: 400;
			line-height: 18px;
			color: #6d7175;
		}
		:focus-visible {
			outline: none;
		}
	}
}
.cardsContainer {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 24px;
	padding: 0 75px 0 0;
}
