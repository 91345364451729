.container {
	.textAreaContainer {
		margin-top: 0.5rem;
	}

	.error {
		color: #dc2626;
		font-size: 0.875rem;
		line-height: 1rem;
		display: flex;
		align-items: center;
		gap: 0.25rem;
		font-weight: 500;
		font-family: "Work Sans";

		.icon {
			height: 1rem;
			width: 1rem;
		}
	}

	.inputError,
	.inputError:focus {
		border: 1px solid #f87171;
	}

	.input {
		border-radius: 5px;
		border-width: 1px;
	}
}
