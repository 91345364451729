.container {
	display: flex;
	gap: 10px;
}

.disabledDeleteButtonContainer {
	cursor: grab;
}

.disabledDeleteButton:disabled {
	background-color: rgb(239, 239, 239);
	color: rgb(34, 34, 34);
}
