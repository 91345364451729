.container {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 8px 12px;
	border-top: 1px solid #e2e8f0;
}

.calendarButton {
	padding: 5px;
}

.fileUploadButton,
.messageButton {
	display: flex;
	padding: 4px 8px;
	align-items: center;
	gap: 4px;
	border-radius: 66px;
	background: #f8fafc;
}

.fileUploadButton div {
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.messageButton div {
	color: var(--text-dark, #1e293b);
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.messageButton {
	margin-right: auto;
}
