$primary-color: #00857a;
$secondar-color: #66b6af;

.container {
	flex-direction: column;
	display: flex;
	gap: 20px;
	background-color: red;
}

.inputContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr min-content;
	column-gap: 13px;
}

.addButton {
	height: 100%;
}

.addIcon path {
	stroke: white;
}

.addIconDisabled path {
	stroke: #d1d5db;
}

div.tabsHeader {
	margin-bottom: 0px;
}

.tabsHeader > div {
	height: 0px;
}

.cardContent {
	gap: 60px;
}

div.tabsRoot {
	gap: 0px;
}

.starIcon {
	fill: $secondar-color;
	path {
		stroke: $secondar-color;
	}
}

.tabsTitleContainer {
	display: flex;
	gap: 4px;
	align-items: center;
}

input[type="text"].comboxBoxInput {
	padding: 14px;
}

button.comboxBoxButton {
	padding-left: 14px;
	padding-right: 14px;
}

.tabPanels {
	border: none;
}
