.container {
	display: flex;
	padding: 12px 20px;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
	border-radius: 16px;
	border: 1px solid #e2e8f0;
	background: #fff;
	flex-direction: column;
	position: relative;

	.header {
		display: flex;
		gap: inherit;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;

		.info {
			display: flex;
			justify-content: space-between;
			flex: 1;

			.titleStatus {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 8px;

				div {
					display: flex;
					align-items: center;
					gap: 12px;
				}

				.label {
					color: #334155;
					font-family: "Work Sans";
					font-size: 15.75px;
					font-style: normal;
					font-weight: 400;
					line-height: 21px;
					letter-spacing: -0.5px;
				}

				.value {
					color: #334155;
					font-family: "Work Sans";
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					letter-spacing: -0.5px;
				}
			}

			.progress {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 12px;

				span {
					color: #334155;
					font-family: "Work Sans";
					font-size: 15.75px;
					font-style: normal;
					font-weight: 400;
					line-height: 21px;
					letter-spacing: -0.5px;
				}
			}
		}

		.right {
			display: flex;
			align-items: flex-start;
			gap: 21px;

			.managerSpace {
				display: flex;
				align-items: flex-start;
				gap: var(--2, 8px);

				.manager {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 12px;
					&.external {
						border-left: 1px solid #e2e8f0;
						padding-left: 16px;
					}
					.label {
						color: #334155;
						font-family: "Work Sans";
						font-size: 15.75px;
						font-style: normal;
						font-weight: 400;
						line-height: 21px;
						letter-spacing: -0.5px;
					}
				}

				.editManager {
					display: flex;
					align-items: center;
					border-radius: 8px;
					border: 1px solid #e2e8f0;
					background: #fff;
					width: 40px;
					height: 40px;
				}
			}
		}
	}

	.items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 8px;
		column-gap: 32px;
		width: 100%;
		&.isSupplierMode {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 24px;
		}
	}

	.separator {
		height: 1px;
		flex-shrink: 0;
		align-self: stretch;
		stroke-width: 1px;
		stroke: #e2e8f0;
		background-color: #e2e8f0;
		&Horizontal {
			width: 1px;
			height: auto;
		}
	}
}

.item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	align-self: stretch;

	height: 24px;
	.title {
		display: flex;
		align-items: center;
		gap: 8px;
		width: max-content;

		span {
			color: #64748b;
			font-family: "Work Sans";
			font-size: 15.75px;
			font-style: normal;
			font-weight: 400;
			line-height: 21px;
			letter-spacing: -0.5px;
		}

		svg {
			width: 20px;
			height: 20px;
			flex-shrink: 0;
		}
	}

	.value {
		color: #334155;
		font-family: "Work Sans";
		font-size: 15.75px;
		font-style: normal;
		font-weight: 500;
		line-height: 21px;
		letter-spacing: -0.5px;
		white-space: nowrap;
	}
}

.emailManagerWrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	color: #64748b;
}

.emailManager {
	color: #374151;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 24px */
}

.supplierMode {
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 8px;
	padding-left: 24px;
	border-left: 1px solid #e2e8f0;
}

.items {
	flex: 2;
}
.info {
	display: flex;
	gap: 24px;
	width: 100%;
}

.avatarClassName {
	padding-left: 0 !important;
}

.emptyValue {
	color: #cbd5e1;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -3.5px;
}

.icon {
	path {
		stroke: #64748b;
	}
}

.customizedHeight {
	height: 35px !important;
}
