.placeholder {
	display: flex;
	padding: 1px 4px;
	align-items: center;
	gap: 2px;
	border-radius: 5px;
	background: #fff2d5;
	height: 21px;
	&.hasValue {
		background: #e6f3f2;
		.placeholderLabel {
			&::before,
			&::after {
				color: #00857a;
			}
		}
	}
	&.readOnly {
		cursor: default;
		pointer-events: none;
	}
}

.placeholderLabel {
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 14px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 1;
	text-transform: capitalize;
	&::before {
		content: "[";
		color: #f59e0b;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%;
	}
	&::after {
		content: "]";
		color: #f59e0b;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%;
	}
}
