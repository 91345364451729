.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem;
	flex-basis: 10%;

	border-bottom: 1px solid #e5e5e5;

	.title {
		font-size: 2.25rem;
		color: #262626;
		font-weight: 500;
		font-family: "basiercircle";
		line-height: 2.5rem;
	}

	.icon {
		cursor: pointer;
		display: flex;
		align-items: center;
	}
}
