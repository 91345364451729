.fileTypeContainer {
	border-radius: 4px;
	padding: 4px 8px;
	width: 100%;
	display: flex;
	align-items: center;
	border: 1px solid lightgray;
	justify-content: center;
	button {
		margin-left: 5px;
		background: transparent;
		padding: 0px;
		.icon {
			fill: black;
		}
	}
}
.justifyContent {
	justify-content: space-between;
}
.fileTypeLabel {
	font-family: "basiercircle";
	text-transform: capitalize;
	color: black;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
