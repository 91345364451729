$primary-color: #00857a;

.container {
	display: grid;
	grid-template-columns: 1fr max-content max-content;
	gap: 5px;
	width: 100%;
}

.input[type="text"] {
	width: 218px;
	height: 40px;
	min-height: 40px;
	display: flex;
	padding: 10px 14px 10px 10px;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

button.button {
	width: 40px;
	height: 40px;
	display: flex;
	padding: 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
}

.close {
	color: green;
}

.check path {
	stroke: $primary-color;
}
