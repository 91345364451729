.privacy {
	&__settings {
		display: grid;
		grid-gap: 25px;
		grid-template-columns: 1fr;
		@media (min-width: 1000px) {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 10px;
		}
	}
	&__leftColumn {
		display: flex;
		flex-direction: column;
		gap: 15px;

		.link {
			gap: 8px;
			display: flex;
			cursor: pointer;
			align-items: center;
			&__success {
				color: #00857a;
				&:hover {
					color: #006a62;
					svg path {
						stroke: #006a62;
					}
				}
			}
			&__danger {
				color: #f87171;
				&:hover {
					color: #ef4444;
					svg path {
						stroke: #ef4444;
					}
				}
			}
			span {
				font-size: 16px;
				text-decoration-line: underline;
			}
		}
	}
}
