.statusContainer {
	border-radius: 4px;
	padding: 4px 8px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		margin-left: 5px;
		background: transparent;
		padding: 0px;
		.icon {
			fill: white;
		}
	}
}
.justifyContent {
	justify-content: space-between;
}
.upcoming {
	background: #ffbf64;
}
.completed {
	background: #5bc4a3;
}
.overdue {
	background: #d82c0d;
}
.isDefault {
	background: #e4e5e7;
	button {
		.icon {
			fill: #202223;
		}
	}
	.statusLabel {
		color: #202223;
	}
}
.statusLabel {
	font-family: "basiercircle";
	text-transform: capitalize;
	color: white;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
