$font-family: "Work Sans";
$primary-color: #00857a;

.dialogContent {
	display: flex;
	flex-direction: column;
	gap: 35px;
	padding: 28px;
	min-width: 1118px;
}

.contentContainer {
	display: grid;
	grid-template-columns: 1fr 1fr min-content 1fr;
	grid-template-rows: max-content max-content 1fr;
	align-items: center;
	row-gap: 7px;
	column-gap: 15px;
	width: 100%;
	height: 325px;
}

.modalTitle {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 23.04px;
	line-height: 24px;
	align-items: center;
	letter-spacing: -0.5px;
	color: #171717;
	margin-bottom: 0px;
}

.closeIconContainer {
	display: flex;
	justify-content: flex-end;
}

.addButton {
	margin-top: 15px;
	margin-left: -10px;
}

.addedSkillsLabel,
.label {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 21px;
	display: flex;
	align-items: center;
	letter-spacing: -0.5px;
	color: #1f2937;
}

.deleteButton {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background-color: transparent;
	flex: 1 0 auto;
}

.addButtonText {
	color: $primary-color;
}

.separator {
	width: 1px;
	height: 100%;
	background-color: #d1d5db;
	grid-row: 1 / -1;
	margin-left: 5px;
	margin-right: 5px;
}

.choosenSkillsContainer {
	grid-row: 2 / -1;
}

.choosenSkillsContainer,
.skillsListContainer,
.chooseFunctionMessage {
	background-color: #f9fafb;

	border-radius: 8px;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9ca3af;
		border-radius: 12px;
	}
}

.choosenSkillsContainer {
	display: flex;
	flex-direction: column;
	row-gap: 11px;
	overflow-y: scroll;
	margin-top: 7px;
	height: 100%;
	padding: 14px;

	&::-webkit-scrollbar {
		height: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #9ca3af;
		border-radius: 12px;
	}
}

.choosenSkillRow {
	display: flex;
	// grid-template-columns: max-content 1fr max-content 1fr;
	// grid-auto-rows: min-content;
	column-gap: 10px;
	align-items: center;
}

.searchInputContainer,
.searchInput {
	height: 100%;
}

.infoContainer {
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #6b7280;
}

.skill {
	overflow: hidden;
	align-items: center;
	display: flex;
	column-gap: 10px;
}

.deleteButton {
	background: transparent;
	padding: 0px;
	margin-left: 37px;
	justify-self: flex-end;
}

.notificationContainer {
	padding: 7px;
	white-space: pre-wrap;
	word-break: break-word;
	width: max-content;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
}

.saveButtonDisabled {
	cursor: not-allowed;
}

.mustHaveButton {
	background-color: transparent;
	display: flex;
	align-items: center;
	width: fit-content;
	padding: 0px;
	margin-top: 0.9px;
}

.modalFooter {
	display: grid;
	grid-template-columns: 1fr max-content max-content;
	align-items: center;
}

.notificationContainer {
	padding: 7px;
	white-space: pre-wrap;
	word-break: break-word;
	width: 279px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 12px;
}

input.searchInput {
	box-shadow: none;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
}

input.searchInput:focus {
	border: 1px solid $primary-color;
	box-shadow: none;
}

.skillName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #1f2937;
}

.star {
	flex: 1 0 auto;
}

.chooseSkillStar {
	width: max-content;
	flex: 1 0 auto;
}

.chooseFunctionMessage,
.skillsListContainer,
.loading {
	grid-row: 3 / -1;
	grid-column: 1 / 3;
}

.skillsListContainer {
	overflow-y: scroll;
	margin-top: 7px;
	grid-template-columns: 1fr 1fr;
	display: grid;
	grid-auto-rows: min-content;
	column-gap: 30px;
	row-gap: 11px;
	padding: 14px;
	height: 100%;
}

.chooseFunctionMessage {
	height: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 44.8px;
	justify-content: center;
	align-items: center;
}

.chooseMessageLabel {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #111827;
}

.addSkillMessage {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	color: #111827;
}

.newSkillContainer {
	display: flex;
	flex-direction: column;
	row-gap: 4px;
}

.categorySelectWrapper {
	overflow: hidden;
}

.loading {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f9fafb;
	border-radius: 8px;
}

.spinner > div {
	border-right: 2px solid #00857a;
}
