.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
	width: 100%;
	overflow-y: auto;
	flex-grow: 1;
}

.myCalendar {
	width: 100%;
}
.checkboxWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 10px;
}

.checkbox {
	width: 19px;
	height: 19px;
}
.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin: 0;
}

.username {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.collaborativeGroups {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: auto;
	flex-grow: 1;
}

.titleContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.addGroup {
	padding: 8px;
	color: #334155;
	width: 37px;
	height: 37px;

	border-radius: 12px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.groups {
	flex-grow: 1;
	overflow: auto;
	padding: 10px 2px 0 0;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}
.group {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	padding: 10px 0 10px 0;
	position: relative;
	&.disabled {
		.checkbox {
			pointer-events: none;
			cursor: not-allowed;
		}
		.name {
			color: #94a3b8;
		}
		.checkboxIconClassName {
			fill: #f1f5f9;
		}
	}
}

.remainingUsers {
	display: flex;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 2px solid #fff;
	background: #f3f4f6;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	cursor: pointer;
}
.name {
	display: block;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 109%;
	margin-bottom: 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.collaborators {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
}
div.firstCollaboratorPicture {
	margin-left: -3px;
}

.collaboratorPicture {
	border-radius: 50%;
	border: 3px solid white;
	margin-left: -8px;
	width: 34px;
	height: 34px;
}

.picture {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
}
.avatar {
	display: flex;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;

	> div {
		position: relative;
		font-size: 13px;
	}
}
.groupName {
	display: flex;
	align-items: center;
	gap: 5px;
}
.status {
	padding: 1px 6px;
	border-radius: 6px;
	background: #e2e8f0;
	color: #64748b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.inputSearch {
	border-radius: 6px;
	border: 1px solid #e2e8f0;
	box-shadow: none;
	padding: 12px;
	height: 32px;
}

.remainingAttendeesList {
	display: flex;
	flex-direction: column;
	gap: 7px;
	padding: 7px 10px;
}
.attendee {
	display: flex;
	align-items: center;
	gap: 7px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.groupDetails {
	width: calc(100% - 29px);
}

.groupActionsButtons {
	margin-left: auto;
}
.popoverClassName {
	z-index: 9998 !important;
}
.attendeeName {
	max-width: 250px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
