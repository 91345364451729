.list {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.card {
	display: grid;
	grid-template-columns: 42px 1fr repeat(3, auto);
	grid-template-rows: repeat(3, auto);
	column-gap: 8px;
	row-gap: 4px;
	padding: 12px;
	border-radius: 12px;
	background: #f8fafc;
}

.nameContainer {
	margin-top: 3px;
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.body {
	grid-row: 3 / 4;
	grid-column: 1 / 2;
}

.date {
	margin-top: 20px;
}
