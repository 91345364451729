.label {
	display: flex;
	gap: 12px;
}

.checkmark {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 16px;
	height: 16px;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	border-color: #e2e8f0;
	background: #fff;
	flex-shrink: 0;
	&.focused {
		box-shadow: 0px 0px 0px 5px rgba(105, 194, 188, 0.12);
	}
	&:hover {
		border-color: #00857a;
	}
	&.selected {
		background-color: #00857a;
		border-color: #00857a;
		&:hover {
			background-color: #006a62;
			border-color: #006a62;
		}
	}
}
