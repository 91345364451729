.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
}

.backButton {
	background-color: white;
	color: #2a3644;
	border-radius: 8px;
	height: 38px;
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: 500;
	border: 1px solid;
	border-color: #eceff3;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	padding: 0 12px;
	box-sizing: border-box;
	&:hover {
		background-color: #f5f5f5;
	}
}

.publishButton {
	background-color: #00857a;
	color: #ffffff;
	border-radius: 8px;
	height: 38px;
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: 500;
	border: 1px solid;
	border-color: #00857a;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	padding: 0 12px;
	box-sizing: border-box;
	&:hover {
		background-color: #006a62;
	}
}
