$notifOff: #e5e7eb;
$primaryColor: #00857a;
$textColor: #334155;

.react-toggle {
	.react-toggle-track {
		background-color: $notifOff;
		width: 36px;
		height: 16px;
	}

	.react-toggle-thumb {
		top: -2px;
		left: -2px;
		width: 20px;
		height: 20px;
		border-color: $notifOff;
		background-color: white;
		box-shadow: none !important;
		border: 1px solid $notifOff;
	}
	&:not(.react-toggle--checked):hover {
		.react-toggle-track {
			background-color: $notifOff;
		}

		.react-toggle-thumb {
			border-color: $notifOff;
		}
	}
	&.react-toggle--checked:hover,
	&.react-toggle--checked {
		.react-toggle-track {
			background-color: $primaryColor;
		}

		.react-toggle-thumb {
			border-color: $primaryColor;
			left: 18px;
		}
	}
}

.react-toggle-track-check {
	color: white;
	height: 15px;
}
