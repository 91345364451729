$primary-color: #00857a;

.uploadContainer {
	display: flex;
	gap: 5px;
	align-items: center;
}

.uploadButton {
	color: $primary-color;
	background-color: transparent;

	&:hover {
		background-color: transparent;
	}
}

.deleteImage {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #dc2626;
	display: flex;
	gap: 6px;
	background: transparent;
	border: none;
	align-items: center;
}
.blockFeaturedImage {
	display: flex;
	align-items: center;
	.uploadBtn {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #2563eb;
		display: flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;
	}
	:global(.image-featured) {
		width: 100px;
		height: 100px;
		border: 1px solid #e5e5e5;
		border-radius: 3px;
		display: flex;
		align-items: center;
		> img {
			display: block;
			width: 100%;
		}
	}
}

.deleteButton {
	color: #d1d5db;
	padding-left: 0px;
	padding-right: 0px;
}

.deleteButton:hover {
	background-color: transparent;
	color: red;

	path {
		stroke: red;
	}
}

.deleteIcon path {
	stroke: #d1d5db;
}

.image {
	border-radius: 12px;
	width: 100px;
	height: 67px;
}
