.settings_container {
	border: 1px solid #f3f4f6;
	background-color: white;
	border-radius: 12px;
	.header {
		padding: 28px 0;
		margin: 0 20px;
		border-bottom: 1px solid #f3f4f6;
		.text {
			font-family: "BasierCircle";
			font-size: 23px;
			font-weight: 500;
			letter-spacing: -0.5px;
			color: #334155;
		}
	}
	.intro {
		font-family: "Work Sans";
		font-size: 16px;
		background-color: #f9fafb;
		border-radius: 8px;
		color: #374151;
		padding: 16px;
		margin-bottom: 24px;
	}
	.title {
		font-family: "BasierCircle";
		font-size: 23px;
		font-weight: 500;
		letter-spacing: -0.5px;
		color: #334155;
		margin: 20px 0;
	}
}

.not_client_container {
	:global {
		.react-toggle {
			display: block;
			.react-toggle-track {
				background-color: #e5e7eb;
			}
			&:hover,
			&--checked {
				.react-toggle-track {
					background-color: #ff6849 !important;
				}
			}
			.react-toggle-thumb {
				transform: scale(0.8);
			}
		}
	}
}
