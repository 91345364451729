.statusWrapper {
	display: flex;
	align-items: center;
	div {
		margin-right: 4px;
	}
}
.priorityWrapper {
	@extend .statusWrapper;
}

.menuLabelContainer {
	background: #ffffff;
	border: 1px solid #d2d5d8;
	border-radius: 4px;
	padding: 12px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	.menuLabel {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		display: flex;
		align-items: center;
	}
	.icon {
		fill: #575959;
	}
}
