.wrapper {
	caret-color: transparent;
	min-width: 4ch;
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	--tw-numeric-spacing: tabular-nums;
	font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
		var(--tw-numeric-figure) var(--tw-numeric-spacing)
		var(--tw-numeric-fraction);
	text-align: right;
	border-radius: 0.125rem;
	box-sizing: content-box;
	display: flex;
	align-items: center;

	div {
		display: flex;
		align-items: center;
	}
}

.segment {
	display: flex;
	align-items: center;
	caret-color: transparent;
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	--tw-numeric-spacing: tabular-nums;
	font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
		var(--tw-numeric-figure) var(--tw-numeric-spacing)
		var(--tw-numeric-fraction);
	text-align: right;

	border-radius: 0.25rem;
	box-sizing: content-box;
	line-height: 20px;
	&:focus {
		--tw-text-opacity: 1;
		color: white;
		background-color: #ff6849;

		.label {
			--tw-text-opacity: 1;
			color: rgb(255 255 255 / var(--tw-text-opacity));
		}
	}

	.label {
		pointer-events: none;
		--tw-text-opacity: 1;
		text-align: center;
	}
}

.separator {
	height: 0px;
	pointer-events: none;
	--tw-text-opacity: 1;
	color: rgb(107 114 128 / var(--tw-text-opacity));
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	color: #525252;
}

.text {
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: 400;
	color: #262626;
	line-height: 0rem;
}
