.dropdown {
	position: absolute;
	top: 45px;
	width: 650px;
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 0 0 12px 12px;
	background: #fff;

	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
		0px 2px 4px -2px rgba(16, 24, 40, 0.06);
	z-index: 1;
}

.field {
	padding: 9px 5px;
	border-bottom: 1px solid #e2e8f0;
	position: relative;
}

.fieldLabel {
	display: flex;
	align-items: center;
	height: 100%;
	margin: 0;
	color: #475569;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	white-space: nowrap;
}

.searchDropdown {
	width: 100%;
	z-index: 1;
	padding: 10px;
	gap: 5px;
	left: 0;
}

.inputClassName {
	padding: 0;
	margin-left: 8px;
	border-bottom: none !important;
	color: #475569;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 9px 5px;
	.label {
		color: #475569;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		margin: 0;
	}
}
.checkbox {
	display: flex;
	align-items: center;
	gap: 5px;
	width: auto !important;
}

.footer {
	background: #fff;
	display: flex;
	padding: 10px;
	justify-content: flex-end;
	align-items: center;
	gap: 14px;
	align-self: stretch;
}

.clearButton {
	color: #334155;
	border: 1px solid #e2e8f0;
	box-shadow: none;
	height: 35px;
}
