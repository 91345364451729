.addButton {
	box-shadow: 0px 0px 0px 1px #e5e7eb inset;
	border-radius: 8px;
	padding: 14px;
}

.addButton:hover {
	background-color: #fafafa;
}

.addButtonText {
	color: #6b7280;
}
