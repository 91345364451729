.container {
	display: flex;
	padding: 20px 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	align-self: stretch;
}

.title {
	color: #334155;
	text-align: center;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	margin: 0;
}

.description {
	color: #64748b;
	text-align: center;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.button {
	padding: 6px 16px;
}
