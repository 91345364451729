.wrapper {
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	padding: 20px;
	font-family: "Work Sans";
	margin-top: 10px;
}

.title {
	display: flex;
	align-items: center;
	color: #334155;
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 16px;
	svg {
		margin-right: 6px;
	}
}

.flex {
	display: flex;
	gap: 16px;
	padding-bottom: 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid #e2e8f0;
}

.formInput {
	display: flex;
	flex-direction: column;
	.label {
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 400;
		color: #334155;
		line-height: 150%;
		display: block;
		margin-bottom: 12px;
	}
	.groupNameSelect {
		height: 40px;
		width: 393px;
		&:hover {
			background: #fff;
		}
		input {
			font-size: 16px;
			font-weight: 400;
			cursor: pointer;
			background: #fff !important;
		}
	}
	.nameInput {
		font-family: "Work Sans";
		width: 393px;
		height: 40px !important;
		min-height: unset !important;
		padding: 12px;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #fff;
		font-size: 16px;
		line-height: 150%;
		&:focus,
		&:active {
			outline: none;
			border: 1px solid #e2e8f0;
		}
	}
}

.collaboratorsTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	.title {
		margin-bottom: 0;
	}
}

.actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 30px;
	.leave,
	.delete {
		font-family: "Work Sans";
		display: flex;
		align-items: center;
		margin-left: 10px;
		height: 40px;
		border-radius: 8px;
		font-size: 16px;
		font-weight: 500;
		line-height: 150%;
		padding: 0 16px;
		svg {
			margin-right: 8px;
		}
	}
	.leave {
		background-color: #fff;
		color: #334155;
		border: 1px solid #334155;
		svg path {
			stroke: #334155;
		}
	}
	.delete {
		background-color: #fff;
		border: 1px solid #d92d20;
		color: #d92d20;
		svg path {
			stroke: #d92d20;
		}
	}
}

.marginTop {
	margin-top: 8px;
}

.emptyState {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 20px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	.title {
		margin-bottom: 8px;
		font-size: 20px;
		font-weight: 600;
		line-height: 21px;
		letter-spacing: -0.5px;
	}
	.description {
		color: #64748b;
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
	}
}

.roleCol,
.closeCol {
	display: none;
}
.addBtn {
	display: flex;
	height: 40px;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	cursor: pointer;
	color: var(--text-dark, #1e293b);
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
	svg {
		height: 20px;
		width: 20px;
	}
}
.disabled {
	opacity: 0.6;
	cursor: not-allowed;
}
