@import "../../Variables/styles.scss";

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	min-height: 14.1rem;
	background-color: $white;
	border-radius: 4px;
	.flex {
		display: flex;
		align-items: center;
		.hide {
			background: transparent;
			border: none;
			outline: none;
			padding: 0;
			margin: 0;
			svg path {
				fill: #6d7175;
			}
		}
		svg {
			cursor: pointer;
		}
		button {
			background: transparent;
			padding: 0;
			display: flex;
		}
	}
	.gap {
		display: flex;
		gap: 2rem;
	}

	.header,
	.footer__card {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		h2 {
			margin: 0 1rem;
			@include cardsTitle;
			line-height: 1.25;
			color: #262626;
		}
		p {
			@include profileContent;
			padding: 0 0 0 1rem;
			margin: 0;
		}
	}
	.content {
		.section {
			display: flex;
			flex-direction: column;
			.title {
				@include profileContent;
				line-height: 1.43;
				color: $raven;
			}
			.row {
				display: flex;
				align-items: center;
				gap: 1rem;
				flex-wrap: wrap;
				.textGras,
				.textNormal {
					&:not(:first-child) {
						::before {
							height: 6px;
							width: 6px;
							border-radius: 50%;
							content: "";
						}
					}
				}
				.textGras {
					p,
					span {
						font-weight: 500;
					}
					&:not(:first-child) {
						::before {
							background: $raven;
							content: "";
						}
					}
				}
				.textNormal {
					text-transform: capitalize;
					p {
						font-weight: normal;
					}
					&:not(:first-child) {
						::before {
							background: #6d7175;
							content: "";
						}
					}
				}
				p,
				span {
					position: relative;
					display: flex;
					align-items: center;
					gap: 0.9rem;
					@include profileContent;
					line-height: 1.43;
					margin: 0;
					color: $blackPearl;
				}
			}
			.justifyBetween {
				display: flex;
				justify-content: space-between;
			}
			.status {
			}
		}
	}
	.viewMore {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 5px 12px;
		background: $white;
		box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		border: 1px solid #babfc3;
		@include profileContent;
		line-height: 1.43;
		color: $blackPearl;
		transition: border 0.2s ease;
		&:hover {
			border: 1px solid #20222380;
		}
	}
	.section_see_all {
		display: flex;
		justify-content: center;
		.btn_see_all {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			padding: 0.75rem 1rem;
			margin-bottom: 1rem;
			background: none;
			border: none;
			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
	.section_view__more {
		padding: 1rem;
	}
	.footer__card {
		justify-content: end;
		border-top: 1px solid #f2f2f2;
	}
	.textBtn {
		font-size: 1rem;
		line-height: 1.125rem;
		font-weight: 500;
		font-family: "Work Sans";
	}
}
.darg {
	box-shadow: 0px 0px 4px 4px rgba(255, 104, 73, 0.14);
	cursor: pointer;
}
.drop {
	border-top: 3px solid $tomato;
}

.emptyState {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	p {
		@include profileContent;
		color: $blackPearl;
		margin-left: 3px;
	}
}
