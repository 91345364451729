.card {
	padding: var(--4, 16px);
	align-self: stretch;
	border-radius: var(--lg, 12px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	width: 100%;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			color: var(--gray-700, #334155);
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
	}

	.divider {
		height: 1px;
		align-self: stretch;
		background: var(--border-gray, #e2e8f0);
		margin: var(--4, 16px) 0;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: var(--3, 16px);
		align-self: stretch;

		.item {
			display: flex;
			padding: 3px 0;
			gap: var(--2, 8px);
			align-items: center;
			align-self: stretch;

			.labelHead {
				display: flex;
				width: 180px;
				align-items: center;
				gap: var(--3, 12px);

				.label {
					color: var(--text-dark, #334155);
					font-variant-numeric: lining-nums tabular-nums;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					line-height: 150%;
				}
			}

			.value {
				flex: 1 0 0;
				color: var(--text-dark, #1e293b);
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				display: flex;
				gap: var(--2, 8px);

				p {
					flex: 1 0 0;
					color: var(--text-dark, #1e293b);
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					display: flex;
					gap: var(--2, 8px);
				}
			}

			&__message {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: var(--3, 12px);
				flex: 1 0 0;

				.labelHead {
					width: unset;
				}
			}

			.viewMore {
				color: var(--border-brand, #00857a);
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				background: transparent;
				padding: 0;
			}
		}

		.bidInfo {
			display: flex;
			gap: var(--4, 16px);
			align-self: stretch;

			&_item {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: var(--3, 12px);
				flex: 1;
				flex-basis: max-content;

				&_labelHead {
					display: flex;
					align-items: center;
					gap: var(--2, 8px);

					svg {
						width: 16px !important;
						height: 20px !important;
					}

					&_label {
						color: var(--text-dark, #334155);
						font-variant-numeric: lining-nums tabular-nums;
						font-family: "Work Sans";
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 150%;
						text-wrap: nowrap;
					}
				}

				&_value {
					color: var(--text-dark, #334155);
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}
			}
		}

		.files {
			display: flex;
			align-items: flex-start;
			gap: var(--2, 8px);
			align-self: stretch;
			flex-wrap: wrap;

			.file {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				align-self: stretch;
				padding: var(--2, 8px);
				gap: 10px;
				flex: 1;
				border-radius: var(--md, 8px);
				border: 1px solid var(--border-gray, #e2e8f0);
				background: var(--text-white, #fff);
				max-width: 32%;
				min-width: 32%;
				cursor: pointer;

				&:hover,
				&:focus {
					border-color: var(--border-brand, #00857a);
					box-shadow: 0px 0px 0px 2px rgba(0, 133, 122, 0.2);
				}

				span {
					color: var(--text-dark, #1e293b);
					font-family: "Work Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					text-overflow: ellipsis;
					overflow: hidden;
					max-height: 24px;
					margin-right: auto;
				}

				svg {
					flex: none;
				}
			}
		}

		&_loading {
			opacity: 0.2;
		}
	}
}

.mission {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	.item {
		display: flex;
		align-items: center;
		gap: 10px;

		.title {
			color: var(--text-dark, #334155);
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 21px;
			letter-spacing: -0.5px;

			&_bold {
				font-weight: 500;
			}

			&_danger {
				color: var(--text-red, #d92d20);
			}
		}

		.btn {
			display: flex;
			padding: var(--2, 8px);
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: var(--md, 8px);
			border: 1px solid var(--brand-400, #339d95);
			background: var(--brand-500, #00857a);
			color: var(--text-white, #fff);
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 500;
			line-height: 100%;
			letter-spacing: -0.5px;

			&:hover {
				background: var(--bg-brand-hover, #006a62);
			}

			&:focus {
				background: var(--bg-brand, #00857a);
				box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
			}
		}
	}
}

.meeting {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	.item {
		display: flex;
		align-items: center;
		gap: 10px;

		.title {
			color: var(--text-dark, #334155);
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 21px;
			letter-spacing: -0.5px;
		}

		.btn {
			display: flex;
			padding: var(--2, 8px);
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: var(--md, 8px);
			border: 1px solid var(--brand-400, #339d95);
			background: var(--brand-500, #00857a);
			color: var(--text-white, #fff);
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 500;
			line-height: 100%;
			letter-spacing: -0.5px;

			&:hover {
				background: var(--bg-brand-hover, #006a62);
			}

			&:focus {
				background: var(--bg-brand, #00857a);
				box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
			}
		}
	}
}

.flex {
	display: flex;
	gap: 8px;
	align-items: baseline;
}

.fee {
	display: flex;
	align-items: center;
	gap: var(--2, 8px);
	color: var(--text-placeholder, #94a3b8);
	font-family: "Work Sans";
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.message {
	display: flex;
	flex-direction: column;
	padding: 10px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: var(--lg, 12px);
	border: 1px solid var(--brand-100, #cce7e4);
	background: rgba(230, 243, 242, 0.5);

	.message_item {
		display: flex;
		align-items: center;
		gap: var(--2, 8px);

		.primary {
			color: var(--text-brand, #00857a);
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
		.seconder {
			color: var(--text-dark, #334155);
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			margin-left: calc(20px + 8px);
		}
	}
}

.reject {
	display: flex;
	flex-direction: column;
	padding: 10px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: var(--lg, 12px);
	border: 1px solid var(--red-200, #fecdca);
	background: var(--red-50, #fef3f2);

	.reject_item {
		display: flex;
		align-items: center;
		gap: var(--2, 8px);

		.primary {
			color: var(--text-red, #d92d20);
			font-variant-numeric: lining-nums tabular-nums;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
		.seconder {
			color: var(--text-dark, #334155);
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			margin-left: calc(20px + 8px);
		}
	}
}

.insideDivider {
	height: 1px;
	align-self: stretch;
	background: var(--border-gray, #e2e8f0);
	margin: 3px 0;
}

.textNoWrap {
	text-wrap: nowrap;
}

.description {
	* {
		font-family: "Work Sans";
		font-size: 16px;
		line-height: 150%;
	}
}
