.dropdown {
	position: absolute;
	width: 650px;
	max-height: 285px;
	overflow: auto;

	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
		0px 2px 4px -2px rgba(16, 24, 40, 0.06);

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.listItem {
	display: flex;
	padding: 5px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
}

.userInfo {
	display: flex;
	flex-direction: column;
}

.username {
	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.email {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
