.btnContainer {
	display: flex;
	gap: 0.75rem;
}

.goBackButton {
	background-color: #f8fafc;
	cursor: pointer;
	padding: 0.75rem;
	margin: 0;

	&:hover {
		background-color: #f5f5f5;
	}

	&svg {
		display: block;
	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: relative;

	.first {
		display: flex;
		gap: 10px;
		align-items: center;
		button {
			display: flex;
			background-color: #f8fafc;
			cursor: pointer;
			padding: 0.75rem;
			margin: 0;

			&:hover {
				background-color: #f5f5f5;
			}

			&svg {
				display: block;
			}
		}
	}
}

.headerCandidate {
	display: flex;
	justify-content: space-between;
	align-items: center;

	h3 {
		margin: 0;
		font-weight: 500;
	}
}
