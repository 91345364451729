.fields {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(2, 1fr);
	width: 100%;
	gap: 21px;
	width: 100%;
	padding-top: 16px;
	border-top: 1px solid #e2e8f0;
}

@media screen and (max-width: 1000px) {
	.fields {
		display: flex;
		flex-direction: column;
	}
}

.field {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.field.fullWidth {
	grid-column: span 2;
}

.label {
	display: flex;
	align-items: center;

	color: #64748b;

	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-transform: capitalize;
}

.icon {
	margin-right: 12px;
	path {
		stroke: #64748b;
	}
}

.input {
	max-height: 40px !important;
	min-height: 40px !important;
	border-radius: 10px !important;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
	padding: var(--3, 12px) 16px !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
}

.marginBottom {
	margin-top: 8px;
}
.fixedWidth {
	width: 239px;
}

.sellingPriceContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
}

.inputContainer {
	position: relative;
	flex-grow: 1;
}

.currency {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 16px;

	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	border-radius: 10px !important;
}

.infoCircle {
	path {
		fill: #334155;
	}
}

.currency {
	position: static;
	transform: none;
}
.currency input {
	height: 40px;
	border-radius: 8px !important;
	padding: 13px !important;
	border: 1px solid #e5e5e5 !important;
	line-height: 16px !important;
	background: #fff !important;
	padding: 0.5rem 0.75rem !important;
	max-height: 40px !important;
	min-height: 40px !important;

	&::placeholder {
		color: #a3a3a3 !important;
	}
}

.currencyLabelClassName {
	top: -4px !important;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.asterisk {
	color: #dc2626;
}
