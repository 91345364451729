.root {
	text-align: left;
	flex-direction: column;
	display: inline-flex;
	position: relative;
}

.fieldWrapper {
	flex-basis: 95%;
	transition-property: color, background-color, border-color, fill, stroke,
		-webkit-text-decoration-color;
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke;
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke, -webkit-text-decoration-color;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
	display: flex;
	align-items: center;
	position: relative;
	text-align: left;
}

.separator {
	padding-left: 1rem;
	padding-right: 1rem;
	color: #d4d4d4;
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: normal;
	display: flex;
	align-items: center;
}
