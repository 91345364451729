@mixin textStyle {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.5px;
}

.uploaderContainer {
	@include textStyle;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	border: 1px dashed;
	border-color: #cbd5e1;
	border-radius: 8px;
	padding: 16px 24px;
	width: 100%;
	background-color: #fff;
	height: 76px;
	&.isDropTarget {
		border-color: #00857a;
		background-color: #00857a05;
	}
	&:hover {
		background-color: #f8fafc;
	}
	&.isReadOnly {
		pointer-events: none;
	}
}

.iconWrapper {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	border: 3px solid #f8fafc;
	background: #f1f5f9;
	color: #64748b;
}

.highlighted {
	color: #00857a !important;
}
