$font-family: "Work Sans";

.container {
	margin-top: 5px;
	display: flex;
	align-items: center;
	gap: 7.92px;
	color: #dc2626;
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 12.25px;
	line-height: 14px;
}
