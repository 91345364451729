.segment {
	caret-color: transparent;
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	--tw-numeric-spacing: tabular-nums;
	font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
		var(--tw-numeric-figure) var(--tw-numeric-spacing)
		var(--tw-numeric-fraction);
	text-align: right;
	border-radius: 0.25rem;
	box-sizing: content-box;
	color: rgb(55 65 81 / var(--tw-text-opacity));
	color: #525252;
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: normal;

	&:focus {
		--tw-text-opacity: 1;
		color: white;
		background-color: #ff6849;

		.label {
			--tw-text-opacity: 1;
			color: rgb(255 255 255 / var(--tw-text-opacity));
		}
	}

	.label {
		pointer-events: none;
		--tw-text-opacity: 1;
		text-align: center;
		width: 100%;
		display: block;
		border-radius: 3px;
		-webkit-appearance: none;
		box-shadow: none;
		outline: none;
	}
}

.separator {
	pointer-events: none;
	--tw-text-opacity: 1;
	color: rgb(107 114 128 / var(--tw-text-opacity));
	text-align: center;
	width: 100%;
	display: block;
	border-radius: 3px;
	box-shadow: none;
	outline: none;
}

.text {
	color: #262626;
	font-size: 14px;
	font-family: "Work Sans";
	font-weight: normal;
}
