.container {
	display: flex;
	align-items: center;
	padding: 1.5rem;
	border-top: 1px solid #e5e5e5;
	flex-basis: auto;
}

button.cancelButton {
	box-shadow: 0px 0px 0px 1px #d1d5db inset;
	padding: 12px 21px;
}

button.cancelButton:hover {
	background-color: #fafafa;
}

.cancelButtonText {
	color: #525252;
}

.sendButton {
	background-color: #edeff2;
	box-shadow: none;
	color: #a3a3a3;
	cursor: not-allowed;
	pointer-events: none;
	svg path {
		stroke: #a3a3a3;
	}
}
