@import "modules/SearchCandidate/TableVariables/fonts.scss";

.counterHeader {
	display: flex;
	margin-top: 20px;
	margin-left: 5px;
	margin-bottom: 14px;
}

.containerFooter {
	position: relative;
	width: 100%;
	min-height: 40px;
	margin-top: 1.5rem;
}
.countLabel {
	@include SmallParagraph;
	color: #737373;
	font-weight: 500;
	line-height: 1.125rem;
	padding: 0;

	.greenText {
		color: #00857a;
		font-weight: 500;
	}

	.grayText {
		color: #737373;
		font-weight: 500;
	}
}
