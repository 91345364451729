@import "modules/SearchCandidate/TableVariables/fonts.scss";

.header {
	display: flex;
	justify-content: space-between;
}

.modalTitle {
	@include H3text;
	font-weight: 500;
	color: #171717;
}

.closeIcon {
	cursor: pointer;
}

.modalDesc {
	@include MediumParagraph;
	color: #262626;
	font-weight: 500;
}
.modalRow {
	display: flex;
	margin-top: 21px;
	margin-bottom: 20px;
	gap: 14px;
	.vacanciesDisplay,
	.pageDisplay {
		display: flex;
		flex-direction: column;
		gap: 24px;
		.links {
			display: flex;
			align-items: center;
			gap: 8px;
			@include SmallParagraph;
			color: #2563eb;
			text-decoration: underline;
			cursor: pointer;
		}
		.select,
		.check {
			display: flex;
			align-items: center;
			gap: 14px;
			.text {
				@include SmallParagraph;
				color: #262626;
			}
		}
		.requestSelect {
			display: flex;
			align-items: center;
			gap: 19px;
			.text {
				@include SmallParagraph;
				color: #262626;
			}
		}
		.check {
			gap: 7px;
			margin-top: 10px;
			cursor: pointer;
		}
	}

	.separator {
		color: red;
		border: 1px solid #d4d4d4;
		transform: rotate(180deg);
	}

	.bigHeight {
		height: 149px;
	}

	.smallHeight {
		height: 90px;
	}
}
.btnContainer {
	display: flex;
	justify-content: flex-end;
	.nextBtn {
		all: unset;
		@include SmallParagraph;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 10.5px 14px;
		gap: 7px;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		border-radius: 5px;
		color: #525252;
		cursor: pointer;
		&:disabled {
			background: #ffffff;
			border: 1px solid #e5e5e5;
			color: #d4d4d4;
			cursor: not-allowed;
		}
	}
}
.btnsContainer {
	display: flex;
	justify-content: space-between;

	&.noBack {
		display: flex;
		justify-content: flex-end;
	}

	.btnsRow {
		display: flex;
		align-items: flex-end;
		margin-top: 28px;
	}
	.cancelBtn {
		@include SmallParagraph;
		font-weight: 500;
		padding: 10.5px 14px;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		border-radius: 5px;
		color: #525252;
		margin-right: 14px;
		cursor: pointer;
	}
	.deleteBtn {
		all: unset;
		@include SmallParagraph;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		padding: 10.5px 14px;
		gap: 9px;
		align-items: center;
		background: #dc2626;
		color: white;
		border-radius: 5px;
		cursor: pointer;
	}
	.backBtn {
		all: unset;
		@include SmallParagraph;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 10.5px 14px;
		gap: 7px;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		border-radius: 5px;
		color: #525252;
		cursor: pointer;
		margin-top: 28px;
	}
}

.deleteContainer {
	display: flex;
	gap: 15px;
	align-items: center;
	margin: 7px 0 7px 0;

	.text {
		@include MediumParagraph;
		font-weight: 500;
		color: #262626;
		width: 35ch;
	}
}

.flex {
	display: flex;
	align-items: center;
}

.briefCaseIcon {
	margin-right: 5px;
}
