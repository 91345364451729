.popover {
	&Container {
		gap: 10px;
		padding: 18px;
		display: flex;
		background: #282f38;
		flex-direction: column;
		font-family: "Work Sans";

		&.radius-for-top-right,
		&.radius-for-right-top {
			border-radius: 12px 0 12px 12px;
		}
		&.radius-for-top-left,
		&.radius-for-left-top {
			border-radius: 0 12px 12px 12px;
		}
		&.radius-for-bottom-right,
		&.radius-for-right-bottom {
			border-radius: 12px 12px 0 12px;
		}
		&.radius-for-bottom-left,
		&.radius-for-left-bottom {
			border-radius: 12px 12px 12px 0;
		}
	}

	&Arrow {
		color: #282f38;
		position: absolute;
		&.arrow-top-left {
			top: -16px;
			left: 0;
		}
		&.arrow-top-right {
			top: -16px;
			right: 0;
			transform: scaleX(-1);
		}
		&.arrow-right-top {
			top: 1px;
			right: -16px;
			transform: rotate(90deg);
		}
		&.arrow-right-bottom {
			bottom: 1px;
			right: -16px;
			transform: rotate(90deg) scaleX(-1);
		}
		&.arrow-bottom-right {
			bottom: -16px;
			right: 0;
			transform: rotate(180deg);
		}
		&.arrow-bottom-left {
			bottom: -16px;
			left: 0;
			transform: rotate(180deg) scaleX(-1);
		}
		&.arrow-left-bottom {
			bottom: 1px;
			left: -16px;
			transform: rotate(270deg);
		}
		&.arrow-left-top {
			top: 1px;
			left: -16px;
			transform: rotate(270deg) scaleX(-1);
		}
	}

	&Header {
		margin: 0;
		color: #fff;
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		line-height: 23.8px;
		font-family: "Work Sans";
	}

	&Body,
	&Step {
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		line-height: 23.8px;
		word-break: initial;
		font-family: "Work Sans";
	}

	&Footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&BtnWrapper {
		gap: 4px;
		display: flex;
		align-items: center;
	}

	&Button {
		gap: 10px;
		display: flex;
		padding: 4px 16px;
		border-radius: 6px;
		align-items: center;

		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		line-height: 23.8px;
		font-family: "Work Sans";

		&:focus-visible {
			outline: none !important;
		}

		&__default {
			color: #282f38;
			background: #fff;
			&:hover {
				background-color: #f9fafb;
			}
		}

		&__link {
			padding: 0;
			color: #fff;
			background-color: transparent;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
