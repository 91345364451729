.sidebar {
	border-right: 1px solid #e2e8f0;
	width: 345px;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}

.header {
	padding: 20px 16px 4px 16px;
}

.titleAndBtn {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.newTemplateBtn {
	all: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	height: 32px;
	padding: 0 16px;
	color: white;
	background-color: #00857a;
	border-radius: 6px;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	&:hover {
		background-color: #006a62;
	}
}

.search {
	display: flex;
	margin-top: 20px;
	position: relative;
	.icon {
		color: #667085;
		position: absolute;
		left: 16px;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
	}
	input {
		all: unset !important;
		height: 40px !important;
		flex-grow: 1 !important;
		background-color: white !important;
		border: 1px solid #e5e7eb !important;
		border-radius: 8px !important;
		padding: 0 16px 0 42px !important;
		font-family: "Work Sans" !important;
		font-size: 14px !important;
		font-style: normal !important;
		font-weight: 400 !important;
		line-height: 100% !important;
		letter-spacing: -0.5px !important;
		&::placeholder {
			color: #94a3b8 !important;
		}
		&:focus {
			border-color: #006a62 !important;
		}
	}
}
