// job & profile description
.jobDescriptionTitle,
.profileDescriptionTitle {
	color: #64748b;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	font-style: normal;
	margin-bottom: 20px;
	letter-spacing: -0.5px;
	font-family: "Work Sans";
}
.jobDescription,
.profileDescription {
	*:is(p, h1, h2, h3, h4, ul, ol, blockquote) {
		margin-bottom: 20px !important;
	}

	p {
		color: #1e293b;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";
		text-align: justify !important;
		*:is(strong, b) {
			font-size: 18px;
			font-weight: 600;
			color: #1e293b;
			line-height: 24px;
			font-style: normal;
			font-family: Roboto;
		}
	}

	li {
		font-size: 14px;
		color: #475569;
		font-weight: 400;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";
		text-align: justify !important;
		*:is(strong, b) {
			font-weight: 600;
			color: #1e293b;
			font-style: normal;
			font-family: Roboto;
		}
	}
	*:last-child {
		margin-bottom: 0 !important;
	}
}
.profileDescriptionTitle {
	margin-top: 20px;
}

// featured Image
.featuredImage {
	margin-top: 24px;
	img {
		width: 100%;
		height: 436px;
		object-fit: cover;
		border-radius: 8px;
	}
}

// featured video
.featuredVideo {
	padding: 0px;
	margin-bottom: 24px;
}

// more job info
.jobMoreInfo {
	gap: 20px;
	display: flex;
	flex-direction: column;
}
/* ----------------------- */
