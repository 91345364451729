.slotItem {
	.slotDateValue {
		color: #64748b;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-bottom: 6px;
	}
	.timeSlotSeparator {
		color: #000;
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
	& > div {
		display: flex;
		gap: 12px;
		align-items: center;
	}
	.addTimeSlotBtn {
		all: unset;
		cursor: pointer;
		margin-left: auto;
		display: flex;
		padding: 4px;
		align-items: center;
		gap: 10px;
		border-radius: 3px;
		background: #f1f5f9;
		&:hover {
			background: #dce0e4;
		}
		&.borderless {
			background: none;
		}
	}
}

.emptyStateContainer {
	display: flex;
	align-items: flex-start !important;
	gap: 0.5rem !important;
	margin-bottom: 12px;
}

.emptyState {
	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.row {
	margin-bottom: 7px;
}

.closeIcon {
	width: 21px;
	height: 21px;
}

.error {
	margin-bottom: 10px;
}

.timeInput {
	min-width: 84px;
	&.error {
		border-color: #d92d20;
	}
}
