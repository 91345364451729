.inputBox {
	height: 100%;
}

.container input.inputGreen {
	border: 1px solid #d4d4d4;
	padding: 0px 9px;
	border-radius: 9px;
	outline: 0ch;
	width: 100%;
	min-height: auto;
	border-radius: 5px;
	font-size: 14px;
	font-family: "Work Sans";
	flex-basis: 80%;
	outline: 0px;
	color: #262626;
	font-size: 1rem;
	line-height: 1.125rem;
	padding: 0.5rem 0.75rem;
	font-weight: 400;
	width: 100%;
	height: 100%;

	&:focus {
		border: 1px solid #00857a !important;
	}

	&::placeholder {
		color: #edeeef;
	}
}

.inputGreen {
	&:focus {
		border: 1px solid #00857a !important;
	}
}

.error {
	color: #e8634a !important;
	height: auto;
	line-height: 16px;
	min-height: 31px;
	padding: 5px 0 10px 0;
	text-transform: none;
	white-space: normal !important;
	clear: both;
	display: flex;
	align-items: flex-start;
	> svg {
		width: 11px;
		height: 11px;
		margin-right: 5px;
	}
}
