.providerWrapper {
	display: flex;
	padding: 12px;
	align-items: center;

	border-radius: 8px;
	border: 1px solid #e5e7eb;
}

.providerEmail {
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 17.5px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	letter-spacing: -0.5px;
}
.syncButtonRoot {
	margin-left: auto;
}
.syncButton {
	border: 1px solid #d1d5db;
	box-shadow: none;
	&.connected {
		color: #d92d20;
	}
	&.disconnected {
		color: #6b7280;
	}
}

.syncStatus {
	margin-left: 15px;

	color: #039855;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 200%;
	&.notSynced {
		color: #d92d20;
	}
}

.providerOptions {
	display: flex;
	flex-direction: column;
	.option {
		display: flex;
		padding: 7px 10.5px;
		align-items: center;

		color: #262626;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		cursor: pointer;
		&:hover {
			background-color: #f5f5f5;
		}
	}
}
.providerValue {
	display: flex;
	align-items: center;
	gap: 10px;

	color: #6b7280;
	font-family: "Work Sans";
	font-size: 17.5px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	letter-spacing: -0.5px;
}
.providerIcon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
.providerValueIcon {
	width: 32px;
	height: 32px;
}

.paperClassName {
	width: 226px !important;
}
.dropDownInputClassName {
	min-width: 237px;
	&.disabled {
		pointer-events: none;
	}
}

.providerLabel {
	display: flex;
	gap: 8px;
}
