.grid2Columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 30px;
	width: 100%;
}

.grid5Columns {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 15px;
	justify-content: flex-start;
}
