div.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px 4px 8px;
	border-radius: 7px;
	cursor: pointer;
	background: transparent;
}

.icon {
	align-items: center;
	display: flex;

	path {
		stroke: #00857a;
	}
}

.title {
	margin-left: 5px;
	color: #00857a;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		text-decoration: underline;
		color: #006a62;
		cursor: pointer;
	}
}
