.filters_bar {
	background: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	display: grid;
	grid-template-columns: 1fr auto;
	padding: 0.5rem 0.5rem 0 0.5rem;
	margin-top: 1rem;
	align-items: center;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.scrollContainer {
	width: 100%;
	overflow-x: scroll;
	padding-right: 0.25rem;
	white-space: nowrap;
	cursor: pointer;

	&::-webkit-scrollbar {
		display: none;
	}
}

.filters_container {
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
}

.pill_container {
	display: inline-block;

	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}

.btn_container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;
	padding-bottom: 0.5rem;
}

.rightContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0.5rem;
	border-left: 1px solid #edeeef;
	gap: 0.5rem;
}
