.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100;
}

.emptyStateText {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #babec3;
	margin: 10px 0px;
}

.addNewTaskButton {
	display: flex;
	align-items: center;
	background: #ffffff;
	padding: 5px 8px;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	border: 1px solid #fd5749;
	span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #fd5749;
		margin-right: 6px;
	}
}
