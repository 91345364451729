@import "../../Variables/styles.scss";

.table {
	border-spacing: 0;
	width: 100%;
	tr {
		cursor: pointer;

		.allProcessesTitle {
			&:nth-child(2) {
				width: 50%;
			}
		}
		.notes {
			@include profileContent;
			&:nth-child(1) {
				width: 10%;
			}
			&:nth-child(2) {
				width: 32%;
			}
			&:nth-child(3) {
				width: 15%;
			}
			&:nth-child(4) {
				width: 32%;
			}
			&:nth-child(5) {
				width: 11%;
			}
		}

		:last-child {
			td {
				border-bottom: 0;
				width: 25%;
			}
		}
	}

	th {
		margin: 0;
		padding: 20px;
		padding-left: 1rem;
	}
	td {
		margin: 0;
		padding: 1rem;
	}
	.tableHead {
		background: $white;
		position: sticky;
		top: 0;
		z-index: 99;
	}
	.tableBody {
		border-top: 1px solid $gris;
		background: $white;
	}
	.highlitedRow {
		background: #fff8f8;
	}
}
.minHeight {
	min-height: 555px;
}
.columnsHeadline {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: $blackPearl;
}
.emtyStateWrraper {
	background: $white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
	min-height: 400px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.sortedField {
	color: $blackPearl;
}

.allProcessesTitle,
.notes {
	.allProcessesTitle {
		@include profileContent;
		margin-top: 2rem;

		.tableBody {
			background: $white;
			box-shadow: none;
			border-radius: 4px;
		}
	}
}
.notes {
	tr {
		height: 6.4rem;
		border-bottom: 1px $gris solid;
	}
}
