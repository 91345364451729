.ctaButton {
	padding: 8px;
	background: #fff;
	border: 1px solid #f3f4f6;
	box-shadow: unset;
	height: 38px;
}

.ctaIcon {
	width: 20px;
	height: 20px;
}

.sendIcon {
	gap: 7px;
	padding: 9px 16px;
	&.disabled {
		border: 1px solid #94a3b8;
		background: #94a3b8;
		box-shadow: unset;
		cursor: default;
		pointer-events: none;
	}
}

.attachmentButton {
	display: flex;
	align-items: center;
	padding: 8px;

	border-radius: 8px;
	border: 1px solid #f3f4f6;
	background: #fff;
}

.actions {
	display: flex;
	gap: 10px;
}
.cta {
	display: flex;
	padding: 8px;
	justify-content: center;
	align-items: center;
	gap: 7px;
	border-radius: 8px;
	border: 1px solid #f3f4f6;
	background: #fff;
	box-shadow: none;
}

.ctaIcon {
	&.trashIcon {
		path {
			stroke: #334155;
		}
	}
}

.confirmButtonClassName {
	background: #d92d20;
	box-shadow: none;
	&:hover {
		background: #d92d20;
	}
}

.visuallyHiddenClassName {
	position: relative !important;
}

.contentHelper {
	flex-shrink: 0;
}

.contentError {
	padding: 16px 16px 0 16px;
}
