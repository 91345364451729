.info {
	display: flex;
	gap: 12px;
	width: 100%;
	background: #fff;
}
.infoContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	padding: 12px;
	align-items: flex-start;
	border-radius: 16px;
	border: 1px solid #e2e8f0;
	background: #fff;
}
.avatar {
	border-radius: 12px;
	border: 1.5px solid #fff;
	cursor: pointer;
}
.infoData {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: 7px;
}
.bottom {
	display: flex;
	align-items: flex-start;
	gap: var(--2, 8px);
	align-self: stretch;

	.item {
		display: flex;
		padding: var(--2, 8px) var(--3, 12px) var(--2, 8px) 10px;
		align-items: center;
		gap: var(--3, 12px);
		border-radius: var(--md, 8px);
		border: 1px solid var(--gray-200, #e2e8f0);
		background: var(--white, #fff);
		text-wrap: nowrap;
		flex-basis: max-content;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: -webkit-grab;

		&_a {
			padding: 6px;
			a {
				display: flex;
			}
		}

		span {
			text-decoration: none;
			color: var(--gray-600, #4b5563);
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 400;
			line-height: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		svg {
			flex: none;
		}
	}
}

.divider {
	height: 1px;
	align-self: stretch;
	background: #e2e8f0;
}
.name {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-weight: 500;
	line-height: 100%;
	cursor: pointer;
}
.flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
}
.top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
	flex-direction: column;
	gap: 12px;
}
