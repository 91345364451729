.selectColumn {
	background-color: #334155;
	:global {
		li {
			display: flex !important;
			align-items: center !important;
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%; /* 16px */
			letter-spacing: -0.5px;
			svg {
				margin-right: 4px;
			}
		}
	}
}

.deleteModal {
	position: absolute;
}
