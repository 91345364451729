.container {
	display: flex;
	align-items: center;
	flex-direction: center;
	flex-direction: column;
	gap: 5px;
}

.text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 17.5px;
	line-height: 24px;
	color: #262626;
}
