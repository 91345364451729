.container {
	margin-right: 7px;
}

.label {
	height: 21px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 21px;
	color: #4b5563;
}

.asterisk {
	color: red;
	font-family: "Work Sans";
	font-size: 15.75px;
}
