$primary-color: #00857a;

.radioButton,
.span {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.label {
	display: flex;
	margin-bottom: 0px;
	cursor: pointer;
}

.containedIcon,
.icon:hover {
	fill: $primary-color;
	path {
		stroke: $primary-color;
	}
}
