.filterBoxContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	.leftSide {
		display: flex;
		align-items: center;
	}
}
