.container {
	position: relative;
	margin: 0px 11px;
}

.label {
	background: #ffffff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	border: 1px solid #575959;
	.selectedValue {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
	}
}

.menuItems {
	position: absolute;
	z-index: 999;
	background: #ffffff;
	padding: 8px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	bottom: 44px;
}

.menuItem {
	background: white;
	border-radius: 4px;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #202223;

	&:hover {
		background: #f6f6f7;
	}
}
