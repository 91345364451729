@import "modules/SearchCandidate/TableVariables/fonts.scss";

.row {
	width: 100%;
	max-width: 100%;
	font-family: "Work Sans";
	display: flex;
	align-items: center;
	border-right: 1px solid #e5e5e5;
	border-left: 1px solid #e5e5e5;
	cursor: pointer;

	&:nth-child(even) {
		background: #f5f5f5;
	}

	&:nth-child(odd) {
		background: #fff;
	}

	&:first-child {
		border-top: 1px solid #e5e5e5;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	&:last-child {
		border-bottom: 1px solid #e5e5e5;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	&.selected {
		border: 1px solid #00857a;
		background: #e6f3f2;
		overflow: hidden;
		.col {
			&:not(:first-child) {
				border-left: 1px solid #cce7e4;
			}

			.filters_pill {
				border: 1px solid #b3dad7;
			}
			.delete_btn_container {
				color: #99ceca;
			}
		}
	}

	&:hover {
		background: #e6f3f2;

		.col {
			&:not(:first-child) {
				border-left: 1px solid #cce7e4;
			}

			.filters_pill {
				border: 1px solid #b3dad7;
			}
			.delete_btn_container {
				color: #99ceca;
			}
		}
	}

	.col {
		margin: 0;
		padding: 0.5rem 1.125rem;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		color: #262626;
		line-height: 1.125rem;
		overflow: hidden;
		width: 100%;

		&:first-child {
			width: 209px;
			max-width: 209px;
			min-width: 209px;
			font-weight: 500;
		}

		&:not(:first-child) {
			border-left: 1px solid #e5e5e5;
		}
	}
}

.bold {
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.filters_pill {
	padding: 0.25rem 0.5rem;
	background: #ffffff;
	border: 1px solid #66b6af;
	border-radius: 50px;
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&.small {
		overflow: hidden;
	}

	&.no_margin {
		margin: 0;
	}
}

.filters_text {
	@include SmallParagraph;
	white-space: nowrap;
	font-weight: 400;
	margin: 0;
	margin-right: 0.25rem;
	color: #262626;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;

	&.small {
		overflow: hidden;
	}

	p {
		@include SmallParagraph;
		color: #262626;
		font-weight: 500;
		line-height: 1.125rem;
		margin: 0;

		&:last-child {
			&.small {
				flex-grow: 1;
				width: 100%;
				max-width: 100%;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}

.filter_op {
	@include SmallParagraph;
	line-height: 1;
	font-weight: 400 !important;
	background-color: #e6f3f2;
	color: #00433d !important;
	border-radius: 3px;
	padding: 0 0.25rem;
	text-transform: lowercase;
}

.removeBtn {
	color: transparent;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	color: #d4d4d4;
	border-radius: 50%;

	&:hover {
		color: #00857a;
		background: #e6f3f2;
	}
}

.delete_btn_container {
	display: flex;
	color: #d4d4d4;

	button {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0;
		background-color: transparent;
	}

	&:hover {
		color: #00433d !important;
	}
}
