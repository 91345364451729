.fieldsContainer {
	margin: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.select label span span {
	color: red;
}

.label {
	color: #6d7175;
	display: flex;
	align-items: center;
	gap: 4px;
	text-transform: capitalize;
	max-width: 100%;
	margin-bottom: 5px;
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.link {
	background: #ffffff;
	box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
	border-radius: 4px;
	border: 1px solid #babfc3;
	font-family: "basiercircle";
	color: #202223;
	padding: 8px 12px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	text-decoration: none;
	line-height: 20px;
	width: fit-content;
}

.red {
	color: red;
}

.error {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	font-weight: normal;
	color: #ed2e7e;
}

.authenticatedBox {
	display: flex;
	gap: 8px;
	padding: 6px;
}

.autenticateInput {
	display: flex;
	flex-direction: column;
}
