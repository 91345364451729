$font-family: "Work Sans";

.title {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500 !important;
	font-size: 23.04px;
	line-height: 24px;
	letter-spacing: -0.5px;
	color: #334155;
}

.plusIcon path {
	stroke: white;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 14px;
}

.addEntryButton {
	div {
		font-size: 16px;
	}
}

.buttonWatchGuide {
	overflow: hidden;
	button {
		background: #fdece9;
		border-radius: 2px;
		color: #df6950;
		text-transform: uppercase;
		border: none;
		padding: 14px 24px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		float: right;
		> span {
			font-size: 20px;
			margin-right: 10px;
		}
		&:focus {
			outline: 0;
			opacity: 0.8;
		}
	}
}
