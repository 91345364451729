.cell {
	outline: 2px solid transparent;
	outline-offset: 2px;
	width: 35px;
	height: 35px;
	border-radius: 5px;
}

td .selected {
	--tw-text-opacity: 1;
	background-color: #1a9187;
	color: white;
}

.disabled {
	color: gray;
}

.button {
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding: 0.5rem;
	font-family: inherit;
	font-size: 100%;
	font-weight: inherit;
	color: inherit;
	margin: 0;
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	line-height: 1;
}

.td {
	padding: 0;
	z-index: 0;
	position: relative;
	cursor: pointer;
}

td.disabledCell {
	cursor: not-allowed;
}

.cellContent {
	color: #56576a;
	justify-content: center;
	height: 100%;
	width: 100%;
	align-items: center;
	display: flex;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
}

.weekendCellContent {
	color: #92939d;
}

div div.cellContentSelected {
	color: white;
	padding: 0;
}

td div.disabledCellContent {
	color: #dee2e8;
}

.tdMiddleRange {
	background-color: #e6f3f2;
}

td .middleRange {
	color: white;
	border-radius: 0%;

	div {
		color: #56576a;
	}
}

.currentDay {
	border: 2px solid #1a9187;
	color: #1a9187;
	border-radius: 4.79px;
}
