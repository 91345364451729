.container {
	font-family: "Work Sans";
}

.label {
	color: var(--text-dark, #334155);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.input {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	height: 40px;
	padding: 0 16px;
}

.segments {
	display: flex;
	gap: 4px;
	align-items: center;
	color: #475569;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.segment:focus {
	color: white;
	background: #00857a;
	outline: none;
}

.icon {
	svg {
		path {
			stroke: #334155;
		}
	}
	display: flex;
	align-items: center;
}

.greenBorder {
	border: 1px solid #66b6af;
}
