@mixin H1text {
	font-size: 2.25rem;
	font-family: "basiercircle", serif;
	line-height: 2.5rem;
}

@mixin H2text {
	font-size: 1.875rem;
	font-family: "basiercircle", serif;
	line-height: 2.25rem;
}

@mixin H3text {
	font-size: 1.5rem;
	font-family: "basiercircle", serif;
	line-height: 2rem;
	letter-spacing: -0.5px;
}

@mixin LargeParagraph {
	font-size: 1.25rem;
	font-family: "Work Sans", serif;
	line-height: 1.75rem;
}

@mixin MediumParagraph {
	font-size: 1.125rem;
	font-family: "Work Sans", serif;
	line-height: 1.5rem;
}

@mixin SmallParagraph {
	font-size: 1rem;
	font-family: "Work Sans", serif;
	line-height: 1.125rem;
}

@mixin XsmallParagraph {
	font-size: 0.875rem;
	font-family: "Work Sans", serif;
	line-height: 1rem;
}
