.root {
	display: "inline-block";
	width: fit-content;
}

.wrapper {
	border-radius: 8px;
	cursor: pointer;
	padding: 0.5rem 0.75rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.contained {
	color: white;
	background: #00857a;
	padding: 0.75rem 1rem;
	box-shadow: 0px 0px 0px 1px #00857a inset;
	height: 100%;

	&:hover {
		background-color: #006a62;
	}
}

.containedText {
	background: transparent;
	padding: 0.75rem 1rem;
	line-height: 1.125rem;
	&:hover {
		background-color: #f5f5f5;
	}
}

button.contained.disabled {
	background-color: #f5f5f5;
	color: #d4d4d4;
	border: none;
	box-shadow: none;
}

.text {
	background: transparent;
	border-radius: 5px;
	padding: 0.75rem 1rem;

	&:hover {
		background-color: #f5f5f5;
	}
}

button.text.disabled {
	color: #d4d4d4;
}

.outlined {
	color: #00857a;
	background: transparent;
	padding: 0.75rem 1rem;
	box-shadow: 0px 0px 0px 1px #00857a inset;

	&:hover {
		background-color: #fafafa;
	}
}

button.outlined.disabled {
	color: #d4d4d4;
	background: transparent;
	border: 1px solid #d4d4d4;
}

.globalText {
	font-family: "Work Sans";
	font-weight: 500;
	line-height: 1;
	font-size: 1rem;
}

button[disabled].disabled {
	opacity: 1;
}

.spinnerContainer div {
	border-right-color: currentColor;
}
