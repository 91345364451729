@import "_vars";
@import "_mixins";

.wrapper {
	display: grid;
	grid-template-columns: repeat(20, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(60px, auto);
	width: 100%;
	padding: 20px;
}
.weather {
	grid-row: 1;
	grid-column: 1 / 3;
	border-radius: $radius;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	@include breakpoint(laptop) {
		grid-column: 1 / 5;
	}
	@include breakpoint(desktop) {
		grid-column: 1 / 4;
	}
	@include breakpoint(desktopL) {
		grid-column: 1 / 3;
	}
}
.tasks {
	background-color: #fff;
	grid-column: 3/8;
	grid-row: 1;
	border-radius: $radius;
	@include breakpoint(laptop) {
		grid-column: 5 / 12;
	}
	@include breakpoint(desktop) {
		grid-column: 4 / 10;
	}
	@include breakpoint(desktopL) {
		grid-column: 3 / 10;
	}
}
.interviews {
	grid-column: 8/17;
	grid-row: 1;
	border-radius: $radius;
	background-color: #fff;
	display: flex;
	@include breakpoint(laptop) {
		grid-column: 12 / 21;
	}
	@include breakpoint(desktop) {
		grid-column: 10 / 21;
	}
}

.tabs {
	width: 100%;
	padding: 0;
	background-color: #fff;
	box-shadow: $shadow;
	border-radius: $radius;
	grid-column: 1/21;
	grid-row: 2;
	display: flex;
	overflow: hidden;
	margin: 0;

	li {
		display: flex;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		align-items: center;
		color: #8c9196;
		cursor: pointer;
		border-bottom: 1px solid #aeb4b9;
		transition: all 0.4s ease-out;
		padding: 20px;
		&.active,
		&:hover {
			color: rgba(253, 87, 73, 1);
			border-bottom: 1px solid rgba(253, 87, 73, 1);
		}
	}
}
.contentTab {
	width: 100%;
	background-color: #fff;
	border: 1px solid #f1f2f3;
	box-sizing: border-box;
	border-radius: $radius;
	grid-column: 1/21;
	grid-row: 3;
}

.middleBox {
	background-color: #fff;
	border-radius: $radius;
	grid-column: 1/10;
	grid-row: 4;
	&.last {
		grid-column: 10/21;
		display: flex;

		> div {
			flex-grow: 1;
			display: flex;
			> div {
				flex-grow: 1;
				display: flex;
				> div {
					flex-grow: 1;
					display: flex;
				}
			}
		}
	}
}
.customTab {
	[data-reach-tab] {
		background: #edeeef;
		border-radius: 4px;
		color: #8c9196;
		display: flex;
		padding: 3px 10px;
		cursor: pointer;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		border: 0;
		margin-right: 4px;
		margin-bottom: 0;
	}
	[data-reach-tab][data-selected] {
		background: #ffe9e8;
		color: #fd5749;
		border: 0;
	}
	[data-reach-tab-list] {
		display: flex;
		background: transparent;
	}
}
