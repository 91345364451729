.modal-visibility {
	&--header {
		padding: 1.143rem;
		border-bottom: 1px solid #e2e8f0;
		margin: 0;
		h3 {
			font-family: "Work Sans";
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			color: #334155;
		}
		.closeIcon {
			background: #f8fafc;
			border-radius: 8px;
			padding: 8px;
		}
	}
	&--footer {
		padding: 1.143rem;
		border-top: 1px solid #e2e8f0;
		.done {
			border-radius: 8px;
		}
		&-btn {
			border-radius: 8px;
			border: 1px solid #e2e8f0;
			background: white;
			padding: 0.75rem 1rem;
			font-size: 1rem;
			line-height: 1.125rem;
		}
	}
}
