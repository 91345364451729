.summaryCardInfos {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	flex: 1 0 0;
}

.infos {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	flex: 1 0 0;
}

.vacancy {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
	margin-top: 5px;
}
