@import "modules/SearchCandidate/TableVariables/fonts.scss";

.container {
	position: relative;
	width: 100%;
}

.input {
	font-size: 14px;
	width: 100%;
}

.label {
	@include SmallParagraph;
}

.paper {
	position: absolute;
	top: 44px;
	z-index: 10;
}

.optionsWrapper {
	display: flex;
	flex-direction: column;
	padding: 0.25rem 0;

	.option {
		padding: 0.5rem 0.75rem;
		font-size: 1rem;
		line-height: 1.125rem;
		cursor: pointer;
		color: #262626;
		font-weight: 400;
		display: flex;
		gap: 0.5rem;

		&:hover {
			background-color: #f5f5f5;
		}
	}

	.iconWrapper {
		width: 1.125rem;
		height: 1.125rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.iconContainer {
	width: 18px;
	height: 18px;
}

.emptyIcon {
	width: 18px;
}
