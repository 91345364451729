@import "modules/SearchCandidate/TableVariables/fonts.scss";

.formContainer {
	padding: 0.5rem 1.5rem;
	flex: 1;
}

.formGroup {
	padding: 1rem 0;
	border-bottom: 1px solid #f5f5f5;
	display: flex;
	align-items: center;

	&:last-child {
		border-bottom: 0;
	}
	.formLabel {
		@include SmallParagraph;
		font-weight: 500;
		line-height: 1.125rem;
		color: #737373;
		width: 25%;
	}

	.required {
		@include SmallParagraph;
		line-height: 1.125rem;
		color: #dc2626;
		font-weight: 600;
		line-height: 16px;
	}

	.textInput {
		@include SmallParagraph;
		line-height: 1.125rem;
		box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
		border: none;
		color: #262626;
		border-radius: 3px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding: 0.75rem;
		min-height: unset;
		flex: 1;

		&::placeholder {
			color: #a3a3a3;
		}
	}

	.addressBtn {
		padding: 0.75rem;
		background: #f5f5f5;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: #525252;

		svg {
			display: block;
		}
	}

	.flexContainer {
		display: flex;
		align-items: center;
		gap: 1.125rem;
	}

	.imageContainer {
		width: 98px;
		height: 98px;
		border: 1px solid #e5e5e5;
		border-radius: 8px;
		overflow: hidden;
	}
}

.drawerFooter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1.5rem;
	border-top: 1px solid #e5e5e5;
	flex-basis: auto;
	gap: 0.5rem;
}
