$primary-color: #00857a;
$font-family: "Work Sans";

.field {
	display: block;
	margin-top: 20px;
}

.field .label {
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	color: #344054;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.poNumber,
.numberFields {
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	font-family: "Work Sans";
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #64748b;
}

.poPlaceHolder,
.numberFieldsPlaceHolder {
	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: -0.5px;
}

.uploadLabel {
	display: flex;
	align-items: center;
	margin-bottom: 14px;

	font-family: "Work Sans";
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	color: #334155;
}

.icon {
	margin-right: 12px;
	path {
		stroke: #64748b;
	}
}

.field .label .asterisk {
	color: #dc2626;
}

.field .input {
	min-height: 40px;
	padding: 0 14px;
	height: 44px;
}
.field {
	&.hasError {
		border-color: #dc2626;
		&:focus {
			box-shadow: 0px 0px 0px 5px rgba(233, 90, 90, 0.12);
		}
	}
}

.generateButton {
	width: 131px;
	min-width: 131px;
	display: flex;
	height: 44px;
	padding: 0px 12px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: var(--lg, 12px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.comboBoxRootClassName {
	border-radius: var(--4, 10px) !important;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
}

.datePickerRoot {
	border-radius: var(--4, 10px) !important;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	height: 44px !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
}

.input {
	border-radius: var(--4, 10px) !important;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
	padding: var(--3, 12px) 16px !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
}

.TextAreaInput {
	font-family: "Work Sans" !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 100% !important; /* 16px */
	letter-spacing: -0.5px !important;
	border-radius: var(--4, 10px) !important;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	padding: var(--3, 12px) 16px !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
	&::placeholder {
		color: #94a3b8 !important;
	}
}

.field .datePickerInput {
	height: 44px;
	box-shadow: unset;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

button.nextButton[type="submit"] {
	padding: 5px 21px;
	line-height: 16px;
	max-width: fit-content;
	border-radius: 8px;
}

button.backButton {
	box-shadow: 0px 0px 0px 1px #d1d5db inset;
	padding: 14px 21px;
	height: 44px;
	border-radius: 8px;
	background-color: #fafafa;
}

button.backButton:hover {
	background-color: #fafafa;
}

.backButtonText {
	color: #525252;
}
.buttonContainer {
	display: flex;
	justify-content: space-between;
	width: 50vw;
	padding: 28px 0px;
}
.semiFields {
	display: flex;
	width: 100%;
	gap: 16px;
	.field {
		width: 50%;
	}
}

div.radioButtonContainer {
	display: grid;
	grid-template-columns: repeat(2, 50%);
	gap: 14px;
}

.radioButtonContainer span:before {
	margin-right: 8px !important;
}
.formContainer {
	display: flex;
	width: 50vw;
	padding: 21px 28.004px 28.004px 28.004px;
	flex-direction: column;
	gap: 16px;
	border-radius: 12.002px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1.00016px 2.00031px 0px rgba(0, 0, 0, 0.06);
	margin-top: 16px;
}
input[type="text"].daysPerWeekInput {
	padding: 14px;
}

input[type="text"].daysPerWeekInput {
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
	padding: var(--3, 12px) 16px !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
}
.comboxInput {
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
}

.daysInOfficeLabel {
	font-family: $font-family;
	font-weight: 400;
	font-size: 13px;
	line-height: 12px;
	border: 1px solid #e5e5e5;
	display: flex;
	border-radius: 8px;
	align-items: center;
	background-color: #fafafa;

	color: #1f2937;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-left-width: 0.5px;
	padding: 14px;
	&:focus {
		border-color: #00857a !important;
	}
}

.daysPerWeekContainer {
	display: grid;
	grid-template-columns: 50px max-content;
}
.hourlyRateContainer {
	display: grid;
	grid-template-columns: 100px max-content;
}

.hourlyRateContainerFullWidth {
	display: grid;
	grid-template-columns: auto max-content;
}

.currency {
	border-radius: 10px;
	margin-bottom: 0px !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0px;
	&:focus {
		border-color: #00857a !important;
	}
}

.currency input {
	border-radius: 10px 0px 0px 10px !important;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
	padding: var(--3, 12px) 16px !important;
	justify-content: space-between !important;
	align-items: center !important;
	flex: 1 0 0 !important;
	&:focus {
		border-color: #00857a !important;
	}
}
.fieldError {
	color: #dc2626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin-top: 8px;
}

input.timePicker {
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	padding: 16px;
	color: #1f2937;
	text-align: right;
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	width: 54.008px;
	min-height: 44px;
	border: 1px solid var(--border-gray, #e2e8f0) !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	display: flex !important;
	height: 44px !important;
	padding: var(--3, 12px) 16px !important;
	justify-content: space-between !important;
	align-items: center !important;
	&:focus {
		border-color: #00857a !important;
	}
}

input.timePicker:focus {
	border: 1px solid $primary-color;
	box-shadow: none;
}

.allowSubmissionContainer {
	display: flex;
	gap: 5px;
	align-items: center;
}

.allowSubmissionText {
	color: #1f2937;
	text-align: right;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.allowSubmissionCheckbox {
	width: fit-content !important;
}

.rateInput {
	width: fit-content;
	margin-top: 20px;
}

.spinner {
	color: "#00857A";
}

.descriptionFooter {
	display: flex;
	justify-content: space-between;

	.descriptionLength {
		margin-left: auto;
		font-family: "Work Sans";
	}
}

.poNumberContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
}

.marginBottom {
	margin-top: 10px;
}
