.combobox {
	height: 44px;
	max-width: 400px;
	&:hover {
		background: #fff !important;
	}
	input {
		cursor: pointer;
		background: #fff !important;
		&:hover {
			background: #fff;
		}
	}
}

.error {
	margin-top: 0.5rem;
}

.optionsWrapper {
	max-height: 300px !important;
}
