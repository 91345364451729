.badge {
	display: flex;
	padding: 4px 7px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	border: 1px solid;
	background: #fff;
	width: max-content;

	span {
		text-align: center;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	&__sm {
		padding: 3px 7px;
		span {
			font-size: 12px;
			font-style: normal;
		}
	}
}

.green {
	border-color: #039855;
	color: #039855;
}
.yellow {
	color: #f59e0b;
	border-color: #f59e0b;
}
.red {
	color: #d92d20;
	border-color: #d92d20;
}
.blue {
	color: #1570ef;
	border-color: #1570ef;
}
