.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px 4px 8px;
	border-radius: 7px;
	border: 1px solid #f1f5f9;
	background-color: #f8fafc;
	height: 38px;
}

.icon {
	align-items: center;
	display: flex;
}

.title {
	margin-left: 5px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
