.modal {
	&__title {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 500;
		font-size: 23.04px;
		margin: 0;
	}

	&__text {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 15.75px;
		letter-spacing: -0.5px;
		line-height: 21px;
		color: #404040;
		margin: 20px 0;
	}

	&__criteria {
		font-weight: 700;
		color: #404040;
		word-break: break-word;
	}

	&__btn_container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;
	}

	&__btn_cancel {
		background: transparent;
		color: #525252;
		font-family: "Work Sans";
		font-weight: 500;
		font-size: 14px;

		&:hover {
			background: #f5f5f5;
		}
	}
}
