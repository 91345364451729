.container {
	background-color: white;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	position: absolute;
	width: 769px;
	min-height: 221px;
	z-index: 9999;
	padding: 10px;
	display: flex;
	flex-direction: column;
	top: 5px;
	right: 0px;
	.modalBody {
		flex: 1;
	}
	.modalFooter {
		display: flex;
		justify-content: flex-start;
		.addFilterButton {
			background: transparent;
			display: flex;
			align-items: center;
			span {
				margin-left: 12px;
				font-family: "basiercircle";
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: #fd5749;
			}
		}
		.clearAllButton {
			background: transparent;
			font-family: "basiercircle";
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #202223;
		}
	}
}

.filterModalContainer {
	position: relative;
}

.singleRowContainer {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 8px 12px;
	justify-content: space-between;
	.whereLabel {
		background: #f6f6f7;
		border-radius: 4px;
		padding: 8px 12px;
		width: 78px;
		span {
			font-family: "basiercircle";
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #fd5749;
		}
	}
	.globalOperator {
		background: #f6f6f7;
		border-radius: 4px;
		padding: 12px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		.icon {
			fill: #c9cccf;
		}
		span {
			font-family: "basiercircle";
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #c9cccf;
		}
	}
}

.globalOpertatorWrraper {
	width: 78px;
	height: 36px;
}

.filterTypeWrraper {
	width: 194px;
	height: 36px;
	margin: 0px 7px;
	.filterTypeMenuLabel {
		font-weight: 600;
	}
}
.anyLabel {
	font-size: 14px;
	line-height: 20px;
	color: #202223;
	font-weight: 600;
	display: contents;
}
.opratorWrraper {
	min-width: 105px;
	height: 36px;
}

.filterOptionsWrraper {
	width: 343px;
	height: 36px;
	margin: 0px 7px;
	overflow: hidden;
}
.deleteButtonWrraper {
	button {
		background: transparent;
		border: none;
	}
}

.listMenuWrraper {
	position: relative;
}

.emptyStateContainer {
	display: flex;
	align-items: center;
	flex-direction: column;
	flex: 1;
	padding-top: 20px;
	span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #999ea4;
		margin-top: 12px;
	}
}

.externalLabel {
	color: #fd5749;
}

.backdrop {
	background-color: rgba(23, 24, 24, 0.05);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9999;
	overflow: auto;
	display: flex;
	align-items: flex-start;
	cursor: auto;
}
