.header {
	position: sticky;
	top: 0;
	z-index: 10;
	display: flex;
	padding: 21px;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid #f5f5f5;
	background-color: white;
	margin-bottom: 24px;
}

.header .title {
	flex-grow: 1;
	margin: 0;
	color: #334155;
	font-family: "BasierCircle";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.header .button {
	display: flex;
	width: 36px;
	height: 36px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;

	&:hover {
		background: #f8fafc;
	}
}

.footer {
	position: sticky;
	bottom: 0;
	z-index: 10;
	display: flex;
	padding: 16px 21px;
	align-items: center;
	gap: 8px;
	border-top: 1px solid #f5f5f5;
	background-color: white;
}

.footer .submitBtn {
	padding: 0px 24px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	color: #fff;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background-color: #006a62;
	}
}

.footer .cancelBtn {
	padding: 0px 16px;
	height: 42px;
	border-radius: 8px;
	background: #00857a;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		background: #f8fafc;
	}
}
.drawerDepartmentContainer {
	display: flex;
	flex-direction: column;
	height: 100vh;
	:global {
		.department-content {
			flex: 1;
			padding: 0px 20px 20px 20px;
		}
		.department-box {
			.dep-owner {
				> span {
					border-radius: 38px;
					border: 1px solid #d0d5dd;
					background: #fff;
					padding: 6px 16px;
					color: #344054;
					font-family: "Work Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px;
					> span {
						color: #007a6e;
						border-left: 1px solid #d0d5dd;
						padding-left: 8px;
						margin-left: 6px;
					}
				}
			}
		}
		.checkboxStyle {
			> div {
				border: none;
				padding: 0;
				display: flex;
				flex-direction: row-reverse;
				gap: 8px;
			}
			label {
				color: #344054;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				text-transform: none;
			}
		}
		.select-wrapper .select-label {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			text-transform: none;
		}
		.department-select .Select-control {
			border-radius: 8px;
			border: 1px solid #cbd5e1;
			background: #fcfdfb;
			.Select-placeholder {
				color: #94a3b8;
				font-family: "Work Sans";
				font-size: 16px !important;
				font-style: normal;
				font-weight: 400;
			}
		}
	}
}
.inputField {
	:global {
		.form-group label {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			text-transform: none;
			&::after {
				content: "*";
				color: #d92d20;
				font-family: "Work Sans";
				font-size: 16px;
				right: -6px;
				position: relative;
			}
		}
		input.form-control {
			border-radius: 8px;
			border: 1px solid #cbd5e1;
			background: #fcfdfb;
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
	}
}
