/*Default Classes*/
.flex {
	display: flex;
}
.alignCenter {
	align-items: center;
}
/*End Default Classes */

.root {
	@extend .flex;
	@extend .alignCenter;
}
.potentialButton {
	position: relative;
	margin-left: 10px;
	button {
		height: 40px;
		font-family: "Work Sans";
		display: flex;
		padding: 8px 16px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		background: #00857a;
		color: white;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
	}
}
.moreActionBtn {
	height: 40px;
	display: flex;
	padding: 8px 12px 8px 16px;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
}
.primaryAction {
	background: #ffffff;
	border: 1px solid #d8dbdd;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #202223;
	padding: 5px 12px;
	margin-right: 10px;
}
.actionsMenu {
	position: relative;
}

.moreActionText {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 24px */
}
