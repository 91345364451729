.tag {
	display: flex;
	align-items: center;
	height: 36px;
	padding: 6px 8px;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	&.hasError {
		border-color: red;
	}
	.tagLabel {
		color: #334155;
		font-family: Work Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: -0.5px;
		margin: 0;
	}
	.tagHr {
		border: none;
		margin: 0;
		border-left: 1px solid #e2e8f0;
		width: 1px;
		height: 100%;
	}
}
