.container {
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 10px 0px;
}

.searchInputContainer {
	padding: 10px 15px;
}
.listTemplates {
	padding: 10px 0;
	flex-grow: 1;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}
.templateItem {
	display: flex;
	padding: 8px 15px;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	align-self: stretch;
	cursor: pointer;
	&:hover {
		background: #e6f3f2;
	}
}

.name {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.version {
	text-transform: uppercase;
	padding: 5px 6px;
	border-radius: 2px;
	border: 1px solid #e2e8f0;
	background: #fff;

	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
}

.skeleton {
	padding: 13px 15px;
	border-radius: 8px;
	border-bottom: 1px solid #f1f5f9;
}
