@import "../../Variables/styles.scss";

.section {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-right: 9px;
	&:not(:last-child) {
		margin-bottom: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid $ghostWhite;
	}
	.title {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.25;
		color: $blackPearl;
	}
	.titleS {
		@include cardsTitle;
		color: $blackPearl;
		font-size: 14px;
		line-height: 1.25;
	}
	.row {
		display: flex;
		gap: 16px;
		flex-wrap: wrap;
		margin-bottom: 8px;
		.tag {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 4px 8px;
			background: $solitude;
			border-radius: 2px;
			p {
				margin: 0;
				font-family: "BasierCircle";
				font-weight: 400;
				font-size: 14px;
				line-height: 1.25;
				color: $blackPearl;
			}
		}
	}
}
.emptyState {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	p {
		@include profileContent;
		color: $blackPearl;
		margin-left: 3px;
	}
}
.langs {
	display: flex;
	flex-direction: column;
	margin-right: 9px;
	gap: 0;
}
