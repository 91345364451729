.optionsGroup {
	display: flex;
	flex-direction: column;
	margin-top: 12px;

	.optionWrapper {
		display: flex;
		flex-direction: column;
		padding: 8px 12px;
		border: 1px solid #e2e8f0;
		border-radius: 12px;
		margin-bottom: 12px;
		background: #f8fafc;

		.option {
			display: flex;
			align-items: center;
			position: relative;
			cursor: pointer;
			.optionTitle {
				display: flex;
				flex-direction: column;
				margin-left: 15px;
				label {
					color: #334155;
					font-family: "Work Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
				}
				span {
					color: #64748b;
					font-family: "Work Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}
			}
			input[type="radio"] {
				line-height: 1.125rem;
				height: 1.714rem;
				width: 1.714rem;
				margin-left: auto;
				position: absolute;
				right: 5px;
				cursor: pointer;
				&:checked + .radioStyle {
					&:before {
						box-shadow: inset 0 0 0 0.6em #00857a;
					}
				}
			}
			.radioStyle {
				margin-left: auto;
				&::before {
					display: flex;
					flex-shrink: 0;
					content: "";
					height: 1.714rem;
					width: 1.714rem;
					border-radius: 50%;
					margin-right: 0.375em;
					transition: 0.25s ease;
					box-shadow: inset 0 0 0 0.125em #d1d5db;
				}
			}
		}
	}
}
