.actions {
	margin-left: auto;
}

.cta {
	background: transparent;
	padding: 0;
	width: 24px;
	height: 24px;
}

.popover {
	display: flex;
	flex-direction: column;
	width: 210px;
	overflow: hidden;
	margin-top: -4px;
	padding: 4px 0;

	color: #262626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	border-radius: 3px;
	border: 1px solid #e5e5e5;
	background: #fff;
	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
}

.menu {
	outline: none;
}

.menuItem {
	outline: none;
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	outline: none;

	label {
		margin-bottom: 0;
	}

	&:hover {
		background: #f8fafc;
		svg {
			color: #262626;
		}
	}

	svg {
		color: #d4d4d4;
	}
}
