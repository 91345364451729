.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 1.5rem;
	flex-basis: 80%;
}

.addButton {
	height: 44px;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
}

.addButton div {
	color: #334155;
	font-size: 16px;
}

.addIcon path {
	stroke: #334155;
	stroke-width: 1;
}

.clockIcon path {
	fill: #94a3b8;
}

.docIcon path {
	stroke: #94a3b8;
}
