.containerWithSibling,
.container {
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
		border-radius: 99px;
	}
}

.container {
	flex-grow: 1;
	overflow-y: auto;
}

.containerWithSibling:nth-child(1) {
	flex-shrink: 0;
	max-height: 50%;
	overflow-y: auto;
}

.containerWithSibling:nth-child(2) {
	flex-grow: 1;
	overflow-y: auto;
}

.header {
	position: sticky;
	top: 0;
	z-index: 1;

	padding: 16px 20px;
	border-top: 1px solid var(--border-gray, #e2e8f0);

	color: var(--gray-700, #334155);
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
	background-color: #ffffff;
}

.infiniteLoadingIndicator {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--gray-700, #334155);
}

.templateName {
	font-weight: 500;
}
