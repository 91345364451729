$borderRadius: 8px;

.actions {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	align-self: flex-start;
	gap: var(--1, 4px);

	.customSelect {
		position: relative;
		background: #ffffff;
		border-radius: var(--md, $borderRadius);

		.visible {
			max-height: 500px;
			height: auto;
			opacity: 1;
			transition: max-height 0.8s ease-in-out, opacity 0.4s ease-in-out;
			margin-top: 3px;
			position: absolute;
			width: 208px;
			right: 0;

			.optionsList {
				display: flex;
				min-width: 150px;
				max-width: 208px;
				flex-direction: column;
				align-items: flex-start;
				position: absolute;
				width: 100%;
				max-height: 18rem;
				overflow-y: auto;
				margin: 0;
				border-radius: var(--md, $borderRadius);
				border: 1px solid var(--border-gray, #e2e8f0);
				background: #fff;
				box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
					0px 4px 8px -2px rgba(16, 24, 40, 0.1);

				&::-webkit-scrollbar-track {
					border: 1px solid #f9fafb;
					padding: 2px 0;
					background-color: #f9fafb;
					border-radius: 0 0 $borderRadius 0;
				}

				&::-webkit-scrollbar {
					width: 4.5px;
					border-radius: $borderRadius;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background-color: #d4d4d4;
					border: 1px solid #d4d4d4;
				}

				.option {
					display: flex;
					height: 36px;
					padding: 10px 12px;
					align-items: center;
					gap: 8px;
					align-self: stretch;
					cursor: pointer;
					text-decoration: none;

					svg {
						width: 20px;
						height: 20px;
					}

					.text {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: var(--text-dark, #1e293b);
						text-overflow: ellipsis;
						font-family: "Work Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;

						&_danger {
							color: var(--text-red, #d92d20);
						}
					}

					&:hover {
						background-color: #f5f5f5;
					}
					&.selected {
						background-color: #f5f5f5;
						font-weight: bold;
					}
					&_disabled {
						background: var(--brand-500, #f1f5f9);
						color: var(--text-white, #cbd5e1);
						cursor: not-allowed;
					}
				}
			}
		}

		.more {
			display: flex;
			padding: var(--2, 8px);
			justify-content: center;
			align-items: center;
			gap: var(--2, 8px);
			border-radius: var(--md, 8px);
			border: 1px solid var(--border-gray, #e2e8f0);
			background: var(--text-white, #fff);

			&:hover {
				border: 1px solid var(--border-gray, #e2e8f0);
				background: var(--bg-hover, #f8fafc);
			}

			&:focus {
				border: 1px solid var(--border-gray, #e2e8f0);
				box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
			}
		}
	}

	.hidden {
		width: 0;
		max-height: 0;
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}

	.primaryBtn {
		display: flex;
		padding: 10px var(--4, 16px);
		align-items: center;
		gap: 10px;
		align-self: stretch;
		border-radius: var(--md, 8px);
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: -0.5px;
		text-decoration: none;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
			text-wrap: nowrap;
		}
	}

	.success {
		border: 1px solid var(--brand-400, #339d95);
		background: var(--brand-500, #00857a);
		color: var(--text-white, #fff);

		&:hover {
			background: var(--bg-brand-hover, #006a62);
		}

		&:focus {
			background: var(--bg-brand, #00857a);
			box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
		}
	}

	.danger {
		border: 1px solid var(--brand-400, #d92d20);
		background: var(--brand-500, #d92d20);
		color: var(--text-white, #fff);

		&:hover {
			background: var(--bg-brand-hover, #b42318);
		}

		&:focus {
			background: var(--bg-brand, #d92d20);
			box-shadow: 0px 0px 0px 3px rgba(217, 45, 32, 0.2);
		}
	}

	.info {
		border: 1px solid var(--border-gray, #e2e8f0);
		background: var(--white, #fff);
		color: var(--text-white, #334155);

		&:hover {
			background: var(--bg-brand-hover, #f8fafc);
		}

		&:focus {
			border: 1px solid var(--border-gray, #e2e8f0);
			background: var(--bg-white, #fff);
			box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
		}
	}
	.disabled {
		border: 1px solid var(--brand-400, #f1f5f9);
		background: var(--brand-500, #f1f5f9);
		color: var(--text-white, #cbd5e1);
		cursor: not-allowed;
	}
}
