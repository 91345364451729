.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 20px;
	height: 100%;
}

.calendar {
	height: 100%;
}

.columnHeader {
	display: block;
	padding: 10px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	width: 100%;
	text-align: left;
	text-transform: uppercase;
	&.isCurrentDay {
		color: #00857a;
		font-weight: 600;
		border-bottom: 2px solid #00857a;
	}
}

.weekend {
	background-color: #fff;
}

.timezone {
	color: #334155;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.old {
	opacity: 0.5;
}
.scheduleMenuItem {
	width: 100%;
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	align-self: stretch;
	background: none;
	border: none;
	color: #334155;
}
.scheduleMenuIcon {
	path {
		stroke: #334155;
	}
}
.scheduleMenuItemText {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.hiddenButton {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

.eventClassName {
	width: 100%;
	height: 100%;
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
	overflow: hidden;
	padding: 2px;
	text-transform: capitalize !important;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500 !important;
	line-height: 150% !important;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
	border-radius: 4px !important;
	background-color: #e6f3f2 !important;
	border: 1px solid #339d95;
	&.interview {
		border: 1px solid #f59e0b;
		background: #fffbeb !important;
		&.oldEventStyle {
			border: 1px solid #f59e0b;
			background: #fffbeb !important;
		}
	}
	&.attendee {
		background: #fff !important;
		border: 1px solid #cbd5e1;
		box-shadow: none !important;
		&.oldEventStyle {
			border-color: #cbd5e1;
		}
	}
	&.oldEventStyle {
		cursor: pointer;
		border-color: #99ceca;
		background: #e6f3f2;
	}
}

.seeMore {
	text-decoration: none;
	overflow: hidden !important;
	color: #00857a;
	text-overflow: ellipsis;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150% !important;
	border-radius: 4px !important;
	background: #f1f5f9;

	width: 100%;
	text-align: center;
	&:hover {
		background-color: #f1f5f9 !important;
	}
}

.eventInfo {
	display: inline-flex;
	align-items: center;
	gap: 4px;
	overflow: hidden;
}
.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
	max-height: 17px;
	width: 17px;
}
.eventTitle {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
	line-height: 100%;
}
.avatar {
	display: flex;
}

.currentDay {
	:global(.fc-daygrid-day-number) {
		padding: 0 3px;
		border-radius: 4px;
		background: #e6f3f2;
		color: #00857a !important;
		font-family: "Work Sans" !important;
		font-size: 12px !important;
		font-style: normal !important;
		font-weight: 600 !important;
		line-height: 150% !important;
	}
}

.allDay {
	visibility: hidden;
}
