.tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	padding: 4px 8px;
	background: #e4e5e7;
	border-radius: 2px;
	p {
		margin: 0;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 1.21;
		color: #202223;
	}
}
.outline {
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #babfc3;
}
