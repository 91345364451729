.container {
	height: 100%;
	position: relative;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-bottom: 1px solid #e2e8f0;

	color: #94a3b8;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	.infos {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.info {
		display: flex;
		align-items: center;
		gap: 2px;
	}

	.icon {
		display: flex;
		align-items: center;
		color: #64748b;
	}

	.by {
		color: #000000;
		margin-right: 6px;
	}
}

.formContainer {
	flex-grow: 1;
	display: flex;
	overflow-y: auto;
}

.form {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.formAndFooter {
	flex-grow: 1;
	display: flex;
	overflow-y: auto;
	flex-direction: column;
}

.titleField {
	padding-top: 16px;
	padding-left: 16px;
	padding-right: 16px;
	.titleInput {
		all: unset !important;
		color: #64748b !important;
		font-family: "Work Sans" !important;
		font-size: 20px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 150% !important;
		width: 100% !important;
		margin-bottom: 4px !important;
		&::placeholder {
			color: #94a3b899;
		}
	}
	margin-bottom: 8px;
}

.contentErrors {
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-top: 16px;
}

.subjectHeader {
	display: flex;
	gap: 20px;
	align-items: center;

	padding: 12px 16px 12px 16px;
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	border-bottom: 1px solid #f3f4f6;

	.subjectInputContainer {
		flex-grow: 1;
	}

	.placeholdersButton {
		all: unset;
		cursor: pointer;
		display: flex;
		padding: 4px 8px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		border-radius: 8px;
		border: 1px solid #f3f4f6;
		background: #fff;

		font-size: 14px;

		&:hover {
			background-color: #f3f4f6;
		}
	}

	.bracket {
		color: #00857a;
	}
}

.footer {
	background-color: white;
	display: flex;
	justify-content: space-between;
	padding: 16px;

	.visibility {
		display: flex;
		align-items: center;
		gap: 4px;

		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	.visibilityBtn {
		all: unset;
		cursor: pointer;
		display: flex;
		padding: 8px 12px;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		color: #64748b;
		background: #fff;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 13px;
		&:hover {
			background-color: #f3f4f6;
		}
	}

	.saveBtn {
		all: unset;
		cursor: pointer;
		display: flex;
		padding: 0 12px;
		height: 36px;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		background: #00857a;
		color: #fff;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 13px;
		&:hover {
			background-color: #006a62;
		}
		&:disabled {
			cursor: not-allowed;
			opacity: 0.6;
		}
	}
}

.editorWrapper {
	position: relative;
	flex-grow: 1;
	:global {
		.editor-container {
			height: 100%;
		}
		.editor-inner {
			position: relative;
		}
		.editor-scroller {
			border: none;

			height: 100%;

			position: absolute;
			inset: 0;
			&::-webkit-scrollbar {
				width: 6px;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: rgba(0, 0, 0, 0.2);
				border-radius: 99px;
			}
		}
		.ContentEditable__root {
			padding: 16px;
		}
		.editor-placeholder {
			top: 18px;
			left: 16px;
		}
	}
}

.systemLabel {
	color: #00857a;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	display: flex;
	align-items: center;
	gap: 4px;
}

.placeholderContainerClassName {
	width: 235px;
	border-left: 1px solid #e2e8f0;
	display: flex;
	overflow-y: auto;
	flex-direction: column;
	height: calc(100vh - 123px);
	padding: 0 !important;
}
.palceholderHeader {
	background: #334155;
	display: flex;
	padding: 10px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	color: #fff;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	button {
		all: unset;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.subjectEditor {
	all: unset;
	outline: none;
	width: 100%;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
}

.modalFooter {
	display: flex;
	gap: 10px;
	align-items: stretch;
	button {
		height: 100%;
	}
}

.discardButton {
	background: #fff;
	color: #d92d20;
	box-shadow: 0px 0px 0px 1px #d92d20 inset;
	&:hover {
		background: #d92d2033;
	}
}

.cancelButton {
	background: #fff;
	color: #334155;
	box-shadow: 0px 0px 0px 1px #e2e8f0 inset;
	&:hover {
		background: #e2e8f0;
	}
}

.contentHelper {
	flex-shrink: 0;
}

.contentError {
	padding: 16px 16px 0 16px;
}
