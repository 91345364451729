.emptyStateMessage {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 16px;
}

.container {
	flex-grow: 1;
	overflow-y: auto;
	margin-top: 12px;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
		border-radius: 99px;
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 1;

	padding: 16px 20px;
	background-color: white;

	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.infiniteLoadingIndicator {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #334155;
}

.templateName {
	font-weight: 600;
}
