.labelCbx {
	user-select: none;
	cursor: pointer;
	margin-bottom: 0;
	display: flex;
	align-items: center;
}
.labelCbx input:checked + .checkbox {
	border-color: #00857a;
}
.labelCbx input:checked + .checkbox svg path {
	fill: #00857a;
}
.labelCbx input:checked + .checkbox svg polyline {
	stroke-dashoffset: 0;
}
// .labelCbx .checkbox {
// 	position: relative;
// 	float: left;
// 	margin-right: 8px;
// 	width: 20px;
// 	height: 20px;
// 	border: 2px solid #999999;
// 	border-radius: 3px;
// }

.checkboxSvg {
	display: block;
}

.labelCbx .checkbox svg {
	position: absolute;
	top: -2px;
	left: -2px;
}
.labelCbx .checkbox svg path {
	fill: none;
	stroke: #20c2e0;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 71px;
	stroke-dashoffset: 71px;
	transition: all 0.6s ease;
}
.labelCbx .checkbox svg polyline {
	fill: none;
	stroke: #fff;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 18px;
	stroke-dashoffset: 18px;
	transition: all 0.3s ease;
}
.labelCbx > span {
	pointer-events: none;
	vertical-align: middle;
}

.cntr {
	width: 100%;
	text-align: center;
}

.invisible {
	position: absolute;
	z-index: -1;
	width: 0;
	height: 0;
	opacity: 0;
}
.unchecked {
	display: block;
}
.checkbox {
	stroke: #a3a3a3;
	display: block;
	&:hover {
		stroke: #737373;
	}
}
.emptySvg {
	display: block;
}
