.g-tool-dark {
	.rc-tooltip-inner {
		background-color: #1e293b !important;
		border-radius: 5px !important;
		padding: 8px 14px !important;
		box-shadow: none !important;
		.overlay-container {
			display: flex;
			flex-direction: column;
			gap: 9px;
			div {
				display: flex;
				flex-direction: column;
				gap: 6px;
				h6 {
					font-family: "Roboto";
					font-style: normal;
					line-height: 16px;
					font-weight: 700;
					font-size: 13px;
					color: white;
				}
				p {
					font-family: "Roboto";
					font-style: normal;
					line-height: 16px;
					padding-left: 8px;
					font-weight: 400;
					font-size: 13px;
					color: white;
				}
			}
		}
	}
	.rc-tooltip-arrow {
		background-color: #1e293b !important;
		z-index: 9999;
	}
}

// svg icons
.i {
	&-success {
		&-50 path {
			stroke: #34d39950;
		}
	}
	&-danger {
		&-50 path {
			stroke: #f8717150;
		}
	}
	&-secondary {
		&-50 path {
			stroke: #94a3b850;
		}
	}
}
