@import "../../../Variables/styles.scss";

@mixin font {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}
.button {
	color: $primayColor;
	@include font;
	background: none;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	border-radius: $raduisButton;
	border: 1px solid $ghostWhiteLight;
	height: 3rem;
	padding: 0 6px;
	display: flex;
	align-items: center;
}

.root {
	background-color: $white;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	.contentLoading {
		filter: blur(10px);
	}
	.topHeader {
		flex-basis: 53px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e5e5e5;
		padding: 1rem;
		.close__drawer {
			cursor: pointer;
		}
		.name__conidate {
			display: flex;
			align-content: center;
			gap: 0.5rem;
		}
		.title {
			@include font;
			font-size: 1.25rem;
			font-weight: 600;
			line-height: 1.75rem;
			color: #262626;
			text-transform: capitalize;
			margin: 0;
		}
	}
	.actions {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.addNote {
			background: $button;
			box-shadow: $box-shadow;
			border-radius: 4px;
			color: $white;
		}
	}

	.profile {
		.vacancy {
			margin-left: 4rem;
			display: flex;
			align-items: center;
			gap: 0.4rem;
			.linkToText {
				@include font;
				color: $raven;
			}
			.linkTo {
				@include font;
				color: $atoll;
				background: none;
				padding: 0;
				display: flex;
				align-items: center;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.description {
		@include font;
		font-size: 16px;
		line-height: 1.25;
		word-break: break-word;
		padding-bottom: 1.6rem;
	}
	.drawer__footer {
		flex-basis: 65px;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 1rem;
		margin-bottom: 0px;
		border-top: 1px solid #e5e5e5;
		display: flex;
		align-items: center;
		.big__item {
			display: flex;
			-webkit-flex: 2 0 0;
			flex: 2 0 0;
			gap: 0.5rem;
		}
		.small__item {
			-webkit-flex: 1 0 0;
			flex: 1 0 0;
			text-align: end;
			.navigation {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 0.5rem;
				.value__pagination {
					font-size: 1rem;
					font-weight: 500;
					line-height: 1.125rem;
					font-family: "Work Sans";
					color: #525252;
				}
				.chevron {
					background-color: none;
					padding: 0.5rem;
					display: flex;
					align-items: center;
					background: none;
					&:hover {
						background-color: #f5f5f5;
					}
				}
			}
		}
	}
}

.loader_container {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
