.rc-cascader-menus.rc-cascader-menus-placement-bottomLeft {
	background: transparent;
	border: none;
	box-shadow: none;
	border-radius: 0;
	z-index: 9999;
}
.rc-cascader-menu {
	width: 254px;
	height: 350px;
}
ul.rc-cascader-menu:first-child,
.rc-cascader-menu:last-child {
	border-top: 1px solid #d9d9d9;
}
ul.rc-cascader-menu:first-child {
	border-bottom: 1px solid #d9d9d9;
	border-left: 1px solid #d9d9d9;
}

.rc-cascader-menu-item {
	padding: 9px 30px !important;
	height: auto;
	font-size: 15px;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	background-color: white;
	border-right: 1px solid #d9d9d9;
	border-left: 4px solid #fff;
}
.rc-cascader-menu-item:last-child {
	border-bottom: 1px solid #d9d9d9;
}
.rc-cascader-menu-item:hover,
.rc-cascader-menu-item.rc-cascader-menu-item-active {
	border-left: 4px solid #ff6849;
	background-color: white;
}

.rc-cascader-menu-item-active {
	background-color: white;
}
