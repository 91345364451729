.label {
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	display: flex;
	align-items: center;
	gap: 6px;
	margin-bottom: 12px;
}

.candidateValue {
	display: flex;
	align-items: center;
	gap: 7px;

	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	&.disabled {
		color: #a3a3a3;
	}
}

.disabledAvatar {
	opacity: 0.7;
}
.entityContainer {
	display: flex;
	padding: 7px 10.5px;
	align-items: center;
	gap: 7px;
	align-self: stretch;

	color: #262626;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	&:hover {
		cursor: pointer;
	}
}

.rootDropDown {
	height: 40px;
}
.dropdownContent {
	max-height: 300px;
	overflow: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}

.dropDownInputClassName {
	padding: 8px 12px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	height: 100%;
	max-width: 297px;
	box-shadow: none !important;
	&:focus {
		box-shadow: none;
	}
	&:active {
		box-shadow: 0px 0px 0px 1px #66b6af inset;
	}
}

.avatar {
	display: flex;
}

.icon {
	path {
		stroke: #64748b;
	}
}
.error {
	margin-top: 10px;
}
