.intro {
	margin-top: 10px;
}

.searchContainer {
	display: flex;
	justify-content: space-between;
	float: left;
	width: 100%;
	margin: 20px 0px;
}

.searchInput {
	line-height: 24px;
	font-size: 16px;
	padding: 0px 12px;
	border-radius: 8px;
	width: 350px;
	height: 40px;
}

.addButton {
	background: #ffffff;
	font-size: 16px;
	line-height: 150%;
	height: 100%;
}

.addButton:disabled {
	box-shadow: 0px 0px 0px 1px #d4d4d4 inset;
	border: none !important;
}

.pagination li > a {
	background-color: transparent;
}

.emptyState {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
}
