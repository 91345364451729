.container {
	padding: 13px 0px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	max-height: 200px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #737373;
		border-radius: 2px;
	}

	.card {
		position: relative;

		.header {
			display: flex;
			align-items: center;
			gap: 0.25rem;
			padding: 0.5rem 0.75rem 0.75rem 0.75rem;
			color: #737373;
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1rem;
			background-color: white;
			font-family: "Work Sans";

			.number {
				color: #339d95;
				font-weight: 600;
			}

			.line {
				background-color: #e5e5e5;
				height: 1px;
				flex: none;
				flex-grow: 1;
			}
		}

		.selectedSkill {
			display: flex;
			gap: 20px;
			align-items: center;
			min-width: 100%;
			justify-content: space-between;

			.label {
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-transform: capitalize;
			}

			div {
				flex-basis: 40%;
			}
		}

		.tag > div {
			padding: 2px;

			.label {
				color: #262626;
				border-right: 1px solid #edeeef;
				width: 40%;
				font-size: 1rem;
			}

			.star {
				border-right: 1px solid #edeeef;
				width: 60%;
			}
		}

		.chipsColumn {
			padding: 0 0.75rem;
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.chipsRow {
			padding: 0 0.75rem;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			gap: 5px;
			font-size: 1rem;
		}

		.tags {
			padding: 0 0.75rem;

			.wrapper {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				gap: 0.5rem;
				.text {
					font-size: 1rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-transform: capitalize;
				}
			}
		}
	}
}
