@mixin textStyle {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.5px;
}

.uploaderContainer {
	@include textStyle;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	border: 1px dashed;
	border-color: #cbd5e1;
	border-radius: 8px;
	padding: 16px 24px;
	width: 100%;
	&.isDropTarget {
		border-color: #00857a;
		background-color: #00857a05;
	}
	&:hover {
		background-color: #f8fafc;
	}
	&.isReadOnly {
		pointer-events: none;
	}
}

.iconWrapper {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	border: 3px solid #f8fafc;
	background: #f1f5f9;
	color: #64748b;
}

.highlighted {
	color: #00857a !important;
}

.previewContainer {
	@include textStyle;
	display: flex;
	padding: 16px;
	border: 1px solid;
	border-color: #cbd5e1;
	gap: 16px;
	border-radius: 8px;
	width: 100%;
	.fileInfos {
		display: flex;
		gap: 16px;
		align-items: center;
		white-space: nowrap;
		flex-grow: 1;
		min-width: 0;
		.name {
			color: #374151;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.fileStatus {
		display: flex;
		gap: 16px;
		align-items: center;
		flex-shrink: 0;
		.completed {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 8px;
			.label {
				color: #26ad74;
				font-weight: 500;
			}
			.deleteIcon {
				display: flex;
				align-items: center;
				margin-left: 8px;
			}
		}
		.progress {
			display: flex;
			align-items: center;
			gap: 16px;
			.percentage {
				color: #374151;
				font-weight: 500;
				width: 30px;
			}
			.bar {
				width: 100px;
				height: 8px;
				border-radius: 99px;
				background-color: #f6f6f6;
				overflow: hidden;
				div {
					height: 100%;
					background: #26ad74;
					transition: width 0.2s;
				}
			}
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 12px;
}
