.tooltip {
	left: 125px;
	width: 250px;
}
.title {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #48494a;
}
