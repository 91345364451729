.root {
	position: relative;
}

.root span,
.root p {
	color: #394047;
	word-break: normal;
}

.description ol,
.description ul {
	padding-left: 40px;
}
