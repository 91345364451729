.root {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2.5rem;
}

.fieldsContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: lighten(#eff0f3, 2%);
	padding: 1rem;
	border-radius: 5px;
	label {
		font-size: 13px;
	}
	.error {
		color: red;
		font-size: 13px;
	}
}
