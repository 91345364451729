.header {
	display: flex;
	justify-content: space-between;
}
.body {
	border-radius: 8px;
	position: relative;
	padding-bottom: 32px;
	background-color: #fff;
}
.columns {
	gap: 20px;
	display: flex;
	padding: 20px 0;
	margin: 0px 20px;
	align-items: flex-start;
	border-top: 1px solid #e2e8f0;
}
.main {
	width: 70%;
}
.sidebar {
	width: 30%;
	display: flex;
	padding: 16px;
	background: #fff;
	border-radius: 8px;
	flex-direction: column;
	align-items: flex-start;
	border: 1px solid #e2e8f0;
}
.sidebarActions {
	gap: 12px;
	width: 100%;
	display: flex;
	padding-bottom: 20px;
	flex-direction: column;
	border-bottom: 1px solid #e2e8f0;
}
/* ------- header actions ------- */
.headerActions {
	gap: 10px;
	display: flex;
	margin-left: auto;
	flex-direction: "row-reverse";
}
.btn {
	gap: 8px;
	height: 40px;
	display: flex;
	border-radius: 8px;
	padding: 12px 16px;
	align-items: center;
	justify-content: center;
	//
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
	font-style: normal;
	font-family: "Work Sans";

	&:focus-visible {
		outline: none !important;
	}

	&Full {
		width: 100%;
	}
	&Lg {
		width: 268px;
	}

	&Green {
		color: white;
		background-color: #00857a;
		&:hover {
			background-color: #006a62;
		}
	}

	// with border
	&Success {
		color: #00857a;
		background-color: white;
		border: 1px solid #99ceca;
		&:hover {
			background-color: #e6f3f2;
		}
	}

	&Secondary {
		height: 36px;
		font-size: 14px;
		color: #1e293b;
		padding: 8px 16px;
		background-color: #ffff;
		border: 1px solid #e2e8f0;

		&:hover {
			background-color: #f9fafb;
		}
	}
}

.backButtonContainer {
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 16px;
	justify-content: space-between;
}
