.container {
	display: flex;
	gap: 4px;
	.g-tool {
		margin: 0;
	}
	.avatar {
		overflow: unset;
	}
	img {
		border-radius: 50%;
		width: 2.4rem;
		height: 2.4rem;
	}
	span {
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 50%;
		background: #f0f3f5;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 12px;
		line-height: 1.25;
		color: #303131;
	}
}
.flex {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	.avatar {
		overflow: unset;
	}
	p {
		font-family: "basiercircle";
		font-size: 12px;
		line-height: 1.25;
		color: #303131;
	}
}
.avatar {
	& > div {
		overflow: visible;
	}
}
