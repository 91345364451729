.placeholder {
	border-radius: 5px;
	background: #e6f3f2;
	padding: 2px 0;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 14px;
	line-height: 1;
}

.placeholder::after {
	content: "]";
	font-weight: 600;
	color: #00857a;
	margin-left: -5px;
	margin-right: 5px;
	position: relative;
	z-index: 1;
}

.placeholder::before {
	content: "[";
	font-weight: 600;
	color: #00857a;
	margin-left: 5px;
	margin-right: -5px;
	position: relative;
	z-index: 1;
}
