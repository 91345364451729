.container {
	display: flex;
	flex-direction: column;
	gap: 12px;

	svg {
		margin-right: 0px;
	}
}

.labelStyles {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	width: 30%;
	cursor: pointer;
}

.required {
	color: #dc2626;
	font-family: "BasierCircle";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin-top: 8px;
}

.labelStyles {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	width: 30%;
	cursor: pointer;
}
.radioContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
