.container {
	padding: 16px 15px 0 15px;
}

.containerFooter {
	position: relative;
	width: 100%;
	min-height: 40px;
	margin-top: 1.5rem;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	width: 100%;
	margin-bottom: 20px;
	h3 {
		font-weight: 500;
		font-size: 1.5rem;
		font-family: "basiercircle", serif;
		line-height: 2rem;
		letter-spacing: -0.5px;
		color: #262626;
	}
	button {
		background-color: #f8fafc;
		cursor: pointer;
		padding: 0.75rem;
		margin: 0;

		&:hover {
			background-color: #f5f5f5;
		}

		&svg {
			display: block;
		}
	}
}

.headerSeparator {
	background-color: #e5e5e5;
	height: 1px;
}

.backAction {
	display: flex;
	align-items: baseline;
	gap: 10px;
}

.sendInvoiceButton {
	height: 44px;
	display: flex;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	&:disabled {
		color: #cbd5e1;
		background: #f1f5f9;
	}
}
.text {
	font-family: "Work Sans";
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 24px !important;
}
