.container {
	display: flex;
	padding: 16px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 12px;
	background: #f8fafc;
}
