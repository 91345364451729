.valueWrapper {
	display: flex;
	gap: 5px;
	&.modeInline {
		flex-wrap: wrap;
	}
}

.dropDownInput {
	height: 100%;
	padding: 0.2rem 0.5rem;
}

.loaderContainer {
	margin: auto;
	width: 10px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader div {
	border-right-color: #00857a;
}

.root {
	position: relative;
}

.rootDropDown {
	overflow-y: unset;
}

.addButtonComponent {
	display: flex;
	align-items: center;
	.addButton {
		background: none;
		color: #94a3b8;
		display: flex;
		padding: 3px;
		align-items: center;
		border-radius: 12px;
		border: 1.3px dashed #94a3b8;

		.plusIcon {
			width: 16px;
			height: 16px;
		}
	}
}

.avatarWrapper {
	width: fit-content;
	display: flex;
	align-items: center;
	cursor: none;
}
div.firstCollaboratorPicture {
	margin-left: -3px;
}

.collaboratorPicture {
	border-radius: 50%;
	border: 3px solid white;
	margin-left: -8px;
}

.picture {
	max-width: 100%;
	max-height: 100%;
	border-radius: 50%;
}

.avatarWithBorder {
	margin-left: -8px;
	border: 3px solid white;
}
.avatar {
	display: flex;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;

	> div {
		position: relative;
		font-size: 13px;
	}
}
