.container {
	font-family: "Work Sans";
	background-color: #fff;
	display: flex;
	align-items: flex-start;
	min-height: calc(100vh - 70px);
	margin-bottom: -60px;
	padding: 20px;
}

.sideBar {
	margin-right: 20px;
}

.contentWrapper {
	padding: 0 0 110px;
	flex-grow: 1;
}
