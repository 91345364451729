.badge {
	display: flex;
	padding: 4px 21px;
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-radius: 20px;
	background: #fff;
	width: max-content;

	span {
		font-variant-numeric: lining-nums tabular-nums;
		text-align: center;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;

		&::first-letter {
			text-transform: uppercase;
		}
	}
}

.green {
	border-color: #039855;
	color: #039855;
}
.red {
	color: #d92d20;
	border-color: #d92d20;
}
.yellow {
	color: #f59e0b;
	border-color: #f59e0b;
}
.blue {
	color: #1570ef;
	border-color: #1570ef;
}
