@import "modules/SearchCandidate/TableVariables/fonts.scss";

.container {
	.header {
		display: flex;
		align-items: center;
		gap: 14px;
		margin-bottom: 14px;
	}

	.title {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 600;
		font-size: 15.75px;
		line-height: 21px;
		letter-spacing: -0.5px;
		color: #262626;
	}

	.card {
		box-sizing: border-box;
		position: relative;
		width: 100%;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		padding: 1rem;
	}

	.buttonWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.button {
		color: #525252;
		box-shadow: 0px 0px 0px 1px #e5e5e5 inset;

		&:hover {
			background-color: #fafafa;
		}
	}

	button.buttonDisabled {
		color: #e5e5e5;
		box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
		border: none;

		&:hover {
			background-color: white;
		}
	}

	.picture {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0px;

		/* /ring-2/ring-white */

		box-shadow: 0px 0px 0px 2px #ffffff;
		border-radius: 50%;
		background: white;

		cursor: pointer;
	}

	.avatar {
		display: flex;
		align-items: center;
		border-radius: 50%;
		cursor: pointer;

		> div {
			position: relative;
			font-size: 13px;
		}
	}

	.avatarWithBorder {
		margin-left: -8px;
		border: 3px solid white;
	}

	.label {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #737373;
	}

	.urlRead,
	.urlEdit {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
	}

	.urlRead {
		gap: 7px;
	}

	.urlEdit {
		gap: 17px;
	}

	.link {
		color: #2563eb;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		text-decoration-line: underline;
		margin-right: 7px;
	}

	.lastEditedContainer {
		display: flex;
		gap: 8px;
		align-items: center;
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12.25px;
		line-height: 14px;
		color: #737373;
	}

	.collaboratorsContainer {
		display: flex;
		gap: 1px;
		align-items: center;
	}

	div.firstCollaboratorPicture {
		margin-left: -3px;
	}

	.collaboratorPicture {
		border-radius: 50%;
		border: 3px solid white;
		margin-left: -8px;
	}

	.images {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.lastEditDate {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12.25px;
		line-height: 14px;
		color: #737373;
	}

	.editIcon,
	.copyIcon {
		cursor: pointer;
	}

	.avatarWrapper {
		width: fit-content;
		display: flex;
		align-items: center;
	}

	.iconWrapper {
		display: flex;
		align-items: center;
	}

	.row {
		display: grid;
		grid-template-columns: 170px 1fr;
		align-items: center;
		padding: 1rem 0;

		&:last-child {
			padding-bottom: 0;
		}
	}

	.row:not(:last-child) {
		border-bottom: 1px solid #f5f5f5;
	}

	.rootDomain {
		color: #2563eb;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		text-decoration-line: underline;
	}

	input.inputSubDomainInput {
		line-height: 0px;
		padding: 0.28rem 0.75rem;
		min-width: 50px;
		padding: 6px;
	}

	.domainWrapper {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	.buttonsWrapper {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	.linkButtons {
		padding: 0.4rem 0.75rem;
	}
}
.statusLabel {
	display: flex;
	align-items: center;
	gap: 3px;
}
.iconWrapper {
	width: 1.125rem;
	height: 1.125rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.label {
	@include SmallParagraph;
}

.status {
	display: flex;
	align-items: center;
	gap: 3px;
	border: none;
	background-color: red;
}
