.container {
	display: flex;
	font-style: normal;
	font-family: "Roboto";
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-top: 45px;
	margin: auto;
	width: 720px;
	gap: 5px;

	.cta {
		display: flex;
		flex-direction: column;
		gap: 40px;

		.heading {
			display: flex;
			flex-direction: column;
			gap: 15px;
			h1 {
				font-weight: 600;
				font-size: 32px;
				line-height: 38px;
			}
			p {
				font-weight: 400;
				font-size: 20px;
				line-height: 150%;
			}
		}
	}
}
