.triggerButton {
	display: flex;
	width: 45px;
	height: 45px;
	padding: 6px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 6px;
	background-color: transparent;
	margin-right: -13px;
}

.menu {
	overflow: hidden;
	outline: none;
	display: inline-flex;
	min-width: 180px;
	max-width: 250px;
	max-height: 400px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
		0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.menuItem {
	display: flex;
	padding: 5.5px 12px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	background: #fff;
	cursor: pointer;
}

.menuItem:hover {
	outline: none;
}

.deleteIcon {
	color: red;
}

.menuItemText {
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.editText {
	color: #1e293b;
}

.deleteText {
	color: #d92d20;
}
