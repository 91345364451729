.container {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	align-items: center;
	padding: 35px;
}

.icon {
	height: 68px;
	width: 68px;
}

.title {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15.75px;
	line-height: 21px;
	color: #202223;
}

.body {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	width: 230px;
	height: 32px;
	text-align: center;
	span {
		display: inline-block;
		vertical-align: middle;
	}
}
