@import "../../shared/shared-components";

.form {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	label {
		font-family: BasierCircle, sans-serif;
		font-size: 13px;
		color: #202223;
	}
}

.input {
	@extend .input;
}

.inputError {
	color: rgb(229, 77, 46);
	font-family: BasierCircle, sans-serif;
	font-size: 13px;
	padding-left: 4px;
}

.dialogClose {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 15px;
	font-weight: 500;
	background-color: white;
	border: none;
	border-radius: 999px;
	width: 24px;
	height: 24px;
	line-height: 1;
	display: grid;
	justify-content: center;
	align-content: center;
	&:hover {
		background-color: rgb(237, 237, 237);
	}
	&:focus {
		box-shadow: 0 0 0 2px rgb(219, 219, 219);
	}
}

.dialogFooter {
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	border-top: 1px solid #e2e8f0;
	padding-top: 16px;
	.mainButton {
		@extend .button;
		text-transform: capitalize;
	}
	.cancelButton {
		@extend .secondaryButton;
	}
}

.alert {
	background-color: rgb(255, 230, 226);
	border-radius: 8px;
	color: rgb(229, 77, 46);
	font-family: BasierCircle, sans-serif;
	font-weight: 600;
	margin-bottom: 2rem;
	padding: 0.5rem 2rem;
}
.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.5px;
	border-bottom: 1px solid #e2e8f0;
	padding-bottom: 16px;
}

.contentContainer {
	padding: 20px 0px;
}
.content {
	color: #64748b;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
