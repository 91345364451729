.flex_align_center {
	display: flex;
	align-items: center;
}
.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.root {
	padding: 16px;
	min-height: calc(100% - 62px);
	background: #fff;
	.header_drawer {
		margin-bottom: 42px;
		@extend .flex_align_center;
		justify-content: space-between;
		.title_drawer {
			font-family: "BasierCircle";
			font-size: 20px;
			line-height: 28px;
			color: #202223;
			margin-right: 16px;
			margin-bottom: 0;
		}
		.recipients_box {
			font-family: "BasierCircle";
			font-size: 13px;
			color: #202223;
			position: relative;
			background-color: #91e0d6;
			padding: 2px 6px;
			border-radius: 10px;
		}
		.button {
			background: transparent;
			border: none;
		}
	}
}
.rootLoading {
	@extend .root;
	filter: blur(10px);
}

.footer_drawer {
	@extend .flex_end;
	height: 62px;
	position: sticky;
	bottom: 0;
	padding: 14px 18px;
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
	z-index: 10;
	width: 100%;
}

button {
	padding: 5px 12px;
	border: none;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	border-radius: 4px;
}
.buttonCancel {
	@extend button;
	background: #ffffff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	color: #202223;
	margin-right: 10px;
	border: 1px solid #babfc3;
}
.buttonSave {
	@extend button;
	background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
		inset 0px -1px 0px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	&:disabled {
		cursor: not-allowed;
		background: #babec3;
		box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
			inset 0px -1px 0px rgba(0, 0, 0, 0.2);
	}
}

.stepField {
	margin: 20px 0;
	h4 {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		color: #6d7175;
		margin-bottom: 18px;
	}

	.errors {
		color: #f76d75;
		margin-top: 5px;
		font-size: 13px;
	}
	.Select-control {
		border: 1px solid #babfc3;
	}
}
.modalShortListDone {
	h3 {
		font-family: "BasierCircle";
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #202223;
		margin-bottom: 20px;
	}
	p {
		font-family: "BasierCircle";
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		margin-bottom: 20px;
	}
	.footer_drawer {
		padding: 0;
		position: static;
		box-shadow: none;
		height: inherit;
	}
}
.modalAddEmail {
	h3 {
		font-family: "BasierCircle";
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		color: #181819;
		margin-bottom: 8px;
	}

	p {
		font-family: "BasierCircle";
		font-size: 16px;
		line-height: 20px;
		color: #000000;
		margin-bottom: 32px;
		strong {
			font-weight: 600;
			margin-right: 8px;
		}
		&.missingAdresses {
			color: #916a00;
		}
	}
	.footer_drawer {
		padding: 0;
		position: static;
		box-shadow: none;
		height: inherit;
		&.footerAddEmail {
			margin-top: 50px;
		}
	}
}
.tableCandidate {
	width: 100%;
	thead {
		tr {
			background: #f8f9fa;
			border-radius: 4px 4px 0 0;
			border: 1px solid #f8f9fa;
			th {
				font-family: "BasierCircle";
				font-weight: 600;
				font-size: 14px;
				line-height: 16px;
				color: #181819;
				padding: 8px 16px;
			}
		}
	}
	tbody {
		border: 1px solid #f0f3f5;
		border-radius: 0 0 4px 4px;
		tr {
			border-bottom: 1px solid #f0f3f5;
			td {
				padding: 12px 16px;
				font-family: "BasierCircle";
				font-size: 14px;
				line-height: 20px;
				color: #48494a;
				input {
					width: 218px;
					height: 36px;
					background: #ffffff;
					border-radius: 4px;
					line-height: 36px;
					border: 1px solid #babfc3;
					min-height: inherit;
					padding: 0 12px;
					&.emailExistsInput {
						border: 1px solid #df006b;
					}
				}
				.inputEmailContainer {
					position: relative;
					.loader {
						position: absolute;
						top: 9px;
						right: 6px;
					}
				}
				.inputEmailState {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 4px;
					span {
						font-family: "BasierCircle";
						font-size: 12px;
						line-height: 16px;
						color: #916a00;
						display: flex;
						align-items: center;
						position: relative;
						.tooltip {
							position: absolute;
							width: 270px;
							background: #1a1c1d;
							box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2),
								0px 2px 10px rgba(0, 0, 0, 0.1);
							border-radius: 4px;
							padding: 6px 10px;
							font-family: "BasierCircle";
							font-size: 14px;
							line-height: 20px;
							color: #ffffff;
							color: #fff;
							z-index: 9999;
							left: -50px;
							top: -1px;
						}
					}
					button {
						background: #ffffff;
						border: 1px solid #aeb4b9;
						border-radius: 4px;
						font-family: "BasierCircle";
						font-size: 12px;
						color: #202223;
						height: 20px;
						padding: 0 5px;
						line-height: 20px;
					}
				}
				.emailExists {
					span {
						font-family: "BasierCircle";
						font-size: 12px;
						line-height: 16px;
						color: #df006b;
					}
				}
				.candidateName {
					display: flex;
					align-items: center;
					span {
						margin-left: 8px;
					}
				}
			}
		}
	}
}
.footer_drawer_loading {
	@extend .footer_drawer;
	filter: blur(10px);
}
.loader_container {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
