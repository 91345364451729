.buttonContainer {
	display: flex;
	justify-content: center;
	padding: 0px;
}

.noResult {
	font-family: "Work Sans";
	padding: 10px 40px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #525252;
}

.separator {
	width: 100;
	height: 1px;
	background-color: #d1d5db;
}
