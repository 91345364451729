.container {
	display: flex;
	margin-bottom: 24px;
	padding: 0 16px;
}

.trigger {
	outline: none !important;
	display: flex;
	height: 40px;
	width: 170px;
	padding: 10px 12px 10px 16px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	gap: 8px;
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	.label {
		flex-grow: 1;
		display: flex;
	}
}

.popover {
	outline: none !important;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	width: 170px;
	overflow: hidden;
	margin-top: -5px;
	box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.09);
	.menu {
		outline: none !important;
	}
	.menuItem {
		outline: none !important;
		height: 40px;
		padding: 10px 12px;

		color: #334155;
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;

		&.selected {
			color: #00857a;
		}

		cursor: pointer;
		&:hover {
			background-color: #f3f4f6;
		}
	}
}
