.root {
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
	border-radius: 8px;
	height: 140px;
	font-family: "basiercircle";
}
