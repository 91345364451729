.cardsContainer {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cardsList {
	grid-row: 2/3;
	grid-column: 2/3;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

button.publishButton[type="submit"] {
	padding: 13px 21px;
	line-height: 16px;
	max-width: fit-content;
	border-radius: 8px;
}

button.previousButton {
	padding: 14px 21px;
	border-radius: 8px;
}
