.lock {
	display: flex;
	padding: 6px 10px;
	justify-content: center;
	align-items: center;
	gap: var(--2, 8px);
	border: 1px solid transparent;
	border-radius: 39px;
	background: var(--yellow-600, #f59e0b);
	position: absolute;
	left: 275px;
	top: 67px;

	span {
		color: var(--text-white, #fff);
		font-family: "Work Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.5px;
	}

	&:hover {
		background: var(--bg-hover, #ee9004);
	}
}

.tooltip {
	.container {
		word-break: normal;
		text-align: center;
	}
}
