.cancel-btn {
	padding: 0.75rem 1rem;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: white;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;
	color: #334155;
}

.confirmButton {
	padding: 0.75rem 1rem;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: white;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.125rem;
	color: #fff;
	background: #d92d20;
	border: none;
	&.dontShare {
		background: #f59e0b;
	}
}
.footer {
	padding: 1.143rem;

	border-top: 1px solid #e2e8f0;
}

.header {
	padding: 1.143rem;
	margin-bottom: 0;
	border-bottom: 1px solid #e2e8f0;
}

.content {
	padding: 20px;
	display: flex;
	align-items: center;
	.contentText {
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		span {
			font-weight: 400;
			color: #334155;
		}
	}
	.alertTriangle {
		background: #fef3f2;
		margin-right: 24px;
		border-radius: 44px;
		padding: 16px;
		&.dontShare {
			background: rgba(248, 187, 85, 0.15);
		}
	}
}
