.root {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.calendar {
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity));
	display: inline-block;
}

.header {
	padding-bottom: 2rem;
	display: grid;
	grid-template-columns: 20% 60% 20%;
	justify-items: center;
	align-items: center;
}

.header h2 {
	font-weight: 700;
	font-size: 1.9rem;
	line-height: 3.5rem;
	flex: 1 1 0%;
	margin: 0;
	text-transform: capitalize;
}

.calendar table {
	flex: 1 1 0%;
	text-indent: 0;
	border-color: inherit;
	border-collapse: collapse;
}

.chevronIcon {
	width: 1.5rem;
	height: 1.5rem;
	display: block;
	color: #898f96;
	cursor: pointer;
}

.tableHeader {
	--tw-text-opacity: 1;
	color: rgb(75 85 99 / var(--tw-text-opacity));
	font-size: 12px;
}

.th {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 30px;
	color: #a9a9a9;
	text-align: center;
}
