.container {
	padding: var(--4, 16px);
	border-radius: var(--lg, 12px);
	border: 1px solid var(--gray-100, #f1f5f9);
	background: var(--gray-50, #f8fafc);
	width: 100%;

	.header {
		display: flex;
		justify-content: space-between;
		width: inherit;
		cursor: pointer;

		.leftHeader {
			display: flex;
			align-items: center;
			gap: var(--3, 12px);
			align-self: stretch;

			.headerTitle {
				color: var(--gray-700, #334155);
				font-family: "Work Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
			}

			.badge {
				display: flex;
				padding: 2px 6px;
				align-items: flex-start;
				gap: 10px;
				border-radius: 40px;
				background: var(--brand-500, #00857a);
				color: var(--white, #fff);
				font-family: "Work Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 100%;
			}
		}

		.rotate {
			transform: rotate(180deg);
		}
	}

	.divider {
		margin: var(--4, 16px) 0;
		height: 1px;
		background: var(--border-gray, #e2e8f0);
		width: inherit;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: var(--3, 12px);
		align-self: stretch;
	}

	.hidden {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}

	.visible {
		opacity: 1;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}
}
