.header {
	display: flex;
	padding: 16px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	align-self: stretch;
	border-radius: 16px;
	background: #f1f5f9;
	padding: 20px;
	margin: 0 20px;

	.subHeader {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;

		.description {
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: stretch;

			.title {
				width: 250px;
				height: 10px;
				border-radius: 40px;
				background: #e2e8f0;
			}

			.status {
				width: 50px;
				height: 10px;
				border-radius: 40px;
				background: #e2e8f0;
			}
		}

		.divider {
			width: 520px;
			height: 10px;
			border-radius: 40px;
			background: #e2e8f0;
		}
	}

	.tabs {
		display: flex;
		align-items: flex-start;
		gap: 20px;
		align-self: stretch;

		.tab {
			display: flex;
			height: 50px;
			padding: 8px 16px;
			justify-content: center;
			align-items: center;
			width: 250px;
			gap: 10px;
			border-radius: 8px;
			background: #e2e8f0;
		}
	}
}

.list {
	width: 100%;
	gap: 5px;
	display: flex;
	flex-direction: column;

	.progressContainer {
		display: flex;
		align-items: center;
		gap: 12px;
		align-self: stretch;
		height: 56px;
		border-radius: 16px;
		border: 1px solid #f8fafc;
		background: #f1f5f9;
		justify-content: space-between;
		padding: 0 20px;
		margin: 0 20px;

		.description {
			display: flex;
			align-items: center;
			gap: 10px;

			.fileIcon {
				width: 20px;
				height: 20px;
				border-radius: 4px;
				background: #e2e8f0;
			}

			.name {
				width: 280px;
				height: 11px;
				border-radius: 40px;
				background: #e2e8f0;
				margin-top: 10px;
			}
		}

		.actionIcon {
			width: 20px;
			height: 20px;
			border-radius: 6px;
			background: #e2e8f0;
		}
	}
}
