.container {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
}

$font-family: "Work Sans";
$primary-color: #00857a;

.datePickerRoot {
	width: 100%;
}

.datePickerInput {
	padding: 14px;
	border-radius: 8px;
}

.jobAddressInputFocused {
	border-color: $primary-color;
}

div.radioButtonContainer {
	display: grid;
	grid-template-columns: repeat(3, 33%);
	gap: 0px;
}

.radioButtonContainer span:before {
	margin-right: 8px !important;
}

.daysPerWeekContainer {
	display: grid;
	grid-template-columns: 50px max-content;
}

input[type="text"].daysPerWeekInput {
	padding: 14px;
}

input[type="text"].daysPerWeekInput {
	padding: 13px;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0px;
}

.daysInOfficeLabel {
	font-family: $font-family;
	font-weight: 400;
	font-size: 13px;
	line-height: 12px;
	border: 1px solid #e5e5e5;
	display: flex;
	border-radius: 8px;
	align-items: center;
	background-color: #fafafa;

	color: #1f2937;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-left-width: 0.5px;
	padding: 14px;
}
