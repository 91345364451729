@import "../../Variables/styles.scss";

@mixin font {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

// .profileContainer {
// 	max-width: $maxWidth;
// }
.button {
	color: $primayColor;
	@include font;
	background: none;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	border-radius: $raduisButton;
	border: 1px solid $ghostWhiteLight;
	height: 30px;
	padding: 0 12px;
	display: flex;
	align-items: center;
}
.font {
	font-family: "BasierCircle";
	font-style: normal;
}
.secondaryButton {
	@include font;
	background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
		inset 0px -1px 0px rgba(0, 0, 0, 0.2);
	border-radius: $raduisButton;
	line-height: 17px;
	color: $white;
	align-self: flex-start;
}

.headerBackground {
	background-color: $primayBackground;
	padding: 0 18px 0 18px;
	.headerContainer {
		padding: $spacing 0 $spacing 0;
		.quickContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 5px;
			.quickText {
				@include quickText;
				color: $swamp;
			}
		}
	}
	.topHeaderButtons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: $spacing;
		button {
			@extend .button;
		}
		.pagination {
			display: flex;
			button:first-child {
				margin-right: 10px;
			}
		}
	}
	.infoProfile {
		display: flex;
		align-items: center;
		gap: 160px;
		padding: 0 5px;
		.headline {
			display: flex;
			align-self: flex-start;
			align-items: center;
			.rightSide {
				margin-left: $spacing;
				h1 {
					@include font;
					font-weight: 600;
					font-size: 20px;
					line-height: 28px;
					color: $primayColor;
					display: flex;
					align-items: center;
					span {
						position: relative;
						display: flex;
						align-items: center;
					}
					svg {
						margin-left: 10px;
						cursor: pointer;
					}
				}
				h2.secondaryTitle {
					@include font;
					line-height: 18px;
					margin: 0;
					display: flex;
					span {
						text-transform: capitalize;
					}
				}
			}
		}
		.contactInfos {
			margin-right: 5px;
			ul.listInfo {
				list-style: none;
				margin: 0;
				padding: 0;
				> li {
					@include font;
					color: $primayColor;
					line-height: 18px;
					display: flex;
					align-items: center;
					margin-bottom: 12px;
					.containerModal {
						position: relative;
					}
					svg:last-child {
						cursor: pointer;
					}
					> span {
						margin: 0 6px;
					}
				}
			}
		}
		.blockCta {
			display: flex;
			align-items: center;
			.actionsMenu {
				position: relative;
				.subMenu {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 100;
					background: $white;
					width: 216px;
					box-shadow: 0 0 2px rgb(0 0 0 / 20%), 0 2px 10px rgb(0 0 0 / 10%);
					border-radius: $raduisButton;
					ul {
						list-style: none;
						padding: 0;
						margin: 0;
						li {
							button {
								background: transparent;
								padding: 12px 8px 12px 24px;
							}
						}
					}
				}
			}
			.moreActionBtn {
				@include font;
				background: transparent;
				padding: 0;
				border: none;
				margin-right: 10px;
				line-height: 20px;
				color: $primayColor;
				svg {
					margin-left: 9px;
				}
			}
		}
	}
}

.tabsContainer [data-reach-tab-list] {
	background-color: $white;
}
.tabsContainer [data-reach-tab] {
	@include font;
	font-weight: 500;
	line-height: 16px;
	color: $swamp;
	border: none;
	margin-right: 10px;
}
.tabsContainer [data-reach-tab][data-selected] {
	color: $crimson;
	background: $aliceWhite;
	border-radius: 4px;
	border: none;
}
.tabsContainerHeader {
	background-color: $white;
	height: 46px;
	display: flex;
	position: relative;
	.tabsSizer {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		padding-left: $paddingLeft;
		padding-right: $paddingRight;
	}
}
.layoutBtn {
	background: none;
	position: relative;
	@include font;
	line-height: 16px;
	color: $blackPearl;
	display: flex;
	align-items: center;
	svg {
		margin-right: 6px;
	}
	padding: 0;
}
.disabledButton {
	border: 1px solid lightgray;
	cursor: not-allowed;
	svg {
		path {
			fill: lightgray;
		}
	}
}

.profileNameContainer {
	cursor: pointer;
}
