.container {
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 16px;
	flex: 1 0 0;
	border-radius: 12px;
	background: #fff;
	color: #00857a;
}
.text {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.aiIcon {
	width: 24px;
	height: 24px;
}
.closeBtn {
	color: #64748b;
	background-color: transparent;
}
