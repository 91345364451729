.descriptionContainer {
	display: flex;
	gap: 21px;
	align-items: center;
	border-bottom: 1px solid #e2e8f0;
	border-top: 1px solid #e2e8f0;
	padding: 20px;
}

.notificationIcon {
	min-height: 72px;
	min-width: 72px;
}

.description {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
