@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #202223;
}
.root {
	background: #ffffff;
	box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
	border-radius: 8px;
	padding: 17px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.logoContainer {
		min-width: 25px;
		display: flex;
		img {
			height: 50px;
			width: 90%;
			object-fit: contain;
		}
	}
	h6 {
		@include font;
		font-weight: 500;
		margin: 12px 0;
	}

	h6.marginZero {
		margin: 0px;
	}

	.description {
		@include font;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.cardBottomContainer {
		margin-top: auto;
	}
	.actionsContainer {
		border-top: 1px solid #babfc3;
		margin-top: 20px;
		padding-top: 20px;
		button {
			width: 100%;
			background: #ffffff;
			box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
			border-radius: 4px;
			border: 1px solid #babfc3;
			color: #202223;
		}
		.buttonDisabled {
			cursor: not-allowed;
			color: gray;
			border-color: lightgray;
		}
	}
	.checkboxContainer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
.info {
	margin-top: 40px;
	.channelDuration {
		background: #ffffff;
		border-radius: 3px;
		border: 1px solid #ff6849;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #ff6849;
		padding: 2px 4px;
		font-weight: 500;
	}
	.freeChannel {
		font-family: "basiercircle";
		font-size: 12px;
		font-weight: 500;
		border: 1px solid #59a67c;
		padding: 2px 4px;
		background: #ffffff;
		border-radius: 3px;
		color: #59a67c;
	}
}

.descriptionWrapper {
	font-size: 14px;
}

.globalToolTip {
	margin-left: 0px;
}

.title {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
