/* CompanyDashboard */
.dashboard-box.job-details .dashboard {
	margin-bottom: 16px;
}

/* List item */
.listItem .section-title {
	color: #334155;
	font-size: 16px;
	font-weight: 500;
	line-height: 21px;
	font-style: normal;
	margin-bottom: 7px;
	font-family: "Work Sans";
	letter-spacing: -0.5px;
}

.listItem .category-name {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #94a3b8;
	font-style: normal;
	font-family: Roboto;
}

.listItem .skills {
	gap: 7px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 21px;
}

.listItem .skills .tag {
	/* gap: 10px; */
	display: flex;
	padding: 7px 10px;
	border-radius: 8px;
	align-items: center;
	background: #e2e8f0;
	flex-direction: column;
	justify-content: center;

	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	color: #334155;
	font-style: normal;
	text-align: justify;
	font-family: "Work Sans";
	margin: 0;
}

/* Job fuctions */
.jobFunctions#can-do {
	margin-top: 25px;
}
.jobFunctions#can-do .section-title {
	color: #334155;
	font-size: 16px;
	font-weight: 500;
	line-height: 21px;
	font-style: normal;
	margin-bottom: 0px;
	font-family: "Work Sans";
	letter-spacing: -0.5px;
}
.jobFunctions#can-do ul.crumbs {
	margin-top: 12px;
	margin-bottom: 0px;
}
.jobFunctions#can-do ul.crumbs.main {
	margin-top: 21px;
	margin-bottom: 0px;
}
.jobFunctions#can-do ul.crumbs li {
	/* float: left; text-decoration: none; position: relative; */
	gap: 6px;
	display: flex;
	padding: 0px;
	padding-left: 50px;
	align-items: center;

	height: 40px;
	font-size: 14px;
	font-weight: 400;
	color: #64748b;
	line-height: 100%;
	font-style: normal;
	font-family: "Work Sans";
	background-color: #e2e8f0;
}

/* li:first-child */
.jobFunctions#can-do ul.crumbs li:first-child {
	border-radius: 8px 0 0 8px;
}
.jobFunctions#can-do ul.crumbs.main li:first-child {
	gap: 6px;
	height: 40px;
	display: flex;
	padding-left: 14px;
	align-items: center;
	border-radius: 8px 0 0 8px;
	background-color: #00857a;

	color: #ffff;
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	font-style: normal;
	text-align: justify;
	font-family: "Work Sans";
}
.jobFunctions#can-do ul.crumbs.main li:first-child svg {
	width: 14px;
	height: 14px;
}
.jobFunctions#can-do ul.crumbs.main li:first-child::after {
	border-left-color: #00857a;
}

/* li:not(:last-child) */
.jobFunctions#can-do ul.crumbs li:not(:last-child)::after {
	margin-top: -35px;
	border-left: 35px solid #e2e8f0;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
}
.jobFunctions#can-do ul.crumbs li:not(:last-child)::before {
	margin-top: -35px;
	border-left: 35px solid #ffff;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
}

/* li:last-child */
.jobFunctions#can-do ul.crumbs li:last-child {
	gap: 6px;
	height: 40px;
	display: flex;
	align-items: center;
	padding-right: 14px;
	background-color: #64748b;
	border-radius: 0px 8px 8px 0px;

	color: #ffff;
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	font-style: normal;
	text-align: justify;
	font-family: "Work Sans";
}
