div.card {
	width: 50vw;
}

.cardsList {
	grid-row: 2/3;
	grid-column: 2/3;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.nextButtonContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.stepper {
	height: 32px;
	grid-column: 2/3;
	grid-row: 1/2;
	margin: 50px auto 60px;
}
