.droppableContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.droppableContainerNotEmpty {
	margin-top: 16px;
}

.droppableContainerEmptyBlock {
	height: 60px;
	border-radius: 8px;
	border: 1px dashed #e2e8f0;
	background: #f8fafc;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-light, #64748b);
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.ghost {
	border-radius: 12px;
	outline: 2px dashed var(--border-gray, #e2e8f0);
	background: var(--bg-hover, #f8fafc);
	* {
		opacity: 0;
	}
}
