.interviewText {
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.marginRight15 {
	margin-right: 15px;
}

.grid {
	display: grid;
	align-items: center;
	grid-template-columns: max-content max-content max-content max-content max-content max-content;
	gap: 5px;
}

.flex {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.separator {
	width: 100%;
	height: 1px;
	background: #e2e8f0;
}

.interviewText,
.contractText {
	color: #1e293b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
