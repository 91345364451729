@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
}

.container {
	margin-bottom: 50px;
	ul[class^="pagination"] {
		margin: 60px auto 0 auto;
	}
}

.selectAllCheckbox {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 15px;
	span {
		@include font;
	}
}

.allChannelsLabel {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
}

.channelsContainer {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 24px;
	width: 100%;
}
