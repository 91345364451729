button.copyButton {
	box-shadow: 0px 0px 0px rgba(16, 24, 40, 0.05);
	border: 1px solid #eceff3;
	background-color: #ffffff;
}

.copyButton div {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #394858;
	line-height: 20px;
}
