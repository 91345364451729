.root {
	position: relative;
}
.aiToolsButton {
	display: flex;
	height: 36px;
	padding: 6px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 0;
	border-right: 1px solid #e2e8f0;
	background: #fff;
	position: relative;
}
.aiToolsButton span {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	white-space: nowrap;
}

.aiOptionsMenu {
	display: flex;
	padding: 6px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
		0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.aiOptionsMenuItemContainer {
	display: flex;
	height: 42px;
	width: 200px;
	padding: 0px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 8px;
	border-radius: 8px;
	background: transparent;
	gap: 8px;
}
.aiOptionsMenuItemContainer span {
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22.8px;
}
.aiOptionsMenuItemContainer:hover {
	display: flex;
	height: 42px;
	padding: 0px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 8px;
	background: #f1f5f9;
	color: #00857a;
	gap: 8px;
	width: 200px;
}

.aiOptions {
	display: flex;
	align-items: center;
	gap: 8px;
	position: absolute;
	left: -2px;
	top: 46px;
}

.aiOptionsMenuLabel {
	display: flex;
	align-items: center;
	gap: 10px;
}
