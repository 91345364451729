.multi-action {
	display: grid;
	grid-template-areas:
		"label cta"
		"form form";
	grid-gap: 3;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	width: 100%;
}
.backdrop {
	background-color: rgba(57, 65, 72, 0.8);
	opacity: unset;
}
.multi-action .cancel-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	height: 45px;
	background: #ffffff;
	border: 1.5px solid #a0a3bd;
	border-radius: 5px;
	margin: 0px 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 22px;
	color: #a0a3bd;
}
.discard-changes-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	background: #ffffff;
	border: 1.5px solid #3e4c5f;
	border-radius: 5px;
	margin: 0px 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 22px;
	color: #3e4c5f;
}
