$success: #aee9d1;
$warning: #ffd79d;
$lightRed: #fff0f0;
$highlight: #a4e8f2;
$solitudeBlue: #e5effd;
$button: linear-gradient(to bottom, #f01c29 10%, #ff6849 100%);
$tomato: #f01c29;
$blackPearl: #202223;
$raven: #6d7175;
$mistyRose: #ffe9e8;
$solitude: #e4e5e7;
$antiqueWhite: #ffebd3;
$heather: #bbbfc3;
$atoll: #347c84;
$aluminium: #8c9196;
$aliceBlue: #f2f7fe;
$ghostWhite: #f6f6f7;
$azure: #ebf9fc;
$linen: #fff5ea;
$critical: #d82c0d;
$criticalText: #df006b;
$white: #ffffff;
$toasterSuccess: #007f5f;
$toasterSuccessText: #e3e5e7;
$swamp: #303131;
$critical: #fff4f4;
$successPressed: #e2f1ea;
$successText: #008060;
$flamingo: #e65e42;
$rawUmber: #7e5700;
$harleyDavidsonOrange: #bc2200;
$blueLagoon: #006a68;
$oysterBay: #d5f3f8;
$aliceBlueCount: #f0f3f5;
$tomatoRed: #ff6849;
$midnight: #212b36;
$mediumCarmine: #b33a22;
$turbo: #fecb26;
$purpleHeart: #4836bf;
$dodgerBlue: #018aee;
$jade: #10b373;
$deepCerise: #ce31a4;
$niagara: #2da78a;
$goldenTainoi: #ffbf64;
$radicalRed: #f75356;
$shamrock: #36ba90;
$linkWater: #d2d7db;
$greyChateau: #a8aaac;
$blackRussian: #181819;
$blackTune: #48494a;
$snow: #f8f9fa;
$floralWhite: #d8dbdd;
$ghostWhiteLight: #babfc3;
$crimson: #ff6849;
$aliceWhite: #ffe1db;
$greyBay: #a7acb1;
$greyBorder: #edeeef;
$borderBlockDrawer: #dbdddf;
$textSubdued: #6d7175;
$greyAlice: #6d7175;
$brown: #916a00;
$gris: gray;
$solitudeGrey: #e1e3e5;
$grayChateau: #a8aaac;
$tomatoOrange: #fd5749;
$azureWhite: #fafbfb;
$linkWaterGray: #d2d5d8;
$tuna: #48494a;
$mako: #575959;
$gris: #f4f5f8;
$box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
	inset 0px -1px 0px rgba(0, 0, 0, 0.2);
$successGreen: #95c9b4;
$bgBannerSuccess: #f1f8f5;
$greytabs: #6d7175;
$defaultColorBanner: #babfc3;
$informationalDark: #98c6cd;
$informationalLight: #ebf9fc;
$warningDark: #e1b878;
$warningLight: #fff5ea;
$labelform: #6d7175;
$borderInput: #babfc3;
$borderExtraBenifits: #c9cccf;
