.list {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.card {
	display: grid;
	gap: 8px;
	grid-template-columns: max-content 1fr max-content;
	border-radius: 12px;
	background: #f8fafc;
	padding: 12px 16px;
}

.profileContainer {
	margin-top: 7px;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 20px;
}

.thumbs svg {
	width: 24px;
	height: 24px;
}
