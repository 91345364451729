.container {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #ffffff;
	border: 1px solid #e1e3e5;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 10px;
}
.flexCenter {
	display: flex;
	align-items: center;
	gap: 3px;
}
.text {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	color: #202223;
	margin-left: 7px;
}
.displayBlock {
	display: block;
}

.numbers {
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 22px;
	line-height: 32px;
	color: #814aff;
}
