@import "../../Variables/styles.scss";
@import "modules/SearchCandidate/TableVariables/fonts.scss";
.noteContainer {
	@include tabsPadding;
	padding-top: 23px;
	.container {
		border-radius: 4px;
		@include profileContent;
		.paginationContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-top: 3.2rem;
			> div {
				display: flex;
				align-items: center;
				font-family: "basiercircle";
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 14px;
				color: #48494a;
			}
			ul {
				justify-content: end;
			}
		}
		.image {
			border-radius: 50%;
			width: 3rem;
		}
		.avatar {
			& > div {
				overflow: unset;
			}
		}

		.section__notes {
			background-color: #ffffff;
			padding: 0.5rem;
			outline: 1px solid #d4d4d4;
			border-radius: 8px;
			box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 6%);
		}
		.results_count {
			@include SmallParagraph;
			color: #737373;
			font-weight: 400;
			line-height: 1.125rem;
			padding: 0;
			.greenText {
				color: #00857a;
				font-weight: 500;
			}
			margin: 0.75rem 0 0.75rem 0.25rem;
		}
		.empty__section__note {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 50vh;
			background-color: #ffffff;
			padding: 0.5rem;
			outline: 1px solid #d4d4d4;
			border-radius: 8px;
			box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 6%);
		}
	}
}
.linkToButton {
	padding: 0;
	background: transparent;
	border: unset;
	span {
		text-transform: capitalize;
	}
	.linkTo {
		@include profileContent;
		color: $tomato;
		&:hover {
			text-decoration: underline;
		}
	}
}

.header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	.title {
		@include tabsTitle;
		margin-left: 2px;
		padding-bottom: 0.6rem;
		color: $swamp;
	}
	.addNote {
		background: $button;
		box-shadow: $box-shadow;
		border-radius: 4px;
		color: $white;
	}
}

.overlay {
	@include profileContent;
	line-height: 1.25;
	color: #505050;
	word-wrap: break-word;
}
.historyContainer {
	padding: 0;
}

.notesHeader {
	margin-bottom: 10.5px;
}

.pagination li > a {
	background-color: transparent;
}
