.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	color: var(--text-dark, #334155);
	font-family: "Basiercircle";
	font-size: 23.04px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.5px;
}

.createBtn {
	display: flex;
	padding: 10px var(--4, 16px);
	align-items: center;
	gap: var(--2, 8px);
	border-radius: var(--md, 8px);
	border: 1px solid var(--text-brand, #00857a);
	background: var(--white, #fff);

	color: var(--text-brand, #00857a);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;

	&:hover {
		background-color: #e6f3f2;
	}
}
