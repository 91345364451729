.avatar {
	border-radius: 50% !important;
}

.imgContainer {
	border-radius: 50%;
}

.small {
	width: 24px;
	height: 24px;
}
