.listFilterContainer {
	display: flex;
	align-items: center;
	position: relative;
	gap: 10px;
}

.filterListButton {
	display: flex;
	align-items: center;
	background: #ffe9e8;
	border-radius: 8px;
	span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 20px;
		margin-right: 5px;
		color: #fd5749;
	}
}

.sortByButton {
	background: #edeeef;
	border-radius: 8px;
	display: flex;
	align-items: center;
	span {
		font-family: "basiercircle";
		margin-right: 7px;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 20px;
		color: #8c9196;
	}
}

.positionRelative {
	position: relative;
	right: 50px;
}

.backdrop {
	background-color: rgba(23, 24, 24, 0.05);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9999;
	overflow: auto;
	display: flex;
	align-items: flex-start;
	cursor: auto;
}

.sortedField {
	display: flex;
	align-items: center;
	border-radius: 2px;
	.changeSortButton {
		background: #fed3d1;
		border-radius: 0px 8px 8px 0px;
		height: 36px;
	}
}

.field {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #f1f2f3;
	padding: 4px 8px;
	border-radius: 8px 0px 0px 8px;
	span {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
	}
	button {
		background: transparent;
		border: none;
		padding: 4px 8px;
	}
}
