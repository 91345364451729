$font-family: "Work Sans";

.container {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-top: 20px;
	margin-left: 31px;
}

.text {
	color: #1f2937;
	font-family: $font-family;
	font-size: 23px;
	font-style: normal;
	font-weight: 500;
}

.icon path {
	fill: #1f2937;
}

.lineContainer {
	padding: 0px 31px;
}

.line {
	background-color: #d1d5db;
	width: 100%;
	height: 1px;
	background-color: #d1d5db;
	height: 1px;
	margin: 24px 0px;
}
