.container {
	gap: 6px;
	display: flex;
	align-items: center;

	color: #dc2626;
	font-weight: 500;
	font-size: 12.75px;
	font-style: normal;
	font-family: Work Sans;
}
