.header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	position: absolute;
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding: 8px;
	font-family: inherit;
	font-size: 100%;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: #fafafa;
	border-radius: 8px;
}

.chevronIcon {
	width: 90px;
	height: 80px;
	display: block;
	color: #898f96;
	cursor: pointer;
	color: #404040;
}

.buttonLeft {
	left: 0px;
}

.buttonRight {
	right: 0px;
}

.span {
	margin-right: 10px;
	text-transform: capitalize;
}

.span,
.text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 14px;
	color: #202223;
}

.flex {
	display: flex;
	justify-content: space-between;
	font-display: row;
	align-items: center;

	div {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 27px */
		letter-spacing: -1px;
	}
}

.whiteButton {
	margin: 16px 0px;
	position: absolute;
	display: flex;
	padding: 10px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	svg {
		width: 20px;
		height: 20px;
	}
}

.h40 {
	height: 40px;
}

.bigText,
.bigText span {
	font-size: 18px;
	color: #334155;
}
