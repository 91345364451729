.mentions {
}

.mentions--singleLine .mentions__control {
	display: inline-block;
	width: 130px;
}
.mentions--singleLine .mentions__highlighter {
	padding: 1px;
	border: 2px inset transparent;
	font-weight: 400;
}
.mentions--singleLine .mentions__input {
	padding: 1px;
	border: 2px inset;
}

.mentions--multiLine .mentions__control {
	font-size: inherit;
}
.mentions--multiLine .mentions__highlighter {
	border: 1px solid transparent;
	padding: 9px;
	height: 100%;
	font-weight: 400;
	overflow: auto !important;
}

.mentions--multiLine .mentions__input {
	padding: 9px;
	outline: 0;
}

.mentions__suggestions__list {
	background-color: white;
	min-width: 200px;
	box-shadow: 0 0 7px 0 rgba(218, 224, 242, 0.48);
	border: solid 1px #e2e2e2;
}

.mentions__suggestions__item {
	cursor: pointer;
	padding: 15px 20px;
	font-weight: 400;
	color: #51585e;
	font-size: 14px;
	border-left: 4px solid transparent;
}
.mention_label {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #a8aaac;
	background: none;
	border: none;
	margin: 0;
	padding: 10px 0;
	text-transform: initial !important;
}
.mentions__suggestions__item--focused {
	background-color: #fafafb;
	border-left-color: #ff6849;
}
.input-maxlength {
	position: absolute;
	right: 20px;
	bottom: 10px;
	font-family: "basiercircle";
	font-size: 12px !important;
	line-height: 14px;
	text-align: right;
	color: #a8aaac;
	margin: 0 !important;
}

.mentions__mention {
	position: relative;
	z-index: 1;
	text-decoration: underline;
	pointer-events: none;
	color: #ff6849;
}
.error_block {
	float: left;
	font-family: "basiercircle";
	font-size: 14px;
	font-weight: normal;
	display: flex;
	align-items: center;
	color: #ff6849;
	line-height: 16px;
	min-height: 31px;
	padding: 5px 0 0 0;
}
.text {
	margin-left: 5px;
}
