.addBtn {
	display: flex;
	height: 40px;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	cursor: pointer;
	color: var(--text-dark, #1e293b);
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
	svg {
		height: 20px;
		width: 20px;
	}
}
