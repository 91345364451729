.dropDownRow {
	display: inline-flex;
	position: absolute;
	align-items: center;
	left: 0;
	gap: 0.5rem;
	cursor: pointer;
	font-family: "Work Sans", sans-serif;
	line-height: 1.125rem;
	color: #737373;
	height: 100%;
}
.selectedNumber {
	position: relative;
	button {
		all: unset;
		background-color: #fff;
		color: #262626;
		box-shadow: 0 0 0 1px #e5e5e5 inset;

		display: flex;
		align-items: center;
		padding: 0.5rem 0.75rem;
		border-radius: 5px;
		gap: 0.5rem;
		&:hover {
			background-color: #f5f5f5;
		}
		&:focus {
			background-color: #fff;
			box-shadow: 0 0 0 1px #66b6af inset;
		}
		span {
			line-height: 1.125rem;
		}
	}
}
.dropDownUl {
	width: 100%;
	position: absolute;
	bottom: 37px;
	left: 0;
	z-index: 1;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	-moz-box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	-webkit-box-shadow: rgb(0 0 0 / 10%) 2px 0 10px -2px;
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	color: #262626;
	padding: 0.25rem 0;
	list-style: none !important ;
}
.dropDownLi {
	width: inherit;
	padding: 0.5rem 0.75rem;
	line-height: 1.25rem;
	text-align: center;
}
.dropDownLi:hover,
.selected {
	background-color: #f5f5f5;
}
