.priorityContainer {
	border-radius: 4px;
	padding: 4px 8px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		margin-left: 5px;
		background: transparent;
		padding: 0px;
	}
}
.justifyContent {
	justify-content: space-between;
}
.priorityLabel {
	font-family: "basiercircle";
	text-transform: capitalize;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}

.high {
	border: 1px solid #d82c0d;
	background: rgba(216, 44, 13, 0.08);
	.priorityLabel {
		color: #d82c0d;
	}
	.icon {
		fill: #d82c0d;
	}
}
.low {
	border: 1px solid #00a47c;
	background: rgba(0, 164, 124, 0.08);
	.priorityLabel {
		color: #00a47c;
	}
	.icon {
		fill: #00a47c;
	}
}
.medium {
	border: 1px solid #f0940e;
	background: rgba(240, 148, 14, 0.08);
	.priorityLabel {
		color: #f0940e;
	}
	.icon {
		fill: #f0940e;
	}
}

.emptyState {
	background: #e5effd;
	border: 1px solid #bdc1cc;
	.priorityLabel {
		color: #999ea4;
	}
}
