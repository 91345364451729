.container {
	display: flex;
	align-items: center;
	gap: 9px;
}

.containerWithBackground {
	border-radius: 6px;
	border: 1px solid #e2e8f0;
	padding: 8px;
	background-color: white;
}

.suffix {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.icon {
	height: 20px;
	width: 20px;
	overflow: initial !important;
}
