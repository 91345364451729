.buttonWithIcon {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(253, 87, 73, 1);
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 5px;
	margin-top: 2px;
	cursor: pointer;
	padding: 0;
	margin: 0;
	background-color: transparent;
	position: relative;
	&:hover,
	&:focus {
		color: rgba(253, 87, 73, 1);
		text-decoration: none;
	}
	.icon {
		display: flex;
	}
	.loader {
		width: 143px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(246, 246, 247, 1);
		position: absolute;
		top: -5px;
		> div {
			margin-bottom: 0;
			padding: 5px 0;
			> span {
				width: 20px;
				height: 20px;
				border: 2px solid rgba(0, 0, 0, 0);
				border-top-color: #ff6849;
			}
		}
	}
}
