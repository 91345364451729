.evaluationScoreBtn {
	gap: 8px;
	display: flex;
	padding: 6px 16px;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	background-color: white;
	border: 1px solid #e2e8f0;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
	font-style: normal;
	font-family: Work Sans;

	&:focus-visible {
		outline: none !important;
	}

	&:hover {
		background: var(--bg-disabled, #f1f5f9);
	}
}
