@import "../../Variables/styles.scss";

@mixin font {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

.root {
	position: absolute;
	background: $primayBackground;
	border: 1px solid $snow;
	box-sizing: border-box;
	box-shadow: 0px 0px 2px 2px rgba(24, 24, 25, 0.04),
		0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198);
	border-radius: $raduisButton;
	width: 384px;
	top: 0;
	left: 12px;
	z-index: 111;
	.headerModal {
		padding: 18.5px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $floralWhite;
		h2 {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			color: #00857a;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin-bottom: 0px;
		}
		button {
			background: none;
			padding: 0;
		}
	}
	.bodyModal {
		padding: $spacing;
		.createdContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.dateCreation {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 300;
			font-size: 13px;
			line-height: 15px;
			color: #6b7280;
			flex: none;
			order: 1;
			flex-grow: 0;
		}
		h5 {
			display: flex;
			align-items: center;
			cursor: pointer;
			&.opened {
				svg {
					transform: rotate(90deg);
				}
			}
			span {
				color: $raven;
				margin-left: 10px;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 300;
				font-size: 13px;
				line-height: 15px;
				color: #6b7280;
				flex: none;
				order: 1;
				flex-grow: 0;
			}
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			margin-top: 26px;
			padding-left: 14px;
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;
				.leftSide {
					display: flex;
					align-items: center;
					.contactInfo {
						margin-left: 8px;
						h3 {
							@include font;
							line-height: 17px;
							color: $primayColor;
							margin-bottom: 6px;
						}
						h4 {
							margin-bottom: 0;
							@include font;
							line-height: 17px;
							color: $raven;
						}
					}
				}
				span {
					@include font;
					line-height: 17px;
					text-align: right;
					color: $raven;
					margin-right: 20px;
				}
			}
		}
	}
}

.followingLabel,
.sourceLabel,
.creationDateLabel,
.viewersLabel {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	color: #1f2937;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.sourceValue,
.creationDateValue,
.followingValue,
h5 {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: #1f2937;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.sourceValue,
.creationDateValue,
ul {
	margin-bottom: 10px;
}

h3,
h4 {
	margin-bottom: 0px;
}

.followingLabel {
	margin-top: 10px;
}
