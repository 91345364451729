@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}

.container {
	width: 100%;
	height: 100%;
}

.isBlur {
	filter: blur(1px);
}

.header {
	padding: 18px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	label {
		@include font;
		color: #f01c29;
	}

	button {
		padding: 0;
		background: white;
	}
}

.subHeader {
	@include font;
	font-size: 24px;
	line-height: 30px;
	padding-bottom: 8px;
	margin: 20px 18px 0px 24px;
}

.subHeader2 {
	@include font;
	margin-left: 24px;
}

.credentialsContainer {
	padding: 32px 24px;

	h6 {
		@include font;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #f01c29;
	}

	.fieldsContainer {
		margin: 24px 0;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}

.scrollableContainer {
	margin: 24px;
	padding: 35px 0;
	border-top: 1px solid #aeb4b9;
	border-bottom: 1px solid #aeb4b9;

	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		margin: 22px 22px 22px 0;
		color: #202223;
	}

	.rightSide {
		display: flex;
		align-items: center;
		gap: 18px;
	}

	svg {
		cursor: pointer;
	}
}

.totalContainer {
	margin: 24px 78px 24px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.totalLabel {
		@include font;
		font-weight: 700;
		color: #202223;
	}

	.totalCount {
		@include font;
		font-weight: 700;
		color: #f01c29;
	}
}

.actions {
	margin-left: 24px;
	display: flex;
	align-items: center;
	gap: 10px;
	padding-bottom: 20px;

	.primary {
		color: white;
		background-color: #f01c29;
		font-weight: 500;
		font-family: "basiercircle";
	}

	.secondary {
		background: #ffffff;
		box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
		border-radius: 4px;
		border: 1px solid #babfc3;
		font-family: "basiercircle";
		color: #202223;
	}
}

.disabled {
	opacity: 0.5;
	cursor: auto !important;
}

.loaderContainer {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 9999;
}
