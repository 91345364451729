.container {
	display: flex;
	align-items: center;
	padding: 8px 20px;
	position: relative;
	cursor: pointer;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 20px;
		right: 20px;
		border-top: 1px solid var(--border-gray, #e2e8f0);
	}
	&:hover {
		background: var(--bg-hover, #f8fafc);
	}
}

.title {
	flex-grow: 1;

	color: var(--gray-600, #475569);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.actions {
	display: flex;
	gap: 4px;
}

.actionButton {
	all: unset;
	color: var(--text-dark, #334155);
	height: 36px;
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	cursor: pointer;

	opacity: 0;
	pointer-events: none;

	&:hover {
		background: var(--bg-hover, #f8fafc);
	}
}

.container:hover {
	.actionButton {
		opacity: 1;
		pointer-events: auto;
	}
}
