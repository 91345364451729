$headingColor: #1e293b;

.white_box {
	padding: 32px;
	border-radius: 12px;
	background-color: white;
	border: 1px solid #cbd5e1;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	&__title {
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		display: flex;
		align-items: center;
		color: $headingColor;
		margin-bottom: 32px;
	}
}

.btn {
	gap: 10px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	font-weight: 600;
	font-size: 16px;
	padding: 9px 16px;
	white-space: nowrap;
	&:focus-visible {
		outline: none !important;
	}

	// with border
	&__success {
		color: #00857a;
		background-color: white;
		border: 1px solid #99ceca;
		&:hover {
			background-color: #fbfbfb;
		}
	}
	&__danger {
		color: #f87171;
		background-color: white;
		border: 1px solid #fecaca;
		&:hover {
			background-color: #fef2f2;
		}
	}
	&__red {
		color: #ffff;
		background-color: #dc2626;
		border: 1px solid #dc2626;
		&:hover {
			background-color: #b91c1c;
		}
	}
	&__secondary {
		color: #64748b;
		background-color: transparent;
		border: 1px solid #cbd5e1;
		&:hover {
			background-color: #f9fafb;
		}
	}

	// without border
	&__green {
		color: white;
		background-color: #00857a;
		&:hover {
			background-color: #006a62;
		}
	}
	&__link {
		color: #64748b;
		background-color: transparent;
		&:hover {
			background-color: #f9fafb;
		}
	}
}
