.container {
	background: #ffffff;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	position: absolute;
	min-width: 170px;
	top: 5px;
	padding: 8px;
	z-index: 9999999;
	ul {
		margin: 0px;
		list-style-type: unset;
		margin-block: 0px;
		margin-inline: 0px;
		padding-inline: 0px;
		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 10px;
			cursor: pointer;
			width: 100%;
			.content {
				display: flex;
				align-items: center;
				flex: 1;
			}
			.icon {
				height: 18px;
			}
			.label {
				font-family: "basiercircle";
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
				margin-left: 18px;
				flex: 1;
			}
		}
		li:hover {
			background: #f6f6f7;
			border-radius: 2px;
		}
	}
	.searchBox {
		border-radius: 4px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		border: 1px solid #d8dbdd;
		padding: 10px 8px;
		margin-bottom: 10px;
		> input {
			border: none;
			background-color: #ffffff;
			margin-left: 10px;
			::placeholder {
				color: #a8aaac;
				opacity: 1;
				font-size: 14px;
				font-weight: normal;
			}
			:focus-visible {
				outline: none;
			}
		}
	}
}
.containerScroll {
	@extend .container;
	position: static;
}
.scroll {
	max-height: 345px;
	overflow-y: auto;
}
.fullWidth {
	width: 100%;
}
.menuLabelContainer {
	background: #ffffff;
	border: 1px solid #d2d5d8;
	border-radius: 4px;
	padding: 12px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	.menuLabel {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		display: flex;
		align-items: center;
	}
	.icon {
		fill: #575959;
	}
}

.subMenuWrraper {
	position: relative;
}

.emptyState {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
	span {
		margin-top: 5px;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #8c9196;
	}
}
