.container {
	display: flex;
	padding: var(--4, 16px) var(--6, 24px);
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-bottom: 1px solid #e5e5e5;

	.title {
		color: var(--text-dark, #1e293b);
		font-family: "Work Sans";
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.5px;
	}

	.closeBtn {
		gap: 12px;
		display: flex;
		border-radius: 8px;
		background: #fff;
		align-items: center;

		color: #1e293b;
		font-weight: 500;
		line-height: 21px;
		font-size: 15.75px;
		font-style: normal;
		letter-spacing: -0.5px;
		font-family: "Work Sans";

		&:hover {
			background: #f8fafc;
		}
	}
}
