@mixin font {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}
.root {
	background: #ffffff;
	box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
	border-radius: 8px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 17px;
	height: max-content;
	label {
		@include font;
		line-height: 17px;
		color: #6d7175;
		display: block;
		margin-bottom: 10px;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		label {
			@include font;
			font-size: 16px;
			line-height: 20px;
			color: #000000;
			margin: 0;
		}
		button {
			@include font;
			line-height: 17px;
			background: transparent;
			color: #ff6849;

			&:focus {
				outline: auto;
			}
		}
	}
	.input {
		border: 1px solid #a7acb1 !important;
		padding: 8px 12px !important;
		border-radius: 4px !important;
		min-height: unset !important;
		width: 100% !important;
		font-family: BasierCircle, sans-serif !important;
		&:focus {
			border-color: #000000 !important;
		}
	}
	.select {
		width: 100%;
		&:focus {
			border-color: #000000 !important;
		}
		[class$="-control"] {
			width: 100%;
			height: 38px;
		}

		[class$="-value"] {
			display: flex;
			align-items: center;
		}
		[class$="-placeholder"] {
			display: flex;
			align-items: center;
		}
	}
}
