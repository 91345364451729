.container {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	position: absolute;
	width: 750px;
	min-height: 200px;
	height: auto;
	top: -22px;
	right: 42px;
	z-index: 9999;
	padding: 20px;
	.modalInterviewHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
		.title {
			font-family: "basiercircle";
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #202223;
		}
		.closeModal {
			background: transparent;
			border: none;
		}
	}
	.modalBody {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0px 8px;
			background: #e4e5e7;
			border-radius: 2px;
			margin-top: 5px;
			margin-right: 5px;
			.leftSide {
				display: flex;
				align-items: center;
			}
			.itemDetail {
				display: flex;
				flex-direction: column;
			}
			span {
				font-family: "basiercircle";
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
				text-align: left;
			}
			button {
				background: transparent;
			}
		}
	}
	.avatar {
		margin-right: 5px;
		border-radius: 50%;
		height: 26px;
		width: 26px;
	}
}

.backdrop {
	background-color: rgba(23, 24, 24, 0.05);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9999;
	overflow: auto;
	display: flex;
	align-items: flex-start;
	cursor: auto;
}
