.evaluationCardBody {
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.evaluationOverview {
	display: flex;
	padding: 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
	border-radius: 0px;
}

.section {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	margin-bottom: 20px;
}

.question,
.scoreTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
}

.scoreTitle {
	margin-top: 15px;
}

.required {
	color: black;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
}

.answer {
	p,
	span {
		color: #334155;
	}

	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.answer {
	padding: 12px;
	border-radius: 8px;
	background: #f8fafc;
	width: 100%;
	line-height: 100%;
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.hr {
	width: 100%;
	color: #e2e8f0;
	margin: auto;
}

.lastDivider {
	width: 100%;
	color: #e2e8f0;
	margin-top: -5px;
	margin-bottom: 1px;
}

.yesNo {
	display: flex;
	padding: 0px;

	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
}
.contentClassName {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.active {
	display: flex;
	width: 77px;
	height: 36px;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	color: red;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid #00857a;
	background: #e6f3f2;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #00857a;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.disactive {
	display: flex;
	width: 77px;
	height: 36px;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.scoreCardContainer {
	display: flex;
	padding: 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: -1px;
	align-self: stretch;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	margin-bottom: 10px;
}

.scoreCard {
	display: flex;
	padding: 10px 14px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 12px;
	background: #fff;
	justify-content: space-between;
}

.scoreCardTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.scoreCardIcons {
	display: flex;
	align-items: center;
	gap: 4px;
}

.thumbs {
	padding: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #e2e8f0;

	svg {
		width: 20px;
		height: 20px;
	}
}

div.blackThumbs {
	display: none;
}

.innerQuestions {
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 100%;
}

.questionContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
}

.questionTitle {
	color: #374151;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
}

.noteContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 0px;

	p,
	span {
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}

.noteLabel {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
