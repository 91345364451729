ul {
	padding: 0;
	margin: 0.25rem auto;
	list-style: none;
}

.menu {
	padding-bottom: 0.25rem;
	// width: 25rem;
	margin: 0 auto;
	list-style: none;
	text-align: center;
}

.input_container {
	padding-bottom: 1.5rem;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 1.5rem;
}
