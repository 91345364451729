/*Default Classes*/
.flex {
	display: flex;
}
.alignCenter {
	align-items: center;
}
/*End Default Classes */

.root {
	background: #ffffff;
	border: 1px solid #f8f9fa;
	box-sizing: border-box;
	box-shadow: 0px 0px 2px 2px rgba(24, 24, 25, 0.04),
		0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198);
	border-radius: 4px;
	z-index: 10;
}

.headerModal {
	@extend .flex;
	@extend .alignCenter;
	padding: 16px;
	border-bottom: 1px solid #d8dbdd;
	justify-content: space-between;
	h1 {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		color: #ff6849;
	}
	button {
		background: #0000;
		border: 0;
	}
}

.vacancyInfo {
	@extend .flex;
	padding: 19px 16px 16px 16px;
}
.leftSide,
.rightSide {
	width: 50%;
}
.blockInfo {
	margin-bottom: 24px;
	&.last {
		margin-bottom: 0;
	}
	h3 {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: #a8aaac;
		margin-bottom: 12px;
	}
	h4 {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #303131;
		margin: 0;
	}
}
.vacancyOwner {
	@extend .flex;
	@extend .alignCenter;
	.avatarOwner {
		margin-right: 8px;
	}
	h4 {
		margin-bottom: 4px;
	}
	h3 {
		margin-bottom: 0;
	}
}
.avatarCollaborators {
	@extend .flex;
}
.avatarRest {
	width: 24px;
	height: 24px;
	border-radius: 9999px;
	display: inline-flex;
	justify-content: center;
	background-color: #a8aaac;
	color: #303131;
	justify-content: center;
	font-size: 10px;
	line-height: 24px;
	font-weight: 400;
}
.avatarContainer {
	cursor: pointer;
}

.contractTypeValue {
	text-transform: capitalize;
}
