.emptyStateContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}
.emptyState {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
}
.emptyStateText {
	color: #000;
	font-family: "Work Sans";
	color: #334155;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}
