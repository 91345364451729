@import "modules/candidate/Profile/Variables/styles.scss";

.btnAdd {
	display: flex;
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	span {
		@include profileContent;
		color: $swamp;
		font-weight: 500;
		margin-left: 6px;
	}
}
.modalFnc {
	:global(.closeModalFnc) {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 15px;
		font-weight: 500;
		background-color: #fff;
		border: none;
		border-radius: 999px;
		width: 24px;
		height: 24px;
		line-height: 1;
		display: grid;
		justify-content: center;
		align-content: center;
		svg {
			width: 8px;
			path {
				fill: #333;
			}
		}
		&:hover {
			background-color: #ededed;
		}
	}
	.titleModal {
		margin: 0px;
		font-weight: 600;
		font-size: 17px;
		font-family: "BasierCircle";
		color: #394047;
	}
}

.contentModal {
	display: flex;
	flex-wrap: wrap;
	margin-top: 24px;
}
.content {
	margin-bottom: 30px;
	.section {
		display: flex;
		flex-direction: column;
		gap: 1.3rem;
		margin-right: 0.6rem;
		&:not(:last-child) {
			margin-bottom: 1.7rem;
			padding-bottom: 1.7rem;
			border-bottom: 1px solid $ghostWhite;
		}
		.title {
			@include profileContent;
			line-height: 1.43;
			color: $raven;
		}
		.row {
			display: flex;
			align-items: center;
			gap: 1rem;
			flex-wrap: wrap;
			[class$="g-tool"] {
				display: flex;
				align-items: center;
			}
			.textGras,
			.textNormal {
				&:not(:first-child) {
					::before {
						height: 6px;
						width: 6px;
						border-radius: 50%;
						content: "";
					}
				}
			}
			.textGras {
				p,
				span {
					font-weight: 500;
				}
				&:not(:first-child) {
					::before {
						background: $raven;
						content: "";
					}
				}
			}
			.textNormal {
				text-transform: capitalize;
				p {
					font-weight: normal;
				}
				&:not(:first-child) {
					::before {
						background: #6d7175;
						content: "";
					}
				}
			}
			p,
			span {
				position: relative;
				display: flex;
				align-items: center;
				gap: 0.9rem;
				@include profileContent;
				line-height: 1.43;
				margin: 0;
				color: $blackPearl;
			}
		}
		.justifyBetween {
			display: flex;
			justify-content: space-between;
		}
		.status {
		}
	}
}
