.separator {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
}
.formGroup {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 24px;
	.input {
		position: relative;
	}
	.selectBox {
		div {
			margin-top: 0;
		}
	}
	> div {
		gap: 10px;
		position: relative;
		display: flex;
		flex-direction: column;
		> div {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 2px;
		}
	}
	label {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #6e7191;
		margin: 0;
	}
	input[type="text"] {
		background: #ffffff;
		border: 1px solid #e2e2e2;
		box-sizing: border-box;
		border-radius: 2px;
		height: 48px;
		min-height: 48px;
		width: 100%;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #626b74;
		width: 67px;
		&::-webkit-input-placeholder {
			/* Edge */
			color: #9d9d9d;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #9d9d9d;
		}

		&::placeholder {
			color: #9d9d9d;
		}
	}
}
.hours {
	position: relative;
}
.placeholderHours {
	background-color: #fff;
	position: absolute;
	display: inline-flex;
	height: 46px;
	bottom: 1px;
	align-items: center;
	right: 1px;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	padding-right: 10px;
	color: #8c9196;
}
.error {
	color: #e8634a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}
