@import "modules/SearchCandidate/TableVariables/fonts.scss";

.uploaderContainer {
	display: flex;
	align-items: center;
	gap: 1.125rem;
}

.uploadBtn {
	@include SmallParagraph;
	padding: 0;
	color: #2563eb;
	text-decoration: underline;
	cursor: pointer;
	background: none;

	& i {
		font-family: "FontAwesome";
		margin-right: 0.25rem;
	}
}
