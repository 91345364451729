.table {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	isolation: isolate;
	overflow: hidden;
	background: #fff;
	border: 1px solid #e2e8f0;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
}

.tr {
	width: 100%;
	display: flex;
	cursor: pointer;

	&_loading {
		cursor: auto;
	}

	:first-child {
		width: 100px;
		justify-content: space-between;
		display: flex;
		align-items: center;
		gap: 15px;
		span,
		svg {
			width: min-content;
		}
	}
}

.tr,
.header {
	background-color: white;
}

.tr:nth-child(even) {
	background-color: #f8f9fb;
}

.row:hover {
	background: rgb(221, 239, 238);
}

.td,
.th {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	display: flex;
	height: 48px;
	padding: 18px 16px;
	align-items: center;
	align-self: stretch;
	overflow: hidden;
	overflow-wrap: break-word;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: calc(100% / 5);

	&CostNotDisplay {
		width: calc(100% / 4);
	}
}

.th {
	font-weight: 500;
	border-right: 1px solid #e5e5e5;
	border-bottom: 2px solid #e5e5e5;
	cursor: auto !important;
}

.td {
	font-weight: 40;
	border-right: 1px solid #e5e5e5;
}

.emptyTable {
	height: 350px;
	background: transparent;
}

.flex {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
}

.emptyState {
	margin-top: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: inherit;

	.extend {
		display: flex;
		padding: 10px 16px 10px 12px;
		justify-content: center;
		align-items: center;
		gap: 7px;
		border-radius: 8px;
		border: 1px solid #e2e8f0;
		background: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;

		&Disable {
			cursor: not-allowed;
			border-radius: 8px;
			border-color: #e2e8f0 !important;
			background: #f1f5f9 !important;
			color: #cbd5e1;
			svg {
				path {
					stroke: #cbd5e1;
				}
			}
		}

		&:hover {
			border-color: #e2e8f0;
			background: #f8fafc;
		}

		&:focus {
			border-color: #e2e8f0;
			background: #fff;
			box-shadow: 0px 0px 0px 3px rgba(59, 180, 172, 0.22);
		}
	}
}

.textNoWrap {
	text-wrap: nowrap;
}
