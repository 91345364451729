$primary-color: #00857a;

.salaryContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 15px;
	justify-content: flex-start;
}

.paymentContainer,
.paymentErrorsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 30px;
}

div.pickerInput {
	height: 44px;
	padding: 10.64px 14px;
	border-radius: 8px;
}

.salaryMax {
	margin-bottom: 0px;
}

.salaryContainer input.salaryMin,
.salaryContainer input.salaryMax {
	padding: 13px !important;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	min-height: 44px;

	&:focus {
		border-color: $primary-color;
		box-shadow: none;
	}
}

div.salaryMinRoot,
div.salaryMaxRoot {
	margin-bottom: 0px;
}

.error {
	align-items: flex-start;
}

.error svg {
	flex-basis: max-content;
}

.error div {
	flex-basis: fit-content;
}
