@import "src/modules/candidate/Profile/Variables/styles.scss";

.containerBanner {
	border: 1px solid $defaultColorBanner;
	padding: $spacing;
	display: flex;
	border-radius: $raduisButton;
	justify-content: space-between;
	margin-bottom: 10px;
	align-items: center;
	&.informational {
		border: 1px solid $informationalDark;
		background: $informationalLight;
	}
	&.warning {
		border: 1px solid $warningDark;
		background: $warningLight;
	}

	.closeBannerButton {
		background: transparent;
	}
	.contentBanner {
		width: 92%;
		@include font;
		color: $blackPearl;
		h3 {
			@include font;
			font-size: 16px;
			font-weight: 600;
			color: $blackPearl;
			margin-bottom: 4px;
		}
	}
}
