.root {
	position: relative;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	color: #a3a3a3;
	border-radius: 3px;
	overflow: hidden;
	font-size: 1rem;
	padding: 0.75rem 1rem;
	border-radius: 5px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-family: "Work Sans";
	line-height: 1.125rem;

	&.slim {
		padding: 0.5rem 0.75rem;
	}

	&.inlineStyle {
		box-shadow: none;
		border-radius: 0;
		overflow: visible;
		padding: 0;
		&:hover {
			background-color: transparent;
		}
	}

	gap: 0.5rem;

	&:hover {
		background-color: #fafafa;
	}
}

.root:focus {
	outline: none;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
}

.open {
	box-shadow: 0px 0px 0px 1px #66b6af inset;

	&:hover {
		background-color: #ffffff;
	}
}

.content {
	color: #262626;
}

div.disabled {
	line-height: 1.125rem;
	background-color: #f5f5f5;
	cursor: default;
	position: relative;
	box-shadow: 0px 0px 0px 1px #e5e5e5 inset;
	color: #a3a3a3;
	overflow: hidden;
	font-size: 1rem;
	padding: 0.75rem 1rem;
	border-radius: 5px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Work Sans";
	pointer-events: none;
	&:hover {
		background-color: #f5f5f5;
	}
}

.value {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 16px;
}
.iconWrapper {
	width: 1.125rem;
	height: 1.125rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconDown,
.iconTop {
	color: #525252;
	stroke: 1.5;
}

.iconTop {
	transform: rotate(180deg);
}
