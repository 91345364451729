.root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0 2px 2px rgba(24, 24, 25, 0.04);
	padding: 2rem;
	> h4 {
		color: #8c9196;
		font-weight: 600;
		font-size: 14px;
	}
	> span {
		white-space: pre-wrap;
	}
}
