$font-family: "Work Sans";

.modal {
	width: 700px;
}

.modalContent {
	display: grid;
	grid-template-rows: min-content auto;
	row-gap: 35px;
	min-height: 200px;
}

div.radioButtonsContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.modalTitle {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 23.04px;
	line-height: 24px;
	align-items: center;
	letter-spacing: -0.5px;
	color: #171717;
	margin-bottom: 0px;
}

.companyAddressContainer {
	display: flex;
	flex-direction: column;
	gap: 7px;
}
