.title {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 20px;
}

.thumbs svg {
	width: 24px;
	height: 24px;
}

.descriptionContainer {
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;

	.noCandidates {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.5px;
		margin-top: -10px;
	}

	.description {
		color: #64748b;
		text-align: center;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		width: 384.867px;
	}
}

.icon {
	margin-bottom: 25px;
	width: 149px;
	height: 120px;
	margin-top: 48px;
}
