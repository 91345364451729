.wrapper {
	position: fixed;
	z-index: 10000000;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.container {
	border-radius: 12px;
	background-color: white;
}

.closeButton {
	top: 13px;
	right: 20px;
	z-index: 100;
	cursor: pointer;
	position: absolute;
	stroke: #4b5563;

	padding: 4px;
	display: flex;
	outline: none;
	border-radius: 8px;
	align-items: center;
	background: white;
	border: 1px solid #e2e8f000;
	&:hover {
		background: #f8fafc;
	}
}

.modal {
	&__criteria {
		opacity: 1;
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
	}
}
