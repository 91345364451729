.paper {
	position: absolute;
	z-index: 9998 !important;
	max-height: unset !important;
}
.multiSitePaper {
	position: absolute;
	z-index: 9998 !important;
	overflow: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
		cursor: pointer;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 5px;
	}
}
