@import "../shared/shared-components";

.root {
	display: flex;
	flex-direction: column;
	h4 {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.table {
	width: 100%;
	margin-top: 2rem;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	background-color: white;
	th,
	td {
		padding: 1rem;
		text-transform: capitalize;
		.status {
			display: flex;
			justify-content: center;
		}
	}
}

.viewMoreButton {
	@extend .secondaryButton;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 12px;
	gap: 1rem;
	text-decoration: none;
}

.statusContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.status {
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	&.accepted {
		background-color: #00a47c;
	}
	&.sent,
	&.draft {
		background-color: #ffea8a;
	}
	&.refused {
		background-color: #fd5749;
	}
}
.category {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 50px;
}
