.main {
	height: 100%;
	.body {
		height: 80%;
	}
	.footer {
		height: 20%;
		align-items: self-end;
	}
}

.drawerContent {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 24px;
	color: #1e293b;
	font-weight: 500;
	line-height: 150%;
	font-style: normal;
	font-family: "Work Sans";
	letter-spacing: -0.5px;
}

.closeBtn {
	gap: 12px;
	display: flex;
	border-radius: 8px;
	background: #fff;
	align-items: center;

	color: #1e293b;
	font-weight: 500;
	line-height: 21px;
	font-size: 15.75px;
	font-style: normal;
	letter-spacing: -0.5px;
	font-family: "Work Sans";

	&:hover {
		background: #f8fafc;
	}
}

.header {
	top: 0;
	position: sticky;
	background-color: #fff;
	border-bottom: 1px solid #e2e8f0;
	.flex {
		display: flex;
		padding: 16px 24px;
		justify-content: space-between;
	}
}

.body {
	gap: 27px;
	padding: 20px;
	display: flex;
	flex-direction: column;

	.intro {
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		padding: 12px;
	}
}

.gridCols2 {
	gap: 24px;
	display: flex;
	grid-template-columns: 1fr;
	width: 100%;
}

.editMissionAvatars {
	width: 100%;
	.selectHeader {
		height: 50px;
		max-height: unset;
	}
}

.formContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	width: 100%;

	.item {
		width: 100%;

		.fieldLabel {
			display: flex;
			align-items: center;
			margin-bottom: 12px;
			gap: 8px;

			label {
				color: #334155;
				font-variant-numeric: lining-nums tabular-nums;
				font-family: "Work Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				margin-bottom: unset;
			}

			svg {
				width: 20px;
				height: 20px;
			}
			.asterisk {
				color: red;
				font-family: "Work Sans";
				font-size: 15.75px;
			}
		}
		.amountInput {
			height: 50px;
			margin-bottom: 0px;
			width: 100%;
			input {
				border-radius: 8px;
				border: 1px solid #e5e5e5;
				height: 40px;
			}
			span {
				font-size: 16px !important;
				font-family: "Work Sans";
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
			}
			:global {
				.currency-label {
					top: 10px !important;
				}
			}
		}
		.inputContainer {
			width: 100%;
			.datePickerRoot {
				width: 100%;
			}
		}

		.datePickerInput {
			padding: 17px 12.5px;
			border-radius: 8px;
			height: 40px;
		}

		.datePickerInputDisabled {
			border: 1px solid #f2f2f2;
			box-shadow: none;
			background-color: #fdfdfd;

			* {
				color: #b2b2b2;
			}

			path {
				stroke: #b2b2b2;
			}
		}

		.error {
			gap: 6px;
			display: flex;
			align-items: center;
			color: #dc2626;
			font-weight: 500;
			font-size: 12.75px;
			font-style: normal;
			font-family: "Work Sans";
		}
	}
}

.footer {
	gap: 16px;
	display: flex;
	margin-top: auto;
	padding: 16px 24px;

	.success,
	.cancel {
		gap: 8px;
		height: 40px;
		display: flex;
		padding: 10px 12px;
		border-radius: 8px;
		align-items: center;
		justify-content: center;

		font-size: 16px;
		font-weight: 500;
		line-height: 150%;
		font-style: normal;
		font-family: "Work Sans";

		&:focus-visible {
			outline: none !important;
		}
	}

	.cancel {
		color: #334155;
		border: 1px solid #e2e8f0;
		background-color: transparent;

		&:hover {
			background-color: #f9fafb;
		}
	}

	.success {
		color: #fff;
		border: 1px solid #00857a;
		background-color: #00857a;

		&:hover {
			background-color: #006a62;
		}
	}
}

.descriptionFooter {
	display: flex;
	justify-content: space-between;

	.descriptionLength {
		margin-left: auto;
		font-family: "Work Sans";
	}
}

.input {
	max-height: 40px !important;
	min-height: 40px !important;
}

.sellingPriceContainer {
	display: flex;
	align-items: center;
	gap: 12px;
}

.inputContainer {
	position: relative;
	&.fixedWidth {
		width: 43% !important;
	}
}
.currency {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 16px;

	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.infoCircle {
	margin-bottom: 6px;
	path {
		fill: #334155;
	}
}

.currencyLabelClassName {
	top: -4px !important;
}
