$notifOff: #e5e7eb;
$primaryColor: #00857a;
$textColor: #334155;

.notifications-toggle {
	display: flex;
	align-items: center;
	gap: 16px;
	label {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: $textColor;
		margin-bottom: 0px;
		cursor: pointer;
	}

	.react-toggle {
		&:hover:not(.react-toggle--disabled) .react-toggle-track,
		.react-toggle-track {
			background-color: $notifOff;
			width: 36px;
			height: 16px;
		}

		.react-toggle-thumb {
			top: -2px;
			left: -2px;
			width: 20px;
			height: 20px;
			border-color: $notifOff;
			background-color: white;
			box-shadow: none !important;
			border: 1px solid $notifOff;
		}

		&.react-toggle--checked:hover,
		&.react-toggle--checked {
			.react-toggle-track {
				background-color: $primaryColor;
			}

			.react-toggle-thumb {
				border-color: $primaryColor;
				left: 18px;
			}
		}
	}

	.react-toggle-track-check {
		color: white;
		height: 15px;
	}
}

.inline-toggle {
	border: 1px solid $notifOff;
	border-radius: 48px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 160px;
	height: min-content;
	cursor: pointer;

	.inline-toggle-item {
		height: 23px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 48px;
		padding: 12px;
	}
	.enabled {
		color: white;
		background: #00857a;
		transition: all 0.4s ease-in-out;
	}

	.disabled {
		background: transparent;
		color: #94a3b8;
	}
}

// global tooltip
.g-tool-dark {
	.rc-tooltip-inner {
		background-color: #1e293b !important;
		border-radius: 5px !important;
		padding: 8px 14px !important;
		box-shadow: none !important;
		.overlay-container {
			display: flex;
			flex-direction: column;
			gap: 9px;
			div {
				display: flex;
				flex-direction: column;
				gap: 6px;
				h6 {
					font-family: "Roboto";
					font-style: normal;
					line-height: 16px;
					font-weight: 700;
					font-size: 13px;
					color: white;
				}
				p {
					font-family: "Roboto";
					font-style: normal;
					line-height: 16px;
					padding-left: 8px;
					font-weight: 400;
					font-size: 13px;
					color: white;
				}
			}
		}
	}
	.rc-tooltip-arrow {
		background-color: #1e293b !important;
		z-index: 9999;
	}
}
