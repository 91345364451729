.container {
	display: flex;
	padding: var(--4, 16px);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--3, 12px);
	align-self: stretch;
	border-radius: var(--lg, 12px);
	border: 1px solid #f1f5f9;
	background: var(--gray-50, #f8fafc);
	position: relative;

	.top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-self: stretch;

		.info {
			display: flex;
			gap: 12px;
			width: 100%;
			background: #fff;
		}
		.infoContainer {
			display: flex;
			flex-direction: column;
			gap: 12px;
			width: 100%;
			padding: 12px;
			align-items: flex-start;
			border-radius: 16px;
			border: 1px solid #e2e8f0;
			background: #fff;
		}
	}

	.contact {
		color: var(--gray-600, #4b5563);
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: -0.5px;
	}

	.bottom {
		display: flex;
		align-items: flex-start;
		gap: var(--2, 8px);
		align-self: stretch;

		.item {
			display: flex;
			padding: var(--2, 8px) var(--3, 12px) var(--2, 8px) 10px;
			align-items: center;
			gap: var(--3, 12px);
			border-radius: var(--md, 8px);
			border: 1px solid var(--gray-200, #e2e8f0);
			background: var(--white, #fff);
			text-wrap: nowrap;
			flex-basis: max-content;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: -webkit-grab;

			&_a {
				padding: 6px;
				a {
					display: flex;
				}
			}

			span {
				text-decoration: none;
				color: var(--gray-600, #4b5563);
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 400;
				line-height: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			svg {
				flex: none;
			}
		}
	}

	.divider {
		height: 1px;
		align-self: stretch;
		background: #e2e8f0;
	}
}

.capitalizeText {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.contactContainer {
	display: flex;
	padding: 12px 12px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	border-radius: 16px;
	border: 1px solid #e2e8f0;
	background: #fff;
	width: 100%;
}
.infoManagerContainer {
	display: flex;
	gap: 12px;
	width: 100%;
	height: auto;
}
.avatar {
	border-radius: 12px;
	border: 1.5px solid #fff;
	cursor: pointer;
}

.infoData {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: 7px;
}
.companyInfoData {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 7px;
	gap: 6px;
}
.type {
	display: flex;
	height: 29px;
	padding: 4px 8px;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 400;
	line-height: 120%;
}

.name {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-weight: 500;
	line-height: 100%;
	cursor: pointer;
}
.flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
}
.companyText {
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.actionsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}
.functionTitle {
	display: flex;
	align-items: center;
	gap: 4px;
	color: #334155;
	font-variant-numeric: lining-nums tabular-nums;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.functionsContainer {
	display: flex;
	width: 20px;
	height: 20px;
	padding: 10px 15px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 9999px;
	background: #f1f5f9;
}
.name {
	color: #334155;
	font-family: "Work Sans";
	font-size: 18px;
	font-weight: 500;
	line-height: 100%;
	cursor: pointer;
}
.flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
}
