.titleForm {
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	color: #6e7191;
	margin-bottom: 0;
}
.saveBtn {
	padding: 0 34px;
	height: 50px;
	background: #fd6950;
	border-radius: 3px;
	font-family: "BasierCircle";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 22px;
	text-transform: uppercase;
	color: #ffffff;
}
button[disabled],
html input[disabled] {
	opacity: 0.5;
}
.formContainer {
	margin-bottom: 30px;
	display: flex;
	gap: 30px;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 80%;
}
.form {
	display: flex;
	gap: 30px;
	flex-direction: column;
	flex-wrap: wrap;
}
.loader_container {
	height: 216px;
	align-items: center;
	justify-content: center;
	display: flex;
	width: 200px;
}
