.container {
	width: 100%;
	.saveQueryConditionsFiledContent {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		.saveQueryInputField {
			display: flex;
			width: 349px;
			height: 39px;
			padding: 4px 8px;
			min-height: 0;
			align-items: center;
			gap: 8px;
			border-radius: 8px;
			border: 1px solid#e2e8f0;
			background: #fff;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			letter-spacing: -0.5px;
		}

		.saveQueryInputField::placeholder {
			color: #cbd5e1;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			letter-spacing: -0.5px;
		}
	}
}
.saveQueryConditionsFieldConatainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	.saveQueryConditionsFieldName {
		color: #334155;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: -0.5p;
	}
}
.saveQueryConditionsTitle {
	color: var(--text-dark, #334155);
	font-family: "Work Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	letter-spacing: -0.5px;
}
.divider {
	width: 100%;
	height: 0px;
	background: #e2e8f0;
}

.noDataContainer {
	display: flex;
	width: 747px;
	height: 457px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	.descriptionContainer {
		display: flex;
		width: 468px;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}
	.title {
		color: #334155;
		font-family: "Work Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 21px;
		letter-spacing: -0.5px;
	}
	.description {
		color: #64748b;
		text-align: center;
		font-variant-numeric: lining-nums tabular-nums;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
}
.count {
	display: flex;
	height: 26px;
	padding: 6px 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 15px;
	border: 1px solid #e2e8f0;
	opacity: 1;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #334155;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
}
.countSkeleton {
	border-radius: 15px;
}
