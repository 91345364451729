@import "modules/candidate/Profile/Variables/styles.scss";

.section {
	display: flex;
	margin-right: 9px;
	flex-wrap: wrap;
	justify-content: space-between;
	&:not(:last-child) {
		margin-bottom: 27px;
		padding-bottom: 27px;
		border-bottom: 1px solid $ghostWhite;
	}
	.row {
		display: flex;
		// margin-bottom: 8px;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		.column {
			display: flex;
			flex-direction: column;
			gap: 13px;
			max-width: 60px;
			.processSquare {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 4px 8px;
				background: $solitudeBlue;
				border-radius: 4px;
				width: 40px;
				height: 30px;
				margin: 0 10px;
				p {
					margin: 0;
					@include cardsTitle;
					color: $blackPearl;
				}
			}
			.title {
				@include profileContent;
				color: $blackPearl;
				text-transform: capitalize;
				margin: 0 10px;
			}
		}
	}
}
.emptyState {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	p {
		@include profileContent;
		color: $blackPearl;
		margin-left: 3px;
	}
}
