.evaluationWrapper {
	display: flex;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;
	padding: 20px;

	.leftSection,
	.rightSection {
		border-radius: 12px;
		border: 1px solid #f3f4f6;
		width: 100%;
		height: 100%;
		padding: 25px;
		background: #fff;
	}

	.leftSection {
		flex: 4;
	}

	.rightSection {
		flex: 2;
	}
}

.evaluationCandidate {
	background: #f8fafc;
	margin: 0px 15px;
}

.hr {
	width: 100%;
	color: #e2e8f0;
	margin: 12px 0px;
}

.search {
	width: 400px;

	input {
		width: 100%;
		padding: 0.75rem 2rem 0.75rem 1rem;
		flex-grow: 1;
		border-radius: 10px;
	}

	input:focus-within {
		box-shadow: 0px 0px 0px 1px #66b6af inset;
	}
}

.results_count {
	color: #737373;
	font-weight: 400;
	line-height: 1.125rem;
	padding: 0;
	font-family: "Work Sans";

	.total {
		display: flex;

		.greenText {
			color: #00857a;
			font-weight: 500;
		}
	}

	margin: 0.75rem 0 0.75rem 0.25rem;
}

.filtersContainer {
	display: flex;
	padding: 0;
	justify-content: space-between;

	.firstContainer,
	.secondContainer {
		display: flex;
		gap: 10px;
		align-items: center;
		align-items: center;

		.search {
			input {
				width: 100%;
				padding: 0.75rem 2rem 0.75rem 1rem;
				flex-grow: 1;
			}

			input:focus-within {
				box-shadow: 0px 0px 0px 1px #66b6af inset;
			}
		}

		.error {
			input:focus-within,
			input {
				box-shadow: 0px 0px 0px 1px red inset;
			}
		}
	}
}

.addBtn {
	display: flex;
	padding: 10px 16px;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #00857a;
	background: #fff;
	margin-left: 10px;

	&:hover {
		border: 1px solid #00857a;
		background: #e6f3f2;
	}

	.addText {
		color: #00857a;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		letter-spacing: -0.5px;
		width: max-content;
	}

	&:disabled {
		border: 1px solid #cbd5e1;
		background: #f1f5f9;

		.addText {
			color: #cbd5e1;
		}
	}
}

.countClassName {
	display: flex;
	padding: 2px 6px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 40px;
	background: #00857a;
	color: #fff;
}

.filter_btns_container_hidden {
	display: none;
}

.btnContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn {
	display: flex;
	height: 40px;
	padding: 12px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: #f8fafc;
}

.btnTitle {
	color: #334155;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.displayNone {
	display: none !important;
}

.list {
	display: flex;
	padding: 12px 12px 12px 12px;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	border-radius: 12px;
	background: #f8fafc;
}

.evaluationOverview {
	overflow: hidden;
	max-height: 40px;
	opacity: 0.6;
}
