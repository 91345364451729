.header {
	display: flex;
	padding: 10px;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid #f1f1f1;
}

.refresh {
	margin-left: 14px;
	padding: 0;
	.icon {
		path {
			stroke: #94a3b8;
		}
	}
	&.animated {
		animation-name: spin;
		animation-duration: 1500ms;
		animation-timing-function: linear;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.right,
.left {
	display: flex;
	align-items: center;
	justify-content: center;
}

.selectionMessage {
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	white-space: nowrap;
	margin-left: 30px;
}

.paginationDetails {
	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	margin-right: 15px;
}
.refresh {
	display: flex;
	align-items: center;
	background: transparent;
	color: #94a3b8;
	margin-left: 20px;
	&:hover {
		color: #64748b;
	}
}
.next,
.prev {
	display: flex;
	align-items: center;
	width: 24px;
	height: 24px;
	padding: unset;
	border: 0.75px solid #94a3b8;
	background: #fff;
	border-radius: 6px;
}
.next {
	margin-left: 6px;
	transform: rotate(180deg);
}

.icon {
	width: 20px;
	height: 20px;
	color: #94a3b8;
	&:hover {
		color: #64748b;
	}
}

.actionsContainer {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: 15px;
	padding-left: 15px;
	border-left: 1px solid #f1f1f1;
}
