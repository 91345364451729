.button {
	padding: 5px;
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: center;

	&:hover {
		border-radius: var(--sm, 6px);
		background: var(--bg-hover, #f8fafc);
	}

	&__disabled {
		opacity: 0.3;
	}
}
