@import "modules/SearchCandidate/TableVariables/fonts.scss";
$red-color: #dc2626;

.container {
	display: grid;
	grid-template-columns: repeat(2, auto) 1fr;
	grid-template-rows: repeat(3, auto);
	row-gap: 20px;
	padding: 23px;
}

.buttonItem,
.tabsItem {
	grid-column: 1 / 6;
}

.phoneContainer,
.mailContainer,
.nameContainer,
.functionContainer {
	display: flex;
	align-items: center;
	gap: 9px;
}

.profileContainer {
	margin-right: 43px;
}

.profileContainer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	row-gap: 4px;
}

.phoneContainer,
.mailContainer {
	align-self: end;
}

.avatar {
	margin-right: 18px;
}

.deleteItem {
	justify-self: end;
	align-self: center;
}

button.backButton {
	box-shadow: 0px 0px 0px 1px #d1d5db inset;
	padding: 10.5px 14px;
	border-radius: 8px;
	background-color: white;
}

button.backButton:hover {
	background-color: white;
}

.backButtonText {
	color: #525252;
}

.phone,
.email,
.function {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15.75px;
	line-height: 21px;

	/* identical to box height, or 133% */
	display: flex;
	align-items: flex-end;
	letter-spacing: -0.5px;

	/* gray/900 */
	color: #111827;
}

.name {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 23.0436px;
	line-height: 24px;

	/* identical to box height, or 104% */
	display: flex;
	align-items: flex-end;
	letter-spacing: -0.500078px;

	/* gray/800 */
	color: #1f2937;
}

.deleteButton {
	background-color: $red-color !important;
	box-shadow: 0px 0px 1px $red-color inset;
}

.trashIcon path {
	stroke: white;
}

.presentationSlider {
	width: 100%;
	height: 2px;
	margin-left: 3px;
	background-color: #e5e5e5;
}

.tabs {
	display: flex;
	align-items: center;
	gap: 25px;
	margin: 0;
	margin-top: 10px;
	padding: 0 3px;
	list-style: none;
}

.tab {
	all: unset;
	position: relative;
	text-align: center;
	display: block;
	padding: 10px 0;
	text-decoration: none;
	@include SmallParagraph;
	font-weight: 500;
	color: #737373;
	&.active {
		color: #262626;
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #00857a;
			bottom: -2px;
			left: 0;
		}
	}
	&:hover {
		color: #222;
		cursor: pointer;
	}

	.candidateCount {
		background-color: #1a9187;
		color: #ffffff;
		@include SmallParagraph;
		border-radius: 12px;
		padding: 2px 8px 2px 5px;
	}
}

.panel {
	margin-top: 25px;
}

.avatar {
	border-radius: 110px;
	height: 56px;
	margin-right: 10px;
}

.flex {
	display: flex;
	gap: 35px;
}

.linkedinLink {
	display: flex;
	align-items: center;
}
