.root {
	padding: 3px 15px;
	border-radius: 4px;
	font-family: BasierCircle, sans-serif;
	text-transform: capitalize;
}

.default {
	@extend .root;
	background-color: #e4e5e7;
	color: #202223;
}

.outlined {
	@extend .root;
	border: 1px solid #bdc1cc;
	color: #6d7175;
	font-weight: 600;
}

.primary {
	@extend .outlined;
	color: #fd5749;
	border-color: #fd5749;
}
