.root {
	position: relative;
}

.dropDownPaper {
	width: 350px;
}

.paper {
	position: absolute;
	left: 100%;
	margin-left: 10px;
	top: 0%;
	min-width: 168px;
	padding: 0.25rem 0px;
	z-index: 10;
	max-height: 190%;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
		border-radius: 99px;
	}
}
