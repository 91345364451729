@import "../../Variables/styles.scss";

.container {
	@include tabsPadding;
	display: grid;
	grid-gap: 2.5rem;
	grid-template-columns: 1fr 32rem;

	.title {
		@include tabsTitle;
		margin-left: 2px;
		padding-bottom: 0.6rem;
		color: $swamp;
	}
	.activeProcess {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		grid-column: 1;
	}
	.sidebar {
		display: flex;
		gap: 1.6rem;
		flex-direction: column;
		grid-row: 1 / span 3;

		.card {
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			background: $white;
			border-radius: 4px;
			padding: 1.6rem;
			.textGras {
				@include layoutItems;
				color: $swamp;
			}
			.description {
				@include profileContent;
				color: $raven;
			}
			.flex {
				display: flex;
				gap: 1rem;
				margin-top: 0.9rem;
			}
			.flexColumn {
				flex-direction: column;
			}
			.box {
				display: flex;
				flex-direction: column;
				gap: 2px;
				box-sizing: border-box;
				border-radius: 8px;
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
				flex-grow: 1;
				padding: 0.75rem 1.2rem;
				p {
					font-size: 24px;
					font-family: "basiercircle";
					line-height: 1.25;
					margin: 0;
				}
				span {
					@include profileContent;
				}
			}
			.active {
				background: $successPressed;
				border: 1px solid #aee9d1;
				p,
				span {
					color: $successText;
				}
			}
			.closed {
				background: $critical;
				border: 1px solid #fed3d1;
				p,
				span {
					color: $tomato;
				}
			}
			.info {
				background: $azure;
				border: 1px solid $azure;
				p,
				span {
					color: $atoll;
				}
			}
		}
	}
	.allVacancies {
		grid-column: 1;
		grid-row: 2;
		tr,
		thead {
			border-bottom: 1px $gris solid;
			height: 5rem;
		}
	}
}
.processesContainer {
	margin-top: 1.6rem;
	border-radius: 4px;
	@include profileContent;
	.listProcesses {
		min-height: max-content;
	}
	.filterBox {
		background: $white;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		padding: 10px;
	}
}

.paginationContainer {
	& > div {
		display: flex;
		align-items: center;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: #48494a;
	}
}

.emptyWrapper {
	width: 100%;
	display: flex;
	background-color: $white;
	border-radius: 4px;
	min-height: 10rem;
}

.showMore {
	background: none;
	border: none;
	&:hover {
		text-decoration: underline;
	}
}
