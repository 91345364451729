.container {
	padding: 0 16px;
}

.skeletonTitle {
	border-radius: 8px;
	margin-bottom: 16px;
}

.skeletonItem {
	border-radius: 8px;
	margin-bottom: 16px;
}
