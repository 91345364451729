.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 86px;
	font-family: "Work Sans";
}
.title {
	color: #334155;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: -0.5px;
	margin: 12.5px 0 8px 0;
}

.description {
	color: #64748b;
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	width: 505px;
	margin-bottom: 24px;
}
