.container {
	gap: 6px;
	width: 100%;
	display: flex;
	padding: 5px 12px;
	border-radius: 8px;
	background: #fff;
	align-items: center;
	border: 1px solid #039855;

	.content {
		font-size: 14px;
		font-weight: 500;
		color: #039855;
		line-height: 150%;
		text-align: center;
		font-style: normal;
		font-family: "Work Sans";
	}
}
