.summaryRecap {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.summaryChart {
	display: flex;
	gap: 8px;
	margin-bottom: 20px;
}

.summaryScoresRecap {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 0px 10px;
}

.summaryScoresRecapElement,
.summaryScoresRecapFirstElement {
	display: flex;
	width: 39%;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
}

.summaryScoresRecapFirstElement {
	width: 22%;
}

.score {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	gap: 10px;
	align-items: center;
}

.scoreStatus {
	color: #334155;
	font-family: "Work Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.scoreTotal {
	color: #334155;
	font-family: "Work Sans";
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -1px;
}

.divider {
	height: 35px;
	margin-left: 75px;
}
