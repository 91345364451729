.container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	border-top: 1px solid #e5e5e5;
	padding: 1rem;
	z-index: 10;
	background-color: white;
}

.button {
	font-size: 20px;
	padding: 7px 15px;

	img {
		height: 15px;
		width: 15px;
	}
}
