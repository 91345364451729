.content {
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	padding: 30px 40px;
	height: fit-content;
	width: 634px;
	span {
		display: block;
		height: fit-content;
	}
}

.title {
	margin-bottom: 20px;
}

.desc1,
.desc2 {
	margin-bottom: 10px;
}

.desc3 {
	margin-bottom: 20px;
}

.member_item {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	&:last-child {
		margin-bottom: 0;
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 10px;
}

.avatar {
	border-radius: 50%;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.policy {
	margin: 50px 0;
	display: flex;
	align-items: flex-start;
	gap: 16px;
	.text {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

.actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
}
