.container {
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	display: flex;
}

.dragHandler {
	padding: 12px;
	display: flex;
	align-items: center;
	border-inline-end: 1px solid var(--border-gray, #e2e8f0);
	cursor: grab;
}

.subContainer {
	flex-grow: 1;
}

.header {
	display: flex;
	padding: 4px 12px;
	align-items: center;
	background: #f8fafc;
	border-bottom: 1px solid var(--border-gray, #e2e8f0);
}

.title {
	flex-grow: 1;
	color: var(--gray-700, #334155);
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.5px;
}

.content {
	padding: 12px;
}

.addButton {
	all: unset;
	color: var(--text-dark, #334155);
	height: 36px;
	padding: 0 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	gap: var(--3, 12px);
	border-radius: var(--md, 8px);
	border: 1px solid var(--border-gray, #e2e8f0);
	background: var(--text-white, #fff);
	cursor: pointer;

	color: #394858;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: -0.5px;

	&:hover {
		background: var(--bg-hover, #f8fafc);
	}
}

.contentError {
	margin-bottom: 16px;
}

.shadow {
	box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
		0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	border-radius: 12px;
}
