.cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	text-align: left;
	font-family: "Work Sans", sans-serif;
	line-height: 1.125rem;
	font-weight: 500;
	text-align: right;
}
