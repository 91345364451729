.root {
	gap: 10px;
}

.title {
	color: #737373;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: "Work Sans", sans-serif;
	cursor: pointer;
}

.tabsHeader {
	margin-bottom: 1.5rem;
}

.tabsHeader > div > :not(:first-child) {
	margin-left: 1rem;
}

.tabsHeader > div > :last-child {
	margin-left: 0px;
}

.tabsHeader > div {
	border-bottom: 2px solid #f5f5f5;
	padding: 0.75rem 0;
}

.tabsHeader > div > div:last-child {
	background-color: #00857a;
	height: 2px;
	margin-top: 44px;
	border-radius: 1px;
	transition-timing-function: ease-in-out;
}

.title:hover,
.selected {
	color: #171717 !important;
	font-weight: 500;
}
