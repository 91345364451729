.container {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.row {
	width: 100%;
	display: flex;
	align-items: flex-start;
	align-self: stretch;
	gap: 32px;
}

.field {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.label {
	color: #6b7280;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;

	display: flex;
	align-items: center;
	gap: 6px;
}

.datePickerRoot {
	max-width: 318px;
}
.datePickerInput {
	padding: 12px 8px 12px 16px;
	height: 40px;
	color: #94a3b8;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-radius: 8px;
	border: 1px solid #e2e8f0;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.dropDownInput {
	min-width: 157px;
	height: 40px;
	border-radius: 8px;
	color: #64748b;
	font-family: "Work Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	background: #fff;
}

.currency {
	border-radius: 8px;
	margin-bottom: 0px !important;
	margin-right: 0px;
	height: 40px;
}

.currencySuffix {
	height: 20px;
	top: -4px !important;
	color: #334155 !important;
	font-family: "Work Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.currency input {
	border-radius: 8px !important;
	margin-right: 0px !important;
	padding: 13px !important;
	border: 1px solid #e5e5e5 !important;
	line-height: 16px !important;
}

.comboxInput {
	max-width: 148px;
	height: 40px;
}

.locationWrapper {
	display: flex;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
	border-radius: 8px;
	background: #f8fafc;
}

.locationPicker {
	padding: 16px;
	border-radius: 6px;
	background: #f8fafc;
}

.error {
	padding-top: 10px;
}
