$font-family: "Work Sans";

.container {
	background-color: #f8fafc;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 70px);
	margin-bottom: -60px;
}

.content {
	background-color: #fff;
	border-radius: 12px;
	border: 1px solid #e2e8f0;
	padding: 30px 40px;
	height: fit-content;
	width: 634px;
	font-family: "Work Sans";
}

.title {
	color: #334155;
	font-size: 24px;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.5px;
	margin-bottom: 10px;
}

.description {
	color: #64748b;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 30px;
}

.checkContainer {
	margin: 50px 0;
	display: flex;
	.check {
		width: fit-content;
		margin-right: 16px;
	}
	.text {
		color: #64748b;
		font-size: 12px;
		font-weight: 400;
		line-height: 150%;
		display: block;
		width: 516px;
		a {
			line-height: 150%;
			font-size: 12px;
			color: #00857a;
			font-weight: 500;
			text-decoration: none;
		}
	}
}
.policyError {
	margin-top: -40px;
	margin-bottom: 40px;
}
.actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.btn {
	height: 40px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: 8px;
	background-color: #00857a;
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
	font-family: $font-family;
}
.discard {
	background-color: #fff;
	color: #334155;
	margin-right: 10px;
	.container {
		background-color: #f8fafc;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: calc(100vh - 70px);
		margin-bottom: -60px;
		font-family: "Work Sans";
	}

	.content {
		background-color: #fff;
		border-radius: 12px;
		border: 1px solid #e2e8f0;
		padding: 30px 40px;
		height: fit-content;
		width: 634px;
	}

	.title {
		color: #334155;
		font-size: 24px;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.5px;
		margin-bottom: 10px;
	}

	.description {
		color: #64748b;
		font-size: 14px;
		font-weight: 400;
		line-height: 150%;
		margin-bottom: 30px;
	}

	.checkContainer {
		margin: 50px 0;
		display: flex;
		.check {
			width: fit-content;
			margin-right: 16px;
		}
		.text {
			color: #64748b;
			font-size: 12px;
			font-weight: 400;
			line-height: 150%;
			display: block;
			width: 516px;
			a {
				line-height: 150%;
				font-size: 12px;
				color: #00857a;
				font-weight: 500;
				text-decoration: none;
			}
		}
	}
	.policyError {
		margin-top: -40px;
		margin-bottom: 40px;
	}
	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.btn {
		font-family: "Work Sans";
		height: 40px;
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		border-radius: 8px;
		background-color: #00857a;
		font-size: 16px;
		font-weight: 500;
		line-height: 150%;
	}
	.discard {
		background-color: #fff;
		color: #334155;
		margin-right: 10px;
	}
}

.colRole {
	display: none;
}
.colClose {
	display: none;
}
.colPermission {
	margin-left: auto;
	margin-right: 0 !important;
}
