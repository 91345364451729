.confirmButtonClassName {
	background-color: #d92d20;
	box-shadow: unset;
	&:hover {
		background-color: #d92d20;
	}
	svg {
		path {
			stroke: #fff;
		}
	}
}

.cancelButton {
	box-shadow: unset;
	border: 1px solid #e2e8f0;
	background: #fff;
	color: #334155;
}

.sendIcon {
	transform: rotate(45deg);
}
