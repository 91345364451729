.dateContainer {
	width: 100%;
}

.cascaderValue {
	width: 100%;
	border: 1px solid #e2e2e2;
	border-radius: 3px;
	padding: 7px 17px;
	color: #9d9d9d;
	cursor: pointer;
	outline: none;
	span {
		color: #626b74;
	}
}
.label {
	font-family: "basiercircle";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6d7175;
	display: flex;
	align-items: center;
	gap: 4px;
	text-transform: capitalize;
}

.errorMessage {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #ed2e7e;
}

.redBorder {
	border: 1px solid red;
}

div[class^="rc-cascader-menus"] {
	z-index: 9999;
}

.red {
	color: red;
}

label.dateLabel {
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6d7175;
	display: flex;
	align-items: center;
	gap: 4px;
	text-transform: capitalize;
}

.select div,
.input,
.datePicker {
	width: 100% !important;
}

.label {
	font-weight: 400;
	line-height: 20px;
	color: #6d7175;
	display: flex;
	align-items: center;
	gap: 4px;
	margin-bottom: -8px;
}

.asterisk {
	color: red;
}
