.footer {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 10px;

	gap: 10px;
}

.conversation {
	height: 100%;
	overflow: auto;
	max-height: calc(100vh - 284px);
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		margin-block: 3px;
		border-radius: 99px;
		background: #f4f4f433;
	}

	&::-webkit-scrollbar-thumb {
		width: 100%;
		background: #cacaca;
		border-radius: 99px;
	}
}
.cta {
	padding: 8px 11px;
}
.replyIcon,
.forwardIcon {
	path {
		stroke: #00857a;
	}
}

.replyIcon {
	transform: rotateX(180deg);
}
.forwardIcon {
	transform: rotate(180deg);
}

.message {
	display: flex;
	max-height: 571px;
}
