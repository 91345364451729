.handle {
  position: absolute;
  transform: translate(calc(-50% + 1px), calc(-50% + 1px));
  cursor: pointer;
  height: 16px;
  width: 16px;
  border: 2px solid #58626b;
  border-radius: 50px;
  background-color: #fff;
}
.handle-middle {
  position: absolute;
  height: 8px;
  width: 8px;
  transform: translate(-50%, -50%);
  background-color: #58626b;
  top: 50%;
  left: 50%;
  border-radius: 50px;
}
