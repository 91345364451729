.emptyBlock {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	.title {
		margin-top: 16px;
		color: var(--gray-900, #101828);
		text-align: center;
		font-family: BasierCircle;
		font-size: 36px;
		font-style: normal;
		font-weight: 600;
		line-height: 44px; /* 122.222% */
		letter-spacing: -0.72px;
	}
	.description {
		color: var(--text-dark, #334155);
		text-align: center;
		font-family: "Work Sans";
		font-size: 17.5px;
		font-style: normal;
		font-weight: 400;
		line-height: 24.5px; /* 140% */
		letter-spacing: -0.5px;
	}
	.button {
		margin-top: 16px;
		display: flex;
		padding: var(--3, 12px) var(--4, 16px);
		justify-content: center;
		align-items: center;
		gap: var(--3, 12px);
		align-self: stretch;
		border-radius: var(--md, 8px);
		background: #00857a;
		color: #fff;
		font-family: "Work Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 23.8px; /* 148.75% */
		text-decoration: none;
		&:hover {
			background-color: #006a62;
		}
	}
}
