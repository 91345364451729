.container {
	margin-block: 6px;
	background-color: white;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: max-content 1fr max-content;
	align-items: flex-start;
	border: 1px solid #e2e8f0;
	border-radius: var(--md, 8px);
	width: 100%;
	height: 215px;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
		0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.head {
	padding: 8px 12px 11px 12px;

	width: 100%;
	display: grid;
	grid-template-columns: 38px 1fr;
	grid-template-rows: 21px 21px;
	row-gap: 8px;
	column-gap: 8px;
	border-bottom: 1px solid #e2e8f0;
}

.avatar {
	grid-row: 1 / 3;
	grid-column: 1 / 2;
}

.body {
	width: 100%;
	height: 100%;
	padding: 8px 12px;
	grid-template-rows: 38px 38px;
	row-gap: 12px;
	grid-template-columns: 1fr;
	padding: 8px 12px;
}

.footer {
	width: 100%;
	border-top: 1px solid #e2e8f0;
	padding: 8px 12px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 1fr max-content max-content max-content;
	grid-template-rows: 26px;
}

.buttonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px;
}

.emptyButton {
	width: 42px;
	height: 26px;
}
